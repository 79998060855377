<template>
    <layout :active="70">
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-xl-8 col-lg-12 col-md-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">Reportes</h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="buy-sell-widget">
                                                <div class="tabs tab-content tab-content-default">
                                                    <div class="tab-content">
                                                        <div role="tabpanel" class="tab-pane active">
                                                            <div class="buy-sell-widget px-2">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">Selecciona el reporte</label>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <label class="input-group-text">
                                                                                <i class="fa fa-chart-line"></i>
                                                                            </label>
                                                                        </div>
                                                                        <select name="currency" class="form-control" v-model="reportSelected">
                                                                            <option value="">-- Seleccione --</option>
                                                                            <option value="orders" v-if="Access('reportes-reportes-ordenes')">Listado de ordenes</option>
                                                                            <option value="products" v-if="Access('reportes-reportes-productos')">Productos en sistema</option>
                                                                            <!-- TODO: AGREGAR EL MUESTREO DEL REPORTE POR ACCESOS -->
                                                                            <!-- v-if="Access('reportes-reportes-ine')" -->
                                                                            <option value="ine">Diagnostico Ine</option>
                                                                            <option value="ventas">Ventas</option>
                                                                            <option value="citas" v-if="Access('reportes-reportes-citas')">Listado de citas</option>
                                                                            <option value="personal" v-if="Access('reportes-reportes-personal')">Usuarios adicionales o personal</option>
                                                                            <option value="suscripciones" v-if="Access('reportes-reportes-suscripciones')">Suscripciones</option>
                                                                            <option value="cotizaciones" v-if="Access('reportes-reportes-suscripciones')">Cotizaciones</option>
                                                                            <option value="smsEnviados" v-if="Access('reportes-reportes-smsEnviados')">SMS enviados</option>
                                                                            <option value="ventasPorProducto">Ventas por producto</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="input-group mb-2 row w-100" v-if="reportSelected === 'orders'">
                                                                            <div class="col-12 col-md-6 mb-3 mb-md-2">
                                                                                <multiselect v-model="orderStatuses"
                                                                                            :options="orderStatusOptions"
                                                                                            track-by="id" label="name"
                                                                                            :close-on-select="true"
                                                                                            :clear-on-select="false"
                                                                                            :preserve-search="true"
                                                                                            placeholder="Selecciona estados"
                                                                                            :multiple="true"
                                                                                            selectLabel="Select "
                                                                                            removeLabel="Remove ">
                                                                                </multiselect>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 mb-3 mb-md-2">
                                                                                <multiselect v-model="orderDateType"
                                                                                            :options="dateTypeOptions"
                                                                                            track-by="id" label="name"
                                                                                            :close-on-select="true"
                                                                                            :clear-on-select="false"
                                                                                            :preserve-search="true"
                                                                                            placeholder="Tipo de fecha"
                                                                                            :multiple="false"
                                                                                            selectLabel="Select "
                                                                                            deselectLabel="Remove ">
                                                                                </multiselect>
                                                                            </div>
                                                                            <small class="col-12 text-primary d-block mb-3">
                                                                             Si no selecciona ningún filtro de estado, se incluirán órdenes con todos los estados. Seleccione el tipo de fecha para el reporte.
                                                                            </small>
                                                                    </div>
                                                                    <div class="input-group mb-3" v-if="reportSelected === 'ventasPorProducto'">
                                                                        <multiselect v-model="selectedProduct"
                                                                                     :options="productSearchResults"
                                                                                     track-by="id"
                                                                                     label="nombre"
                                                                                     :close-on-select="true"
                                                                                     :clear-on-select="true"
                                                                                     :preserve-search="true"
                                                                                     placeholder="Búsqueda de productos"
                                                                                     :multiple="false"
                                                                                     :internal-search="false"
                                                                                     @search-change="searchProducts"
                                                                                     selectLabel=""
                                                                                     selectedLabel=""
                                                                                     deselectLabel="">
                                                                            <template slot="noOptions">
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">Inicie su búsqueda</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect>
                                                                    </div>
                                                                    <div class="input-group mb-3" v-if="reportSelected === 'cotizaciones'">
                                                                        <multiselect v-model="selectedProduct"
                                                                                     :options="productSearchResults"
                                                                                     track-by="id"
                                                                                     label="nombre"
                                                                                     :close-on-select="true"
                                                                                     :clear-on-select="true"
                                                                                     :preserve-search="false"
                                                                                     placeholder="Búsqueda de productos"
                                                                                     :multiple="false"
                                                                                     :internal-search="false"
                                                                                     @search-change="searchProducts"
                                                                                     selectLabel=""
                                                                                     selectedLabel=""
                                                                                     deselectLabel="">
                                                                            <template slot="noOptions">
                                                                                <div class="option__desc">
                                                                                    <span class="option__title">Inicie su búsqueda</span>
                                                                                </div>
                                                                            </template>
                                                                        </multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group" v-if="validReports.includes(reportSelected)">
                                                                    <div class="row date-picker-container">
                                                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                                            <label>Fecha Desde</label>
                                                                            <date-picker v-model="dateRange.start"/>
                                                                        </div>
                                                                        <div class="col-12 col-md-6">
                                                                            <label>Fecha Hasta</label>
                                                                            <date-picker v-model="dateRange.end"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<p class="p-4"> Note: Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi cupiditate suscipit explicabo voluptas eos in tenetur error temporibus dolorum. Nulla! </p>-->
                                </div>
                                <div class="col-xl-4 col-lg-12 col-md-12">
                                    <div class="card">
                                        <div class="card-body text-center">
                                            <h6>Seleccione el reporte y las fechas para su generación</h6>
                                            <hr>
                                            <div class="text-muted">
                                                Para descargar un reporte, debe permitir las ventanas emergentes en su navegador
                                            </div>
                                            <div class="mt-3">
                                                <button @click="generateReport" class="btn btn-success btn-block mt-2"><i class="fas fa-eye mr-2"></i>Ver reporte</button>
                                            </div>
                                            <div class="mt-1">
                                                <button @click="generateReport(1)" class="btn btn-primary btn-block mt-2"><i class="fas fa-download mr-2"></i>Descargar reporte</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" v-if="results">
                        <div class="card-body text-center">
                            <div class="reportResult">
                                <template v-if="Array.isArray(results) && results.length > 0">
                                    <table class="table table-striped">
                                        <tr v-for="item in results" :key="item">
                                            <td v-for="value in item" :key="value">
                                                {{value}}
                                            </td>
                                        </tr>
                                    </table>
                                </template>
                                <template v-else>
                                    <div class="alert alert-info">
                                        <i class="fas fa-info-circle mr-2"></i>
                                        No se encontraron resultados para los parámetros seleccionados
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import {store} from 'src/store'
import DataValidation from "src/core/DataValidation";
import {DatePicker} from 'v-calendar';
import Multiselect from 'vue-multiselect'
import dayjs from "dayjs";


export default {
    components: {
        Layout,
        DatePicker,
        Multiselect
    },
    data() {
        return {
            dateRange: {
                start: new Date(),
                end: new Date(),
            },
            reportSelected: '',
            validReports: [
                'orders',
                'citas',
                'suscripciones',
                'cotizaciones',
                'smsEnviados',
                'ine',
                'ventas',
                'ventasPorProducto', // Add new report type
            ],
            results: false,
            orderStatuses: [],
            orderStatusOptions: [
                { id: 'creada', name: 'Creada' },
                { id: 'en_progreso', name: 'En progreso' },
                { id: 'finalizada', name: 'Finalizada' },
                { id: 'cancelada', name: 'Cancelada' },
            ],
            selectedProduct: null,
            productSearchResults: [{
                nombre: 'Inicie su búsqueda...'
            }],
            isLoading: false,
            dateTypeOptions: [
                { id: 'creation', name: 'Fecha de creación' },
                { id: 'payment', name: 'Fecha de pago' },
            ],
            orderDateType: null,
        };
    },
    watch: {
        reportSelected(val) {
            this.results = false;
            // Eliminar esta parte ya que no queremos cargar todos los productos
            /*if (val === 'ventasPorProducto') {
                this.loadProducts();
            }*/
        }
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted: function () {

    },
    methods: {
        // Eliminar el método loadProducts() ya que no lo necesitamos
        searchProducts(query) {
            const self = this;
            self.isLoading = true;
            
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/products/search/' + query, {},
                    function (res) {
                        self.productSearchResults = res.data;
                        self.isLoading = false;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.isLoading = false;
                    });
            }
        },
        generateReport(download) {
            const self = this;
            if (!download) download = false;

            if (Tools.isEmptyString(self.reportSelected)) {
                API.showErrorNotify('Seleccione un reporte');
                return false;
            }

            if (self.reportSelected === 'ventasPorProducto' && !self.selectedProduct) {
                API.showErrorNotify('Seleccione un producto');
                return false;
            }

            // Formatear las fechas para enviar solo la fecha sin hora
            const formatDate = (date) => {
                return dayjs(date).format('YYYY-MM-DD');
            };

            API.send('POST', 'reports/download/' + self.reportSelected, {
                    download: (parseInt(download) === 1),
                    dateFrom: formatDate(self.dateRange.start),
                    dateTo: formatDate(self.dateRange.end),
                    orderStatuses: self.reportSelected === 'orders' ? self.orderStatuses.map(status => status.id) : [],
                    productId: self.selectedProduct ? self.selectedProduct.id : null,
                    orderDateType: self.orderDateType ? self.orderDateType.id : 'creation',
                },
                function (response) {
                    if (typeof response.data !== 'undefined' && typeof response.data.resultFile !== 'undefined') {
                        window.open(response.data.resultFile);
                    }
                    if (typeof response.data !== 'undefined' && typeof response.data.report !== 'undefined') {
                        self.results = response.data.report;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        }
    }
};
</script>

<style>
.multiselect {
    min-height: 40px;
}

.multiselect__tags {
    min-height: 40px;
    padding: 8px 40px 0 8px;
}

.multiselect__select {
    height: 40px;
}

.multiselect__input, .multiselect__single {
    margin-top: 2px;
}

@media (max-width: 767px) {
    .date-picker-container {
        margin: 0;
    }
    
    .date-picker-container > div {
        padding: 0;
    }

    /* Ajustes para el calendario en móvil */
    .vc-container {
        width: 100% !important;
    }
}
</style>
