import Clients from "./views/Clients";
import ClientForm from "./views/ClientForm.vue";
import ClientsMassive from "./views/ClientsFormMassive";
import AdmisionPacientes from "./views/AdmisionPacientes.vue";
//
export default [
    {
        path: "/clients",
        component: Clients,
    },
    {
        path: "/client/:id",
        component: ClientForm,
    },
    {
        path: "/clients/add/massive",
        component: ClientsMassive,
    },
    {
        path: "/clients/admision",
        component: AdmisionPacientes,
    }
];
