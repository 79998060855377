<template>
    <setting-layout :active="31">
        <div class="card ordenesListado">
            <div class="card-header">
                <h4 class="card-title">Ordenes</h4>
                <div>
                    <router-link to="/order/creation" class="btn btn-primary btn-sm px-3 mr-3 mb-1">
                        <i class="fa fa-plus-circle"></i> Nueva
                    </router-link>
                    <button class="btn btn-primary  btn-sm mb-1 px-3" @click="loadItems">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
            <div class="card-body order-container">
                <div class="order-filters">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-group pb-sm-2">
                                <input id="filter-input" type="text" placeholder="Filtrar por: código | nombres | nit | teléfono | correo" v-model="filter" class="form-control" @keyup="debouncedSearch">
                                <div class="input-group-append">
                                <span id="basic-addon2" class="input-group-text">
                                    <i class="fa fa-search"></i>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <nav aria-label="navigation" class="p-2">
                                <ul class="pagination">
                                    <li class="page-item" v-for="item in pagination" :key="'paginate_' + parseFloat(item)">
                                        <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                        <div v-else class="page-link">{{ item }}</div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="order-scroll">
                    <div v-if="isLoading" class="status-message">
                        <div class="loading-content">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Cargando...</span>
                            </div>
                            <p>Cargando órdenes...</p>
                        </div>
                    </div>
                    <div v-else-if="!orderList || Object.keys(orderList).length === 0" class="status-message">
                        <div class="loading-content">
                            <i class="fas fa-exclamation-circle"></i>
                            <p>No se encontraron órdenes</p>
                        </div>
                    </div>
                    <div v-else class="orderItem" v-for="item in orderList" :key="item.id" @click="goToOrder(item.id)">
                        <div class="row">
                            <div class="col-12 col-sm-8">
                                <div class="orderStatusColor" :class="{
                                    'warning-thumb': (!item.pagado && (item.estado === 'creada' || item.estado === 'en_progreso')),
                                    'danger-thumb': item.estado === 'cancelada',
                                    'success-thumb': (item.pagado && (item.estado === 'creada' || item.estado === 'en_progreso')),
                                    'primary-thumb': item.estado === 'finalizada'
                                }"></div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <i class="mdi mdi-file-document mr-2"></i>
                                            <span>Codigo de orden: {{ item.codigoOrden }} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <i class="mdi mdi-account-circle mr-2"></i>
                                            <span>
                                                {{ capitalizeText(item.clientName || 'Cliente sin nombre') }}
                                            </span>
                                            <span class="ml-2">
                                                ({{ (item.taxCode !== '' && item.taxCode !== '0') ? item.taxCode : 'CF' }})
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <i class="mdi mdi-email-variant mr-2"></i>
                                            <span>
                                                {{ (item.correoContacto !== '') ? item.correoContacto : 'Sin correo' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="d-flex">
                                            <i class="mdi mdi-phone mr-2"></i>
                                            <span>
                                                {{ (item.telefonoContacto !== '') ? item.telefonoContacto : 'Sin teléfono' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="Object.keys(item.reservas).length > 0" class="order-actions">
                                    <button type="button" v-tooltip="'Ver detalles de reserva'" class="btn-action" @click.stop="toggleAgenda(item.id)" :class="{ 'active': activeAgenda === item.id }">
                                        <i class="mdi mdi-calendar-clock"></i>
                                        <span class="badge badge-light ml-1">{{ Object.keys(item.reservas).length }}</span>
                                    </button>
                                    <button v-if="!item.montoPagado || parseFloat(item.montoPagado) === 0" 
                                            class="btn-action" 
                                            @click.stop="copyLink(item.tokenUrl)" 
                                            v-tooltip="'Copiar enlace de pago'">
                                        <i class="mdi mdi-link-variant"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="col-12 col-sm-4">
                                <div class="text-right">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="orderActions">
                                                <div class="m-0" :class="{
                                                    'text-warning': (!item.pagado && (item.estado === 'creada' || item.estado === 'en_progreso')),
                                                    'text-danger': item.estado === 'cancelada',
                                                    'text-success': (item.pagado && (item.estado === 'creada' || item.estado === 'en_progreso')),
                                                    'text-primary': item.estado === 'finalizada'
                                                }">
                                                    <div v-if="item.estado === 'cancelada'" class="order-status">
                                                        Cancelada
                                                    </div>
                                                    <div v-else-if="item.estado === 'finalizada'" class="order-status">
                                                        Finalizada
                                                    </div>
                                                    <div v-else-if="item.estado === 'en_progreso'" class="order-status">
                                                        <span v-if="item.pagado">En progreso (Pagada)</span>
                                                        <span v-else>En progreso (Pendiente de pago)</span>
                                                    </div>
                                                    <div v-else class="order-status">
                                                        <span v-if="item.pagado">Creada (Pagada)</span>
                                                        <span v-else>Creada (Pendiente de pago)</span>
                                                    </div>
                                                </div>
                                                <div class="text-dark-gray">
                                                    Creada el <span>{{ dayjs(item.createdAt).format('DD-MM-YYYY H:mm') }}</span>
                                                </div>
                                                <div>
                                                    Total {{ Numbro((item.monto) ? item.monto : 0).formatCurrency() }}
                                                </div>
                                                <div>
                                                    <span :class="{'text-warning': item.descuento > 0, '': item.descuento === 0}">
                                                        Descuento {{ Numbro((item.descuento) ? item.descuento*-1 : 0).formatCurrency() }}
                                                    </span>
                                                </div>
                                                <div v-if="item.ordenIdParent > 0" class="text-success">
                                                    Pagado desde {{whiteLabelInfo.domain}}
                                                </div>
                                                <div>
                                                    <div :class="{'text-success': item.montoPagado > 0, 'text-danger': item.montoPagado === 0 }">
                                                        Pagado {{ Numbro((item.montoPagado) ? item.montoPagado : 0).formatCurrency() }}
                                                    </div>
                                                </div>
                                                <div v-if="item.fcUID">
                                                    <span v-if="!item.acUID">
                                                        <i class="fas fa-check-circle-o text-success" style="font-size: 1em"></i> Factura emitida
                                                    </span>
                                                    <span v-else>
                                                        <i class="fas fa-check-circle-o text-danger" style="font-size: 1em"></i> Factura anulada
                                                    </span>
                                                </div>
                                                <div v-if="(item.reconsultaOrdenRef !== null && item.reconsultaOrdenRef > 0)" class="">
                                                    <span class="badge badge-primary d-inline-flex align-items-center">
                                                        <i class="mdi mdi-refresh me-1"></i> Reconsulta
                                                    </span>
                                                </div>
                                                <div v-if="(item.reconsulta && item.reconsulta.length > 0)" class="">
                                                    <span class="badge badge-primary d-inline-flex align-items-center p-1">
                                                        <i class="mdi mdi-refresh-circle me-1"></i> Tiene reconsultas
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <transition name="fade">
                            <div v-if="Object.keys(item.reservas).length > 0 && activeAgenda === item.id" class="agenda-content">
                                <div v-for="reserva in item.reservas" :key="reserva.id" class="agenda-item">
                                    <div class="agenda-header">
                                        <div class="d-flex align-items-center justify-content-between w-100">
                                            <div class="d-flex align-items-center">
                                                <span class="agenda-date">
                                                    <i class="mdi mdi-calendar-clock"></i>
                                                    {{ dayjs(reserva.fechaReservada).format('DD-MM-YYYY') }}
                                                </span>
                                                <span class="agenda-time">
                                                    <i class="mdi mdi-clock-outline"></i>
                                                    {{ reserva.horaInicio }} - {{ reserva.horaFin }}
                                                </span>
                                                <span class="agenda-status" :class="{'status-confirmada': reserva.confirmado === 1, 'status-pendiente': reserva.confirmado === 0}">
                                                    {{ reserva.confirmado === 1 ? 'Confirmado' : 'Sin confirmar' }}
                                                </span>
                                            </div>
                                            <button class="btn btn-outline-primary btn-xs" @click.stop="goToFicha(reserva.id)" v-tooltip="'Ver ficha médica'">
                                                <i class="mdi mdi-file-document-outline"></i>
                                                <span>Ver ficha</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="agenda-body">
                                        <div class="row g-0">
                                            <div class="col-12 col-md-6">
                                                <div class="agenda-info-group">
                                                    <div class="agenda-info-label">Cliente:</div>
                                                    <div class="agenda-info-value" v-if="reserva.cliente">
                                                        <i class="mdi mdi-account"></i>
                                                        {{ capitalizeText(reserva.cliente.nombres + ' ' + reserva.cliente.apellidos) }}
                                                    </div>
                                                    <div class="agenda-info-value text-muted" v-else>
                                                        <i class="mdi mdi-account-question"></i>
                                                        Sin asignar
                                                    </div>
                                                </div>
                                                <div class="agenda-info-group">
                                                    <div class="agenda-info-label">Personal:</div>
                                                    <div class="agenda-info-value">
                                                        {{ reserva.personal.nombres && reserva.personal.apellidos || 'Sin notas adicionales' }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="agenda-info-group">
                                                    <div class="agenda-info-label">Servicio:</div>
                                                    <div class="agenda-info-value">
                                                        {{ reserva.producto.nombre || 'No especificado' }}
                                                    </div>
                                                </div>
                                                <div class="agenda-info-group">
                                                    <div class="agenda-info-label">Locación:</div>
                                                    <div class="agenda-info-value">
                                                        <i class="mdi mdi-card-account-details-outline"></i>
                                                        {{ reserva.locacion.nombre || 'Sin locación asiganada' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {mapGetters} from "vuex";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            Tools: Tools,
            orderList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filter: '',
            searchTerm: 0,
            filteredByReserva: false,
            activeAgenda: null,
            isLoading: false,
            searchTimeout: null,
        };
    },
    mounted() {
        this.loadItems();
    },
    watch: {
        // Quitamos el watcher de filter ya que ahora usaremos el método de debounce
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    methods: {
        Numbro,
        dayjs,
        debouncedSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = setTimeout(() => {
                // Permitir caracteres alfanuméricos, @, puntos, guiones y espacios
                // Esto cubre correos electrónicos, teléfonos, nombres, etc.
                const cleanedFilter = this.filter.replace(/[^\w\s@.\-_]/gi, '').trim();
                
                // Actualizamos el filtro con el valor limpio
                this.filter = cleanedFilter;
                
                if (this.filter.length >= 1) {
                    this.pageSelected = 1;
                    this.limit = 20;
                } else {
                    this.filter = '';
                    this.limit = 10;
                }

                this.loadItems();
            }, 400); // 400ms de debounce
        },
        loadItems() {
            const self = this;
            self.isLoading = true;
            
            let searchTermForUrl;
            if(self.filter.length === 0){
                searchTermForUrl = 0;
            }
            else{
                // Codificar el término de búsqueda para la URL
                searchTermForUrl = encodeURIComponent(self.filter);
            }
            
            API.send('GET', 'orders/all-2/1/'+self.pageSelected+'/'+self.limit+'/'+searchTermForUrl, {},
            function (data) {
                if (data.status) {
                    self.orderList = data.data.ordenes;
                    self.pagination = data.data.paginas;
                }
                self.isLoading = false;
            },
            function (data) {
                API.showErrorAlert(data.msg);
                self.isLoading = false;
            })
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue, 'Enlace de pago copiado al portapapeles');
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2)
            return val.toString()
        },
        notEmptyObject(someObject) {
            if (someObject) {
                return Object.keys(someObject).length
            }
            else {
                return false;
            }
        },
        capitalizeText(text) {
            if (!text) return '';
            return text.toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        },
        toggleAgenda(id) {
            this.activeAgenda = this.activeAgenda === id ? null : id;
        },
        goToOrder(id) {
            this.$router.push('/order/view/' + id);
        },
        goToFicha(reservaId) {
            this.$router.push(`/medic/ficha/${reservaId}`);
        },
        formatDate(dateString) {
            const date = new Date(dateString);

            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
    },
};
</script>

<style scoped>
.warning-thumb {
    background: #ffb007;
    height: 50px;
    width: 5px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.danger-thumb {
    background: #e6394a;
    height: 50px;
    width: 5px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.success-thumb {
    background: #28a745;
    height: 50px;
    width: 5px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.primary-thumb {
    background: var(--primary-color);
    height: 50px;
    width: 5px;
    position: absolute;
    left: 0px;
    top: 0px;
}

.orderItem {
    position: relative;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #eee;
    border-radius: 3px;
    min-height: 100px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.orderItem:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    border-color: #e0e0e0;
}

.orderStatusColor {
    transition: all 0.3s ease;
    position: absolute;
    margin: 0px
}

.order-actions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.btn-action {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 0.8em;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #666;
}

.btn-action:hover {
    background-color: #e9ecef;
    color: #333;
}

.btn-action.active {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #333;
}

.btn-action i {
    font-size: 1.1em;
}

.btn-action .badge {
    font-size: 0.85em;
    padding: 2px 6px;
}

.agenda-content {
    position: relative;
    width: calc(100% + 30px);
    margin: 12px -15px -10px -15px;
    padding: 8px 12px;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
}

.agenda-item {
    padding: 12px;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-radius: 6px;
    margin-bottom: 8px;
}

.agenda-header {
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 8px;
}

.agenda-date {
    font-weight: 500;
    margin-right: 20px;
}

.agenda-time {
    color: #666;
    margin-right: 20px;
}

.agenda-status {
    font-size: 0.85em;
    padding: 2px 8px;
    border-radius: 12px;
}

.status-pendiente {
    background-color: #fff3cd;
    color: #856404;
}

.status-confirmada {
    background-color: #d4edda;
    color: #155724;
}

.agenda-body {
    padding-top: 8px;
}

.agenda-info-group {
    margin-bottom: 8px;
    display: flex;
    align-items: baseline;
}

.agenda-info-label {
    font-weight: 500;
    width: 100px;
    color: #666;
    font-size: 0.9em;
}

.agenda-info-value {
    flex: 1;
    color: #333;
}

.agenda-info-value i {
    width: 16px;
    margin-right: 4px;
    color: #666;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateY(-10px);
}

.me-3 {
    margin-right: 1rem;
}

.me-4 {
    margin-right: 1.5rem;
}

.g-0 {
    margin-right: 0;
    margin-left: 0;
}

.order-container {
    height: 100vh; /* Adjust based on your header heights */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
}

.order-filters {
    padding: 1rem;
    background: #fff;
    border-bottom: 1px solid #eee;
}

.order-scroll {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

/* Custom scrollbar for better UX */
.order-scroll::-webkit-scrollbar {
    width: 6px;
}

.order-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.order-scroll::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
}

.order-scroll::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}

.btn-xs {
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.25rem 0.5rem;
    margin: 0.125rem 0;
}

.orderItem {
    margin-bottom: 1rem;
    background: #fff;
}

.status-message {
    text-align: center;
    padding: 2rem;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #eee;
    margin: 1rem 0;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-content {
    text-align: center;
}

.loading-content .spinner-border {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    display: block;
    margin: 0 auto;
}

.loading-content p {
    margin-top: 1rem;
    color: #666;
    font-weight: 500;
}

.mdi-spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

</style>
