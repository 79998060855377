<template>
    <div>
        <ficha-layout title="Urgencia" color="#FF0000FF" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #consulta>
               <consulta></consulta>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #seguimiento>
                <seguimiento></seguimiento>
            </template>
        </ficha-layout>
    </div>
</template>


<script>

import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


import FichaLayout from '../components/FichaLayout.vue';

//Componente urgencia
import Consulta from './components/Consulta.vue';


// componentes medicos
import Antecedentes from '../components/Antecedentes.vue';
import ExploracionFisica from '../components/ExploracionFisica.vue';
import Tratamiento from '../components/Tratamiento.vue';
import LaboratorioRadiologia from '../components/LaboratorioRadiologia.vue';
import PreClinica from '../components/PreClinica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Historial from '../components/HistorialPr.vue';
import Resumen from '../components/Resumen.vue';
import Adjuntos from "../components/Adjuntos.vue";
import Seguimiento from '../components/Seguimiento.vue';

export default {
    components: {
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        LaboratorioRadiologia,
        PreClinica,
        ImpresionClinica,
        Historial,
        Resumen,
        Consulta,
        Adjuntos,
        Seguimiento
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fa-solid fa-truck-medical',
                    name: 'Urgencia',//Consulta se refiere a el componente que contiene los datos del tipo de consulta actual de la ficha.
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
                seguimiento: {
                    icon: 'fa-solid fa-user-clock',
                    name: 'Seguimiento',
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    watch: {
    },
    mounted() {
        // this.loadItems();
    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
    },
};

</script>
