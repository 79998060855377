import localDisk from 'src/localDisk';
import API from "src/core/Api";

export const medicoState = {
    state: {
        medicoFichaActive: localDisk.read('medicoFichaActive'),
        reloadFicha: false,
        historyFiltred: localDisk.read('historyFiltred') || {},
        clienteMetada: localDisk.read('clienteMetada') || {},
        currentFichaType: null  // nuevo estado
    },
    mutations: {
        // categorias
        MutateMedicoFichaActive(state, data) {
            if (typeof data.metadata === 'undefined') {
                data.metadata = {};
            }
            state.medicoFichaActive = data;
            localDisk.write('medicoFichaActive', state.medicoFichaActive);
        },
        MutateReloadFicha(state, data) {
            state.reloadFicha = data;
        },
        MutateHistoryFiltred(state, data) {
            state.historyFiltred = data;
            localDisk.write('historyFiltred', state.historyFiltred);
        },
        MergeClienteMetada(state, data) {
            Object.keys(data).forEach(key => {
                state.clienteMetada[key] = data[key];
            });
            localDisk.write('clienteMetada', state.clienteMetada);
        },
        MutateClienteMetada(state, data) {
            state.clienteMetada = data;
            localDisk.write('clienteMetada', state.clienteMetada);
        },
        ClearClienteData(state) {
            state.medicoFichaActive = {};
            localDisk.remove('medicoFichaActive');
        },
        ClearAllData(state) {
            state.medicoFichaActive = {};
            state.historyFiltred = {};
            state.clienteMetada = {};
            localDisk.remove('medicoFichaActive');
            localDisk.remove('historyFiltred');
            localDisk.remove('clienteMetada');
        },
        // nueva mutación
        SetCurrentFichaType(state, fichaType) {
            state.currentFichaType = fichaType;
        },
        // nueva mutación para actualizar solo metadata
        UpdateMedicoFichaMetadata(state, newMetadata) {
            if (state.medicoFichaActive) {
                state.medicoFichaActive.metadata = newMetadata;
                localDisk.write('medicoFichaActive', state.medicoFichaActive);
            }
        }
    },
    getters: {
        GetMedicoFichaActive: state => {
            if (!state.medicoFichaActive || typeof state.medicoFichaActive !== 'object') {
                return {
                    cliente: {},
                    metadata: {},
                    producto: {},
                    orden: {}
                };
            }
            return {
                cliente: state.medicoFichaActive.cliente || {},
                metadata: state.medicoFichaActive.metadata || {},
                producto: state.medicoFichaActive.producto || {},
                orden: state.medicoFichaActive.orden || {},
                ...state.medicoFichaActive
            };
        },
        GetReloadFicha: state => state.reloadFicha,
        GetHistoryFiltred: state => state.historyFiltred,
        GetClienteMetada: state => state.clienteMetada,
    },
    actions: {
        // Categories
        SetMedicoFichaActive: ({commit}, data) => {
            commit('ClearClienteData');
            commit('MutateMedicoFichaActive', data);
        },
        SetReloadFicha: ({commit}, data) => {
            commit('MutateReloadFicha', data);
        },
        SetClienteMetada: ({commit}, data) => {
            commit('MutateClienteMetada', data);
        },
        SetHistoryFiltred: ({commit}, data) => {
            commit('MutateHistoryFiltred', data);
        },
        FetchHistoryFiltred: ({commit, getters}, slugs) => {
            return new Promise((resolve, reject) => {
                // Función para verificar si los datos del cliente están disponibles
                const checkClientData = () => {
                    const medicoFichaActive = getters.GetMedicoFichaActive;
                    if (medicoFichaActive && medicoFichaActive.cliente && medicoFichaActive.cliente.id) {
                        // Si tenemos los datos del cliente, procedemos con la consulta
                        commit('MutateReloadFicha', true);
                        API.send('POST', 'medico/getHistoryFiltred', {
                            clienteId: medicoFichaActive.cliente.id,
                            slugs,
                        }, function (response) {
                            commit('MutateHistoryFiltred', response.data);
                            commit('MutateReloadFicha', false);
                            resolve(response);
                        }, function (response) {
                            API.showErrorAlert(response.msg);
                            commit('MutateReloadFicha', false);
                            reject(response);
                        });
                    } else {
                        // Si no tenemos los datos, esperamos 500ms y volvemos a verificar
                        setTimeout(checkClientData, 500);
                    }
                };

                // Iniciamos la verificación
                checkClientData();
            });
        },
        FetchClienteMetadaBySlug: ({commit, getters}, data) => {
            return new Promise((resolve, reject) => {
                const checkClientData = () => {
                    const medicoFichaActive = getters.GetMedicoFichaActive;
                    if (medicoFichaActive && medicoFichaActive.cliente && medicoFichaActive.cliente.id) {
                        API.send('POST', 'clients/metadata/get-by-slug', {
                            clienteId: medicoFichaActive.cliente.id,
                            slugs: data.slugs
                        }, function (response) {
                            if (data.merge) {
                                commit('MergeClienteMetada', response.data);
                            } else {
                                commit('MutateClienteMetada', response.data);
                            }
                            resolve(response);
                        }, function (response) {
                            API.showErrorAlert(response.msg);
                            reject(response);
                        });
                    } else {
                        setTimeout(checkClientData, 500);
                    }
                };

                checkClientData();
            });
        },
        FetchClienteMetadaByClient: ({commit, getters}) => {
            return new Promise((resolve, reject) => {
                const checkClientData = () => {
                    const medicoFichaActive = getters.GetMedicoFichaActive;
                    if (medicoFichaActive && medicoFichaActive.cliente && medicoFichaActive.cliente.id) {
                        commit('MutateReloadFicha', true);
                        API.send('POST', 'clients/metadata/get-by-client', {
                            clienteId: medicoFichaActive.cliente.id
                        }, function (response) {
                            commit('MutateClienteMetada', response.data);
                            commit('MutateReloadFicha', false);
                            resolve(response);
                        }, function (response) {
                            API.showErrorAlert(response.msg);
                            commit('MutateReloadFicha', false);
                            reject(response);
                        });
                    } else {
                        setTimeout(checkClientData, 500);
                    }
                };

                checkClientData();
            });
        },
        SaveClienteMetada: ({commit}, data) => {
            API.send('POST', 'clients/metadata/save', data.payload, function (response) {
                API.showSuccessAlert(response.msg);
                if (data.merge) {
                    commit('MergeClienteMetada', response.data);
                } else {
                    commit('MutateClienteMetada', response.data);
                }
            }, function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        DeleteClienteMetadaDetail: ({dispatch, commit, getters}, data) => {
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                commit('MutateReloadFicha', true);
                API.send('POST', 'clients/metadata/delete-detail', {
                    metadataId: data.metadataId
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    if (data.slug) {
                        dispatch('FetchClienteMetadaBySlug', {slugs:[data.slug], merge: true});
                    } else {
                        commit('MutateReloadFicha', false);
                    }
                }, function (response) {
                    API.showErrorAlert(response.msg);
                    commit('MutateReloadFicha', false);
                });
            })
        },
        ClearAllFichaData: ({commit}) => {
            commit('ClearAllData');
        },
        FetchFichaData: ({commit, state, dispatch}, {reservaId, slugs, initialData}) => {
            // Se utiliza initialData: 0 (false) o 1 (true)
            commit('MutateReloadFicha', true);
            API.send('POST', 'orders/metadata/ficha-data', {
                reservaId,
                slugs,
                initialData
            }, function (response) {
                if (initialData == 1) {
                    const safeData = response.data || {};
                    commit('MutateMedicoFichaActive', safeData);
                    // Validación más robusta para evitar accesos a propiedades undefined
                    const fichaType = safeData && safeData.producto && safeData.producto.fichaMedica ? safeData.producto.fichaMedica : null;
                    commit('SetCurrentFichaType', fichaType);
                } else {
                    // initialData == 0: solo se reemplaza la metadata
                    const safeMetadata = response.data && response.data.metadata ? response.data.metadata : {};
                    commit('UpdateMedicoFichaMetadata', safeMetadata);
                }
                commit('MutateReloadFicha', false);

            }, function (response) {
                API.showErrorNotify(response.msg);
                commit('MutateReloadFicha', false);
            });
        }
    }
}
