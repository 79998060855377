<template>
    <setting-layout :active="42">
        <!-- Toast container -->
        <div id="toastContainer"></div>

        <h1 class="mb-4 text-center text-md-start">Ingreso y Admisión de Pacientes</h1>

        <!-- Pestañas navegación -->
        <ul class="nav nav-tabs flex-wrap" role="tablist">
            <li v-for="(tab, index) in computedTabs" :key="tab.id" class="nav-item" role="presentation">
                <button class="nav-link" :class="{ active: currentTab === tab.id }" @click="switchTab(tab.id)">
                    <i :class="tab.icon + ' icon-lg'"></i> {{ tab.title }}
                </button>
            </li>
        </ul>

        <!-- Contenido de cada pestaña -->
        <div class="tab-content mt-3">
            <!-- Ingreso del Paciente: con búsqueda -->
            <div v-if="currentTab === 'ingreso'" class="tab-pane fade show active" id="ingreso" role="tabpanel">
                <!-- Modo búsqueda -->
                <div v-if="!modoEdicion">
                    <div class="card mb-4">
                        <div class="card-body">
                            <!-- Etiqueta de búsqueda -->
                            <label class="form-label mb-2">Buscar paciente</label>
                            <div class="row g-2">
                                <div class="col-12 col-md-6">
                                    <div class="input-group mb-3 input-icon">
                                        <span class="input-group-text">
                                            <i class="fa fa-search"></i>
                                        </span>
                                        <input type="search" class="form-control" placeholder="Ingrese nombre, DPI, etc." v-model="busqueda" @keyup.enter="buscarPaciente" />
                                        <div class="dropdown">
                                            <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="fa fa-sliders"></i>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <a class="dropdown-item" href="#" @click.prevent="setFiltro(1)">DPI o Pasaporte</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#" @click.prevent="setFiltro(3)">Nombre</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#" @click.prevent="setFiltro(4)">Apellido</a>
                                                </li>
                                                <li>
                                                    <a class="dropdown-item" href="#" @click.prevent="setFiltro(5)">Correo electrónico</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-2">
                                    <button type="button" class="btn btn-primary w-100" @click="buscarPaciente">
                                        <i class="fa fa-search mr-2"></i> Buscar
                                    </button>
                                </div>
                            </div>

                            <!-- Resultados de la búsqueda -->
                            <div v-if="busquedaRealizada">
                                <div v-if="pacientes.length">
                                    <div class="list-group list-group-flush list-group-hoverable">
                                        <div class="list-group-item" v-for="pac in pacientes" :key="pac.id">
                                            <div class="row align-items-center">
                                                <div class="col-auto">
                                                    <span class="avatar" style="background-image: url('https://via.placeholder.com/40');">
                                                        <i class="fa fa-user"></i>
                                                    </span>
                                                </div>
                                                <div class="col text-truncate mt-2 mt-sm-0">
                                                    <a href="#" class="text-body d-block" title="Editar paciente">
                                                        {{ pac.nombres }} {{ pac.apellidos }}
                                                        <small class="text-muted">({{ pac.id }})</small>
                                                    </a>
                                                    <div class="d-block text-secondary text-truncate mt-n1">
                                                        DPI: {{ pac.cui_passport || 'N/A' }}
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-auto mt-2 mt-sm-0 d-flex flex-wrap gap-1 actions">
                                                    <button class="btn btn-sm btn-outline-info" @click="editarPaciente(pac)" title="Editar Información">
                                                        <i class="fa fa-pencil"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-outline-success" @click="procederAdmision(pac)" title="Proceder a Admisión">
                                                        <i class="fa fa-arrow-right"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-outline-secondary" @click="verDetalles(pac.id)" title="Ver Detalles">
                                                        <i class="fa fa-info-circle"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-outline-danger" @click="eliminarPaciente(pac.id)" title="Eliminar">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Paginación -->
                                    <nav aria-label="navigation" class="px-1 py-2 mt-3">
                                        <ul class="pagination">
                                            <li class="page-item" v-for="item in paginacion" :key="'page' + item">
                                                <a v-if="!isNaN(parseFloat(item))" @click="paginaSeleccionada = item; buscarPaciente();" 
                                                   :class="paginaSeleccionada == item ? 'page-link cursor-pointer page-link-active' : 'page-link cursor-pointer'">
                                                   {{ item }}
                                                </a>
                                                <div v-else class="page-link">{{ item }}</div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div v-else>
                                    <p>No se encontraron pacientes.</p>
                                    <button class="btn btn-success" @click="crearPacienteNuevo">
                                        Crear Nuevo Paciente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modo edición / creación -->
                <div v-else>
                    <div class="card mb-4">
                        <div class="card-body">
                            <h4 class="card-title section-title">
                                <i class="fa fa-id-badge icon-lg"></i> Datos del Paciente
                            </h4>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Nombres</label>
                                    <input type="text" class="form-control" v-model="paciente.nombres" placeholder="Nombres">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Apellidos</label>
                                    <input type="text" class="form-control" v-model="paciente.apellidos" placeholder="Apellidos">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">DPI o Pasaporte</label>
                                    <input type="text" class="form-control" v-model="paciente.cui_passport" placeholder="DPI o Pasaporte">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Correo Electrónico</label>
                                    <input type="email" class="form-control" v-model="paciente.email" placeholder="Correo Electrónico">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Género</label>
                                    <select class="form-select" v-model="paciente.genero">
                                        <option value="M">Hombre</option>
                                        <option value="F">Mujer</option>
                                        <option value="O">Otro</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Fecha de Nacimiento</label>
                                    <input type="date" class="form-control" v-model="paciente.fechaNacimiento">
                                </div>
                            </div>

                            <h4 class="card-title section-title mt-4">
                                <i class="fa fa-users icon-lg"></i> Datos de Contacto de Emergencia
                            </h4>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Nombres</label>
                                    <input type="text" class="form-control" v-model="paciente.extradata.emergencia.nombres" placeholder="Nombres">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Apellidos</label>
                                    <input type="text" class="form-control" v-model="paciente.extradata.emergencia.apellidos" placeholder="Apellidos">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Parentesco</label>
                                    <input type="text" class="form-control" v-model="paciente.extradata.emergencia.parentesco" placeholder="Parentesco">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Teléfono</label>
                                    <input type="tel" class="form-control" v-model="paciente.extradata.emergencia.telefono" placeholder="Teléfono">
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4 mb-2">
                                    <label class="form-label">Dirección</label>
                                    <input type="text" class="form-control" v-model="paciente.extradata.emergencia.direccion" placeholder="Dirección">
                                </div>
                            </div>

                            <div class="d-flex justify-content-center gap-2 mt-3 flex-wrap">
                                <button class="btn btn-primary" @click="guardarPaciente">
                                    <i class="fa fa-save"></i> Guardar Paciente
                                </button>
                                <button class="btn btn-success" @click="activarAdmision" v-if="paciente.id">
                                    <i class="fa fa-arrow-right"></i> Proceder a Admisión
                                </button>
                                <button class="btn btn-secondary" @click="modoEdicion = false">
                                    <i class="fa fa-arrow-left"></i> Volver a Búsqueda
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Dashboard / Estadísticas -->
            <div v-if="currentTab === 'dashboard'" class="tab-pane fade show active" id="dashboard" role="tabpanel">
                <div class="card mb-4">
                    <div class="card-body">
                        <h4 class="card-title">Estadísticas de Pacientes</h4>
                        <div class="row">
                            <div class="col-12 col-md-6 mb-3">
                                <div class="chart-container">
                                    <h5>Admisiones por Mes</h5>
                                    <p class="text-muted">Aquí irá una gráfica de admisiones mensuales</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 mb-3">
                                <div class="chart-container">
                                    <h5>Distribución por Género</h5>
                                    <p class="text-muted">Aquí irá una gráfica de distribución por género</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Admisión (Wizard) -->
            <div v-if="currentTab === 'admision'" class="tab-pane fade show active" id="admision" role="tabpanel">
                <div class="card mb-4">
                    <div class="card-body">
                        <div v-if="!admisionPacienteSeleccionado">
                            <p class="text-center">Por favor, seleccione un paciente desde la pestaña de Ingreso para proceder con la admisión.</p>
                            <div class="text-center mt-3">
                                <button class="btn btn-primary" @click="switchTab('ingreso')">
                                    <i class="fa fa-arrow-left"></i> Ir a Ingreso de Pacientes
                                </button>
                            </div>
                        </div>
                        <div v-else class="wizard">
                            <ul class="nav nav-tabs flex-nowrap flex-sm-wrap">
                                <li class="nav-item" v-for="(step, index) in wizardSteps" :key="index">
                                    <button type="button" class="nav-link" :class="{ active: currentStep === index }" @click="goToStep(index)">
                                        <i :class="step.icon"></i> {{ step.title }}
                                    </button>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <!-- Paso 1: Información Básica -->
                                <div v-if="currentStep === 0">
                                    <h4 class="section-title">Información Básica de Admisión</h4>
                                    <div class="row">
                                        <div class="col-12 col-md-6 mb-3">
                                            <label class="form-label">Fecha de Admisión</label>
                                            <input type="date" class="form-control" v-model="admision.fechaAdmision">
                                        </div>
                                        <div class="col-12 col-md-6 mb-3">
                                            <label class="form-label">Hora de Admisión</label>
                                            <input type="time" class="form-control" v-model="admision.horaAdmision">
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label class="form-label">Motivo de Admisión</label>
                                            <textarea class="form-control" v-model="admision.motivo" rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Paso 2: Documentos -->
                                <div v-if="currentStep === 1">
                                    <h4 class="section-title">Documentos Requeridos</h4>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <div class="dropzone" @click="triggerFileInput">
                                                <i class="fa fa-cloud-upload fa-3x mb-2"></i>
                                                <p>Haga clic o arrastre archivos aquí para subir</p>
                                                <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" multiple>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <ul class="list-group" v-if="admision.documentos.length">
                                                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(doc, index) in admision.documentos" :key="index">
                                                    <span>{{ doc.nombre }}</span>
                                                    <button class="btn btn-sm btn-danger" @click="eliminarDocumento(index)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                            <p v-else class="text-muted text-center mt-3">No hay documentos cargados</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Paso 3: Confirmación -->
                                <div v-if="currentStep === 2">
                                    <h4 class="section-title">Confirmación de Admisión</h4>
                                    <div class="alert alert-info">
                                        <p><strong>Paciente:</strong> {{ pacienteAdmision.nombres }} {{ pacienteAdmision.apellidos }}</p>
                                        <p><strong>Fecha y Hora:</strong> {{ formatDateTime(admision.fechaAdmision, admision.horaAdmision) }}</p>
                                        <p><strong>Motivo:</strong> {{ admision.motivo }}</p>
                                        <p><strong>Documentos:</strong> {{ admision.documentos.length }} archivos</p>
                                    </div>
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" v-model="admision.confirmado" id="confirmarAdmision">
                                        <label class="form-check-label" for="confirmarAdmision">
                                            Confirmo que toda la información proporcionada es correcta
                                        </label>
                                    </div>
                                </div>
                                
                                <!-- Botones de navegación del wizard -->
                                <div class="d-flex justify-content-between mt-4 wizard-footer">
                                    <button class="btn btn-secondary" :disabled="currentStep === 0" @click="prevStep">
                                        <i class="fa fa-arrow-left"></i> Anterior
                                    </button>
                                    <button v-if="currentStep < wizardSteps.length - 1" class="btn btn-primary" @click="nextStep">
                                        Siguiente <i class="fa fa-arrow-right"></i>
                                    </button>
                                    <button v-else class="btn btn-success" @click="completarAdmision" :disabled="!admision.confirmado">
                                        Completar Admisión <i class="fa fa-check"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import { mapGetters } from "vuex";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            // Control de pestañas
            currentTab: 'ingreso',
            tabs: [
                { id: 'ingreso', title: 'Ingreso', icon: 'fa fa-user-plus', visible: true },
                { id: 'dashboard', title: 'Dashboard', icon: 'fa fa-chart-bar', visible: true },
                { id: 'admision', title: 'Admisión', icon: 'fa fa-clipboard-list', visible: true }
            ],
            
            // Búsqueda y listado
            busqueda: '',
            filtroTipo: 1, // 1=DPI, 3=Nombre, 4=Apellido, 5=Email
            busquedaRealizada: false,
            pacientes: [],
            paginacion: [],
            paginaSeleccionada: 1,
            limite: 10,
            
            // Control de modo
            modoEdicion: false,
            
            // Datos del paciente
            paciente: {
                id: null,
                nombres: '',
                apellidos: '',
                email: '',
                cui_passport: '',
                genero: 'M',
                fechaNacimiento: '',
                extradata: {
                    emergencia: {
                        nombres: '',
                        apellidos: '',
                        parentesco: '',
                        telefono: '',
                        direccion: ''
                    }
                }
            },
            
            // Datos de admisión
            admisionPacienteSeleccionado: false,
            pacienteAdmision: null,
            currentStep: 0,
            wizardSteps: [
                { title: 'Información Básica', icon: 'fa fa-info-circle' },
                { title: 'Documentos', icon: 'fa fa-file' },
                { title: 'Confirmación', icon: 'fa fa-check-circle' }
            ],
            admision: {
                fechaAdmision: '',
                horaAdmision: '',
                motivo: '',
                documentos: [],
                confirmado: false
            }
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        }),
        computedTabs() {
            return this.tabs.filter(tab => tab.visible);
        }
    },
    mounted() {
        // Inicializar fecha y hora actual para admisión
        const now = new Date();
        this.admision.fechaAdmision = now.toISOString().substr(0, 10);
        this.admision.horaAdmision = now.toTimeString().substr(0, 5);
    },
    methods: {
        // Navegación entre pestañas
        switchTab(tabId) {
            this.currentTab = tabId;
        },
        
        // Búsqueda de pacientes
        setFiltro(tipo) {
            this.filtroTipo = tipo;
        },
        buscarPaciente() {
            if (!this.busqueda) {
                API.showErrorAlert("Ingrese un término de búsqueda");
                return;
            }
            
            const self = this;
            self.pacientes = [];
            self.paginacion = [];
            
            // Mapear el filtro según el tipo seleccionado
            let filterDPI = '';
            let filterNombre = '';
            let filterApellido = '';
            let filterEmail = '';
            
            switch (this.filtroTipo) {
                case 1: // DPI
                    filterDPI = this.busqueda;
                    break;
                case 3: // Nombre
                    filterNombre = this.busqueda;
                    break;
                case 4: // Apellido
                    filterApellido = this.busqueda;
                    break;
                case 5: // Email
                    filterEmail = this.busqueda;
                    break;
            }
            
            API.send('POST', 'clients/get-list', {
                page: self.paginaSeleccionada,
                perPage: self.limite,
                filterDPI: filterDPI,
                filterNombre: filterNombre,
                filterApellido: filterApellido,
                filterEmail: filterEmail
            },
            function (data) {
                if (data.status) {
                    self.pacientes = data.data.clientes;
                    self.paginacion = data.data.paginas;
                    self.busquedaRealizada = true;
                }
            },
            function (data) {
                API.showErrorAlert(data.msg);
            });
        },
        
        // Acciones con pacientes
        crearPacienteNuevo() {
            this.paciente = {
                id: null,
                nombres: '',
                apellidos: '',
                email: '',
                cui_passport: '',
                genero: 'M',
                fechaNacimiento: '',
                extradata: {
                    emergencia: {
                        nombres: '',
                        apellidos: '',
                        parentesco: '',
                        telefono: '',
                        direccion: ''
                    }
                }
            };
            this.modoEdicion = true;
        },
        editarPaciente(pac) {
            // Clonar paciente para evitar modificaciones directas
            this.paciente = JSON.parse(JSON.stringify(pac));
            
            // Asegurar estructura de extradata
            if (!this.paciente.extradata) {
                this.paciente.extradata = {
                    emergencia: {
                        nombres: '',
                        apellidos: '',
                        parentesco: '',
                        telefono: '',
                        direccion: ''
                    }
                };
            } else if (!this.paciente.extradata.emergencia) {
                this.paciente.extradata.emergencia = {
                    nombres: '',
                    apellidos: '',
                    parentesco: '',
                    telefono: '',
                    direccion: ''
                };
            }
            
            this.modoEdicion = true;
        },
        guardarPaciente() {
            const self = this;
            let method = 'POST';
            let endpoint = 'clients/save';
            let payload = this.paciente;
            
            API.send(method, endpoint, payload,
                function(response) {
                    if (response.status) {
                        API.showSuccessAlert("Paciente guardado correctamente");
                        if (!self.paciente.id) {
                            // Si era un paciente nuevo, actualizamos el ID
                            self.paciente.id = response.data.id;
                        }
                        // Si el usuario quiere proceder a admisión
                        if (self.admisionPacienteSeleccionado) {
                            self.procederAdmision(self.paciente);
                        }
                    }
                },
                function(error) {
                    API.showErrorAlert(error.msg || "Error al guardar el paciente");
                }
            );
        },
        eliminarPaciente(id) {
            const self = this;
            
            API.showConfirm('¿Está seguro de eliminar este paciente?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'clients/delete/' + id, {},
                    function (data) {
                        API.showSuccessAlert("Paciente eliminado correctamente");
                        self.buscarPaciente();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            });
        },
        verDetalles(id) {
            // Redirigir a la página de detalles del cliente
            this.$router.push(`/client/${id}`);
        },
        
        // Admisión
        procederAdmision(paciente) {
            this.admisionPacienteSeleccionado = true;
            this.pacienteAdmision = paciente;
            this.currentStep = 0;
            this.switchTab('admision');
        },
        activarAdmision() {
            // Guardar primero el paciente y luego proceder con la admisión
            this.admisionPacienteSeleccionado = true;
            this.guardarPaciente();
        },
        
        // Navegación del wizard
        goToStep(step) {
            if (step < this.wizardSteps.length) {
                this.currentStep = step;
            }
        },
        nextStep() {
            if (this.currentStep < this.wizardSteps.length - 1) {
                this.currentStep++;
            }
        },
        prevStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
        
        // Manejo de documentos
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileUpload(event) {
            const files = event.target.files;
            if (!files || files.length === 0) return;
            
            // Convertir a array para poder manipularlo
            Array.from(files).forEach(file => {
                // Verificar si ya existe un archivo con el mismo nombre
                const existeArchivo = this.admision.documentos.some(doc => doc.nombre === file.name);
                if (!existeArchivo) {
                    this.admision.documentos.push({
                        nombre: file.name,
                        tamano: file.size,
                        tipo: file.type,
                        archivo: file
                    });
                }
            });
            
            // Limpiar el input para permitir subir el mismo archivo de nuevo si es necesario
            event.target.value = '';
        },
        eliminarDocumento(index) {
            this.admision.documentos.splice(index, 1);
        },
        
        // Completar admisión
        completarAdmision() {
            if (!this.admision.confirmado) {
                API.showErrorAlert("Debe confirmar que la información es correcta");
                return;
            }
            
            // Aquí se enviaría la información de admisión al backend
            // Esto es solo un ejemplo ya que no hay un endpoint específico para admisiones
            API.showSuccessAlert("Admisión completada correctamente");
            
            // En un caso real, aquí se enviarían los datos al backend
            // y se manejaría la respuesta adecuadamente
            
            // Volver a la pestaña de ingreso
            this.switchTab('ingreso');
            
            // Resetear estado de admisión
            this.admisionPacienteSeleccionado = false;
            this.pacienteAdmision = null;
            this.admision = {
                fechaAdmision: new Date().toISOString().substr(0, 10),
                horaAdmision: new Date().toTimeString().substr(0, 5),
                motivo: '',
                documentos: [],
                confirmado: false
            };
        },
        
        // Utilidades
        formatDateTime(fecha, hora) {
            if (!fecha || !hora) return 'No especificado';
            
            const options = { 
                year: 'numeric', 
                month: 'long', 
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            };
            
            try {
                // Construir fecha completa (fecha + hora)
                const fechaCompleta = new Date(`${fecha}T${hora}`);
                return fechaCompleta.toLocaleDateString('es-ES', options);
            } catch (error) {
                return `${fecha} ${hora}`;
            }
        }
    }
};
</script>

<style scoped>
/* Estilos adaptados del original */
.input-group .btn.dropdown-toggle {
    border: 0;
    background: transparent;
    padding: 0.375rem 0.75rem;
}

.form-label {
    font-size: 1.1rem;
}

.form-control,
.form-select {
    font-size: 0.9rem;
    padding: 0.5rem;
}

.nav-tabs .nav-link {
    font-size: 1rem;
    padding: 0.75rem;
}

.tab-content {
    padding: 0.75rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.icon-lg {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.section-title {
    font-size: 1.1rem;
    border-bottom: 2px solid #dee2e6;
    margin-bottom: 0.75rem;
    padding-bottom: 0.5rem;
}

/* Wizard */
.wizard ul.nav-tabs {
    border-bottom: none;
}

.wizard .nav-tabs .nav-link {
    border: none;
    border-bottom: 3px solid transparent;
}

.wizard .nav-tabs .nav-link.active {
    border-bottom: 3px solid #0d6efd;
    font-weight: bold;
}

.wizard-footer button {
    min-width: 100px;
}

/* Lista de admisiones */
.list-group-item {
    border: none;
    border-bottom: 1px solid #dee2e6;
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item .actions button {
    margin-left: 0.5rem;
}

/* Dropzone */
.dropzone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    color: #ccc;
    cursor: pointer;
}

.chart-container {
    min-height: 320px;
}

/* Paginación */
.page-link {
    cursor: pointer;
}

.page-link-active {
    background-color: #0d6efd;
    color: white;
}

/* Botones responsive */
@media (max-width: 576px) {
    .actions {
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .actions button {
        margin-bottom: 0.5rem;
    }
}
</style> 