<template>
    <layout :active="34">
        <div class="content-body">
            <div class="container">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Agenda</h4>
                    </div>
                    <div class="card-body">
                        <div class="progress" style="height: 2px;">
                        <div ref="progressBar" class="progress-bar" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        
                            <div class="agenda-box-content">

                                <div v-if="showLeftPanel" class="agenda-content-left row">
                                     
                                    <div class="col-12">
                                        <!-- Controles de contracción -->
                                        <div class="row">
                                            <div class="col-12 mb-1">
                                                <div class="contraerBox px-3 cursor-pointer" @click="handlerLefPanel" title="Ocultar panel izquierdo">
                                                    <small class="d-flex aling-items-center p-0 m-0">Ocultar panel</small> 
                                                    <i class="fa-regular fa-square-minus"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Tipo de calendario -->
                                        <div class="row">
                                            <div class="btn-group col-12 mt-sm-0 mt-md-0 mb-1" role="group" aria-label="Agendar y Vistas">
                                                <div type="button" @click="viewCalendar = 'day'; startProgress;" class="btn btn-secondary btn-lg border-right">
                                                    <i class="fa-solid fa-calendar-day"></i> Día
                                                </div>

                                                <div type="button" @click="viewCalendar = 'week'; startProgress;" class="btn btn-secondary btn-lg">
                                                    <i class="fas fa-calendar-week"></i> Semana
                                                </div>

                                                <div type="button" @click="viewCalendar = 'month'; startProgress;" class="btn btn-secondary btn-lg" border-left>
                                                    <i class="fas fa-calendar-alt"></i> Mes
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-12">
                                                <div style="width: 100%; display: flex; flex-direction: column; justify-content: center; border: 1px solid #0076B6; border-radius: 6px; padding: 6px;">
                                                    <div style="width: 100%; display: flex; justify-content: space-evenly">
                                                        <div style="width: 50%; display: flex; justify-content: space-between;">
                                                        <span
                                                                class="q-button"
                                                                style="cursor: pointer; user-select: none;"
                                                                @click="onPrevM"
                                                        >&lt;</span>
                                                            {{ formattedMonth }}
                                                            <span
                                                                    class="q-button"
                                                                    style="cursor: pointer; user-select: none;"
                                                                    @click="onNextM"
                                                            >&gt;</span>
                                                        </div>
                                                        <div style="width: 30%; display: flex; justify-content: space-between;">
                                                        <span
                                                                class="q-button"
                                                                style="cursor: pointer; user-select: none;"
                                                                @click="addToYear(-1)"
                                                        >&lt;</span>
                                                            {{ selectedYear }}
                                                            <span
                                                                    class="q-button"
                                                                    style="cursor: pointer; user-select: none;"
                                                                    @click="addToYear(1)"
                                                            >&gt;</span>
                                                        </div>
                                                    </div>

                                                    <div style="display: flex; justify-content: center; align-items: center; flex-wrap: nowrap;">
                                                        <div style="display: flex; width: 100%;">
                                                            <q-calendar-month
                                                                    style="position: relative;"
                                                                    ref="calendarM"
                                                                    v-model="selectedDate"
                                                                    hoverable
                                                                    :locale="locale"
                                                                    animated
                                                                    use-navigation
                                                                    hide-outside-days
                                                                    mini-mode
                                                                    :focus-type="['date', 'weekday']"
                                                                    :day-class="onDayClass"
                                                                    :weekday-class="onWeekdayClass"
                                                                    :drag-enter-func="onDragEnter"
                                                                    :drag-over-func="onDragOver"
                                                                    :drag-leave-func="onDragLeave"
                                                                    :drop-func="onDrop"
                                                                    @change="onChange"
                                                                    @moved="onMoved"
                                                                    @click-date="onClickDate"
                                                                    @click-day="onClickDay"
                                                            >
                                                                <template #day="{ scope }">
                                                                    <div
                                                                            v-if="getEventsC(scope.timestamp)"
                                                                            class="date-calendar"
                                                                    >
                                                                    <div class="date-day">
                                                                        <span >+{{getEventsC(scope.timestamp)}}</span>
                                                                    </div>
                                                                    </div>
                                                                </template>
                                                            </q-calendar-month>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-12 mt-2 mb-1">
                                                <div class="filter-content">
                                                    <div class="mb-2">
                                                        <div class="d-flex align-items-center justify-content-between p-2 border-bottom mr-sm-2">
                                                                    <small class="d-flex aling-items-center p-0 m-0">
                                                                    <i class="fa-solid fa-user-doctor px-2 d-flex justify-content-center align-items-center"></i> Filtrar por personal
                                                                    </small> 
                                                                    <i v-if="showPersonalFilter" class="fa-regular fa-square-minus" @click="showPersonalFilter = !showPersonalFilter"></i>
                                                                    <i v-else class="fa-regular fa-square-plus" @click="showPersonalFilter = !showPersonalFilter"></i>
                                                                     
                                                        </div>
                                                    
                                                    </div>
                                                    <div class="m-1" v-if="showPersonalFilter">
                                                    <multiselect v-model="asignaciones"
                                                                 :options="personal"
                                                                 track-by="id" label="name"
                                                                 :close-on-select="true"
                                                                 :clear-on-select="false"
                                                                 :preserve-search="true"
                                                                 placeholder="Selecciona personal"
                                                                 :multiple="true"
                                                                 selectLabel="Select "
                                                                 removeLabel="Remove "
                                                                 >
    
                                                    </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 mt-2 mb-1">
                                                <div class="filter-content">
                                                    <div class="mb-2">
                                                        <div class="d-flex align-items-center justify-content-between p-2 border-bottom mr-sm-2">
                                                                    <small class="d-flex aling-items-center p-0 m-0">
                                                                    <i class="fa-regular fa-hospital px-2 d-flex justify-content-center align-items-center"></i> Filtrar por locación
                                                                    </small> 
                                                                    <i v-if="showLocationFilter" class="fa-regular fa-square-minus" @click="showLocationFilter = !showLocationFilter"></i>
                                                                    <i v-else class="fa-regular fa-square-plus" @click="showLocationFilter = !showLocationFilter"></i>
                                                                     
                                                        </div>

                                                    </div>
                                                    <div class="m-1" v-if="showLocationFilter">
                                                    <multiselect v-model="locacionasignada"
                                                                 :options="locaciones"
                                                                 track-by="id" label="name"
                                                                 :close-on-select="true"
                                                                 :clear-on-select="false"
                                                                 :preserve-search="true"
                                                                 placeholder="Selecciona locación"
                                                                 :multiple="true"
                                                                 selectLabel="Select "
                                                                 removeLabel="Remove "
                                                                 >
    
                                                    </multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div v-else class="agenda-content-min">
                                    <div @click="handlerLefPanel" class="show-panel-handler cursor-pointer" title="Mostrar panel izquierdo">
                                        <div class="hide-panel-box">
                                            <div class="plus-mini-btn">
                                                <i class="fa-regular fa-square-plus d-flex justify-content-between align-items-center" style="color: #0076B6;"></i>
                                            </div>
                                            <div class="vertical-text"> <small class="">Mostrar panel</small></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="agenda-content-right p-0 m-0 row">
                                    <div class="col-12 mb-1">
                                        <div class="q-calendar-header">
                                            <div tabindex="0" class="row-left btn btn-secondary btn-lg" @click="onPrev">   
                                                <i class="fa-solid fa-chevron-left" style="color: #ffffff;"></i>
                                                <span class="q-calendar__focus-helper" tabindex="-1" />
                                            </div>
                                            <div class="btn btn-secondary btn-lg q-calendar-title p-0 m-0 ">
                                                <p class="d-flex justify-content-center align-items-center text-capitalize p-0 m-0 w-100 h-100">{{ formattedMonth }}</p>
                                            </div>
                                            <div tabindex="0" class="row-right btn btn-secondary btn-lg" @click="onNext">
                                                <span class="q-calendar__focus-helper" tabindex="-1" />
                                                <i class="fa-solid fa-chevron-right" style="color: #ffffff;"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="q-calenday-container col-12">
                                            <div style="width: 100%; max-height: 600px; overflow: scroll">
                                                <div style="width: 100%; display: flex; flex-direction: column;">
                                                    
                                                    <q-calendar-day
                                                            ref="calendar"
                                                            v-model="selectedDate"
                                                            :view="viewCalendar"
                                                            hide-outside-days
                                                            :locale="'es'"
                                                            :drag-enter-func="onDragEnter"
                                                            :drag-over-func="onDragOver"
                                                            :drag-leave-func="onDragLeave"
                                                            :drop-func="onDrop"
                                                            :weekday-class="onWeekdayClass"
                                                            :interval-class="onIntervalClass"
                                                            :interval-minutes="intervalos"
                                                            :interval-count="finIntervalo"
                                                            :interval-height="50"
                                                            :weekdays="[0,1,2,3,4,5,6]"
                                                            :interval-start="inicioIntervalo"
                                                            hoverable
                                                            animated
                                                            bordered
                                                            @change="onChangeDay"
                                                            @moved="onMoved"
                                                            @click-date="onClickDate"
                                                            @click-time="onClickTime"
                                                            @click-interval="onClickInterval"
                                                            @click-head-intervals="onClickHeadIntervals"
                                                            @click-head-day="onClickHeadDay"
                                                            @click-head-date="onClickHeadDate"
                                                    >

                                                        <template v-if="viewCalendar ==='day'" #day-interval="{ scope }">
                                                            <div v-if="hasEvents(scope.timestamp)"
                                                                    class="interval-qcalendar" >
                                                                <template v-for="event in getEvents(scope.timestamp)" :key="event.id">
                                                                    <div @click.stop="onClickHeadDate(event)" class="item-interval-qcalendar cursor-pointer" :class="selectItemIntervalClass(event)" :title="selectEstadoTitle(event)"
                                                                    :style="getIntervalStateStyle(event)">
                                                                        <div class="item-interval-qcalendar-estado cursor-pointer" :class="selectEstadoClass(event)" :style="getItemStateStyle(event)">
                                                                        </div>
                                                                        <div class="item-interval-qcalendar-name cursor-pointer">
                                                                            <i v-if="(event.estado === 'creada') && (event.confirmado == false)" class="fa-solid fa-clock pr-1 text-warning"></i>
                                                                            <i v-if="(event.estado === 'creada') && (event.confirmado == true)" class="fa-solid fa-check pr-1 text-success"></i>
                                                                            <i v-if="event.estado === 'en_progreso'" class="fa-solid fa-spinner pr-1 text-info"></i>
                                                                            <i v-if="event.estado === 'finalizada'" class="fa-solid fa-square-check pr-1 text-primary"></i>
                                                                            <i v-if="event.estado === 'cancelada'" class="fa-solid fa-ban pr-1 text-danger"></i>
                                                                             {{truncateEventName(event.name)}}
                                                                        </div>
                                                                        <div class="item-interval-qcalendar-motivo cursor-pointer">
                                                                            {{ event.motivo || '' }}
                                                                        </div>
                                                                        <span class="badge badge-primary badge-position" :style="{ backgroundColor : event.extraData.personalColor}">{{
                                                                            (event.extraData.personalNombre === " " || !event.extraData.personalNombre) ? 'Sin encargado definido' : event.extraData.personalNombre 
                                                                            }}</span>
                                                                    </div>

                                                                </template>
                                                            </div>
                                                        </template>

                                                        <template v-if="viewCalendar ==='week'" #day-interval="{ scope }">
                                                            <div v-if="hasEvents(scope.timestamp)"
                                                                    class="interval-qcalendar" >
                                                                <template v-for="event in getEvents(scope.timestamp)" :key="event.id">
                                                                    <div @click.stop="onClickHeadDate(event)" class="item-w-interval-qcalendar cursor-pointer" :class="selectItemIntervalClass(event)" :title="selectEstadoTitle(event)"
                                                                    :style="getIntervalStateStyle(event)">
                                                                        <div class="item-interval-qcalendar-estado cursor-pointer" :class="selectEstadoClass(event)" :style="getItemStateStyle(event)">
                                                                        </div>
                                                                        <div class="item-interval-qcalendar-name cursor-pointer">
                                                                            <i v-if="(event.estado === 'creada') && (event.confirmado == false)" class="fa-solid fa-clock pr-1 text-warning"></i>
                                                                            <i v-if="(event.estado === 'creada') && (event.confirmado == true)" class="fa-solid fa-check pr-1 text-success"></i>
                                                                            <i v-if="event.estado === 'en_progreso'" class="fa-solid fa-spinner pr-1 text-info"></i>
                                                                            <i v-if="event.estado === 'finalizada'" class="fa-solid fa-square-check pr-1 text-primary"></i>
                                                                            <i v-if="event.estado === 'cancelada'" class="fa-solid fa-ban pr-1 text-danger"></i>
                                                                             {{truncateEventName(event.name)}}
                                                                        </div>
                                                                        <div class="item-interval-qcalendar-motivo cursor-pointer">
                                                                            {{ event.motivo || '' }}
                                                                        </div>
                                                                        <span class="badge badge-primary badge-w-position" :style="{ backgroundColor : event.extraData.personalColor}">{{
                                                                            (event.extraData.personalNombre === " " && event.estado !== 'finalizada') ? 'Sin personal definido' : event.extraData.personalNombre 
                                                                            }}</span>
                                                                    </div>

                                                                </template>
                                                            </div>
                                                        </template>

                                                        <template v-if="viewCalendar === 'month'" #day-interval="{ scope }">
                                                            <div
                                                                    v-if="hasEvents(scope.timestamp)"
                                                                    style="display: flex; flex-wrap: nowrap; justify-content: space-evenly; align-items: center; font-size: 9px; height: 50px; background-color: white;"
                                                            >
                                                                <template v-for="event in getEvents(scope.timestamp)" :key="event.id">
                                                                    <div @click.stop="onClickHeadDate(event)">
                                                                        <div v-if="viewCalendar === 'month'" class="avatar">
                                                                            {{ event.name.charAt(0).toUpperCase() }}
                                                                        </div>
                                                                        <div v-else>
                                                                            {{ truncateEventName(event.name) }}
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </template>
                                                    </q-calendar-day>

                                                </div>
                                            </div>

                                        </div>
                                </div>

                            </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isModalVisible" class="order-search" id="modal-reserva" tabindex="-1" aria-labelledby="modal-reserva-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header" v-if="!editarEventoV">
                        <div class="d-flex align-items-center">
                            <h5 class="modal-title" id="modal-reserva-titulo">Información de reserva</h5>
                            <div class="d-flex justify-content-center align-items-center pl-2 ml-2 border-left">
                                <span v-if="(modalEvent.estado === 'creada') && (modalEvent.confirmado == false)"  class="badge badge-warning py-1 px-2">Creada y en espera</span>
                                <span v-if="(modalEvent.estado === 'creada') && (modalEvent.confirmado == true)"  class="badge badge-primary py-1 px-2">Confirmada</span>
                                <span v-if="modalEvent.estado === 'en_progreso'" class="badge badge-info py-1 px-2">En progreso</span>
                                <span v-if="modalEvent.estado === 'finalizada'" class="badge badge-success py-1 px-2">Finalizada</span>
                                <span v-if="modalEvent.estado === 'cancelada'" class="badge badge-danger py-1 px-2">Cancelada</span>
                            </div>
                        </div>
                        <button type="button" class="close" @click="handleModal(5)" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-header" v-else>
                        <h5 class="modal-title" id="modal-reserva-titulo">Editar</h5>
                        <button type="button" class="close" @click="handleModal(5)" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="!editarEventoV">
                        <div>

                            <!-- Datos Personales -->
                            <div class="form-group row">
                                <label for="nombre" class="col-12 col-sm-2 col-form-label">Nombre:</label>
                                <div class="col-12 col-sm-10">
                                    <input
                                      v-if="modalEvent.extraData"
                                      v-model="modalEvent.extraData.nombre"
                                      type="text"
                                      class="form-control"
                                      id="nombre"
                                      placeholder="Nombre completo"
                                      disabled
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nombre" class="col-12 col-sm-2 col-form-label">Locacion:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-html="modalEvent.locacion"
                                      type="text"
                                      class="form-control"
                                      id="nombre"
                                    ></span>
                                </div>

                                <label for="nombre" class="col-12 col-sm-2 col-form-label">Encargado:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-html="modalEvent.personal"
                                      type="text"
                                      class="form-control"
                                      id="nombre"
                                    ></span>
                                </div>
                            </div>


                            <div class="form-group row">
                                <label for="edad" class="col-12 col-sm-2 col-form-label">Edad:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-if="modalEvent.extraData"
                                      v-html="modalEvent.extraData.edad"
                                      type="text"
                                      class="form-control"
                                      :class="{ 'shake': isEditing }"
                                      id="edad"
                                      placeholder="Edad"
                                    ></span>
                                </div>

                                <label for="genero" class="col-12 col-sm-2 col-form-label">Género:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-if="modalEvent.extraData"
                                      v-html="(modalEvent.extraData.genero=='F'?'Femenino': modalEvent.extraData.genero=='M'?'Masculino':'')"
                                      type="text"
                                      class="form-control"
                                      :class="{ 'shake': isEditing }"
                                      id="genero"
                                    ></span>
                                </div>
                            </div>

                            <!-- Información de Contacto -->
                            <div class="form-group row">
                                <label for="email" class="col-12 col-sm-2 col-form-label">Correo Electrónico:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-if="modalEvent.extraData"
                                      v-html="modalEvent.extraData.email"
                                      type="text"
                                      class="form-control"
                                      :class="{ 'shake': isEditing }"
                                      id="email"
                                      placeholder="Correo Electrónico"
                                    ></span>
                                </div>
                               
                                <label for="telefono" class="col-12 col-sm-2 col-form-label">Teléfono:</label>
                                <div class="col-12 col-sm-4">
                                    <span
                                      v-if="modalEvent.extraData"
                                      v-html="modalEvent.extraData.telefono"
                                      type="text"
                                      class="form-control"
                                      :class="{ 'shake': isEditing }"
                                      id="telefono"
                                      placeholder="Teléfono"
                                    ></span>
                                </div>
                            </div>

                            <!-- Hora de Inicio y Fin -->
                            <div class="form-group row">
                                <label for="horaInicio" class="col-12 col-sm-2 col-form-label">Hora de Inicio:</label>
                                <div class="col-12 col-sm-4">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                        </div>
                                        <input
                                                v-if="modalEvent.extraData"
                                                :value="modalEvent.extraData.fechaInicial ? dayjs(modalEvent.extraData.fechaInicial).format('HH:mm') : ''"
                                                type="time"
                                                class="form-control editable-field"
                                                :class="{ 'shake': isEditing }"
                                                id="horaInicio"
                                                disabled
                                        >
                                    </div>
                                </div>

                                <label for="horaFin" class="col-12 col-sm-2 col-form-label">Hora de Fin:</label>
                                <div class="col-12 col-sm-4">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                        </div>
                                       <input
                                         v-if="modalEvent.extraData"
                                         :value="modalEvent.extraData.fechaFinal ? dayjs(modalEvent.extraData.fechaFinal).format('HH:mm') : ''"
                                         type="time"
                                         class="form-control editable-field"
                                         :class="{ 'shake': isEditing }"
                                         id="horaFin"
                                         disabled
                                         >
                                         
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="telefono" class="col-12 col-sm-2 col-form-label">Motivo:</label>
                                <div class="col-12 col-sm-10">
                                    <textarea v-if="modalEvent.motivo" v-model="modalEvent.motivo" type="text" class="form-control"
                                           :class="{ 'shake': isEditing }" id="Motivo" placeholder="Motivo" disabled>
                                    </textarea>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div v-if="(modalEvent.estado == 'creada') && (modalEvent.confirmado == false)"
                              class="btn btn-success mx-1"
                              @click="confirmarReserva(modalEvent.extraData.id)"
                            >
                                <i class="fa-solid fa-check"></i> Confirmar
                            </div>
                            <div
                              class="btn btn-primary mx-1"
                              @click="goToDetail(modalEvent.extraData.id)"
                            >
                                <i class="fas fa-notes-medical"></i> Ver detalles
                            </div>
                           <div
                              class="btn btn-primary mx-1"
                              @click="facturar(modalEvent.extraData.ordenId)"
                            >
                                <i class="fas fa-notes-medical"></i> Facturar
                            </div>
                            <div
                              class="btn btn-primary mx-1"
                              @click="startEditing()"
                            >
                                <i class="fas fa-file-pen"></i> {{(editarEventoV)?'Dejar de editar': 'Editar'}}
                            </div>
                            <div
                              class="btn btn-group-sm btn-danger mx-1"
                              @click="eliminarEvento(modalEvent.extraData.id)"
                            >
                                <i class="fas fa-trash-alt"></i> Eliminar
                            </div>
                        </div>
                    </div>
                    <div class="modal-body" v-else>
                        <div>
                            <!-- reserva -->
                            <h6 class="text-primary" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">Editar reserva</h6>
                            <!-- Datos generales -->
                            <div class="form-group row">
                                <label for="nombre" class="col-12 col-sm-2 col-form-label">Nombre:</label>
                                <div class="col-12 col-sm-10">
                                    <input
                                      v-if="modalEvent.extraData"
                                      v-model="modalEvent.extraData.nombre"
                                      type="text"
                                      class="form-control"
                                      id="nombre"
                                      placeholder="Nombre completo"
                                      disabled
                                    >
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="nombre" class="col-12 col-sm-2 col-form-label">Encargado:</label>
                                <div class="col-12 col-sm-10">
                                   <select
                                     class="form-control editable-field"
                                     :class="{ 'shake': isEditing }"
                                     v-model="personaSeleccionada"
                                   >
                                        <option value="0">Seleccione encargado de cita</option>
                                        <option v-for="(persona, index) in personal" :key="index" :value="persona.id">{{ persona.name }}</option>
                                    </select>
                                </div>
                            </div>


                            <!-- Hora de Inicio y Fin -->
                            <div class="form-group row">
                                <label for="horaInicio" class="col-12 col-sm-2 col-form-label">Fecha</label>
                                <div class="col-12 col-sm-4">
                                    <div class="input-group" v-if="modalEvent.date">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                        </div>
                                        <input
                                                :value="modalEvent.date"
                                                type="date"
                                                class="form-control editable-field"
                                                :class="{ 'shake': isEditing }"
                                                id="fechaReservada"
                                                @change="checkDate"
                                        >
                                    </div>
                                </div>
                                <label for="horaInicio" class="col-sm-2 col-form-label">Hora de Inicio:</label>
                                <div v-if="modalEvent.timeIni" class="col-sm-4">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                        </div>
                                        <input
                                                :value="modalEvent.timeIni"
                                                type="time"
                                                class="form-control editable-field"
                                                :class="{ 'shake': isEditing }"
                                                id="horaInicio"
                                                @change="checkTimeIni"
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="horaFin" class="col-sm-2 col-form-label">Hora final automatica</label>
                                <div class="col-sm-4">
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" v-model="editHoraFinal" :checked="editHoraFinal"/>
                                        <div class="toggle-switch"></div>
                                        <span class="toggle-label">
                                            {{editHoraFinal ?  'Habilitado':'Deshabilitado'}}
                                        </span>
                                    </label>
                                </div>

                                <label v-if="!editHoraFinal" for="horaFin" class="col-sm-2 col-form-label">Hora de Fin:</label>
                                <div v-if="!editHoraFinal" class="col-sm-4">
                                    <div v-if="modalEvent.timeFin" class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-clock"></i></span>
                                        </div>
                                        <input
                                                :value="modalEvent.timeFin"
                                                type="time"
                                                class="form-control editable-field"
                                                :class="{ 'shake': isEditing }"
                                                id="horaFin"
                                        >
                                    </div>
                                </div>
                                
                            </div>

                            <!-- cliente -->

                            <h6 class="text-primary mt-2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.1);">
                                {{ AuthGetUserInfo.layout === 'medico' ? 'Editar paciente' : 'Editar cliente' }}
                            </h6>

                            <div class="form-group row">
                                <label for="nombres" class="col-12 col-sm-2 col-form-label">Nombres:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.nombres"
                                    type="text"
                                    class="form-control"
                                    id="nombres"
                                    placeholder="Nombre completo"
                                    >
                                </div>

                                <label for="apellidos" class="col-12 col-sm-2 col-form-label">Apellidos:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.apellidos"
                                    type="text"
                                    class="form-control"
                                    id="apellidos"
                                    placeholder="Apellidos"
                                    >
                                </div>
                            </div>
                         
                            <div class="form-group row">
                                <label for="taxId" class="col-12 col-sm-2 col-form-label">Tax ID:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.taxId"
                                    type="text"
                                    class="form-control"
                                    id="taxId"
                                    placeholder="Tax ID"
                                    >
                                </div>

                                <label for="email" class="col-12 col-sm-2 col-form-label">Correo Electrónico:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.email"
                                    type="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Correo Electrónico"
                                    >
                                </div>
                            </div>
                        
                            <div class="form-group row">
                                <label for="telefono" class="col-12 col-sm-2 col-form-label">Teléfono:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.telefono"
                                    type="text"
                                    class="form-control"
                                    id="telefono"
                                    placeholder="Teléfono"
                                    >
                                </div>
                                <label for="fechaNacimiento" class="col-12 col-sm-2 col-form-label">Fecha de Nacimiento:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.fechaNacimiento"
                                    type="date"
                                    class="form-control"
                                    id="fechaNacimiento"
                                    >
                                </div>
                            </div>
                           
                            <div class="form-group row">
                                <label for="cui_passport" class="col-12 col-sm-2 col-form-label">CUI/Passport:</label>
                                <div class="col-12 col-sm-4">
                                    <input
                                    v-model="client.cui_passport"
                                    type="text"
                                    class="form-control"
                                    id="cui_passport"
                                    placeholder="CUI/Passport"
                                    >
                                </div>
                                <label for="genero" class="col-12 col-sm-2 col-form-label">Género:</label>
                                <div class="col-12 col-sm-4">
                                    <select
                                    v-model="client.genero"
                                    class="form-control"
                                    id="genero"
                                    >
                                        <option value="M">Masculino</option>
                                        <option value="F">Femenino</option>
                                    </select>
                                </div>
                            </div>

                        <div class="modal-footer">
                            <div class="btn btn-secondary mx-1" @click="saveReagendamiento(modalEvent.extraData.id)">
                                <i class="fa-solid fa-floppy-disk"></i> Guardar
                            </div>
                            <div class="btn btn-secondary mx-1"  @click="startEditing()">
                                <i class="fas fa-file-pen"></i> {{(editarEventoV)?'Dejar de editar': 'Editar'}}
                            </div>
                            <div class="btn btn-group-sm btn-danger mx-1"  @click="eliminarEvento(modalEvent.extraData.id)">
                                <i class="fas fa-trash-alt"></i>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modalNewIsVisible" class="order-search" id="modal-reserva" tabindex="-1" aria-labelledby="modal-reserva-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modal-reserva-titulo">Registrar evento {{dateSelected}} {{horaSelected}}</h5>
                        <button type="button" class="close" @click="handleModal(5);" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>

                            <!-- Datos Personales -->
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-3 col-form-label">Seleccione el evento</label>
                                <div class="col-sm-9">
                                    <eventos-in-line :show-product-search-box="true" :tipo-orden="tipoOrden" ref="searchComponent" @productSelected="productSelected" tipo-productos="agendable"></eventos-in-line>
                                    <div class="mt-2">
                                        <span class="alert alert-info " v-if="producto.nombre" ><b>Evento seleccionado: </b>{{producto.nombre}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-3 col-form-label">Agendando a:</label>
                                <div class="col-sm-9">
                                    <clientes-in-line :show-client-search="true" :inline="true" @clientSelected="selectClient"></clientes-in-line>
                                    <div class="mt-2">
                                        <span class="alert alert-info" v-if="agendarCliente.nombres" ><b>Cliente seleccionado: </b>{{agendarCliente.nombres}} {{agendarCliente.apellidos}}</span>
                                    </div>
                                </div>
                            </div>
                            <reservas-agendar-in-line v-if="producto.nombre && agendarCliente.nombres"  :producto="producto" :date-selected="dateSelected" :hora-selected="horaSelected" @selected="selectFecha" @createOrder="createOrder"></reservas-agendar-in-line>
                        </form>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success" @click="handleModal(4)" data-dismiss="modal"><i class="fa fa-rocket"></i> Carga masiva</button>
                            <button type="button" class="btn btn-primary " @click="handleModal(3)" data-dismiss="modal"><i class="fa-solid fa-notes-medical"></i> Agendar multiple</button>
                            <button type="button" class="btn btn-danger" @click="handleModal(5)" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modalMultiple" class="order-search" id="modal-reserva" tabindex="-1" aria-labelledby="modal-reserva-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-flex flex-column">
                            <h5 class="modal-title" id="modal-reserva-titulo"  aria-describedby="titleMulti">Registrar Eventos {{dateSelected}} {{horaSelected}}</h5>
                            <small id="titleMulti" class="form-text text-primary mt-1">*Puedes agregar multiples ordenes para el producto seleccionado.</small>
                        </div>
                        <button type="button" class="close" @click="handleModal(5)" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">


                        <form>
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-3 col-form-label">Seleccione el evento</label>
                                <div class="col-sm-9" aria-describedby="multihelp">
                                    <eventos-in-line :show-product-search-box="true" :tipo-orden="tipoOrden" ref="searchComponent" @productSelected="productSelected" tipo-productos="agendable"></eventos-in-line>
                                    <div class="mt-2">
                                        <span class="alert alert-info " v-if="producto.nombre" ><b>Evento seleccionado: </b>{{producto.nombre}}</span>
                                    </div>
                                    <small v-if="producto.nombre" id="multihelp" class="form-text text-success mt-2">Todas las reservas se harán con los productos seleccionados</small>
                                </div>
                            </div>
                            <reserva-multiple :producto="producto" :date-selected="dateSelected" :hora-selected="horaSelected" @createOrderMultiple="createOrderMultiple" @AddClient="AddClient" :ordersMultiples="ordersMultiples" @deleteOrdenMulti="deleteOrdenMulti"></reserva-multiple>
                        </form>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-success" @click="handleModal(4)" data-dismiss="modal"><i class="fa fa-rocket"></i> Carga masiva</button>
                            <button type="button" class="btn btn-primary" @click="handleModal(2)" data-dismiss="modal"><i class="fa-solid fa-square-plus"></i> Agendar individual</button>
                            <button type="button" class="btn btn-danger" @click="handleModal(5)" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="modalMasive" class="order-search" id="modal-reserva" tabindex="-1" aria-labelledby="modal-reserva-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="d-flex flex-column">
                            <h5 class="modal-title" id="modal-reserva-titulo"  aria-describedby="titleMulti">Registrar eventos masivamente {{dateSelected}} {{horaSelected}}</h5>
                            <small id="titleMulti" class="form-text text-primary mt-1">*Puedes agregar ordenes masivamente cargando desde un archivo .xlsx o .csv hasta un maximo de 5000 citas.</small>
                        </div>
                        <button type="button" class="close" @click="handleModal(5)" data-dismiss="modal" aria-label="Cerrar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="mt-4 text-dark text-center">
                            <i class="fa fa-arrow-circle-down"></i> Sube el archivo que contenga las citas para cargar, hasta un maximo de 5000
                        </div>
                        <div class="mt-4">
                            <FileUploader url="payments/massive/create-pay-order" :accepted-mime="acceptedMime" remove-file-on-finish="0" :extra-data-form="formExtraData" @fileSuccess="fileSuccess" @errorUpload="errorUpload" v-model:file-uploaded="fileUploaded"></FileUploader>
                        </div>
                        <div class="card" v-if="preview.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Previsualización de datos
                                            <i class="fa fa-question-circle" v-tooltip="'Verifica si las columnas de tu archivo fueron detectadas correctamente'"></i>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    <div class="mb-4">
                                                        <h4>Se detectaron <span class="text-primary">{{preview.countRows}}</span> filas a procesar</h4>
                                                    </div>
                                                    <div>
                                                        La previsualización online está limitada a 5 filas por estado, para observar la previsualización completa, descargue el archivo.
                                                    </div>
                                                    <a class="btn btn-sm btn-primary mt-3" :href="preview.resultFile" target="_blank">Descargar archivo de previsualización</a>
                                                </div>
                                                <div v-if="preview.created.length>1" class="mt-4">
                                                    <h6>Productos que se crearán</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped table-sm">
                                                            <tr v-for="(item,key) in preview.created" :key="'uploaded_' + item" :class="key==0 ? 'font-weight-bold':''">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div v-else class="d-flex justify-content-center align-items-center">
                                                    <p>No hay ordenes creadas en los datos</p>
                                                </div>
                                                <div v-if="preview.error.length>1">
                                                    <h6>Productos con errores</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.error" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div v-else class="d-flex justify-content-center align-items-center">
                                                    <p>No hay errores en los datos</p>
                                                </div>
                                                <div class="text-center m2-4" aria-describedby="uploadAlter">
                                                    <small v-if="alertMasiveUpdate" id="uploadAlter" class="form-text text-danger mt-1">*No puedes cambiar de opcion mientras el proceso de carga este en curso</small>
                                                </div>
                                                <div class="text-center mt-4">
                                                    <div class="btn btn-sm btn-success mt-3" @click="doProccess">Realizar carga y procesar productos</div>
                                                    <div class="btn btn-sm btn-danger mt-3 ml-2" @click="$router.go()">Cancelar carga</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-if="process.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Resultados de carga
                                        </h4>
                                    </div>
                                    <div class="card-body" >
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    La carga ha finalizado, para ver los resultados, descargue el archivo.
                                                    <div class="mt-3">
                                                        <a class="btn btn-sm btn-primary mr-2" :href="process.resultFile" target="_blank">Descargar archivo de resultados</a>
                                                        <div @click="$router.go()" class="btn btn-sm btn-dark px-4">
                                                            Realizar otra carga
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" @click="handleModal(2)" data-dismiss="modal"><i class="fa-solid fa-square-plus" ></i> Agendar individual</button>
                            <button type="button" class="btn btn-primary" @click="handleModal(3)" data-dismiss="modal"><i class="fa-solid fa-square-plus"></i> Agendar multiple</button>
                            <button type="button" class="btn btn-danger" @click="handleModal(5)" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCancelModal" class="order-search" id="modal-reserva" tabindex="-1" aria-labelledby="modal-reserva-titulo" aria-hidden="true">
            <div class="modal-dialog modal-md">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="cancelModalLabel">Motivo de Cancelación</h5>
                    </div>
                    <div class="modal-body">
                        <textarea v-model="cancelReason" class="form-control" placeholder="Ingrese el motivo de la cancelación"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="cancelOrder">Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<style src="@quasar/quasar-ui-qcalendar/dist/QCalendarDay.min.css">
</style>
<style scoped>
/* ESTILOS PARA CALENDARIO */
.date-calendar{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 15px;  
}
.date-day{
    font-weight: 400; 
    font-size: 10px;
}
.card-body{
    position: relative;
}
.progress{
    position: absolute;
    width: calc( 100% + 40px);
    top:0px;
    left:0px;
}


</style>
<style scope>

.contraerBox{
  background-color:#fff;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
  height: 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .contraerBox:hover{
  background-color: #fcfcfc;
 }

.agenda-box-content{
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
}

.agenda-content-left{
    width: 30%;
    height: 100%;
}

.agenda-content-min{
    width: 39px;
}

.hide-panel-box{
    background-color:#fff;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 40px;
    width: 39px;
    padding-bottom: 15px;
}

.plus-mini-btn{
    width: 39px;
    height: 39px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-text {
    writing-mode: vertical-lr;
    text-orientation: mixed;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0076B6;
}


.show-panel-handler{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.show-panel-handler:hover{
    background-color: #fcfcfc;
}

.agenda-content-right{
    min-width: 70%;
    max-width: 100%;
    height: 100%;
}

.filter-content{
    background-color:#fff;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
}

.q-calendar-header{
    display: flex;
    width: 100%;
    background-color: #0076B6;
    border-radius: 6px;

}

.row-left{
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
    color: #fff;
}
.row-right{
    width: 20%;
    display: flex;
    justify-content: end;
    align-items: center;
    color: #fff;
}
.q-calendar-title{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

}

.q-calendar-container{
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    width: 100%; 
    border-radius: 6px;
    border: 1px solid #0076B6;
}

.list-group{
    margin: 5px;
}

.list-item{
    background-color:#fff;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    padding: 0px;
    margin: 0px;
    padding: 2px 15px;
}

.list-item label{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}

/* estilos de las citas */

.interval-qcalendar{
    display: flex; 
    flex-wrap: nowrap;
    justify-content: space-evenly; 
    align-items: center; 
    height: 50px; 
    background-color: white
}

.item-interval-qcalendar{
    position: relative;
    display: flex; 
    flex-direction: column;
    justify-items: start;
    align-items: center; 
    text-align: left;
    background-color: white; 
    flex-wrap: nowrap;    
    border-radius: 4px; 
    padding: 2px; 
    margin: 1px;
    min-width: 120px;
    max-width: 300px;
    height:48px; 
    transition: 0.3s;
}

.item-interval-qcalendar-estado{
    height: 48px;
    width: 4px;
    position: absolute;
    top: 0px;
    left: 0px;

}

.item-interval-qcalendar-name{
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: start;
    padding-left: 5px;
    width:calc( 100% - 10px);
    height: 52%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}


.item-interval-qcalendar-motivo{
    display: flex;
    justify-content: start;
    align-items: center;
    text-align: start;
    padding-left: 5px;
    width:calc( 100% - 10px);
    height: 48%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
}

.border-success-aditional{
    border: 5px solid #6dc260 ;
}

/* Estilos para la versión semana */

.item-w-interval-qcalendar{
    position: relative;
    display: flex; 
    flex-direction: column;
    justify-items: start;
    align-items: center; 
    text-align: left;
    background-color: white; 
    flex-wrap: nowrap;    
    border-radius: 4px; 
    padding: 2px; 
    margin: 1px;
    min-width: 50px;
    max-width: 100%;
    height:48px;
    transition: 0.3s;
}
.item-w-interval-qcalendar:hover{ 
    min-width: 120px;
    max-width: 300px;
    height:52px;
    transform: scale(1.1);
    z-index: 1;
    
}


/* badges para identificar personal */
.badge-position{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    bottom: -10px;
    right: -10px;
    z-index: 1;
    transition: 0.3;
}

.badge-w-position{
    visibility: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    bottom: -10px;
    right: -10px;
    z-index: 0;
    transition: 0.3s;
}

.item-w-interval-qcalendar:hover .badge-w-position{
    visibility: visible;
    z-index: 2;
}
.item-interval-qcalendar:hover .badge-position{
    transform: translateX(10px);
    transition: 0.3s;
}


@media (max-width: 1047px) {
    .agenda-box-content{
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }
    .agenda-content-left{
        width: 100%;
        height: fit-content;
    }
    .agenda-content-right{
        width: 100%;
        max-height: 100%;
    }

    .agenda-content-min{
        height: 39px;
        width: 100%;
    }

    .hide-panel-box{
        background-color:#fff;
        border-radius: 6px;
        border: 1px solid #d8d8d8;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 39px; 
        padding: 0px; 
    }

    .agenda-content-min:hover{
        background-color: #fcfcfc;
    }

    .vertical-text {
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
        padding-left:15px;
    }

    .show-panel-handler{
        display: flex;
        flex-direction:row-reverse;
        align-items: center;
        justify-content: space-between;
        padding: 0px 15px;
    }
    

}
</style>
<style>
/* En tu archivo de estilos o en la sección de estilos del componente */
.editable-field {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.shake {
    animation: shake 0.3s;
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}
</style>
<style>
.multiselect__option--highlight {
  background-color: #0076B6; /* Color azul para el elemento seleccionado */
  color: white;
}

.multiselect__tag{
    background-color: #0076B6; /* Color azul para el elemento seleccionado */
    color: white;
}
.avatar {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--primary-color); /* Puedes ajustar el color de fondo según tus preferencias */
}

/* Estilos para el paginador */
.pagination {
    display: flex;
    cursor: pointer;
}

.pagination span {
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #eee;
    margin-right: 5px;
}

.pagination span:hover {
    background-color: #ddd;
}
.title-bar {
    position: relative;
    width: 100%;
    height: 70px;
    background: #3f51b5;
    overflow: hidden;
    border-radius: 3px;
    user-select: none;
}
.modal {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-dialog {
    width: 600px;
}

.modal-header {
    background-color: #f0f0f0;
}

.modal-title {
    font-size: 1.8em;
}

.modal-body {
    padding: 1em;
}

.modal-footer {
    padding: 1em;
}

.direction-button {
    background: #3f51b5;
    color: white;
    width: 40px;
    max-width: 50px !important;
}

.direction-button__left:before {
    content: '<';
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-weight: 900;
    font-size: 3em;
}

.direction-button__right:before {
    content: '>';
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-weight: 900;
    font-size: 3em;
}

.date-button {
    color: white;
    background: #3f51b5;
    z-index: 2;
    height: 100%;
    outline: 0;
    cursor: pointer;
    border-radius: 3px;
    display: inline-flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    border: 0;
    vertical-align: middle;
    padding: 0;
    font-size: 14px;
    line-height: 1.715em;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    user-select: none;
}

/* Estilos específicos para asegurar que los bordes de las citas confirmadas sean visibles */
.border-2 {
    border-width: 3px !important;
}

/* Estilos específicos para el icono de check en citas confirmadas */
.text-success {
    color: #28a745 !important;
}

/* Estilos específicos para citas finalizadas */
.border-primary .item-interval-qcalendar-name i.fa-square-check {
    color: #007bff !important;
}

/* Estilo personalizado para bordes verdes */
.custom-border-green {
    border: 1px solid #6dc260 !important;
}
</style>

<script>
import FileUploader from 'src/components/files/FileUploader';
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import Multiselect from 'vue-multiselect'
import {DatePicker} from 'v-calendar';
import {
    QCalendarDay,
    QCalendarMonth,
    addToDate,
    createDayList,
    createNativeLocaleFormatter,
    getEndOfWeek,
    getStartOfWeek,
    getWeekdaySkips,
    parseTimestamp,
    today
} from '@quasar/quasar-ui-qcalendar/src/index.js'
import "qalendar/dist/style.css";
import '@quasar/quasar-ui-qcalendar/dist/QCalendarVariables.css'
import '@quasar/quasar-ui-qcalendar/dist/QCalendarTransitions.css'
import '@quasar/quasar-ui-qcalendar/dist/QCalendarDay.css'
import '@quasar/quasar-ui-qcalendar/dist/QCalendarMonth.css'

// otros
import ReservasAgendar from "../../components/ReservasAgendar.vue";
import OrderClientSearch from "../../components/OrderClientSearch";
import ClientesInLine from "../../components/clientesInLine.vue";
import EventosInLine from "../../components/eventosInLine.vue";
import ReservasAgendarInLine from "../../components/ReservasAgendarInLine.vue";
import ReservaMultiple from "../../components/ReservaMultiple.vue";
import OrderProductSearch from "../../components/OrderProductSearch";
import {mapGetters} from "vuex";
import {computed, reactive, ref, watch} from "vue";


export default {
    setup() {
        const selectedDate = ref(today());
        const locale = ref('es');
    },
    components: {
        Layout,
        Multiselect,
        ClientesInLine,
        EventosInLine,
        ReservasAgendarInLine,
        ReservasAgendar,
        OrderProductSearch,
        QCalendarDay,
        QCalendarMonth,
        DatePicker,
        ReservaMultiple,
        FileUploader,
    },
    data() {
        return {
            // agendamiento
            agendamientoCalendarAttr: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,
                    dates: new Date(),

                }
            ],
            loadingItems:false,
            editarEventoV: false,
            isEditing: false,
            recetaSearchMedicamentos: [],
            showClientSearch: false,
            showAgendamiento: false,
            showProductSearchBox: false,
            showResumen: false,
            showPersonalFilter:true,
            showLocationFilter:true,
            agendarCliente: {},
            intervalos:15,
            tipoOrden: 'directo',
            viewCalendar: 'day',
            producto: {},
            fecha: {},
            personaSeleccionada: 0,
            productoSeleccionado: 0,
            filteredPersonal: [],
            alphabet: [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'],
            locale: 'es',
            paymentMethod: 'later_on_store',
            montoProductos: 0,
            impuestos: 0,

            // cliente
            client: {
                id: 0,
                taxId: '',
                email: '',
                nombres: '',
                apellidos: '',
                telefono: '',
                fechaNacimiento: '',
                cui_passport: '',
                genero: '',
            },

            showLeftPanel:true,
            isModalVisible: false,
            modalNewIsVisible: false,
            modalMultiple:false,
            modalMasive:false,
            modalEvent: {},
            modalNewEvent: {},
            startUp: {},
            personal: [],
            asignaciones: [],
            locaciones:[],
            locacionasignada: [],

            // Q calendar
            dateStart: '',
            dateEnd: '',
            dateStartOfMonth: '',
            dateEndOfMonth: '',
            config: {
                week: {
                    // Takes the value 'sunday' or 'monday'
                    // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
                    startsOn: 'monday',
                    // Takes the values 5 or 7.
                    nDays: 7,
                    // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
                    // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
                    scrollToHour: 5,
                },
                month: {
                    // Hide leading and trailing dates in the month view (defaults to true when not set)
                    showTrailingAndLeadingDates: true,
                },
                // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
                defaultMode: 'month',
                // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
                isSilent: true,
                disableResize: true,
                isEditable:true,
                showCurrentTime: true, // Display a line indicating the current time,
                style: {
                    colorSchemes: {
                        meetings: {
                            color: "#fff",
                            backgroundColor: "#131313",
                            borderRadius: "5px", // Añade bordes redondeados para que parezca una tarea
                            padding: "5px", // Ajusta el relleno para un aspecto más limpio
                            marginBottom: "5px", // Agrega un margen inferior para separar las tareas
                        },
                        sports: {
                            color: "#fff",
                            backgroundColor: "#ff4081",
                            borderRadius: "5px",
                            padding: "5px",
                            marginBottom: "5px",
                        },
                    },
                }
            },
            eventosList: [],
            eventosListTmp: [],
            selectedDate: today(),
            selectedYear: new Date().getFullYear(),
            formattedMonth: new Intl.DateTimeFormat('es' || undefined, {
                month: 'long',
                timeZone: 'UTC'
            }).format(new Date()),
            dragItems: [
                {
                    id: 1,
                    name: 'Emergencia'
                },
                {
                    id: 2,
                    name: 'Urgencia'
                },
                {
                    id: 3,
                    name: 'Consulta'
                }
            ],
            defaultEvent: {
                id: 0,
                type: 0,
                name: '',
                date: '',
                time: '',
                allDay: false,
                extraData: {}
            },
            events: [],
            eventsCount:[],
            isEventClicked: false,
            inicioIntervalo: 1,
            finIntervalo: 96,
            currentMonth: null,
            dateSelected: false,
            horaSelected: false,
            modalActive:{
                INFORMACION:1,
                INDIVIDUAL:2,
                MULTIPLE:3,
                MASIVO:4,
                CERRAR:5
            },

            //variable para agendamiento multiple
            ordersMultiples:[],

            //Carga masiva filepond
            process: {},
            preview: {},
            formExtraData: {
                preview: 1
            },
            fileUploaded: [],
            acceptedMime:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv',
            alertMasiveUpdate: false,
            editHoraFinal: false,
            showCancelModal: false,
            cancelReason: '',
            cancelEventId: null, // Add this line
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderGetPosCart: 'OrderGetPosCart',
            OrderGetClientSelected: 'OrderGetClientSelected',
        }),
        groupedPersonal() {
            // Agrupar el personal de la misma manera que lo hacías antes
            const grouped = {};
            this.personal.forEach(person => {
                const firstLetter = person.name.charAt(0).toUpperCase();
                if (!grouped[firstLetter]) {
                    grouped[firstLetter] = [];
                }
                grouped[firstLetter].push(person);
            });

            return grouped;
        },
        filteredAlphabet() {
            return this.alphabet.filter(letter => this.groupedPersonal[letter]);
        },
        // convert the events into a map of lists keyed by date
        eventsMap () {
            const map = {}
            this.events.forEach(event => event.allDay !== true && ((map[ event.date ] = map[ event.date ] || []).push(event)))
            return map
        },

        allDayEventsMap () {
            const map = {}
            if (this.events.length > 0) {
                this.events.forEach(event => event.allDay === true && ((map[ event.date ] = map[ event.date ] || []).push(event)))
            }
            return map
        }
    },
    watch: {
        OrderGetPosCart: function () {
            this.calculateProductsAmount();
        },
        selectedDate(newValue) {
            const date = new Date(newValue);

            this.getFormattedMonth(date);

        },
        asignaciones: function() {
            this.loadItems();
            this.loadCountItems();
        },
        locacionasignada: function() {
            this.loadItems();
            this.loadCountItems();
        }
    },
    mounted() {
        this.loadPersonal();
        this.getLocaciones();
    },
    methods: {
        Numbro,
        dayjs,
        getLocaciones(){
            const self = this;

            self.locaciones=[];
            
            API.send('GET', 'locaciones/all', {},
                function (response) {
                    if (response.status) {

                        for (const [key, value] of Object.entries(response.data)) {
                            self.locaciones.push({name: value.nombre , id: value.id});
                        }
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },
        confirmarReserva(id){
            const self = this;

            API.send('POST', `orders/reservas/confirmar/${id}`, {},
                function (response) {
                    if (response.status) {
                        API.showSuccessAlert(response.msg);
                        let event = self.events.find(event => event.id == id);
                        if(event){
                            event.confirmado=true;
                        }
                        self.isModalVisible = false;
                    }
                    else {
                        API.showErrorNotify(response.msg);
                        self.isModalVisible = false;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                    self.isModalVisible = false;
                });
        },
        selectItemIntervalClass(item) { 
            // Si está finalizada, tiene máxima prioridad y usará color primary
            if (item.estado === 'finalizada') {
                return 'border border-primary border-2';
            }
            
            // Para los diferentes estados
            if (!item.colorCita || item.colorCita === '#FFFFFF') { 
                switch (item.estado) {
                    case 'creada':
                        // Borde verde personalizado para citas confirmadas
                        return item.confirmado ? 'custom-border-green' : 'border border-warning';
                    case 'en_progreso':
                        return 'border border-info';
                    case 'cancelada':
                        return 'border border-danger';
                    default:
                        return 'border border-dark';
                }
            } else {
                // Si tiene color personalizado pero está confirmada, asegurarse de que tenga borde verde
                if (item.estado === 'creada' && item.confirmado) {
                    return 'custom-border-green';
                }
                return '';
            }
        },
        selectEstadoClass(item) {
            // Si es finalizada, siempre usar azul para mantener coherencia
            if (item.estado === 'finalizada') {
                return 'bg-primary';
            }
            
            if (!item.colorCita || item.colorCita === '#FFFFFF') { 
                switch (item.estado) {
                    case 'creada':
                        return item.confirmado ? 'bg-success' : 'bg-warning';
                    case 'en_progreso':
                        return 'bg-info';
                    case 'cancelada':
                        return 'bg-danger';
                    default:
                        return 'bg-dark';
                }
            } else {
                // Si tiene color personalizado pero es confirmada o finalizada, asegurar que tenga los colores correspondientes
                if (item.estado === 'finalizada') {
                    return 'bg-primary';
                } else if (item.estado === 'creada' && item.confirmado) {
                    return 'bg-success';
                }
                return '';
            }
        },
        getIntervalStateStyle(item) {
        if (item.colorCita  || item.colorCita=== '#FFFFFF') {
            return {
                border: `1px solid ${item.colorCita}`,
            };
        }
        return {};
        },
        getItemStateStyle(item) {
        if (item.colorCita && item.colorCita !== '#FFFFFF') {
            return {
            backgroundColor: item.colorCita
            };
        }
        return {};
        },
        selectEstadoTitle(item){
            switch (item.estado) {
                case 'creada':
                if(item.confirmado){
                         return 'Confirmada'
                    }else{
                         return 'Creada y en espera...'
                    }
                case 'en_progreso':
                    return 'En progreso...'
                case 'finalizada':
                    return 'Finalizada'
                case 'cancelada':
                     return 'Cancelada'
                default:
                    return 'Sin estado'
            }
        },
        handlerLefPanel(){
            this.showLeftPanel= !this.showLeftPanel;
        },
        saveReagendamiento(){
            const self = this;
        
            let fechaReservada =  dayjs(self.modalEvent.date).format('YYYY/MM/DD');
            let personal = self.personaSeleccionada ? self.personaSeleccionada : '';
            let horaInicio = self.modalEvent.timeIni;
            let horaFin= self.modalEvent.timeFin;
            if(self.editHoraFinal){
                 horaFin = self.timeDefault(self.modalEvent.timeIni);
            } 

            if (this.hasClientDataChanged()) {
                API.showConfirm('¿Estas seguro de este cambio?', 'Se han hecho cambios en los datos personales del cliente', function (){
                    API.send('POST', 'clients/edit/', {
                        id: self.client.id,
                        taxId: self.client.taxId,
                        email: self.client.email,
                        nombres: self.client.nombres,
                        apellidos: self.client.apellidos,
                        telefono: self.client.telefono,
                        fechaNacimiento: self.client.fechaNacimiento,
                        cui_passport: self.client.cui_passport,
                        genero: self.client.genero,
                    }, function (response) {
                        if (response.status) {
                        API.showSuccessAlert('Datos del cliente actualizados correctamente');
                        } else {
                        API.showErrorAlert('Error al actualizar los datos del cliente');
                        }
                    }, function (response) {
                        API.showErrorAlert('Error al actualizar los datos del cliente');
                    });
   
                    API.send('POST', 'orders/reservas/edit', {
                            id: self.modalEvent.id,
                            fechaReservada,
                            horaInicio,
                            horaFin,
                            personal
                        }, function (response) {
                            API.showSuccessAlert(response.msg);
                            self.loadItems();
                            self.isModalVisible = false;
                            self.editarEventoV=false;
                        },
                        function (response) {
                            API.showErrorNotify(response.msg);
                        });
                });
            }else{
                    API.send('POST', 'orders/reservas/edit', {
                        id: self.modalEvent.id,
                        fechaReservada,
                        horaInicio,
                        horaFin,
                        personal
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.loadItems();
                        self.isModalVisible = false;
                        self.editarEventoV=false;
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
        },
        
        timeDefault(time){
            if (!/^\d{2}:\d{2}$/.test(time)) {
            return time;
        }

        // Parsear la hora de fin
        let [hours, minutes] = time.split(':');
        let date = new Date();
        date.setHours(parseInt(hours));
        date.setMinutes(parseInt(minutes));

        // Sumar 30 minutos
        date.setMinutes(date.getMinutes() + 30);

        // Formatear la nueva hora en HH:mm
        let newHours = String(date.getHours()).padStart(2, '0');
        let newMinutes = String(date.getMinutes()).padStart(2, '0');
        let timeDef = `${newHours}:${newMinutes}`;

        return timeDef;
        },
        checkTimeIni(e){
            this.modalEvent.timeIni = e.target.value;
        },
        checkDate(e){
            this.modalEvent.date = e.target.value;
        },
        handleModal(modalType){
         if(this.preview.type!=='preview'){
            // HAY 5 TIPOS DE MODALES EN LA DATA PARA MANEJAR LOS MODALES
            switch (modalType) {
                    case this.modalActive.INFORMACION:
                        this.modalNewIsVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=false;
                        this.isModalVisible=true;
                        this.editarEventoV=false;

                    break;
                    case this.modalActive.INDIVIDUAL:
                        this.isModalVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=false;
                        this.modalNewIsVisible=true;
                    break;
                    case this.modalActive.MULTIPLE:
                        this.isModalVisible=false;
                        this.modalNewIsVisible=false;
                        this.modalMasive=false;
                        this.modalMultiple=true;
                    break;
                    case this.modalActive.MASIVO:
                        this.isModalVisible=false;
                        this.modalNewIsVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=true;
                    break;
                    case this.modalActive.CERRAR:
                        this.isModalVisible=false;
                        this.modalNewIsVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=false;
                        if(this.process.type=== 'process'){
                            this.process = {};
                            this.$router.go()
                        }
                        if(this.preview.type=== 'preview'){
                            this.preview = {};
                            this.$router.go()
                        }

                    break;
                default:
                        this.isModalVisible=false;
                        this.modalNewIsVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=false;
                    break;
            }
         }else{
            this.alertMasiveUpdate=true;
               switch (modalType) {
                    case this.modalActive.CERRAR:
                        if(this.process.type=== 'process'){
                            this.process = {};
                            this.$router.go()
                        }
                        if(this.preview.type=== 'preview'){
                            this.preview = {};
                            this.$router.go()
                        }
                         this.isModalVisible=false;
                        this.modalNewIsVisible=false;
                        this.modalMultiple=false;
                        this.modalMasive=false;
                    break;
                }
            }
        },
        fileSuccess(data) {
            if (typeof data.type !== 'undefined') {
                if (data.type === 'preview') {
                    this.preview = data;
                }
                else {
                    this.process = data;
                    this.preview = {};
                    if(this.process.type=== 'process'){
                            API.showSuccessAlert('Las ordenes han sido cargadas');
                        }
                }
            }
        },
        errorUpload(response) {

            if (Object.prototype.toString.call(response.data) === '[object Array]') {
                const errorsList = response.data.join(', ');
                API.showErrorNotify('Por favor revise campos', errorsList);
            }
            },
        doProccess() {
            if (typeof this.fileUploaded.pond !== 'undefined') {
                this.preview = {};
                this.formExtraData.preview = 0;
                this.fileUploaded.pond.processFile(this.fileUploaded.fileId);
            }
            else {
                API.showErrorNotify('Error al realizar carga');
            }

        },
        isSelected(person) {
            return this.asignaciones.some(asignacion => asignacion.id === person.id);
        },
        startEditing() {
            this.isEditing = true;
            this.editarEventoV = !this.editarEventoV
            if (this.editarEventoV) {
                this.client={
                id: this.modalEvent.clienteId,
                taxId: this.modalEvent.extraData.tax,
                email: this.modalEvent.extraData.email,
                nombres: this.modalEvent.extraData.nombres,
                apellidos: this.modalEvent.extraData.apellidos,
                telefono: this.modalEvent.extraData.telefono,
                fechaNacimiento: this.modalEvent.extraData.fechaNacimiento,
                cui_passport: this.modalEvent.extraData.cui_passport,
                genero: this.modalEvent.extraData.genero,
                }
            }else{
                this.client={
                id: 0,
                taxId: '',
                email: '',
                nombres: '',
                apellidos: '',
                telefono: '',
                fechaNacimiento: '',
                cui_passport: '',
                genero: '',
                }
            }

            // Después de un tiempo, detener la animación

            setTimeout(() => {
                this.isEditing = false;
            }, 10000);
        },
        hasClientDataChanged() {
            const clientData = this.client;
            const modalData = this.modalEvent.extraData;

            return (
            clientData.nombres !== modalData.nombres ||
            clientData.apellidos !== modalData.apellidos ||
            clientData.taxId !== modalData.tax ||
            clientData.email !== modalData.email ||
            clientData.telefono !== modalData.telefono ||
            clientData.fechaNacimiento !== modalData.fechaNacimiento ||
            clientData.cui_passport !== modalData.cui_passport ||
            clientData.genero !== modalData.genero
            );
        },
        badgeClasses (event, type) {
            const isHeader = type === 'header'
            return {
                [ `text-white bg-${ event.bgcolor }` ]: true,
                'full-width': !isHeader && (!event.side || event.side === 'full'),
                'left-side': !isHeader && event.side === 'left',
                'right-side': !isHeader && event.side === 'right',
                'rounded-border': true
            }
        },

        badgeStyles (event, type, timeStartPos = undefined, timeDurationHeight = undefined) {
            const s = {}
            if (timeStartPos && timeDurationHeight) {
                s.top = timeStartPos(event.time) + 'px'
                s.height = timeDurationHeight(event.duration) + 'px'
            }
            s[ 'align-items' ] = 'flex-start'
            return s
        },
        scrollToEvent (event) {
            this.$refs.calendar.scrollToTime(event.time, 350)
        },

        // Alternar la selección de una persona
        toggleSelection(person) { 
            const index = this.asignaciones.findIndex(asignacion => asignacion.id === person.id);

            if (index !== -1) {
                // Si ya está seleccionado, quítalo
                this.asignaciones.splice(index, 1);
            } else {
                // Si no está seleccionado, agrégalo
                this.asignaciones.push(person);
            }
            this.loadItems();
            this.loadCountItems();
        },

        // Filtra las personas según la letra seleccionada
        setFilter(letter) {
            this.filteredPersonal = this.groupedPersonal[letter] || [];
        },
        closeModal() {
            // Cierra el modal
            this.isModalVisible = false;
            this.modalEvent = {};
        },
        loadItems() {
            const self = this;
            const personalArray = self.asignaciones.map(item => ({ id: item.id }));
            const locacionArray = self.locacionasignada.map(item => ({ id: item.id }));

            API.send('POST', 'orders/reservas/list', {
                    dateStart: self.dateStart,
                    dateEnd: self.dateEnd,
                    personal: personalArray,
                    locacion: locacionArray
                },
                function (response) {
                    if (response.status) {
                        const eventosListTmp = [];
                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {
                                const evento = response.data.detalle[a];
                                // Determinando el motivo priorizando la metadata primero, luego entrecitaMotivo, y finalmente un valor por defecto
                                let motivo = 'Sin motivo de asistencia especificado';
                                
                                if (typeof evento.metadata !== 'undefined' && 
                                    typeof evento.metadata.preclinica_motivo !== 'undefined' && 
                                    typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') {
                                    motivo = evento.metadata.preclinica_motivo.motivo_principal_consulta;
                                } else if (typeof evento.entrecitaMotivo !== 'undefined' && evento.entrecitaMotivo) {
                                    motivo = evento.entrecitaMotivo;
                                }
                                
                                const estado = evento.estado;
                                const fechaInicialParts = evento.fechaInicial.split(' ');
                                const fechaFinalParts = evento.fechaFinal.split(' ');
                                const fecha = fechaInicialParts[0];
                                const timeIni = fechaInicialParts[1] ? fechaInicialParts[1] : '';
                                const timeFin = fechaFinalParts[1]  ?  fechaFinalParts[1]: '';
                                // Ajuste de la hora al próximo múltiplo de 15
                                const horaInicial = parseInt(fechaInicialParts[1].split(':')[0]);
                                const minutosInicial = parseInt(fechaInicialParts[1].split(':')[1]);
                                const minutosAjustados = Math.ceil(minutosInicial / 15) * 15;
                                const horaAjustada = minutosAjustados === 60 ? horaInicial + 1 : horaInicial;
                                const minutosHoraProxima =  minutosAjustados === 60? 0: minutosAjustados;
                                
                                // Calcular el intervalo temporal (posición en la grilla del calendario)
                                const intervaloTemporal = Math.floor(horaAjustada * 4) + Math.floor(minutosHoraProxima / 15);
                                const hora = `${horaAjustada.toString().padStart(2, '0')}:${minutosHoraProxima.toString().padStart(2, '0')}`;

                                // Actualiza intervalo solo si encuentras un intervalo menor
                                if ((self.inicioIntervalo > 1) && (intervaloTemporal < self.inicioIntervalo)) {
                                    self.inicioIntervalo = intervaloTemporal;
                                }
                                if(self.inicioIntervalo === 1){
                                    self.inicioIntervalo = intervaloTemporal;
                                }
                                if(self.finIntervalo === 96){
                                    self.finIntervalo = intervaloTemporal;
                                }
                                if(intervaloTemporal > self.finIntervalo){
                                    self.finIntervalo = intervaloTemporal;
                                }
                                eventosListTmp.push({
                                    id: evento.id,
                                    clienteId: evento.clienteId,
                                    personal: evento.personalNombre,
                                    locacion: evento.locacion,
                                    name: evento.nombre,
                                    date: fecha,
                                    time: hora,
                                    timeIni,
                                    timeFin,
                                    motivo,
                                    colorCita: evento.colorCita,
                                    confirmado: evento.confirmado,
                                    estado,
                                    allDay: false,
                                    extraData: evento
                                });
                            })
                        }
                        self.events = eventosListTmp;
                        self.finishProgress();
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                    self.finishProgress();
                })
        },
        loadCountItems() {
            const self = this;
            const personalArray = self.asignaciones.map(item => ({ id: item.id }));
            const locacionArray = self.locacionasignada.map(item => ({ id: item.id }));
            API.send('POST', 'orders/reservas/count', {
                    dateStart: self.dateStartOfMonth,
                    dateEnd: self.dateEndOfMonth,
                    personal: personalArray,
                    locacion: locacionArray
                },
                function (response) {
                    if (response.status) {
                        if (typeof response.data.detalle !== 'undefined') {
                            self.eventsCount=response.data.detalle
                        }
                        self.finishProgress();
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                    self.finishProgress();
                })
        },
        cancelSuscription(suscripcionId) {

            const self = this;

            API.showConfirm('Antes de continuar', 'La cancelación de una suscripción es definitiva, si la cancela, no podrá reactivarla.', function () {
                API.send('POST', 'orders/subscription/cancel', {
                        "suscripcionId": suscripcionId,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
        loadPersonal() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            API.send('GET', 'personal/all', {}, function (response) {
                    if (response.status) {

                        for (const [key, value] of Object.entries(response.data)) {
                            self.personal.push({name: value.nombres + ' ' + value.apellidos, id: value.id});
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        handleDateClick: function (arg) {
            alert('date click! ' + arg.dateStr)
        },
        eventClick: function (info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate
        },
        goToDetail(data) {
            window.location.href = '/medic/ficha/' + data;
        },
        facturar(data) {
            window.location.href = '/order/view/' + data;
        },
        getData(query) {
            const self = this;
            self.isLoading = true;
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/products/search/' + query, {},
                    function (res) {
                        self.recetaSearchMedicamentos = res.data;
                        self.isLoading = false;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.isLoading = false;
                    });
            }
        },
        eliminarEvento(data) {
            const self = this;
            API.showConfirm('Antes de continuar', 'Si cancela esta reserva el estado de la orden cambiara a "Cancelado" y el espacio estará disponible nuevamente, ¿desea continuar?', function () {
                self.isModalVisible = false;
                self.cancelEventId = data;
                self.showCancelModal = true; 
            });
        },
        cancelOrder(){
            const self = this;
            let orderId = self.modalEvent.extraData.ordenId;
            API.send('PUT', 'orders/changeStatus', {
                        orderId,
                        status: 'cancelada',
                        motivo: self.cancelReason
                        },
                        function (response) {
                            if (response.status) {
                                API.showSuccessNotify(response.msg);
                                self.cancelReserva(self.cancelEventId); 
                                }
                                else {
                                    API.showErrorNotify(response.msg);
                                }
                                self.cancelReason='';
                                self.showCancelModal=false;
                            },
                            function (response) {
                                API.showErrorNotify('Ha ocurrido un error al cancelar la orden' , response.msg);
                                self.showCancelModal=false;
                            });

        },
        cancelReserva(eventId) {
            const self = this;
            API.send('POST', 'orders/reservas/cancel', {
                    id: eventId
                }, function (response) {
                    self.isModalVisible = false;
                    self.loadItems();
                    self.loadCountItems();
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
        getFirstWord(text) {
            return text.split(' ')[0];
        },
        truncateEventName(name) {
            // Truncate the name to 4 words
            const words = name.split(' ');
            return words.slice(0, 4).join(' ');
        },

        // Agendamiento
        getFormattedMonth(date) {
            try {
                this.formattedMonth = new Intl.DateTimeFormat(this.locale || undefined, {
                    month: 'long',
                    timeZone: 'UTC'
                }).format(date);
            } catch (e) {
                // Manejar el error según sea necesario
                this.formattedMonth = '';
            }
        },
        selectClient: function (data) {
            this.agendarCliente = data;
            this.showAgendamiento = true;
        },
        productSelected: function (data) {

            this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');

            this.producto = data;
            this.showProductSearchBox = false;
            this.showClientSearch = true;
        },
        selectFecha: function (data) {
            this.fecha = data;
            this.showResumen = true;
        },
        cancelarAgendamiento: function () {
            this.showResumen = false;
            this.showAgendamiento = false;
            this.showClientSearch = false;
            this.showProductSearchBox = false;
            this.fecha = {};
            this.producto = {};
            this.agendarCliente = {};
        },
        calculateProductsAmount() {
            this.montoProductos = 0;
            this.impuestos = 0;
            for (let item in this.OrderGetPosCart) {
                this.OrderGetPosCart[item]['product']['subTotal'] = 0;
                this.OrderGetPosCart[item]['product']['impuestoTotal'] = 0;
                this.OrderGetPosCart[item]['product']['iva'] = 0;

                if (typeof this.OrderGetPosCart[item] !== 'undefined') {

                    // si es un producto por pago directo
                    if (this.OrderGetPosCart[item]['product']['id'] === '_DIRECT_AMOUNT_') {
                        this.hasProducts = false;
                    }
                    const productPrice = (this.OrderGetPosCart[item]['product']['onSale']) ? this.OrderGetPosCart[item]['product']['precioOnSale'] : this.OrderGetPosCart[item]['product']['precio'];


                    if ((productPrice > 0) && (typeof this.OrderGetPosCart[item]['quantity'] !== 'undefined')) {
                        const amount = parseFloat(this.OrderGetPosCart[item]['quantity']) * parseFloat(productPrice);
                        this.OrderGetPosCart[item]['product']['subTotal'] = amount;
                        //const iva = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestos']);
                        if (this.emitirFactura) {
                            if (this.hasProducts) {
                                if (typeof this.OrderGetPosCart[item]['product']['impuestoMonto'] !== 'undefined' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '0' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '') {
                                    const impuestos = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestoMonto']);
                                    this.montoProductos += impuestos + amount;

                                    this.OrderGetPosCart[item]['product']['subTotal'] = impuestos + amount;
                                    this.OrderGetPosCart[item]['product']['impuestoTotal'] = impuestos;
                                    this.impuestos = parseFloat(this.impuestos) + impuestos;
                                }

                            }
                            else {
                                const impuestos = 0;
                                this.montoProductos += impuestos + amount;

                            }

                            const montoGravable = amount / 1.12;
                            const IVA = amount - montoGravable;
                            this.OrderGetPosCart[item]['product']['iva'] = IVA;
                        }
                        else {
                            this.montoProductos += amount;
                        }
                    }
                }
            }
            this.montoProductos = this.montoProductos.toFixed(2);
        },
        createOrder(data = {}) {

            const self = this;

            const ordenDetalle = {};
            let counter = 0;
            this.calculateProductsAmount();

            // si es monto directo
            if (typeof self.OrderGetPosCart['_DIRECT_AMOUNT_'] !== 'undefined') {
                ordenDetalle[counter] = {
                    "productoId": 0,
                    "productoPrecio": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['precio'],
                    "productoCantidad": self.OrderGetPosCart['_DIRECT_AMOUNT_']['quantity'],
                    "productoDescMontoDirecto": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['nombre']
                };
            }
            else {
                // validate if posCart is corrupted or something0
                for (let item in self.OrderGetPosCart) {
                    if (typeof self.OrderGetPosCart[item] !== 'undefined') {

                        const precio = (self.OrderGetPosCart[item]['product']['onSale']) ? self.OrderGetPosCart[item]['product']['precioOnSale'] : self.OrderGetPosCart[item]['product']['precio'];
                        const productTmp = self.OrderGetPosCart[item]['product'];

                        ordenDetalle[counter] = {};
                        ordenDetalle[counter]['productoId'] = productTmp.id;
                        ordenDetalle[counter]['productoPrecio'] = precio;
                        ordenDetalle[counter]['productoCantidad'] = (productTmp.agendable) ? 1 : productTmp.cantidad; // Si es agendable, siempre llevará cantidad 1
                        ordenDetalle[counter]['citaLocacion'] = self.fecha.locacionId;
                        ordenDetalle[counter]['citaFecha'] = self.fecha.fecha;
                        ordenDetalle[counter]['citaHora'] = self.fecha.horario;
                        ordenDetalle[counter]['citaEntrecita'] = self.fecha.entrecita;
                        ordenDetalle[counter]['citaEntrecitaMotivo'] = self.fecha.entrecitaMotivo;
                        ordenDetalle[counter]['citaColor'] = self.fecha.citaColor;
                        ordenDetalle[counter]['citaPersonal'] = self.fecha.personal;

                        ordenDetalle[counter]['clienteId'] = self.fecha.clienteCita.id;
                        ordenDetalle[counter]['clienteNombre'] = self.fecha.clienteCita.nombres;
                        ordenDetalle[counter]['clienteApellido'] = self.fecha.clienteCita.apellidos;
                        ordenDetalle[counter]['clienteCuiPassport'] = self.fecha.clienteCita.dpi;
                        ordenDetalle[counter]['clienteTelefono'] = self.fecha.clienteCita.telefono;
                        ordenDetalle[counter]['clienteEmail'] = self.fecha.clienteCita.email;
                        ordenDetalle[counter]['clienteFechaNacimiento'] = self.fecha.clienteCita.fechaNacimiento;
                        ordenDetalle[counter]['clienteGenero'] = self.fecha.clienteCita.genero;
                        ordenDetalle[counter]['clienteMotivoCita'] = self.fecha.clienteCita.motivoCita;
                    }
                    counter++;
                }
            }

            if (Object.keys(ordenDetalle).length === 0) {
                API.showErrorAlert('Su orden no contiene productos');
                return false;
            }

            if (typeof self.OrderGetClientSelected.nombres === 'undefined') {
                API.showErrorAlert('Debe seleccionar un cliente o Consumidor Final');
                return false;
            }

            const dataPay = {
                "pagoMetodo": self.paymentMethod, // card, pos, cash, qr, contra entrega, recoger en tienda
                "pagoTcNombre": '',
                "pagoTcNumero": '',
                "pagoTcExp": '',
                "pagoTcCvv": '',
                "pagoCuotas": '',
                "pagoAfiliacion": '',

                "clienteNombre": (typeof self.OrderGetClientSelected.nombres !== 'undefined') ? self.OrderGetClientSelected.nombres + ' ' + self.OrderGetClientSelected.apellidos : '',
                "clienteTaxId": (typeof self.OrderGetClientSelected.taxId !== 'undefined') ? self.OrderGetClientSelected.taxId : '',
                "clienteEmail": (typeof self.OrderGetClientSelected.email !== 'undefined') ? self.OrderGetClientSelected.email : '',
                "clienteTelefono": (typeof self.OrderGetClientSelected.telefono !== 'undefined') ? self.OrderGetClientSelected.telefono : '',
                "clienteDireccion": (typeof self.OrderGetClientSelected.direccion !== 'undefined') ? self.OrderGetClientSelected.direccion : '',

                "ordenTiendaDomain": self.AuthGetUserInfo.s,
                "ordenMonto": self.montoProductos,
                "ordenDetalle": ordenDetalle,
                "ordenDesactivarNotificaciones": 1,
                "ordenId": 0,

                "facturaEmitir": false,
                "reciboEmitir": false,
                "facturaCambiaria": false,
                "facturaFecha": false,
            }


            API.send('POST', 'payments/create-pay-order', dataPay,
                function (response) {

                    if (typeof response.data !== 'undefined') {
                        /*self.orderCreated = response.data.ordenCodigo;
                        self.orderCreatedId = response.data.ordenId;
                        self.orderPayLink = response.data.paylink;
                        if (typeof response.data.extra.factura !== 'undefined') {
                            self.facturaDetails = response.data.extra.factura;
                        }
                        if (typeof response.data.extra.recibo !== 'undefined') {
                            self.reciboDetails = response.data.extra.recibo;
                        }
                        self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                        self.showOrderDetailsCreation = true;*/
                        self.$store.dispatch('OrderClearProducts');
                        self.$store.dispatch('OrderPosClientClear');

                        self.showResumen = false;
                        self.fecha = {};
                        self.producto = {};
                        self.agendarCliente = {};

                        API.showSuccessAlert('Cita agendada con éxito');
                        self.cancelarAgendamiento();
                        self.loadItems();
                        self.loadCountItems();
                    }
                    else {
                        API.showErrorAlert('Error al crear orden, por favor, intente de nuevo');
                    }
                },
                function (response) {
                    if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                        API.showErrorAlert(response.data.extra.error);
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                })
        },
        createOrderMultiple(){
            const self = this;
            //Indicamos que el agendamiento multiple aplica el mismo producto para todos
            let orderCount=0;
            let result= [];
            API.showConfirm('¡Atencion!', 'Todas las ordenes multiples se agendaran con los productos seleccionados', function () {
            for (const orden of self.ordersMultiples) {
            const ordenDetalle = {};
            let counter = 0;
            self.calculateProductsAmount();

            // si es monto directo
            if (typeof self.OrderGetPosCart['_DIRECT_AMOUNT_'] !== 'undefined') {
                ordenDetalle[counter] = {
                    "productoId": 0,
                    "productoPrecio": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['precio'],
                    "productoCantidad": self.OrderGetPosCart['_DIRECT_AMOUNT_']['quantity'],
                    "productoDescMontoDirecto": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['nombre']
                };
            }
            else {
                // validate if posCart is corrupted or something0
                for (let item in self.OrderGetPosCart) {
                    if (typeof self.OrderGetPosCart[item] !== 'undefined') {

                        const precio = (self.OrderGetPosCart[item]['product']['onSale']) ? self.OrderGetPosCart[item]['product']['precioOnSale'] : self.OrderGetPosCart[item]['product']['precio'];
                        const productTmp = self.OrderGetPosCart[item]['product'];

                        ordenDetalle[counter] = {};
                        ordenDetalle[counter]['productoId'] = productTmp.id;
                        ordenDetalle[counter]['productoPrecio'] = precio;
                        ordenDetalle[counter]['productoCantidad'] = (productTmp.agendable) ? 1 : productTmp.cantidad; // Si es agendable, siempre llevará cantidad 1
                        ordenDetalle[counter]['citaLocacion'] = orden.locacionId;
                        ordenDetalle[counter]['citaFecha'] = orden.fecha;
                        ordenDetalle[counter]['citaHora'] = orden.horario;
                        ordenDetalle[counter]['citaEntrecita'] = orden.entrecita;
                        ordenDetalle[counter]['citaEntrecitaMotivo'] = orden.entrecitaMotivo;
                        ordenDetalle[counter]['citaPersonal'] = orden.personal;

                        ordenDetalle[counter]['clienteId'] = orden.clienteCita.id;
                        ordenDetalle[counter]['clienteNombre'] = orden.clienteCita.nombres;
                        ordenDetalle[counter]['clienteApellido'] = orden.clienteCita.apellidos;
                        ordenDetalle[counter]['clienteCuiPassport'] = orden.clienteCita.dpi;
                        ordenDetalle[counter]['clienteTelefono'] = orden.clienteCita.telefono;
                        ordenDetalle[counter]['clienteEmail'] = orden.clienteCita.email;
                        ordenDetalle[counter]['clienteFechaNacimiento'] = orden.clienteCita.fechaNacimiento;
                        ordenDetalle[counter]['clienteGenero'] = orden.clienteCita.genero;
                        ordenDetalle[counter]['clienteMotivoCita'] = orden.clienteCita.motivoCita;
                    }
                    counter++;
                }
            }

            if (Object.keys(ordenDetalle).length === 0) {
                API.showErrorAlert('Su orden no contiene productos');
                return false;
            }

            if (typeof self.OrderGetClientSelected.nombres === 'undefined') {
                API.showErrorAlert('Debe seleccionar un cliente o Consumidor Final');
                return false;
            }

            const dataPay ={
                "pagoMetodo": self.paymentMethod, // card, pos, cash, qr, contra entrega, recoger en tienda
                "pagoTcNombre": '',
                "pagoTcNumero": '',
                "pagoTcExp": '',
                "pagoTcCvv": '',
                "pagoCuotas": '',
                "pagoAfiliacion": '',

                "clienteNombre":orden.clienteCita.nombres+' '+orden.clienteCita.apellidos || '',
                "clienteTaxId":orden.clienteCita.taxId || '',
                "clienteEmail":orden.clienteCita.email || '',
                "clienteTelefono":orden.clienteCita.telefono || '',
                "clienteDireccion":orden.clienteCita.direccion || '',

                "ordenTiendaDomain": self.AuthGetUserInfo.s,
                "ordenMonto": self.montoProductos,
                "ordenDetalle": ordenDetalle,
                "ordenDesactivarNotificaciones": 1,
                "ordenId": 0,

                "facturaEmitir": false,
                "reciboEmitir": false,
                "facturaCambiaria": false,
                "facturaFecha": false,
            };
            orderCount++;
            API.send('POST', 'payments/create-pay-order', dataPay,
                function (response) {

                    if (typeof response.data !== 'undefined') {

                        // API.showSuccessAlert('Cita agendada con éxito');
                            result.push({
                                status: 1,
                                cliente:dataPay.clienteNombre,
                                sucessMesage: 'Orden agendada con exito!',
                                errorMessage: ''
                            });
                            if(self.ordersMultiples.length  === orderCount){

                                self.ordersMultiples=[];
                                self.modalMultiple=false;
                                self.$store.dispatch('OrderClearProducts');
                                self.$store.dispatch('OrderPosClientClear');
                                self.producto = {};
                                self.cancelarAgendamiento();
                                self.loadItems();
                                self.loadCountItems();                             
                                let errorsCount = 0;
                                for (const item of result) {
                                    if(item.status == 0){
                                        errorsCount++;
                                    }
                                }
                                if(errorsCount==0){
                                    API.showSuccessAlert('Citas agendadas con éxito');
                                }else if (errorsCount==result.length){
                                    API.showErrorAlert('Error al crear las ordenes, por favor, intente de nuevo');
                                }else {
                                    API.showErrorAlert('Algunas ordenes presentaron errores al ser registradas');
                                }
                            }

                    }
                    else {
                        result.push({
                                status: 0,
                                cliente:dataPay.clienteNombre,
                                sucessMesage: '',
                                errorMessage: 'Error al crear orden, por favor, intente de nuevo'
                            });
                        // API.showErrorAlert('Error al crear las ordenes, por favor, intente de nuevo');
                    }
                },
                function (response) {
                    if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                        result.push({
                                status: 0,
                                cliente:dataPay.clienteNombre,
                                sucessMesage: '',
                                errorMessage: response.data.extra.error
                            });
                    }
                    else {
                        result.push({
                                status: 0,
                                cliente:dataPay.clienteNombre,
                                sucessMesage: '',
                                errorMessage: response.msg
                            });
                    }

                    if(self.ordersMultiples.length  === orderCount){

                            self.ordersMultiples=[];
                            self.modalMultiple=false;
                            self.$store.dispatch('OrderClearProducts');
                            self.$store.dispatch('OrderPosClientClear');
                            self.producto = {};
                            self.cancelarAgendamiento();
                            self.loadItems();
                            let errorsCount = 0;
                            for (const item of result) {
                                if(item.status == 0){
                                    errorsCount++;
                                }
                            }
                            if(errorsCount==0){
                                API.showSuccessAlert('Citas agendadas con éxito');
                            }else if (errorsCount==result.length){
                                API.showErrorAlert('Error al crear las ordenes, por favor, intente de nuevo');
                            }else {
                                API.showErrorAlert('Algunas ordenes presentaron errores al ser registradas');
                            }
                        }
                });
        }

            });

        },
        deleteOrdenMulti(orden){
            let filteredOrders = this.ordersMultiples.filter((item,key) => key !== orden);
            this.ordersMultiples = filteredOrders;
        },
        AddClient(data){
            this.ordersMultiples.push(data);
        },
        onDragStart (event, item) {
            event.dataTransfer.dropEffect = 'copy'
            event.dataTransfer.effectAllowed = 'move'
            event.dataTransfer.setData('ID', item.id)
        },

        onDragEnter (e, type, scope) {
            e.preventDefault()
            return true
        },

        onDragOver (e, type, scope) {
            e.preventDefault()
            return true
        },

        onDragLeave (e, type, scope) {
            return false
        },

        onDrop (e, type, scope) {

            const itemID = parseInt(e.dataTransfer.getData('ID'), 10)
            const event = { ...this.defaultEvent }
            event.id = this.events.length + 1
            const item = this.dragItems.filter(item => item.id === itemID)
            event.type = item[ 0 ].id
            event.name = item[ 0 ].name
            event.date = scope.timestamp.date
            if (type === 'interval') {
                event.time = scope.timestamp.time
            }
            else { // head-day
                event.allDay = true
            }
            this.events.push(event)
            return false
        },
        getEvents(timestamp) {
            const times = this.eventsMap[ timestamp.date ];
            if (times) {
                return times.filter(item => item.time === timestamp.time)
            }
            return []
        },
        getEventsC(timestamp) {
            for (const item of this.eventsCount) {
                if (item.fecha === timestamp.date) {
                    return item.totalReservas;
                }
            }
            return 0;
        },
        hasEvents (timestamp, allCalendar = false) {
            if(!allCalendar){
                return this.getEvents(timestamp).length > 0
            }
            else{
                return this.getEventsC(timestamp) > 0
            }

        },
        checkCountEvents(timestamp){
            return this.eventsCount[timestamp] ? true : false
        },
        onIntervalClass ({ scope }) {
            return {
                droppable: scope.droppable === true
            }
        },

        onWeekdayClass ({ scope }) {
            return {
                droppable: scope.droppable === true
            }
        },
        onClickDay (data) {
            this.selectedDate = data.date;
        },
        hasWeekdayEvents (weekday) {
            return this.getWeekdayEvents(weekday).length > 0
        },
        getWeekdayEvents (weekday) {
            const events = this.eventsMap[ weekday ]
            if (!events) return []
            return events
        },
        addToYear (amount) {
            // parse current date to timestamp
            let ts = parseTimestamp(this.selectedDate)
            // add specified amount of days
            ts = addToDate(ts, { year: amount })
            // re-assign values
            this.selectedDate = ts.date
            this.selectedYear = ts.year
        },
        onDayClass ({ scope }) {
            return {
                droppable: scope.droppable === true
            }
        },

        onToday () {
            this.$refs.calendar.moveToToday()
        },
        onPrev () {
            this.$refs.calendar.prev()
            this.startProgress();
        },
        onNext () {
            this.$refs.calendar.next()
            this.startProgress();
        },
        onPrevM () {
            this.$refs.calendarM.prev()
            this.startProgress();
        },
        onNextM () {
            this.$refs.calendarM.next()
            this.startProgress();
        },
        onMoved (data) {
           
        },
        startProgress() {
            // this.loadingItems = true;
            const progressBar = this.$refs.progressBar;
            if (progressBar) {
                progressBar.style.width = '0%';  // Reinicia la barra
                progressBar.style.transition = 'width 0.5s ease';
                setTimeout(() => {
                    progressBar.style.width = '100%';  // Comienza la carga
                }, 10);
            }
        },
        finishProgress() {
            // this.loadingItems = false;
            const progressBar = this.$refs.progressBar;
            if (progressBar) {
                progressBar.style.transition = 'width 0.2s ease';
                progressBar.style.width = '100%';
                setTimeout(() => {
                    progressBar.style.width = '0%';
                }, 200);
            }
        },
        onChange(data) {
            const newStartOfMonth = dayjs(data.start).startOf('month');
            const newEndOfMonth = dayjs(data.end).endOf('month');

            // Verifica si el mes ha cambiado
            const newMonth = newStartOfMonth.month();
            if (newMonth !== this.currentMonth) {
                // Actualiza las fechas de inicio y fin del mes
                this.dateStartOfMonth = newStartOfMonth.format('YYYY-MM-DD');
                this.dateEndOfMonth = newEndOfMonth.format('YYYY-MM-DD');
                // Actualiza el mes actual
                this.currentMonth = newMonth;
                // Luego, carga los items para el nuevo rango de fechas
                this.startProgress();
                this.loadCountItems(); 
            }
        },
        onChangeDay(data) {
            const newStartDay = dayjs(data.start);
            const newEndDay = dayjs(data.end);
            
            let start = newStartDay.format('YYYY-MM-DD');
            let end = newEndDay.format('YYYY-MM-DD');
            // Actualiza las fechas de inicio y fin del mes
            
            // Verifica si los dias cambiaron
            if ((this.dateStart !== start) && (this.dateEnd !== end)) {
                this.dateStart = start;
                this.dateEnd = end;
                this.startProgress();
                this.loadItems();
            }
        },
        onClickDate (data) {
            this.selectedDate = data.scope.timestamp.date;
        },
        onClickTime (data) {
            //this.showProductSearchBox = true;
            const fecha = data.scope.timestamp.date;
            const hora = data.scope.timestamp.time;

            this.dateSelected = fecha;
            this.horaSelected = hora;

            // Hacer visible el modal
            this.modalNewIsVisible = true;
        },
        onClickInterval (data) {
        },
        onClickHeadIntervals (data) {
          
        },
        onClickHeadDay (data) {
          
        },
        onClickHeadDate(data) {
          
            let fixAge
            if((typeof data.extraData.metadata !== 'undefined')&&(typeof data.extraData.metadata.datos_paciente !== 'undefined')&&(typeof data.extraData.metadata.datos_paciente.fecha_nacimiento !== 'undefined')){
                 fixAge = this.calcularEdad(data.extraData.metadata.datos_paciente.fecha_nacimiento);
            }else{
                 fixAge = 0;
            }
            data.extraData.edad = fixAge;
            this.isEventClicked = data;
            this.isModalVisible = true;
            this.modalEvent = data;
        },
        calcularEdad(fechaNacimiento) {
            return Tools.calculateAge(fechaNacimiento)
        },

    },

};
</script>

