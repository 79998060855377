<template>
    <layout :active="40" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Mi cuenta</h4>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item">
                                        <router-link to="/clients" class="nav-link" :class="active === 40 && 'active'">
                                            <i class="mdi mdi-account"></i>
                                            <span>Listado de clientes</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/clients/admision" class="nav-link" :class="active === 42 && 'active'">
                                            <i class="mdi mdi-hospital"></i>
                                            <span>Admisión de Pacientes</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
};
</script>
