<template>
    <box title="Listado de adjuntos" icon="fa fa-paperclip" :contraction='true'>
        <div v-if="typeof GetMedicoFichaActive.metadata.attach === 'undefined'">
            <div class="text-center text-danger">No tiene adjuntos cargados</div>
        </div>
        <div v-else>
            <div class="modo-vista mt-2 p-2 d-flex justify-content-end align-items-center">
                <div @click="viewMode = 'table'" class="icon-container p-2">
                    <i class="fa-solid fa-table-list p-0 m-0"></i>
                </div>
                <div @click="viewMode = 'grid'" class="icon-container ml-2 p-2">
                    <i class="fa-solid fa-grip p-0 m-0"></i>
                </div>
            </div>

            <!-- Vista tabla -->
            <section v-if="viewMode === 'table'" class="mt-1 p-2">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Fecha de carga</th>
                            <th>Hora de carga</th>
                            <th>Categoría</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(attachCat, key) in filteredAttachments" :key="key">
                            <template v-for="item in attachCat" :key="item.id">
                                <tr>
                                    <td>{{ item.detail.name || 'Sin nombre' }}</td>
                                    <td>{{ dayjs(item.createdAt).format('DD-MM-YYYY') }}</td>
                                    <td>{{ dayjs(item.createdAt).format('HH:mm') }}</td>
                                    <td>{{ selectBoxTitle(key) }}</td>
                                    <td>
                                        <i class="fa-regular fa-eye text-primary mr-2 p-2" @click="openPDFModal(item.urlTMP.fileUrl, item)"></i>
                                        <i class="fas fa-download text-success mr-2 p-2" @click="downloadAdjunto(item)"></i>
                                        <i class="fas fa-envelope text-info mr-2 p-2" @click="sendAdjunto(item, key)"></i>
                                        <i class="fas fa-trash text-danger p-2" @click="deleteAdjunto(item)"></i>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </section>

            <!-- Vista grid -->
            <section v-else class="row mt-1 p-2">
                <template v-for="(attachCat, categoryKey) in filteredAttachments" :key="categoryKey">
                    <template v-for="item in attachCat" :key="item.id">
                        <div class="col-12 col-sm-4 mb-3">
                            <div class="attachmentLine">
                                <div class="mb-3">
                                    <div title="Ver pdf" @click="openPDFModal(item.urlTMP.fileUrl, item)" class="d-flex justify-content-between align-items-center title_container">
                                        <div>
                                            <h6 class="font-weight-bold m-0">{{ item.detail.name || 'Sin nombre' }}</h6>
                                            <small class="text-muted">{{ selectBoxTitle(categoryKey) }}</small>
                                        </div>
                                        <i class="fa-regular fa-eye expand-icon"></i>
                                    </div>
                                    <hr class="my-1">
                                    <div>
                                        <b>Fecha de carga:</b> {{ dayjs(item.createdAt).format('DD-MM-YYYY') }}
                                    </div>
                                    <div>
                                        <b>Hora de carga:</b> {{ dayjs(item.createdAt).format('HH:mm') }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <div v-if="item.urlTMP.fileUrl" class="pdf-container">
                                            <div class="loading-overlay" v-show="!iframeLoaded[item.id] && !loadingTimeout[item.id]">
                                                <div class="loading-content">
                                                    <i class="fas fa-spinner fa-spin"></i>
                                                    <p>Cargando visualización del documento...</p>
                                                </div>
                                            </div>
                                            <div class="loading-overlay" v-show="loadingTimeout[item.id]">
                                                <div class="error-preview">
                                                    <i class="fas fa-exclamation-triangle text-warning mb-2"></i>
                                                    <p class="text-muted mb-2">La vista previa no está disponible</p>
                                                    <div class="d-flex flex-column gap-1">
                                                        <button class="btn btn-primary btn-xs px-2 py-1" @click="downloadAdjunto(item)">
                                                            <i class="fas fa-download mr-1"></i>Descargar
                                                        </button>
                                                        <template v-if="getFileType(item) !== 'word'">
                                                            <button class="btn btn-info btn-xs px-2 py-1" @click="openPDFModal(item.urlTMP.fileUrl, item)">
                                                                <i class="fas fa-expand mr-1"></i>Ver ampliado
                                                            </button>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <!-- Vista previa para PDF -->
                                            <template v-if="getFileType(item) === 'pdf'">
                                                <iframe 
                                                    :src="generateGoogleViewerUrl(item.urlTMP.fileUrl)"
                                                    width="100%" 
                                                    height="400"
                                                    frameborder="0"
                                                    class="pdf-viewer"
                                                    @load="handleIframeLoad($event, item.id)"
                                                    @error="handleIframeError(item.id)"
                                                ></iframe>
                                            </template>

                                            <!-- Vista previa para documentos Word -->
                                            <template v-else-if="getFileType(item) === 'word'">
                                                <div class="loading-overlay" style="position: relative; min-height: 350px;">
                                                    <div class="error-preview">
                                                        <i class="fas fa-exclamation-triangle text-warning mb-2"></i>
                                                        <p class="text-muted mb-2">La vista previa no está disponible</p>
                                                        <div class="d-flex flex-column gap-1">
                                                            <button class="btn btn-primary btn-xs px-2 py-1" @click="downloadAdjunto(item)">
                                                                <i class="fas fa-download mr-1"></i>Descargar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <!-- Los botones de acciones para documentos Word se mostrarán en la fila de acciones al final de la card -->
                                            </template>

                                            <!-- Vista previa para imágenes -->
                                            <template v-else-if="getFileType(item) === 'image'">
                                                <img 
                                                    :src="item.urlTMP.fileUrl" 
                                                    class="img-fluid" 
                                                    @load="handleIframeLoad($event, item.id)"
                                                    @error="handleIframeError(item.id)"
                                                >
                                            </template>
                                        </div>
                                        <div v-else>
                                            <p>Archivo no disponible</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">                   
                                    <div class="col-12 d-flex justify-content-end align-items-center p-2">
                                        <button class="m-1 btn btn-info btn-sm action-button" @click="sendAdjunto(item, categoryKey)">
                                            Enviar <i class="fas fa-envelope"></i>
                                        </button>
                                        <button class="m-1 btn btn-primary btn-sm action-button" @click="downloadAdjunto(item)">
                                            Descargar <i class="fas fa-download"></i>
                                        </button>
                                        <button class="m-1 btn btn-danger btn-sm action-button" @click="deleteAdjunto(item)">
                                            Eliminar <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </section>
        </div>
        <hr>
        <div>
            <h5 class="text-primary">Carga de adjuntos <i class="fa fa-upload ml-3"></i></h5>
        </div>
        <div class="row mt-5">
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar Laboratorios</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader
                                url="orders/metadata-attachments/save"
                                :accepted-mime="[
                                    'image/*',
                                    'application/pdf',
                                    'application/x-xls',
                                    'application/vnd.ms-excel',
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/dicom', // .dcm
                                    'application/hdr',    // .hdr
                                    'application/nii',    // .nii
                                    'application/ktx',
                                ]"
                                remove-file-on-finish="1"
                                :extra-data-form="laboratoriosUploadExtraDataForm"
                                @fileSuccess="fileSuccess"
                                ></FileUploader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar Imágenes</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader url="orders/metadata-attachments/save"
                                :accepted-mime="['image/*', 'application/pdf']"
                                    remove-file-on-finish="1"
                                    :extra-data-form="imagenesUploadExtraDataForm"
                                    @fileSuccess="fileSuccess"></FileUploader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div>
                    <h5>Cargar otros adjuntos</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <FileUploader
                                url="orders/metadata-attachments/save"
                                :accepted-mime="[
                                    'image/*',
                                    'file/.dcm',
                                    'application/pdf',
                                    'application/x-xls',
                                    'application/vnd.ms-excel',
                                    'application/msword',
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                    'application/octet-stream',
                                ]"
                                remove-file-on-finish="1"
                                :extra-data-form="otrosUploadExtraDataForm"
                                @fileSuccess="fileSuccess"
                            ></FileUploader>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal para visualización de PDF -->
        <div v-if="modalPDFViewer" class="order-search" id="modal-cita" tabindex="-1" aria-labelledby="modal-cita-titulo" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <h5 class="modal-title" id="modal-cita-titulo">Vista de documento</h5>
                            <button type="button" class="btn btn-danger" @click="handlerModal" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                    <div class="modal-body position-relative">
                        <!-- Contenido para documentos Word -->
                        <div v-if="getFileType(medicDocUrl) === 'word'" class="d-flex flex-column align-items-center justify-content-center" style="height: 500px;">
                            <div class="error-preview text-center p-4">
                                <i class="fas fa-exclamation-triangle text-warning mb-3" style="font-size: 3rem;"></i>
                                <h4 class="text-muted mb-3">La vista previa no está disponible</h4>
                                <p class="text-muted mb-4">Los documentos Word no pueden ser visualizados directamente.</p>
                                <div class="d-flex flex-column gap-1">
                                    <button class="btn btn-primary px-2 py-1" @click="downloadAdjunto(currentAdjunto)">
                                        <i class="fas fa-download mr-2"></i>Descargar
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Contenido para PDFs y otros documentos -->
                        <template v-else>
                            <div class="loading-overlay" v-show="!modalIframeLoaded">
                                <div class="loading-content">
                                    <i class="fas fa-spinner fa-spin"></i>
                                    <p>Cargando visualización del documento...</p>
                                </div>
                            </div>
                            <iframe 
                                id="pdfViewer" 
                                :src="generateGoogleViewerUrl(medicDocUrl)" 
                                style="width: 100%; height: 700px;" 
                                frameborder="0"
                                @load="handleModalIframeLoad"
                            ></iframe>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </box>
    <box title="Histórico de Adjuntos" icon="fa fa-history" :contraction='true'>
        <div v-if="typeof GetMedicoFichaActive.metadata.attach === 'undefined'">
            <div class="text-center text-danger">No tiene adjuntos cargados</div>
        </div>
        <template v-else>
            <div class="modo-vista mt-2 p-2 d-flex justify-content-end align-items-center">
                <div @click="viewMode = 'table'" class="icon-container p-2">
                    <i class="fa-solid fa-table-list p-0 m-0"></i>
                </div>
                <div @click="viewMode = 'grid'" class="icon-container ml-2 p-2">
                    <i class="fa-solid fa-grip p-0 m-0"></i>
                </div>
            </div>
            <section v-if="GetHistoryFiltred && viewMode === 'grid'" class="row mt-1 p-2">
                <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                    <div class="col-12 mt-2" v-if="Object.keys(item.metadata).length > 0">
                        <h6 class="font-weight-bold w-100">Adjuntos de Orden N° {{ item.ordenId }} con fecha {{ dayjs(item.fechaReserva).format('DD-MM-YYYY') }}</h6>
                        <hr class="my-1 w-100">
                        <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                            <div class="row mt-2" v-if="Object.keys(attachCat).length > 0">
                                <template v-if="(key==='L') || (key==='I') || (key==='O')">
                                    <h6 class="font-weight-bold col-12">{{ selectBoxTitle(key) }}</h6>
                                    <div class="col-12 row p-2">
                                        <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                            <div class="col-12 col-sm-4 mb-3">
                                                <div class="attachmentLine">
                                                    <div class="mb-3">
                                                        <div title="Ver pdf" @click="openPDFModal(attachItem.urlTMP.fileUrl, attachItem)" class="d-flex justify-content-between align-items-center title_container">
                                                            <h6 class="font-weight-bold m-0">{{ attachItem.detail.name || 'Sin nombre' }}</h6>
                                                            <i class="fa-regular fa-eye expand-icon"></i>
                                                        </div>
                                                        <hr class="my-1">
                                                        <div>
                                                            <b>Fecha de carga:</b> {{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') || '' }}
                                                        </div>
                                                        <div>
                                                            <b>Hora de carga:</b> {{ dayjs(attachItem.createdAt).format('HH:mm') || '' }}
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 text-center">
                                                            <div v-if="attachItem.urlTMP.fileUrl" class="pdf-container">
                                                                <div class="loading-overlay" v-show="!iframeLoaded[attachKey] && !loadingTimeout[attachKey]">
                                                                    <div class="loading-content">
                                                                        <i class="fas fa-spinner fa-spin"></i>
                                                                        <p>Cargando visualización del documento...</p>
                                                                    </div>
                                                                </div>
                                                                <div class="loading-overlay" v-show="loadingTimeout[attachKey]">
                                                                    <div class="error-preview">
                                                                        <i class="fas fa-exclamation-triangle text-warning mb-2"></i>
                                                                        <p class="text-muted mb-2">La vista previa no está disponible</p>
                                                                        <div class="d-flex flex-column gap-1">
                                                                            <button class="btn btn-primary btn-xs px-2 py-1" @click="downloadAdjunto(attachItem)">
                                                                                <i class="fas fa-download mr-1"></i>Descargar
                                                                            </button>
                                                                            <template v-if="getFileType(attachItem) !== 'word'">
                                                                                <button class="btn btn-info btn-xs px-2 py-1" @click="openPDFModal(attachItem.urlTMP.fileUrl)">
                                                                                    <i class="fas fa-expand mr-1"></i>Ver ampliado
                                                                                </button>
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <!-- Vista previa para PDF -->
                                                                <template v-if="getFileType(attachItem) === 'pdf'">
                                                                    <iframe 
                                                                        :src="generateGoogleViewerUrl(attachItem.urlTMP.fileUrl)"
                                                                        width="100%" 
                                                                        height="400"
                                                                        frameborder="0"
                                                                        class="pdf-viewer"
                                                                        @load="handleIframeLoad($event, attachKey)"
                                                                        @error="handleIframeError(attachKey)"
                                                                    >
                                                                        <p>El visor de PDF no está disponible. Puedes <a :href="attachItem.urlTMP.fileUrl" target="_blank">descargar el PDF</a> en su lugar.</p>
                                                                    </iframe>
                                                                </template>

                                                                <!-- Vista previa para documentos Word -->
                                                                <template v-else-if="getFileType(attachItem) === 'word'">
                                                                    <div class="loading-overlay" style="position: relative; min-height: 350px;">
                                                                        <div class="error-preview">
                                                                            <i class="fas fa-exclamation-triangle text-warning mb-2"></i>
                                                                            <p class="text-muted mb-2">La vista previa no está disponible</p>
                                                                            <div class="d-flex flex-column gap-1">
                                                                                <button class="btn btn-primary btn-xs px-2 py-1" @click="downloadAdjunto(attachItem)">
                                                                                    <i class="fas fa-download mr-1"></i>Descargar
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <!-- Los botones de acciones para documentos Word se mostrarán en la fila de acciones al final de la card -->
                                                                </template>

                                                                <!-- Vista previa para imágenes -->
                                                                <template v-else-if="getFileType(attachItem) === 'image'">
                                                                    <img 
                                                                        :src="attachItem.urlTMP.fileUrl" 
                                                                        class="img-fluid" 
                                                                        @load="handleIframeLoad($event, attachKey)"
                                                                        @error="handleIframeError(attachKey)"
                                                                    >
                                                                </template>
                                                            </div>
                                                            <div v-else>
                                                                Sin PDF adjunto.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Agregar botones de acción a todas las tarjetas del histórico -->
                                                    <div class="row">                   
                                                        <div class="col-12 d-flex justify-content-end align-items-center p-2">
                                                            <button class="m-1 btn btn-primary btn-sm action-button" @click="downloadAdjunto(attachItem)">
                                                                Descargar <i class="fas fa-download"></i>
                                                            </button>
                                                            <button class="m-1 btn btn-danger btn-sm action-button" @click="deleteAdjunto(attachItem)">
                                                                Eliminar <i class="fas fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                </template>
            </section>
            <section v-if="GetHistoryFiltred && viewMode === 'table'" class="mt-1 p-2">
                <table class="table table-striped table-sm">
                    <thead>
                        <tr>
                            <th>Orden N°</th>
                            <th>Nombre</th>
                            <th>Fecha de carga</th>
                            <th>Hora de carga</th>
                            <th>Categoría</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, key) in GetHistoryFiltred" :key="key">
                            <template v-if="Object.keys(item.metadata).length > 0">
                                <template v-for="(attachCat, key) in item.metadata.attach" :key="key">
                                    <template v-if="(key==='L') || (key==='I') || (key==='O')">
                                        <template v-for="(attachItem, attachKey) in attachCat" :key="attachKey">
                                            <tr>
                                                <td>{{ item.ordenId }}</td>
                                                <td>{{ attachItem.detail.name || 'Sin nombre' }}</td>
                                                <td>{{ dayjs(attachItem.createdAt).format('DD-MM-YYYY') }}</td>
                                                <td>{{ dayjs(attachItem.createdAt).format('HH:mm') }}</td>
                                                <td>{{ selectBoxTitle(key) }}</td>
                                                <td>
                                                    <i class="fa-regular fa-eye text-primary mr-2 p-2" @click="openPDFModal(attachItem.urlTMP.fileUrl, attachItem)"></i>
                                                    <i class="fas fa-download text-success mr-2 p-2" @click="downloadAdjunto(attachItem)"></i>
                                                    <i class="fas fa-trash text-danger p-2" @click="deleteAdjunto(attachItem)"></i>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </section>
            <section v-else class="mt-3 d-flex justify-content-center">
                <p class="text-muted">No se encontro historico de adjuntos</p>
            </section>
        </template>
    </box>
</template>
<script>
import API from "src/core/Api";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import FileUploader from "src/components/files/FileUploader.vue";
import Tools from "src/core/Tools";

// Componentes

export default {
    components: {
        FileUploader,
        Box,
        Slider
    },
    props: [
        'type',
    ],
    data() {
        return {
            adjunto: {},
            currentAdjunto: null, // Añadido para el manejo de documentos Word en el modal
            laboratoriosUploadExtraDataForm: {
                orderId: 0,
                category: 'L',
            },
            imagenesUploadExtraDataForm: {
                orderId: 0,
                category: 'I',
            },
            otrosUploadExtraDataForm: {
                orderId: 0,
                category: 'O',
            },
            medicDocUrl: '',
            modalPDFViewer: false,
            viewMode: 'table',
            iframeLoaded: {},
            modalIframeLoaded: false,
            loadingErrors: {},
            loadingTimeout: {},
            loadingTimers: {},
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
            GetHistoryFiltred: 'GetHistoryFiltred',
        }),
        filteredAttachments() {
            if (!this.GetMedicoFichaActive.metadata || !this.GetMedicoFichaActive.metadata.attach) return {};
            
            const allowedCategories = ['L', 'I', 'O'];
            const filtered = {};
            
            Object.entries(this.GetMedicoFichaActive.metadata.attach).forEach(([key, value]) => {
                if (allowedCategories.includes(key)) {
                    filtered[key] = value;
                }
            });
            
            return filtered;
        }
    },
    watch: {
        GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {

            }
        }
    },
    mounted() {
        this.emitter.emit("medico-ficha-load",['attach']);
        this.laboratoriosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id;
        this.imagenesUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id;
        this.otrosUploadExtraDataForm.orderId = this.GetMedicoFichaActive.orden.id;
        this.FetchHistoryFiltred(['attach']);
        
        // Iniciar timers para cada adjunto
        if (this.GetMedicoFichaActive.metadata && this.GetMedicoFichaActive.metadata.attach) {
            Object.values(this.GetMedicoFichaActive.metadata.attach).forEach(category => {
                Object.keys(category).forEach(key => {
                    this.startLoadingTimer(key);
                });
            });
        }
    },
    beforeDestroy() {
        // Limpiar timers al desmontar el componente
        Object.values(this.loadingTimers).forEach(timer => {
            clearTimeout(timer);
        });
    },
    methods: {
        ...mapActions({
            OrderSaveMetadata: 'OrderSaveMetadata',
            FetchHistoryFiltred: 'FetchHistoryFiltred', // Add this line to map the action
        }),
        dayjs,
        Numbro,
        selectBoxTitle(key){
            if(key==='L'){
                return 'Laboratorios';
            }else if(key==='I'){
                return 'Imagenes';
            }else if(key==='O'){
                return 'Otros';
            }
        },
        selectBoxIcon(key){
            if(key==='L'){
                return 'fa fa-vial';
            }else if(key==='I'){
                return 'fa-solid fa-image';
            }else if(key==='O'){
                return 'fa fa-file';
            }
        },
        // subida de archivos
        fileSuccess(data) {
            this.emitter.emit("medico-ficha-refresh",['attach']);
            this.FetchHistoryFiltred(['attach']);
        },
        // La función downloadAdjunto se activa solo al hacer clic en el botón "Descargar"
        downloadAdjunto(item) {
            API.send('POST', 'orders/metadata-attachments/download', {
                metadataId: item.id,
            }, function (response) {
                if (response.data) {
                    window.open(response.data);
                }
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        sendAdjunto(item, tipoAdjunto){
          API.send('POST', 'orders/metadata-attachments/send', {
                metadataId: item.id,
                orderId: this.GetMedicoFichaActive.orden.id,
                title: tipoAdjunto
              }, function (response) {
                API.showSuccessAlert(response.msg);
              },
              function (response) {
                API.showErrorAlert(response.msg);
              });
        },
        deleteAdjunto(item) {
            API.showConfirm('Espere', 'Si elimina este adjunto, no podrá recuperarlo. ¿Desea continuar?', () => {
                API.send('POST', 'orders/metadata-attachments/delete', {
                    metadataId: item.id,
                }, (response) => {
                    API.showSuccessAlert(response.msg);
                    this.emitter.emit("medico-ficha-refresh",['attach']);
                    this.FetchHistoryFiltred(['attach']);
                }, (response) => {
                    API.showErrorAlert(response.msg);
                });
            });
        },
        handlerModal(){
            this.modalPDFViewer = !this.modalPDFViewer;
        },
        openPDFModal(url, item) {
            this.medicDocUrl = url;
            this.modalIframeLoaded = false;
            
            // Asignar el adjunto actual si está disponible
            if (item) {
                this.currentAdjunto = item;
            } else {
                // Buscar el adjunto que corresponde a esta URL
                if (this.GetMedicoFichaActive.metadata && this.GetMedicoFichaActive.metadata.attach) {
                    Object.values(this.GetMedicoFichaActive.metadata.attach).forEach(category => {
                        Object.values(category).forEach(adjunto => {
                            if (adjunto.urlTMP && adjunto.urlTMP.fileUrl === url) {
                                this.currentAdjunto = adjunto;
                            }
                        });
                    });
                }
            }
            
            this.handlerModal();
        },
        getFileType(item) {
            let fileName = '';
            if (item && item.detail && item.detail.name) {
                fileName = item.detail.name.toLowerCase();
            } else if (typeof item === 'string') {
                fileName = item.toLowerCase();
            }
            
            const extension = fileName.split('.').pop();
            
            const fileTypes = {
                pdf: ['pdf'],
                word: ['doc', 'docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
                image: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']
            };

            for (const [type, extensions] of Object.entries(fileTypes)) {
                if (extensions.some(ext => 
                    fileName.endsWith(`.${ext}`) || 
                    fileName.includes(ext) ||
                    (item && item.detail && item.detail.mimeType && item.detail.mimeType.includes(ext))
                )) {
                    return type;
                }
            }

            return 'other';
        },
        generateGoogleViewerUrl(fileUrl) {
            if (!fileUrl) return null;
            const fileType = this.getFileType(fileUrl);
            
            if (fileType === 'pdf') {
                return `https://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
            }
            return fileUrl;
        },
        handleIframeLoad(event, key) {
            // Limpiar el timer existente si lo hay
            if (this.loadingTimers[key]) {
                clearTimeout(this.loadingTimers[key]);
            }
            
            // Actualizar directamente usando Object.assign para mantener reactividad
            this.iframeLoaded = Object.assign({}, this.iframeLoaded, { [key]: true });
            this.loadingTimeout = Object.assign({}, this.loadingTimeout, { [key]: false });
        },
        handleIframeError(key) {
            if (this.loadingTimers[key]) {
                clearTimeout(this.loadingTimers[key]);
            }
            // Actualizar directamente usando Object.assign para mantener reactividad
            this.loadingTimeout = Object.assign({}, this.loadingTimeout, { [key]: true });
            this.iframeLoaded = Object.assign({}, this.iframeLoaded, { [key]: true });
        },
        startLoadingTimer(key) {
            // Limpiar timer existente
            if (this.loadingTimers[key]) {
                clearTimeout(this.loadingTimers[key]);
            }

            // Inicializar estados usando Object.assign
            this.iframeLoaded = Object.assign({}, this.iframeLoaded, { [key]: false });
            this.loadingTimeout = Object.assign({}, this.loadingTimeout, { [key]: false });

            // Configurar nuevo timer
            this.loadingTimers[key] = setTimeout(() => {
                this.loadingTimeout = Object.assign({}, this.loadingTimeout, { [key]: true });
                this.iframeLoaded = Object.assign({}, this.iframeLoaded, { [key]: true });
            }, 5000); // 5 segundos en lugar de 10
        },
        handleModalIframeLoad() {
            setTimeout(() => {
                this.modalIframeLoaded = true;
            }, 500);
        },
        handleIframeError(itemId) {
            this.$set(this.loadingErrors, itemId, true);
            this.$set(this.iframeLoaded, itemId, true);
            API.showErrorAlert('Error al cargar la vista previa del documento');
        },
    },
};

</script>
<style scoped>
.expand-icon {
    transition: transform 0.2s;
    transform-origin: center;
}
.title_container:hover .expand-icon{
    transform: scale(1.2);
    color:#0ca7e0;
}
.modo-vista i {
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.2rem;
}
.icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.1); /* Subtle border */
    border-radius: 4px;
}
.pdf-container {
    position: relative;
    width: 100%;
    min-height: 400px; /* Aumentar la altura mínima */
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    min-height: 400px; /* Asegurar que el iframe tiene suficiente altura */
    border: none;
}

/* Ajustar la vista previa para documentos Word */
.loading-overlay[style="position: relative; min-height: 350px;"] {
    min-height: 400px !important; /* Aumentar la altura mínima */
}

/* Para imágenes */
.pdf-container img.img-fluid {
    max-height: 400px;
    object-fit: contain;
}

/* Ajustar tamaños para el modal */
#pdfViewer {
    width: 100%;
    height: 700px;
}

/* Responsive: en pantallas pequeñas, reducir un poco la altura */
@media (max-width: 767px) {
    .pdf-container {
        min-height: 300px;
    }
    .pdf-viewer, .loading-overlay[style="position: relative; min-height: 350px;"] {
        min-height: 300px !important;
    }
}

/* Aumentar el espacio para la vista de errores */
.error-preview {
    text-align: center;
    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    max-width: 220px;
    margin: 0 auto;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pdf-container {
    position: relative;
    width: 100%;
    min-height: 350px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.loading-content {
    text-align: center;
    color: #666;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.loading-content i {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #0ca7e0;
}

.loading-content p {
    margin: 0;
    font-weight: 500;
}

/* Estilos para los botones de acción */
.action-button {
    transition: all 0.2s ease;
}

.action-button:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
}

td i {
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 4px;
}

td i:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
}

td i.fa-eye:hover {
    color: #0056b3 !important;
}

td i.fa-download:hover {
    color: #28a745 !important;
}

td i.fa-envelope:hover {
    color: #17a2b8 !important;
}

td i.fa-trash:hover {
    color: #dc3545 !important;
}

.modo-vista i {
    cursor: pointer;
    margin-right: 10px;
    font-size: 1.2rem;
    transition: all 0.2s ease;
}

.modo-vista i:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.05);
}

.icon-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    transition: all 0.2s ease;
}

.icon-container:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.05);
}

.doc-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    min-height: 350px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    text-align: center;
}

.doc-preview i {
    margin-bottom: 1.5rem;
    font-size: 3.5rem;
    color: #2b579a; /* Color oficial de Word */
}

.doc-preview p {
    margin-bottom: 0.5rem;
    color: #495057;
    font-weight: 500;
    font-size: 1.1rem;
}

.doc-preview small {
    color: #6c757d;
    display: block;
    margin-bottom: 1rem;
}

.error-preview {
    text-align: center;
    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    max-width: 200px;
    margin: 0 auto;
}

.error-preview i {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.error-preview p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}

.loading-content {
    text-align: center;
    padding: 1.5rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.loading-content i {
    font-size: 2rem;
    color: #0ca7e0;
    margin-bottom: 1rem;
    display: block;
}

.loading-content p {
    margin: 0;
    color: #666;
}

.btn-xs {
    font-size: 0.75rem;
    line-height: 1;
    padding: 0.25rem 0.5rem;
    margin: 0.125rem 0;
}
</style>
