<template>
    <layout :class="{ active: isActive('/account') }" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row icon-container">
                    <div v-if="isCollapsed" class="show-sidebar-container">
                        <i @click="toggleSidebar" class="fa-solid fa-chevron-right toggle-icon" v-tooltip="'Mostrar'"></i>
                    </div>
                    <div :class="[isCollapsed ? 'd-none' : 'col-xl-3 col-md-4']" style="transition: width 0.3s ease-in-out;">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Configuración</h4>
                                <i @click="toggleSidebar" class="fa-solid fa-chevron-left toggle-icon" v-tooltip="'Ocultar'"></i>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item" v-if="Access('users-config-plan')">
                                        <router-link to="/plan-usage" class="nav-link" :class="{ active: isActive('/plan-usage') }">
                                            <i class="mdi mdi-gauge"></i>
                                            <span>Medidor de consumo</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-form')">
                                        <router-link to="/forms-list" class="nav-link" :class="{ active: isActive('/forms-list') }">
                                            <i class="mdi mdi-file-document"></i>
                                            <span>Docs+</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-form')">
                                        <router-link to="/shortlink-create" class="nav-link" :class="{ active: isActive('/shortlink-create') }">
                                            <i class="mdi mdi-link"></i>
                                            <span>Acortador de URLs</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Configuración</h4>
                            </div>
                            <div class="card-body">
                                <ul>
<!--                                    <li class="nav-item" v-if="Access('users-config-plan')">
                                        <router-link to="/plan-and-packages" class="nav-link" :class="active === 22 && 'active'">
                                            <i class="mdi mdi-credit-card"></i>
                                            <span>Plan y método de pago</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-config-perfil')">
                                        <router-link to="/account" class="nav-link" :class="{ active: isActive('/account') }">
                                            <i class="mdi mdi-account"></i>
                                            <span>Editar perfil</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/medic/dashboard" class="nav-link" :class="active === 28 && 'active'">
                                            <i class="mdi mdi-medical-bag"></i>
                                            <span>Medico</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-integraciones-admin')">
                                        <router-link to="/account-affiliations" class="nav-link" :class="{ active: isActive('/account-affiliations') }">
                                            <i class="la la-boxes"></i>
                                            <span>Integraciones</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-integraciones-solicitud')">
                                        <router-link to="/account-solicitudes" class="nav-link" :class="{ active: isActive('/account-solicitudes') }">
                                            <i class="la la-list-ol"></i>
                                            <span>Solicitudes de integración</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-locations" class="nav-link" :class="{ active: isActive('/account-locations') }">
                                            <i class="mdi mdi-store"></i>
                                            <span>
                                                Localidad, bodegas, envíos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/personal/forms" class="nav-link" :class="{ active: isActive('/personal/forms') }">
                                            <i class="mdi mdi-receipt"></i>
                                            <span>
                                                Envío de facturas a personal
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-quirofanos" class="nav-link" :class="{ active: isActive('/account-quirofanos') }">
                                            <i class="mdi mdi-medical-bag"></i>
                                            <span>
                                                Quirófanos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-catalogos" class="nav-link" :class="{ active: isActive('/account-catalogos') }">
                                            <i class="mdi mdi-database"></i>
                                            <span>
                                                Catálogos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('personal-usuarios_adicionales')">
                                        <router-link to="/account-personal" class="nav-link" :class="{ active: isActive('/account-personal') }">
                                            <i class="la la-users"></i>
                                            <span>Usuarios adicionales</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/account-shipping" class="nav-link" :class="active === 27 && 'active'">
                                            <i class="mdi mdi-truck-delivery"></i>
                                            <span>Envíos y coberturas</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-sistema')">
                                        <router-link to="/account-config" class="nav-link" :class="{ active: isActive('/account-config') }">
                                            <i class="mdi mdi-cogs"></i>
                                            <span>Sistema</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div :class="isCollapsed ? 'col-12 col-xl-12 col-md-12' : 'col-xl-9 col-md-8'">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    data() {
        return {
            isCollapsed: false
        };
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
        })
    },
    methods: {
        isActive(path) {
            return this.$route.path === path;
        },
        toggleSidebar() {
            this.isCollapsed = !this.isCollapsed;
            this.$emit("toggleSidebar", this.isCollapsed);
        }
    },
};
</script>

<style scoped>
.toggle-icon {
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 5px;
    border-radius: 30%;
}
.toggle-icon:hover {
    box-shadow: rgba(50, 50, 93, 0.15) 0px 15px 30px -6px inset, rgba(0, 0, 0, 0.15) 0px 9px 18px -9px inset;
}

.icon-container{
    position: relative;
}

.show-sidebar-container{
    position: absolute;
    top: 20px;
    left: 0;
    background-color: white;
    cursor: pointer;
    z-index: 1000; /* Ensure the icon is on top */
    border-radius: 15px;
}

</style>
