<script>
import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Box from '/src/modules/medico/views/fichas/Box.vue';
import ModalDetail from "./ModalDetail.vue";
import BodyPartsSelector from "./BodyPartsSelector.vue";

export default { 
 data() {
        return {
        //data
        fisioterapia_tratamientos:{
          ejercicios_activos:{
            name: 'Ejercicios Activos',
            value: false,
            observations: '',
          },
          ejercicios_pasivos:{
            name: 'Ejercicios Pasivos',
            value: false,
            observations: '',
          },
          ejercicios_de_amplitud_articular:{
            name: 'Ejercicios de amplitud articular',
            value: false,
            observations: '',
          },
          ejercicios_de_fortalecimiento:{
            name: 'Ejercicios de fortalecimiento',
            value: false,
            observations: '',
          },
          ejercicios_isometricos:{
            name: 'Ejecicios isométricos',
            value: false,
            observations: '',
          },
          electro_estimulo:{
            name: 'Electro estímulo',
            value: false,
            observations: '',
          },
          empaques_calientes:{
            name: 'Empaques calientes',
            value: false,
            observations: '',
          },
          empaques_frios:{
            name: 'Empaques fríos',
            value: false,
            observations: '',
          },
          hidroterapia:{
            name: 'Hidroterapia',
            value: false,
            observations: '',
          },
          laser:{
            name: 'Láser',
            value: false,
            observations: '',
          },
          masaje:{
            name: 'Masaje',
            value: false,
            observations: '',
          },
          propiocepcion:{
            name: 'Propiocepción',
            value: false,
            observations: '',
          },
          reeducacion:{
            name: 'Reeducación',
            value: false,
            observations: '',
          },
          ultrasonido:{
            name: 'Ultrasonido',
            value: false,
            observations: '',
          },
          otro:{
            name: 'Otro',
            value: false,
            observations: '',
          },
        },
        observaciones:{
          general:'',
          fuerza:''
        },
        indicaciones_amplitud_articular:{
          hombro:{
            name: 'Hombro',
            adduction: '',
            adduction_grades: 'Grado ideal - 40°',
            abduction: '',
            abduction_grades: 'Grado ideal - 180°',
            flexion: '',
            flexion_grades: 'Grado ideal - 180°',
            extension: '',
            extension_grades: 'Grado ideal - 45°',
            internal_rotation:'',
            internal_rotation_grades:'Grado ideal - 90°',
            external_rotation: '',
            external_rotation_grades:'Grado ideal - 90°',
          },
          codo_antebrazo:{
            name: 'Codo/Antebrazo',
            flexion: '',
            flexion_grades: 'Grado ideal - 145°',
            pronation: '',
            pronation_grades: 'Grado ideal - 80°',
            supination:'',
            supination_grades: 'Grado ideal - 80°',
          },
          muñeca:{
            name: 'Muñeca',
            ulnar_flexion: '',
            ulnar_flexion_grades: 'Grado ideal - 20°',
            extension: '',
            extension_grades: 'Grado ideal - 70°',
            radial_flexion:'',
            radial_flexion_grades:'Grado ideal - 45°',
            flexion: '',
            flexion_grades: 'Grado ideal - 80°',
          },
          cadera:{
            name: 'Cadera',
            adduction: '',
            adduction_grades: 'Grado ideal - 10°',
            abduction: '',
            abduction_grades: 'Grado ideal - 45°',
            internal_rotation:'',
            internal_rotation_grades:'Grado ideal - 125°',
            external_rotation: '',
            external_rotation_grades:'Grado ideal - 45°',
            extension: '',
            extension_grades: 'Grado ideal - 30°',
            flexion: '',
            flexion_grades: 'Grado ideal - 45°',
          },
          rodilla:{
            name: 'Rodilla',
            extension: '',
            extension_grades: 'Grado ideal - 0°',
            flexion: '',
            flexion_grades: 'Grado ideal - 140°',
          },
          tobillo:{
            name: 'Tobillo',
            inversion:'',
            inversion_grades:'Grado ideal - 40°',
            eversion: '',
            eversion_grades: 'Grado ideal - 20°',
            plantar_flexion: '',
            plantar_flexion_grades: 'Grado ideal - 45°',
            dorsiflexion: '',
            dorsiflexion_grades: 'Grado ideal - 20°',
          },
        },
        showBodyPart: false,
        detailData: {},
        detailModal: false,
        showTable:true,

        primera_evaluacion_fisio:{
          fecha:'',
          eva:0,
          informacion_general:'',
          informacion_adicional:'',
          agente_fisico:{
            us:false,
            laser:false,
            ee:false,
            magneto_tecar:false,
            frio:false,
            calor:false,
          },
          ejercicios:{
            estiramientos:false,
            otros:false,
            isometricos:false,
            lbs:false,
            fm_liga:false,
            rv:false,
            a:false,
            az:false,
            n:false,
            p:false,
            propiocepcion:false,
            electroestimulacion:false,
          },
          terapia_manual:{
            masaje:false,
            movilidad_pa:false,
            manipulacion:false,
            drenaje_linfatico:false,
            streching:false,
            reeducacion_sensorial:false
          },
          opciones_manipulacion:{
              flex:false,
              add:false,
              ext:false,
              abd:false,
              r_int:false,
              r_ext:false
          },
          otros:{
            traccion_lumbar:false,
            reeduc_marcha:false,
            traccion_cervical:false,
            presoterapia:false,
            bicicleta:false
          }
        },

        quinta_evaluacion_fisio:{
          fecha:'',
          eva:0,
          informacion_general:'',
          informacion_adicional:'',
          agente_fisico:{
            us:false,
            laser:false,
            ee:false,
            magneto_tecar:false,
            frio:false,
            calor:false,
          },
          ejercicios:{
            estiramientos:false,
            otros:false,
            isometricos:false,
            lbs:false,
            fm_liga:false,
            rv:false,
            a:false,
            az:false,
            n:false,
            p:false,
            propiocepcion:false,
            electroestimulacion:false,
          },
          terapia_manual:{
            masaje:false,
            movilidad_pa:false,
            manipulacion:false,
            drenaje_linfatico:false,
            streching:false,
            reeducacion_sensorial:false
          },
          opciones_manipulacion:{
              flex:false,
              add:false,
              ext:false,
              abd:false,
              r_int:false,
              r_ext:false
          },
          otros:{
            traccion_lumbar:false,
            reeduc_marcha:false,
            traccion_cervical:false,
            presoterapia:false,
            bicicleta:false
          }
        },

        decima_evaluacion_fisio:{
          fecha:'',
          eva:0,
          informacion_general:'',
          informacion_adicional:'',
          apreciacion_estado_sintomas:{
              resolvio:false,
              no_resolvio:false,
              va_en_proceso:false
            },
            apreciacion_consistencia_tratamiento:{
              constante:false,
              inconstante:false
            }
        },
        
        decima_evaluacion_sintomas_fisio:[],

        conclusiones: {
          notas_sugerencias: '',
          sugiere_ciclo_terapias: false,
          para: '',
          finaliza_tratamiento: {
            indicacion_cirugia: {
              value: false,
              fecha: ''
            },
            no_concluyo_tratamiento: false,
            no_autorizaron_terapias: false,
            otro: {
              value: false,
              descripcion: ''
            }
          },
          campos_adicionales: {
            rom: '',
            fm: '',
            inflamacion: '',
            edema: '',
            dolor: '',
            propio: ''
          }
        },
        currentEvaluacion: 'primera',
        sintoma: {
            id: 0,
            detail: {
                descripcion: '',
            }
        },
        showEditionSintoma: false,
 }},
 components:{
  Box,
  ModalDetail,
  BodyPartsSelector
 },
 watch:{
  GetMedicoFichaActive: {
        handler() {

          const self = this;

            self.showBodyPart = true;

            // Cargo tratamientos
            if (self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos) {
              Object.keys(self.fisioterapia_tratamientos).forEach(function (value) {
                const metadata = self.GetMedicoFichaActive.metadata.fisioterapia_tratamientos;
                self.fisioterapia_tratamientos[value].value = metadata[value] === "1";
                self.fisioterapia_tratamientos[value].name = metadata[value + '_name'] || self.fisioterapia_tratamientos[value].name;
                self.fisioterapia_tratamientos[value].observations = metadata[value + '_observations'] || '';
              });
            }

            // Cargo observaciones
            if (self.GetMedicoFichaActive.metadata.observaciones_fisioterapeuticas) {
              self.observaciones = {
                general: self.GetMedicoFichaActive.metadata.observaciones_fisioterapeuticas.general || '',
                fuerza: self.GetMedicoFichaActive.metadata.observaciones_fisioterapeuticas.fuerza || ''
              };
            }

            // Cargo indicaciones de amplitud
            if (self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular) {
              Object.keys(self.indicaciones_amplitud_articular).forEach(function (value) {
                const metadata = self.GetMedicoFichaActive.metadata.indicaciones_amplitud_articular;
                
                // Cargo nombre
                self.indicaciones_amplitud_articular[value].name = metadata[value + '_name'] || self.indicaciones_amplitud_articular[value].name;

                // Cargo todos los campos de movimiento
                const movementFields = [
                  'adduction', 'abduction', 'flexion', 'extension',
                  'internal_rotation', 'external_rotation', 'pronation',
                  'supination', 'ulnar_flexion', 'radial_flexion',
                  'inversion', 'eversion', 'plantar_flexion', 'dorsiflexion'
                ];

                movementFields.forEach(field => {
                  if (field in self.indicaciones_amplitud_articular[value]) {
                    self.indicaciones_amplitud_articular[value][field] = metadata[value + '_' + field] || '';
                  }
                });
              });
            }

            // Cargo primera evaluación
            if (self.GetMedicoFichaActive.metadata.primera_evaluacion_fisioterapia) {
              const metadata = self.GetMedicoFichaActive.metadata.primera_evaluacion_fisioterapia;
              
              // Datos básicos
              self.primera_evaluacion_fisio.fecha = metadata.fecha || '';
              self.primera_evaluacion_fisio.eva = metadata.eva || 0;
              self.primera_evaluacion_fisio.informacion_general = metadata.informacion_general || '';
              self.primera_evaluacion_fisio.informacion_adicional = metadata.informacion_adicional || '';

              // Agente físico
              Object.keys(self.primera_evaluacion_fisio.agente_fisico).forEach(key => {
                self.primera_evaluacion_fisio.agente_fisico[key] = metadata['agente_fisico_' + key] === "1";
              });
              self.primera_evaluacion_fisio.agente_fisico.informacion_adicional = metadata.agente_fisico_informacion_adicional || '';

              // Ejercicios
              Object.keys(self.primera_evaluacion_fisio.ejercicios).forEach(key => {
                self.primera_evaluacion_fisio.ejercicios[key] = metadata['ejercicios_' + key] === "1";
              });
              self.primera_evaluacion_fisio.ejercicios.informacion_adicional = metadata.ejercicios_informacion_adicional || '';

              // Terapia manual
              Object.keys(self.primera_evaluacion_fisio.terapia_manual).forEach(key => {
                self.primera_evaluacion_fisio.terapia_manual[key] = metadata['terapia_manual_' + key] === "1";
              });
             
              // Opciones de manipulación
              Object.keys(self.primera_evaluacion_fisio.opciones_manipulacion).forEach(key => {
                self.primera_evaluacion_fisio.opciones_manipulacion[key] = metadata['opciones_manipulacion_' + key] === "1";
              });

              // Otros
              Object.keys(self.primera_evaluacion_fisio.otros).forEach(key => {
                self.primera_evaluacion_fisio.otros[key] = metadata['otros_' + key] === "1";
              });
            }

            // Cargo quinta evaluación
            if (self.GetMedicoFichaActive.metadata.quinta_evaluacion_fisioterapia) {
              const metadata = self.GetMedicoFichaActive.metadata.quinta_evaluacion_fisioterapia;
              
              // Datos básicos
              self.quinta_evaluacion_fisio.fecha = metadata.fecha || '';
              self.quinta_evaluacion_fisio.eva = metadata.eva || 0;
              self.quinta_evaluacion_fisio.informacion_general = metadata.informacion_general || '';
              self.quinta_evaluacion_fisio.informacion_adicional = metadata.informacion_adicional || '';

              // Agente físico
              Object.keys(self.quinta_evaluacion_fisio.agente_fisico).forEach(key => {
                self.quinta_evaluacion_fisio.agente_fisico[key] = metadata['agente_fisico_' + key] === "1";
              });

              // Ejercicios
              Object.keys(self.quinta_evaluacion_fisio.ejercicios).forEach(key => {
                self.quinta_evaluacion_fisio.ejercicios[key] = metadata['ejercicios_' + key] === "1";
              });

              // Terapia manual
              Object.keys(self.quinta_evaluacion_fisio.terapia_manual).forEach(key => {
                self.quinta_evaluacion_fisio.terapia_manual[key] = metadata['terapia_manual_' + key] === "1";
              });

              // Opciones de manipulación
              Object.keys(self.quinta_evaluacion_fisio.opciones_manipulacion).forEach(key => {
                self.quinta_evaluacion_fisio.opciones_manipulacion[key] = metadata['opciones_manipulacion_' + key] === "1";
              });

              // Otros
              Object.keys(self.quinta_evaluacion_fisio.otros).forEach(key => {
                self.quinta_evaluacion_fisio.otros[key] = metadata['otros_' + key] === "1";
              });
            }

            // Cargo décima evaluación
            if (self.GetMedicoFichaActive.metadata.decima_evaluacion_fisioterapia) {
              const metadata = self.GetMedicoFichaActive.metadata.decima_evaluacion_fisioterapia;
              
              // Datos básicos
              self.decima_evaluacion_fisio.fecha = metadata.fecha || '';
              self.decima_evaluacion_fisio.eva = metadata.eva || 0;
              self.decima_evaluacion_fisio.informacion_general = metadata.informacion_general || '';
              self.decima_evaluacion_fisio.informacion_adicional = metadata.informacion_adicional || '';

              // Apreciación estado síntomas
              Object.keys(self.decima_evaluacion_fisio.apreciacion_estado_sintomas).forEach(key => {
                self.decima_evaluacion_fisio.apreciacion_estado_sintomas[key] = metadata['apreciacion_estado_sintomas_' + key] === "1";
              });

              // Apreciación consistencia tratamiento
              Object.keys(self.decima_evaluacion_fisio.apreciacion_consistencia_tratamiento).forEach(key => {
                self.decima_evaluacion_fisio.apreciacion_consistencia_tratamiento[key] = metadata['apreciacion_consistencia_tratamiento_' + key] === "1";
              });
            }

            // Cargar síntomas
            if (self.GetMedicoFichaActive.metadata.sintomas_presentes) {
              self.decima_evaluacion_sintomas_fisio = self.GetMedicoFichaActive.metadata.sintomas_presentes;
            } else {
              self.decima_evaluacion_sintomas_fisio = [];
            }

            // Cargo conclusiones
            if (self.GetMedicoFichaActive.metadata.conclusiones_fisioterapia) {
                const metadata = self.GetMedicoFichaActive.metadata.conclusiones_fisioterapia;
                
                // Notas y sugerencias
                self.conclusiones.notas_sugerencias = metadata.notas_sugerencias || '';
                self.conclusiones.sugiere_ciclo_terapias = metadata.sugiere_ciclo_terapias === "1";
                self.conclusiones.para = metadata.para || '';

                // Finaliza tratamiento
                self.conclusiones.finaliza_tratamiento = {
                    indicacion_cirugia: {
                        value: metadata.finaliza_tratamiento_indicacion_cirugia_value === "1",
                        fecha: metadata.finaliza_tratamiento_indicacion_cirugia_fecha || ''
                    },
                    no_concluyo_tratamiento: metadata.finaliza_tratamiento_no_concluyo_tratamiento === "1",
                    no_autorizaron_terapias: metadata.finaliza_tratamiento_no_autorizaron_terapias === "1",
                    otro: {
                        value: metadata.finaliza_tratamiento_otro_value === "1",
                        descripcion: metadata.finaliza_tratamiento_otro_descripcion || ''
                    }
                };

                // Campos adicionales
                self.conclusiones.campos_adicionales = {
                    rom: metadata.campos_adicionales_rom || '',
                    fm: metadata.campos_adicionales_fm || '',
                    inflamacion: metadata.campos_adicionales_inflamacion || '',
                    edema: metadata.campos_adicionales_edema || '',
                    dolor: metadata.campos_adicionales_dolor || '',
                    propio: metadata.campos_adicionales_propio || ''
                };
            }

        },
        deep: true,
        // immediate: true
    }
 },
 computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
        }),
 },
 mounted() {

  this.emitter.emit("medico-ficha-load", [
    'fisioterapia_tratamientos',
    'observaciones_fisioterapeuticas',
    'indicaciones_amplitud_articular',
    'primera_evaluacion_fisioterapia',
    'quinta_evaluacion_fisioterapia',
    'decima_evaluacion_fisioterapia',
    'conclusiones_fisioterapia',
    'sintomas_presentes',
    'front',
    'back'
  ]);
  
},
 methods:{
  ...mapActions({
}),
  saveTreatments(){
    const self = this;

    // transformo los keys a un array normal
    const arrToSave = {};
    Object.keys(self.fisioterapia_tratamientos).forEach(function (value) {
                arrToSave[value] = self.fisioterapia_tratamientos[value].value;
                arrToSave[value + '_name'] = self.fisioterapia_tratamientos[value].name;
                arrToSave[value + '_observations'] = self.fisioterapia_tratamientos[value].observations;
            });

    // Guardo y luego emito el evento
    API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'fisioterapia_tratamientos',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-notify",['fisioterapia_tratamientos']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
              },

  saveIndications(){
  const self = this;

  // transformo los keys a un array normal
  const arrToSave = {};
  Object.keys(self.indicaciones_amplitud_articular).forEach(function (value) {
    // name
              arrToSave[value + '_name'] = self.indicaciones_amplitud_articular[value].name;
    
    // Campos de movimiento
    const movementFields = [
      'adduction', 'abduction', 'flexion', 'extension',
      'internal_rotation', 'external_rotation', 'pronation',
      'supination', 'ulnar_flexion', 'radial_flexion',
      'inversion', 'eversion', 'plantar_flexion', 'dorsiflexion'
    ];

    movementFields.forEach(field => {
      if (field in self.indicaciones_amplitud_articular[value]) {
        arrToSave[value + '_' + field] = self.indicaciones_amplitud_articular[value][field];
      }
    });
  });

  // Guardo y luego emito el evento
  API.send('POST', 'orders/metadata/save/', {
                  orderId: self.GetMedicoFichaActive.orden.id,
                  slug: 'indicaciones_amplitud_articular',
                  detail: arrToSave,
                  uniqueMeta: 1,
              }, function (response) {
                  API.showSuccessAlert(response.msg);
                  self.emitter.emit("medico-ficha-notify",['indicaciones_amplitud_articular']);
              },
              function (response) {
                  API.showErrorAlert(response.msg);
              });
            },

  saveObservaciones(){
    const self = this;

    API.send('POST', 'orders/metadata/save/', {
                  orderId: self.GetMedicoFichaActive.orden.id,
                  slug: 'observaciones_fisioterapeuticas',
                  detail: self.observaciones,
                  uniqueMeta: 1,
              }, function (response) {
                  API.showSuccessAlert(response.msg);
                  self.emitter.emit("medico-ficha-notify",['observaciones_fisioterapeuticas']);
              },
              function (response) {
                  API.showErrorAlert(response.msg);
              });
  },

  savePrimeraEvaluacion() {
    const self = this;

    API.send('POST', 'orders/metadata/save/', {
      orderId: self.GetMedicoFichaActive.orden.id,
      slug: 'primera_evaluacion_fisioterapia',
      detail: self.primera_evaluacion_fisio,
      uniqueMeta: 1,
    }, function (response) {
      API.showSuccessAlert(response.msg);
      self.emitter.emit("medico-ficha-notify",['evaluaciones_fisioterapia']);
    },
    function (response) {
      API.showErrorAlert(response.msg);
    });
  },
  saveQuintaEvaluacion() {
    const self = this;

    API.send('POST', 'orders/metadata/save/', {
      orderId: self.GetMedicoFichaActive.orden.id,
      slug: 'quinta_evaluacion_fisioterapia',
      detail: self.quinta_evaluacion_fisio,
      uniqueMeta: 1,
    }, function (response) {
      API.showSuccessAlert(response.msg);
      self.emitter.emit("medico-ficha-notify",['evaluaciones_fisioterapia']);
    },
    function (response) {
      API.showErrorAlert(response.msg);
    });
  },
  saveDecimaEvaluacion() {
    const self = this;

    API.send('POST', 'orders/metadata/save/', {
      orderId: self.GetMedicoFichaActive.orden.id,
      slug: 'decima_evaluacion_fisioterapia',
      detail: self.decima_evaluacion_fisio,
      uniqueMeta: 1,
    }, function (response) {
      API.showSuccessAlert(response.msg);
      self.emitter.emit("medico-ficha-notify",['evaluaciones_fisioterapia']);
    },
    function (response) {
      API.showErrorAlert(response.msg);
    });
  },

  saveEvaluacion(slug) {
    const self = this;
    let dataToSave;
    let flattenedData = {};

    // Función auxiliar para aplanar objetos
    const flattenObject = (obj, prefix = '') => {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          flattenObject(obj[key], prefix + key + '_');
        } else {
          flattenedData[prefix + key] = obj[key];
        }
      }
    };

    // Determinar qué datos guardar según el slug
    switch(slug) {
      case 'primera_evaluacion_fisioterapia':
        dataToSave = {
          ...self.primera_evaluacion_fisio
        };
        break;
      case 'quinta_evaluacion_fisioterapia':
        dataToSave = {
          ...self.quinta_evaluacion_fisio
        };
        break;
      case 'decima_evaluacion_fisioterapia':
        dataToSave = {
          ...self.decima_evaluacion_fisio
        };
        break;
      default:
        console.error('Slug de evaluación no válido');
        return;
    }

    // Aplanar el objeto antes de enviarlo
    flattenObject(dataToSave);

    // Realizar la llamada a la API
    API.send('POST', 'orders/metadata/save/', {
      orderId: self.GetMedicoFichaActive.orden.id,
      slug: slug,
      detail: flattenedData,
      uniqueMeta: 1,
    }, function (response) {
      API.showSuccessAlert(response.msg);
      self.emitter.emit("medico-ficha-notify",['evaluaciones_fisioterapia']);
    },
    function (response) {
      API.showErrorAlert(response.msg);
    });
  },

  saveConclusiones() {
    const self = this;
    let flattenedData = {};

    // Función auxiliar para aplanar objetos
    const flattenObject = (obj, prefix = '') => {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                flattenObject(obj[key], prefix + key + '_');
            } else {
                flattenedData[prefix + key] = obj[key];
            }
        }
    };

    // Aplanar el objeto antes de enviarlo
    flattenObject(self.conclusiones);

    API.send('POST', 'orders/metadata/save/', {
        orderId: self.GetMedicoFichaActive.orden.id,
        slug: 'conclusiones_fisioterapia',
        detail: flattenedData,
        uniqueMeta: 1,
    }, function (response) {
        API.showSuccessAlert(response.msg);
        self.emitter.emit("medico-ficha-notify",['conclusiones_fisioterapia']);
    },
    function (response) {
        API.showErrorAlert(response.msg);
    });
  },

  openDetailModal(item){
this.detailData = item;
this.detailModal = true;
  },

  closeDetailModal(){
this.detailData = {};
this.detailModal = false;
  },

  saveDataFromModal(item){
    const self = this;

    if (item && item.observations) {
    const arrToSave = {};
Object.keys(self.fisioterapia_tratamientos).forEach(function (value) {
    arrToSave[value] = self.fisioterapia_tratamientos[value].value;
    arrToSave[value + '_name'] = self.fisioterapia_tratamientos[value].name;
    if (item.name === self.fisioterapia_tratamientos[value].name) {
    arrToSave[value + '_observations'] = self.detailData.observations;
} else {
    arrToSave[value + '_observations'] = self.fisioterapia_tratamientos[value].observations;
    }
});

API.send('POST', 'orders/metadata/save/', {
        orderId: self.GetMedicoFichaActive.orden.id,
        slug: 'fisioterapia_tratamientos',
        detail: arrToSave,
        uniqueMeta: 1,
    }, function (response) {
        API.showSuccessAlert(response.msg);
        self.emitter.emit("medico-ficha-notify",['fisioterapia_tratamientos']);
    },
    function (response) {
        API.showErrorAlert(response.msg);
    });
} else {
      API.showErrorAlert('No hay cambios para guardar')
    }
    this.closeDetailModal();
  },

  saveSintomas() {
    const self = this;
    
    API.send('POST', 'orders/metadata/save/', {
      orderId: self.GetMedicoFichaActive.orden.id,
      slug: 'sintomas_presentes',
      detail: self.decima_evaluacion_sintomas_fisio,
      uniqueMeta: 1,
    }, function (response) {
      API.showSuccessAlert(response.msg);
      self.emitter.emit("medico-ficha-notify",['sintomas_presentes']);
    },
    function (response) {
      API.showErrorAlert(response.msg);
    });
  },

  addSintoma() {
    this.resetSintoma();
    this.showEditionSintoma = true;
  },

  removeSintoma(index) {
    if (index >= 0 && index < this.decima_evaluacion_sintomas_fisio.length) {
      // Crear una copia del array antes de modificarlo
      const sintomasActualizados = [...this.decima_evaluacion_sintomas_fisio];
      // Eliminar el elemento del índice
      sintomasActualizados.splice(index, 1);
      // Actualizar el array original
      this.decima_evaluacion_sintomas_fisio = sintomasActualizados;
      // Guardar el array actualizado
      this.saveSintomas();
    } else {
      API.showErrorAlert('Índice de síntoma inválido');
    }
  },
  resetSintoma() {
      this.sintoma = {
          id: 0,
          detail: {
              descripcion: '',
          }
      };
  },
  cancelEditSintoma() {
      this.resetSintoma();
      this.showEditionSintoma = false;
  },
  saveSintoma() {
      const self = this;
      if (self.sintoma.detail.descripcion && self.sintoma.detail.descripcion.length > 0) {
          API.send('POST', 'orders/metadata/save/', {
              orderId: self.GetMedicoFichaActive.orden.id,
              metadataId: self.sintoma.id,
              slug: 'sintomas_presentes',
              detail: self.sintoma.detail,
          }, function (response) {
              API.showSuccessAlert(response.msg);
              self.emitter.emit("medico-ficha-refresh", ['sintomas_presentes']);
          },
          function (response) {
              API.showErrorAlert(response.msg);
          });
      } else {
          API.showErrorAlert('No se ha ingresado una descripción del síntoma');
      }
      this.cancelEditSintoma();
  },
  editSintoma(item) {
      this.resetSintoma();
      this.sintoma = item;
      this.showEditionSintoma = true;
  },
  deleteSintoma(item) {
      const self = this;
      API.showConfirm('¿Está seguro de eliminar?', '', function (){
          API.send('POST', 'orders/metadata/delete', {
                  metadataId: item.id,
              }, function (response) {
                  API.showSuccessAlert(response.msg);
                  self.emitter.emit("medico-ficha-refresh",['sintomas_presentes']);
              },
              function (response) {
                  API.showErrorAlert(response.msg);
              });
      })
  },
 }

 }
</script>
 
 
<template>
  <div>
  <Box title="Zona de afectación" icon="fa-solid fa-person-circle-exclamation" contraction="true">
   <div v-if="showBodyPart">
    <small class="form-text text-primary">*Seleccione las zonas musculares en las que el paciente presenta afectación.</small>
    <small class="form-text text-primary">*Puedes seleccionar multiples zonas</small>
      <BodyPartsSelector />
   </div>
   <div v-else>
    <small class="form-text text-primary">Cargando...</small>
   </div>
  </Box>

    <Box title="Tratamientos" icon="fa-regular fa-calendar-days" contraction="true">
    <div class="row">
      <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in fisioterapia_tratamientos" :key="key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveTreatments"/>
                    <div class="toggle-switch"></div>
            <span class="toggle-label">{{ item.name }}</span>
                </label>
            </div>
    </div>
  </Box>

  <Box title="Informacion de tratamiento actual" icon="fa-solid fa-circle-info" contraction="true">
    <ModalDetail v-if="detailModal" :detail="detailData" @closeModal="closeDetailModal" @saveData="saveDataFromModal"/>
    <div class="w-100">
      <div class="changeShowTable cursor-pointer" @click="showTable=!showTable">
      <p v-if="showTable" class="table-title"><i class="fa-solid fa-eye-slash"></i>  Ocultar tabla de observaciones detalladas</p>
          <p v-else><i class="fa-solid fa-eye"></i>  Ver tabla de observaciones detalladas</p>
    </div>
    </div>
    <div v-if="showTable">
      <table class="table">
        <thead class="thead-light">
          <tr>
            <th scope="col">Tratamiento</th>
            <th scope="col">Opción</th>
          </tr>
        </thead>
        <tbody>
          <tr class="item-table cursor-pointer" @click="openDetailModal(item)" v-show="item.value" v-for="(item, key) in fisioterapia_tratamientos" :key="key">
            <td>{{ item.name }}</td>
            <td><i title="Ver o Editar" class="fa-solid fa-eye text-primary"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="form-group col-12">
                    <label class="section-label">Observación general</label>
                    <textarea v-model="observaciones.general" class="form-control" placeholder="Escribe aquí..." @change="saveObservaciones"></textarea>
        </div>
        <div class="form-group col-12">
                    <label class="section-label">Fuerza muscular</label>
                    <textarea v-model="observaciones.fuerza" class="form-control" placeholder="Escribe aquí..." @change="saveObservaciones"></textarea>
        </div>
    </div>
  </Box>

  <Box v-if="fisioterapia_tratamientos.ejercicios_de_amplitud_articular.value" title="Indicaciones de amplitud articular" icon="fa-solid fa-person-chalkboard" contraction="true">
    <div class="cards-container singleborderbox row mx-1">
      <div v-for="(item, key) in indicaciones_amplitud_articular" class="col-12 col-md-4" style="width: 15rem;" :key="key">
        <div class="card px-1">
          <div class="card-body">
          <h5 class="card-title mb-2">{{ item.name }}</h5>
          <div v-show="item.adduction_grades" class="form-group">
              <label class="section-label">Adducción | {{ item.adduction_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.adduction" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.abduction_grades" class="form-group">
              <label class="section-label">Abducción | {{item.abduction_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.abduction" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.flexion_grades" class="form-group">
              <label class="section-label">Flexión | {{item.flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.extension_grades" class="form-group">
              <label class="section-label">Extensión | {{item.extension_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.extension" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.internal_rotation_grades" class="form-group">
              <label class="section-label">Rotación interna | {{item.internal_rotation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.internal_rotation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.external_rotation_grades" class="form-group">
              <label class="section-label">Rotación externa | {{item.external_rotation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.external_rotation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.pronation_grades" class="form-group">
              <label class="section-label">Pronación | {{item.pronation_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.pronation" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.supination_grades" class="form-group">
              <label class="section-label">Supinación | {{item.supination_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.supination" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.ulnar_flexion_grades" class="form-group">
              <label class="section-label">Flexión cubital | {{item.ulnar_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.ulnar_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.radial_flexion_grades" class="form-group">
              <label class="section-label">Flexión radial | {{item.radial_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.radial_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.inversion_grades" class="form-group">
              <label class="section-label">Iversión | {{item.inversion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.inversion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.eversion_grades" class="form-group">
              <label class="section-label">Eversion | {{item.eversion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.eversion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.plantar_flexion_grades" class="form-group">
              <label class="section-label">Flexión plantar | {{item.plantar_flexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.plantar_flexion" @change="saveIndications"/>
              </div>
          </div>
          <div v-if="item.dorsiflexion_grades" class="form-group">
              <label class="section-label">Flexión dorsal | {{item.dorsiflexion_grades }}</label>
              <div class="input-group">
                  <input type="text" class="form-control" v-model="item.dorsiflexion" @change="saveIndications"/>
              </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Box>

    <Box title="Evaluaciones" icon="fa-solid fa-clipboard-list" contraction="true">
      <div class="mb-4">
        <div class="btn-group mb-4 evaluation-tabs w-100" role="group">
          <button type="button" class="btn evaluation-tab" 
                  :class="{'active': currentEvaluacion === 'primera'}"
                  @click="currentEvaluacion = 'primera'">
            <i class="fa-solid fa-1"></i> Primera Evaluación
          </button>
          <button type="button" class="btn evaluation-tab" 
                  :class="{'active': currentEvaluacion === 'quinta'}"
                  @click="currentEvaluacion = 'quinta'">
            <i class="fa-solid fa-5"></i> Quinta Evaluación
          </button>
          <button type="button" class="btn evaluation-tab" 
                  :class="{'active': currentEvaluacion === 'decima'}"
                  @click="currentEvaluacion = 'decima'">
            <i class="fa-solid fa-1"></i><i class="fa-solid fa-0"></i> Décima Evaluación
          </button>
        </div>

        <div v-if="currentEvaluacion === 'primera'">
          <div class="form-group mb-3">
            <label  class="section-label">Fecha de Evaluación</label>
            <input type="date" class="form-control" v-model="primera_evaluacion_fisio.fecha" @change="saveEvaluacion('primera_evaluacion_fisioterapia')"/>
          </div>

          <div class="form-group mb-3">
            <label  class="section-label">EVA (Escala Visual Analógica)</label>
            <div class="eva-container">
              <input type="range" 
                     class="eva-range" 
                     min="0" 
                     max="10" 
                     v-model.number="primera_evaluacion_fisio.eva" 
                     @change="saveEvaluacion('primera_evaluacion_fisioterapia')"/>
              <div class="eva-labels">
                <div class="eva-label">
                  <span class="eva-value">0</span>
                  <span class="eva-text">Sin dolor</span>
                </div>
                <div class="eva-current">
                  <span class="eva-value">{{ primera_evaluacion_fisio.eva }}</span>
                </div>
                <div class="eva-label">
                  <span class="eva-value">10</span>
                  <span class="eva-text">Dolor máximo</span>
                </div>
              </div>
              <div class="eva-markers">
                <div class="eva-marker" v-for="n in 11" :key="n-1"></div>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
                <label class="section-label">Información Adicional de la evaluación</label>
                <textarea class="form-control" 
                         v-model="primera_evaluacion_fisio.informacion_general"
                         @change="saveEvaluacion('primera_evaluacion_fisioterapia')" 
                         rows="3"
                         placeholder="Ingrese información general sobre la evaluación..."></textarea>
          </div>

            <!-- Agente Físico -->
            <div class="mb-4">
              <label class="section-label">Agente Físico</label>
              <div class="row">
                <template v-for="(value, key) in primera_evaluacion_fisio.agente_fisico" :key="key">
                  <div class="col-12 col-sm-4 mb-3" >
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                             v-model="primera_evaluacion_fisio.agente_fisico[key]"
                             @change="saveEvaluacion('primera_evaluacion_fisioterapia')"
                             :id="'af-' + key"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                        {{ key === 'us' ? 'Ultrasonido' :
                           key === 'ee' ? 'Electroestimulación' :
                           key === 'magneto_tecar' ? 'Magneto Tecar' :
                           key === 'frio' ? 'Frío' :
                           key === 'calor' ? 'Calor' :
                           key === 'laser' ? 'Láser' : key.toUpperCase() }}
                      </span>
                    </label>
                  </div>
                </template>
              </div>
            </div>

            <!-- Ejercicios -->
            <div class="mb-4">
              <label class="section-label">Ejercicios</label>
              <div class="row">
                <template v-for="(value, key) in primera_evaluacion_fisio.ejercicios" :key="key">
                  <div class="col-12 col-sm-4 mb-3" >
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                             v-model="primera_evaluacion_fisio.ejercicios[key]"
                           @change="saveEvaluacion('primera_evaluacion_fisioterapia')"
                           :id="'primera-ej-' + key"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                      {{ key === 'lbs' ? 'Libras' :
                         key === 'fm_liga' ? 'Fuerza Muscular Liga' :
                         key === 'rv' ? 'Resistencia Variable' :
                         key === 'a' ? 'Activo' :
                         key === 'az' ? 'Azul' :
                         key === 'n' ? 'Negro' :
                         key === 'p' ? 'Propiocepción' :
                         key === 'estiramientos' ? 'Estiramientos' :
                         key === 'otros' ? 'Otros' :
                         key === 'isometricos' ? 'Isométricos' :
                         key === 'propiocepcion' ? 'Propiocepción' :
                         key === 'electroestimulacion' ? 'Electroestimulación' : 
                         key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                    </span>
                  </label>
                </div>
              </template>
              </div>

              <!-- Terapia Manual -->
              <div class="mb-4">
                <label class="section-label">Terapia Manual</label>
                <div class="row">
                  <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in primera_evaluacion_fisio.terapia_manual" :key="key">
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                            v-model="primera_evaluacion_fisio.terapia_manual[key]"
                            @change="saveEvaluacion('primera_evaluacion_fisioterapia')"
                            :id="'primera-tm-' + key"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                        {{ key === 'movilidad_pa' ? 'Movilidad Pasiva' :
                          key === 'drenaje_linfatico' ? 'Drenaje Linfático' :
                          key === 'streching' ? 'Estiramiento' :
                          key === 'reeducacion_sensorial' ? 'Reeducación Sensorial' :
                          key === 'masaje' ? 'Masaje' :
                          key === 'manipulacion' ? 'Manipulación' :
                          key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                      </span>
                    </label>
                  </div>
                </div>
              
                <div v-if="primera_evaluacion_fisio.terapia_manual.manipulacion" class="mt-3">
                <label class="section-label">Opciones de Manipulación</label>
                <div class="row">
                  <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in primera_evaluacion_fisio.opciones_manipulacion" :key="key">
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                            v-model="primera_evaluacion_fisio.opciones_manipulacion[key]"
                            @change="saveEvaluacion('primera_evaluacion_fisioterapia')"
                            :id="'primera-manipulacion-' + key"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                        {{ key === 'flex' ? 'Flexión' :
                          key === 'add' ? 'Aducción' :
                          key === 'ext' ? 'Extensión' :
                          key === 'abd' ? 'Abducción' :
                          key === 'r_int' ? 'Rotación Interna' :
                          key === 'r_ext' ? 'Rotación Externa' :
                          key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

                <div class="form-group mt-3">
                  <label class="section-label">Información Adicional de la evaluación</label>
                  <textarea class="form-control" 
                          v-model="primera_evaluacion_fisio.informacion_adicional"
                          @change="saveEvaluacion('primera_evaluacion_fisioterapia')" 
                          rows="3"
                          placeholder="Ingrese información adicional sobre la evaluación..."></textarea>
                </div>
              </div>

              <!-- Otros -->
              <div class="mb-4">
                <label class="section-label">Otros</label>
                <div class="row">
                  <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in primera_evaluacion_fisio.otros" :key="key">
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                            v-model="primera_evaluacion_fisio.otros[key]"
                            @change="saveEvaluacion('primera_evaluacion_fisioterapia')"
                            :id="'primera-otros-' + key"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                        {{ key === 'traccion_lumbar' ? 'Tracción Lumbar' :
                          key === 'reeduc_marcha' ? 'Reeducación de Marcha' :
                          key === 'traccion_cervical' ? 'Tracción Cervical' :
                          key === 'presoterapia' ? 'Presoterapia' :
                          key === 'bicicleta' ? 'Bicicleta' :
                          key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        <div v-if="currentEvaluacion === 'quinta'">
        <div class="form-group mb-3">
          <label  class="section-label">Fecha de Evaluación</label>
          <input type="date" class="form-control" v-model="quinta_evaluacion_fisio.fecha" @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"/>
        </div>

        <div class="form-group mb-3">
          <label  class="section-label">EVA (Escala Visual Analógica)</label>
          <div class="eva-container">
            <input type="range" 
                    class="eva-range" 
                    min="0" 
                    max="10" 
                    v-model.number="quinta_evaluacion_fisio.eva" 
                    @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"/>
            <div class="eva-labels">
              <div class="eva-label">
                <span class="eva-value">0</span>
                <span class="eva-text">Sin dolor</span>
              </div>
              <div class="eva-current">
                <span class="eva-value">{{ quinta_evaluacion_fisio.eva }}</span>
              </div>
              <div class="eva-label">
                <span class="eva-value">10</span>
                <span class="eva-text">Dolor máximo</span>
              </div>
            </div>
            <div class="eva-markers">
              <div class="eva-marker" v-for="n in 11" :key="n-1"></div>
            </div>
          </div>
        </div>

        <div class="form-group mt-3">
              <label  class="section-label">Información General</label>
              <textarea class="form-control" 
                        v-model="quinta_evaluacion_fisio.informacion_general"
                        @change="saveEvaluacion('quinta_evaluacion_fisioterapia')" 
                        rows="3"
                        placeholder="Ingrese información general sobre la evaluación..."></textarea>
        </div>

          <!-- Agente Físico -->
          <div class="mb-4">
            <label class="section-label">Agente Físico</label>
            <div class="row">
              <template v-for="(value, key) in quinta_evaluacion_fisio.agente_fisico" :key="key">
                <div class="col-12 col-sm-4 mb-3" >
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" 
                            v-model="quinta_evaluacion_fisio.agente_fisico[key]"
                            @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"
                            :id="'quinta-af-' + key"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                      {{ key === 'us' ? 'Ultrasonido' :
                          key === 'ee' ? 'Electroestimulación' :
                          key === 'magneto_tecar' ? 'Magneto Tecar' :
                          key === 'frio' ? 'Frío' :
                          key === 'calor' ? 'Calor' :
                          key === 'laser' ? 'Láser' : key.toUpperCase() }}
                    </span>
                  </label>
                </div>
              </template>
            </div>
          </div>

          <!-- Ejercicios -->
          <div class="mb-4">
            <label class="section-label">Ejercicios</label>
            <div class="row">
              <template v-for="(value, key) in quinta_evaluacion_fisio.ejercicios" :key="key">
                <div class="col-12 col-sm-4 mb-3" >
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" 
                            v-model="quinta_evaluacion_fisio.ejercicios[key]"
                          @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"
                          :id="'quinta-ej-' + key"/>
                  <div class="toggle-switch"></div>
                  <span class="toggle-label">
                    {{ key === 'lbs' ? 'Libras' :
                        key === 'fm_liga' ? 'Fuerza Muscular Liga' :
                        key === 'rv' ? 'Resistencia Variable' :
                        key === 'a' ? 'Activo' :
                        key === 'az' ? 'Azul' :
                        key === 'n' ? 'Negro' :
                        key === 'p' ? 'Propiocepción' :
                        key === 'estiramientos' ? 'Estiramientos' :
                        key === 'otros' ? 'Otros' :
                        key === 'isometricos' ? 'Isométricos' :
                        key === 'propiocepcion' ? 'Propiocepción' :
                        key === 'electroestimulacion' ? 'Electroestimulación' : 
                        key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                  </span>
                </label>
              </div>
            </template>
            </div>

            <!-- Terapia Manual -->
            <div class="mb-4">
              <label class="section-label">Terapia Manual</label>
              <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in quinta_evaluacion_fisio.terapia_manual" :key="key">
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" 
                          v-model="quinta_evaluacion_fisio.terapia_manual[key]"
                          @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"
                          :id="'quinta-tm-' + key"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                      {{ key === 'movilidad_pa' ? 'Movilidad Pasiva' :
                        key === 'drenaje_linfatico' ? 'Drenaje Linfático' :
                        key === 'streching' ? 'Estiramiento' :
                        key === 'reeducacion_sensorial' ? 'Reeducación Sensorial' :
                        key === 'masaje' ? 'Masaje' :
                        key === 'manipulacion' ? 'Manipulación' :
                        key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                    </span>
                  </label>
                </div>
              </div>
            
              <div v-if="quinta_evaluacion_fisio.terapia_manual.manipulacion" class="mt-3">
                <label class="section-label">Opciones de Manipulación</label>
                <div class="row">
                  <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in quinta_evaluacion_fisio.opciones_manipulacion" :key="key">
                    <label class="toggle">
                      <input class="toggle-checkbox" type="checkbox" 
                            v-model="quinta_evaluacion_fisio.opciones_manipulacion[key]"
                            @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"
                            :id="'quinta-manipulacion-' + key"/>
                      <div class="toggle-switch"></div>
                      <span class="toggle-label">
                        {{ key === 'flex' ? 'Flexión' :
                          key === 'add' ? 'Aducción' :
                          key === 'ext' ? 'Extensión' :
                          key === 'abd' ? 'Abducción' :
                          key === 'r_int' ? 'Rotación Interna' :
                          key === 'r_ext' ? 'Rotación Externa' :
                          key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group mt-3">
                <label>Información Adicional</label>
                <textarea class="form-control" 
                        v-model="quinta_evaluacion_fisio.informacion_adicional"
                        @change="saveEvaluacion('quinta_evaluacion_fisioterapia')" 
                        rows="3"
                        placeholder="Ingrese información adicional sobre la evaluación..."></textarea>
              </div>
            </div>

            <!-- Otros -->
            <div class="mb-4">
              <label class="section-label">Otros</label>
              <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(value, key) in quinta_evaluacion_fisio.otros" :key="key">
                  <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" 
                          v-model="quinta_evaluacion_fisio.otros[key]"
                          @change="saveEvaluacion('quinta_evaluacion_fisioterapia')"
                          :id="'quinta-otros-' + key"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                      {{ key === 'traccion_lumbar' ? 'Tracción Lumbar' :
                        key === 'reeduc_marcha' ? 'Reeducación de Marcha' :
                        key === 'traccion_cervical' ? 'Tracción Cervical' :
                        key === 'presoterapia' ? 'Presoterapia' :
                        key === 'bicicleta' ? 'Bicicleta' :
                        key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentEvaluacion === 'decima'">
          <div class="form-group mb-3">
            <label>Fecha de Evaluación</label>
            <input type="date" class="form-control" v-model="decima_evaluacion_fisio.fecha" @change="saveEvaluacion('decima_evaluacion_fisioterapia')"/>
          </div>

          <div class="form-group mb-3">
            <label>EVA (Escala Visual Analógica)</label>
            <div class="eva-container">
              <input type="range" 
                     class="eva-range" 
                     min="0" 
                     max="10" 
                     v-model.number="decima_evaluacion_fisio.eva" 
                     @change="saveEvaluacion('decima_evaluacion_fisioterapia')"/>
              <div class="eva-labels">
                <div class="eva-label">
                  <span class="eva-value">0</span>
                  <span class="eva-text">Sin dolor</span>
                </div>
                <div class="eva-current">
                  <span class="eva-value">{{ decima_evaluacion_fisio.eva }}</span>
                </div>
                <div class="eva-label">
                  <span class="eva-value">10</span>
                  <span class="eva-text">Dolor máximo</span>
                </div>
              </div>
              <div class="eva-markers">
                <div class="eva-marker" v-for="n in 11" :key="n-1"></div>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Información General</label>
            <textarea class="form-control" 
                     v-model="decima_evaluacion_fisio.informacion_general"
                     @change="saveEvaluacion('decima_evaluacion_fisioterapia')" 
                     rows="3"
                     placeholder="Ingrese información general sobre la evaluación..."></textarea>
          </div>

          <!-- Síntomas Presentes -->
          <div class="mt-3">
            <label class="section-label">Síntomas Presentes</label>
            <div class="mt-5 mb-2" v-if="showEditionSintoma">
                <div>
                    <h5>Agregar síntoma</h5>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="section-label">Descripción del síntoma</label>
                            <input type="text" class="form-control" 
                                   v-model="sintoma.detail.descripcion"
                                   placeholder="Describa el síntoma..."/>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-3">
                    <button @click="cancelEditSintoma" class="btn btn-danger mr-3">Cancelar</button>
                    <button @click="saveSintoma" class="btn btn-primary mr-3">Guardar</button>
                </div>
            </div>
          
            <div v-if="typeof GetMedicoFichaActive.metadata.sintomas_presentes === 'undefined'">
                <div class="text-center text-danger">No hay síntomas registrados</div>
            </div>
            <div v-else>
                <table class="table align-middle m-0">
                    <thead class="small flex-sm-nowrap font-weight-bold">
                        <tr class="font-weight-bold small">
                            <th class="font-weight-bold">Descripción</th>
                            <th class="font-weight-bold"></th>
                        </tr>
                    </thead>
                    <tbody class="small">
                        <tr v-for="(item) in GetMedicoFichaActive.metadata.sintomas_presentes" :key="item.id">
                            <template v-if="(typeof item.detail !== 'undefined')">
                                <td>{{ item.detail.descripcion }}</td>
                                <td class="text-right">
                                    <i @click="editSintoma(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                                    <i @click="deleteSintoma(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div v-if="!showEditionSintoma" class="mt-4 mb-2 text-right">
                <button class="btn btn-primary" @click="addSintoma">
                    <i class="fa fa-plus mr-2"></i>Agregar síntoma
                </button>
            </div>

          </div>

          <!-- Apreciación Estado de Síntomas -->
          <div class="mt-3">
            <label class="section-label">Estado de Síntomas</label>
            <div class="estado-sintomas-container">
              <div class="row">
                <div class="col-12 col-sm-4 mb-3">
                  <div class="estado-card" :class="{'estado-card-active': decima_evaluacion_fisio.apreciacion_estado_sintomas.resolvio}" @click="decima_evaluacion_fisio.apreciacion_estado_sintomas.resolvio = !decima_evaluacion_fisio.apreciacion_estado_sintomas.resolvio; saveEvaluacion('decima_evaluacion_fisioterapia')">
                    <input class="d-none" type="checkbox" 
                           v-model="decima_evaluacion_fisio.apreciacion_estado_sintomas.resolvio"
                           id="sintomas-resolvio"/>
                    <div class="estado-card-content">
                      <div class="estado-icon-container">
                        <i class="fa-solid fa-check-circle"></i>
                      </div>
                      <div class="estado-text">
                        <h6 class="mb-0">Se resolvió</h6>
                        <small>El paciente ya no presenta síntomas</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <div class="estado-card" :class="{'estado-card-active': decima_evaluacion_fisio.apreciacion_estado_sintomas.no_resolvio}" @click="decima_evaluacion_fisio.apreciacion_estado_sintomas.no_resolvio = !decima_evaluacion_fisio.apreciacion_estado_sintomas.no_resolvio; saveEvaluacion('decima_evaluacion_fisioterapia')">
                    <input class="d-none" type="checkbox" 
                           v-model="decima_evaluacion_fisio.apreciacion_estado_sintomas.no_resolvio"
                           id="sintomas-no-resolvio"/>
                    <div class="estado-card-content">
                      <div class="estado-icon-container estado-icon-error">
                        <i class="fa-solid fa-times-circle"></i>
                      </div>
                      <div class="estado-text">
                        <h6 class="mb-0">No se resolvió</h6>
                        <small>El paciente continúa con síntomas</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-4 mb-3">
                  <div class="estado-card" :class="{'estado-card-active': decima_evaluacion_fisio.apreciacion_estado_sintomas.va_en_proceso}" @click="decima_evaluacion_fisio.apreciacion_estado_sintomas.va_en_proceso = !decima_evaluacion_fisio.apreciacion_estado_sintomas.va_en_proceso; saveEvaluacion('decima_evaluacion_fisioterapia')">
                    <input class="d-none" type="checkbox" 
                           v-model="decima_evaluacion_fisio.apreciacion_estado_sintomas.va_en_proceso"
                           id="sintomas-en-proceso"/>
                    <div class="estado-card-content">
                      <div class="estado-icon-container estado-icon-warning">
                        <i class="fa-solid fa-spinner"></i>
                      </div>
                      <div class="estado-text">
                        <h6 class="mb-0">Va en proceso</h6>
                        <small>El paciente muestra mejoría parcial</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Consistencia del Tratamiento -->
          <div class="mt-3">
            <label class="section-label">Consistencia del Tratamiento</label>
            <div class="consistencia-container">
              <div class="row">
                <div class="col-12 col-sm-6 mb-3">
                  <div class="consistencia-card" :class="{'consistencia-card-active': decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.constante}" @click="decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.constante = !decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.constante; saveEvaluacion('decima_evaluacion_fisioterapia')">
                    <input class="d-none" type="checkbox" 
                           v-model="decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.constante"
                           id="tratamiento-constante"/>
                    <div class="consistencia-card-content">
                      <div class="consistencia-icon-container">
                        <i class="fa-solid fa-calendar-check"></i>
                      </div>
                      <div class="consistencia-text">
                        <h6 class="mb-0">Constante</h6>
                        <small>El paciente asistió regularmente a sus terapias</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6 mb-3">
                  <div class="consistencia-card" :class="{'consistencia-card-active': decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.inconstante}" @click="decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.inconstante = !decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.inconstante; saveEvaluacion('decima_evaluacion_fisioterapia')">
                    <input class="d-none" type="checkbox" 
                           v-model="decima_evaluacion_fisio.apreciacion_consistencia_tratamiento.inconstante"
                           id="tratamiento-inconstante"/>
                    <div class="consistencia-card-content">
                      <div class="consistencia-icon-container consistencia-icon-error">
                        <i class="fa-solid fa-calendar-times"></i>
                      </div>
                      <div class="consistencia-text">
                        <h6 class="mb-0">Inconstante</h6>
                        <small>El paciente tuvo ausencias en sus terapias</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group mt-3">
            <label>Información Adicional</label>
            <textarea class="form-control" 
                     v-model="decima_evaluacion_fisio.informacion_adicional"
                     @change="saveEvaluacion('decima_evaluacion_fisioterapia')" 
                     rows="3"
                     placeholder="Ingrese información adicional sobre la evaluación..."></textarea>
          </div>
        </div>

      </div>
    </Box>

    <!-- Conclusiones -->
    <Box title="Conclusiones" icon="fa-solid fa-flag-checkered" contraction="true">
      <div class="form-group mt-3">
        <label class="section-label">Notas y Sugerencias</label>
        <textarea class="form-control" v-model="conclusiones.notas_sugerencias" 
                  @change="saveConclusiones" rows="3"></textarea>
      </div>

      <div class="mt-3">
        <label class="toggle">
          <input class="toggle-checkbox" type="checkbox" 
                 v-model="conclusiones.sugiere_ciclo_terapias"
                 @change="saveConclusiones"
                 id="sugiere-ciclo"/>
          <div class="toggle-switch"></div>
          <span class="toggle-label">Sugiere ciclo de terapias</span>
        </label>
      </div>

      <div class="form-group mt-3" v-if="conclusiones.sugiere_ciclo_terapias">
        <label class="section-label">Para</label>
        <input type="text" class="form-control" v-model="conclusiones.para" @change="saveConclusiones"/>
      </div>

      <div class="mt-4">
        <label class="section-label mb-2">Finaliza Tratamiento</label>
        <div class="row">
          <div class="col-12 col-sm-4 mb-3">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" 
                     v-model="conclusiones.finaliza_tratamiento.indicacion_cirugia.value"
                     @change="saveConclusiones"
                     id="indicacion-cirugia"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">Indicación de cirugía</span>
            </label>
          </div>
        </div>
        
        <div class="form-group mt-3" v-if="conclusiones.finaliza_tratamiento.indicacion_cirugia.value">
          <label class="section-label">Fecha de Cirugía</label>
          <input type="date" class="form-control" 
                 v-model="conclusiones.finaliza_tratamiento.indicacion_cirugia.fecha"
                 @change="saveConclusiones"/>
        </div>

        <div class="row">
          <div class="col-12 col-sm-4 mt-3">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" 
                     v-model="conclusiones.finaliza_tratamiento.no_concluyo_tratamiento"
                     @change="saveConclusiones"
                     id="no-concluyo"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">No concluyó tratamiento</span>
            </label>
          </div>

          <div class="col-12 col-sm-4 mt-3">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" 
                     v-model="conclusiones.finaliza_tratamiento.no_autorizaron_terapias"
                     @change="saveConclusiones"
                     id="no-autorizaron"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">No autorizaron terapias</span>
            </label>
          </div>

          <div class="col-12 col-sm-4 mt-3">
            <label class="toggle">
              <input class="toggle-checkbox" type="checkbox" 
                     v-model="conclusiones.finaliza_tratamiento.otro.value"
                     @change="saveConclusiones"
                     id="otro-motivo"/>
              <div class="toggle-switch"></div>
              <span class="toggle-label">Otro</span>
            </label>
          </div>
        </div>

        <div class="form-group mt-3" v-if="conclusiones.finaliza_tratamiento.otro.value">
          <label>Descripción</label>
          <textarea class="form-control" 
                    v-model="conclusiones.finaliza_tratamiento.otro.descripcion"
                    @change="saveConclusiones" rows="3"></textarea>
        </div>
      </div>

      <div class="mt-4">
        <label class="section-label">Campos Adicionales</label>
        <div class="row">
          <div class="col-12 col-sm-4 mt-3" v-for="(value, key) in conclusiones.campos_adicionales" :key="key">
            <label class="section-label">{{ key === 'rom' ? 'Rango de Movimiento' :
                     key === 'fm' ? 'Fuerza Muscular' :
                     key === 'inflamacion' ? 'Inflamación' :
                     key === 'edema' ? 'Edema' :
                     key === 'dolor' ? 'Dolor' :
                     key === 'propio' ? 'Propiocepción' :
                     key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}</label>
            <textarea class="form-control" v-model="conclusiones.campos_adicionales[key]"
                      @change="saveConclusiones" rows="3"
                      placeholder="Ingrese información adicional sobre {{ key }}..."></textarea>
          </div>
        </div>
      </div>
    </Box>
  </div>
</template>
 
 
<style scoped>

.item-table{
  transition: 0.3s;
}
 .item-table:hover{
  color:#0ca7e0;
  transform: scale(1.01);
 }

.changeShowTable{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px 2px 10px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.144);
  height: 40px;
  padding:0 10px;
}

.changeShowTable:hover{
  background-color: rgba(145, 144, 157, 0.097);
}

/* Estilos para las pestañas de evaluación */
.evaluation-tabs {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0,0,0,0.05);
  border: 1px solid rgba(0,0,0,0.1);
}

.evaluation-tab {
  flex: 1;
  text-align: center;
  padding: 12px 16px;
  background-color: #f8f9fa;
  color: #495057;
  border: none;
  transition: all 0.3s ease;
  font-weight: 500;
  position: relative;
}

.evaluation-tab:not(:last-child) {
  border-right: 1px solid rgba(0,0,0,0.1);
}

.evaluation-tab.active {
  background-color: #0ca7e0;
  color: white;
  box-shadow: 0 2px 10px rgba(12, 167, 224, 0.3);
  z-index: 2;
}

.evaluation-tab:hover:not(.active) {
  background-color: #e9ecef;
}

.evaluation-tab i {
  margin-right: 6px;
}

/* Estilos para los síntomas */
.sintomas-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05);
}

.empty-sintomas {
  color: #6c757d;
  background-color: white;
  border-radius: 6px;
  border: 1px dashed #dee2e6;
}

.empty-sintomas i {
  font-size: 24px;
  display: block;
  color: #adb5bd;
}

.sintomas-table {
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0;
}

.sintomas-table thead th {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  font-weight: 600;
  padding: 12px;
}

.sintomas-table tbody td {
  padding: 12px;
  vertical-align: middle;
}

.sintoma-input {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  transition: all 0.2s ease;
  width: 100%;
}

.sintoma-input:focus {
  border-color: #0ca7e0;
  box-shadow: 0 0 0 0.2rem rgba(12, 167, 224, 0.25);
}

.sintoma-add {
  padding: 8px 20px;
  border-radius: 20px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sintoma-add:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(12, 167, 224, 0.2);
}

.sintoma-add i {
  margin-right: 8px;
  font-size: 14px;
}

.table-title{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.changeShowTable p{
  margin: 0;
}

.form-range {
  height: 1.5rem;
  padding: 0;
  background: transparent;
  -webkit-appearance: none;
  margin: 10px 0;
}

.form-range:focus {
  outline: none;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #e9ecef;
  border-radius: 4px;
}

.form-range::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0ca7e0;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
  transition: all 0.2s ease-in-out;
}

.form-range::-webkit-slider-thumb:hover {
  background: #0b96c9;
  transform: scale(1.1);
}

.form-range::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #e9ecef;
  border-radius: 4px;
}

.form-range::-moz-range-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #0ca7e0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.form-range::-moz-range-thumb:hover {
  background: #0b96c9;
  transform: scale(1.1);
}

h5 {
  color: #495057;
  font-weight: 600;
}

h6 {
  color: #6c757d;
  font-weight: 500;
}

.toggle {
  margin-bottom: 0;
}

.form-group label {
  font-size: 0.875rem;
  color: #495057;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.form-control {
  font-size: 0.875rem;
}

h5 {
  font-size: 1rem;
  color: #495057;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

h6 {
  font-size: 0.875rem;
  color: #6c757d;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}

.toggle-label {
  font-size: 0.875rem;
  color: #495057;
}

.btn {
  font-size: 0.875rem;
  font-weight: 500;
}

.small, small {
  font-size: 0.8125rem;
}

.text-primary {
  color: #0ca7e0 !important;
}

.btn-primary {
  background-color: #0ca7e0;
  border-color: #0ca7e0;
}

.btn-outline-primary {
  color: #0ca7e0;
  border-color: #0ca7e0;
}

.btn-outline-primary:hover {
  background-color: #0ca7e0;
  border-color: #0ca7e0;
}

.form-range::-webkit-slider-thumb {
  background: #0ca7e0;
}

.form-range::-moz-range-thumb {
  background: #0ca7e0;
}

.form-range::-webkit-slider-thumb:hover {
  background: #0b96c9;
}

.form-range::-moz-range-thumb:hover {
  background: #0b96c9;
}

.cards-container {
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  padding: 1rem;
}

.cards-container .form-group {
  margin-bottom: 1rem;
}

.cards-container label {
  font-size: 0.8125rem;
  margin-bottom: 0.25rem;
}

.cards-container input {
  font-size: 0.875rem;
}

.cards-container h5 {
  font-size: 0.9375rem;
  margin-bottom: 1rem;
}

.eva-container {
  padding: 1.5rem 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.eva-range {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #4c82af, #FFC107, #FF5252);
  border-radius: 4px;
  outline: none;
  -webkit-appearance: none;
  margin: 1rem 0;
}

.eva-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  background: #0ca7e0;
  border: 3px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
}

.eva-range::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0,0,0,0.25);
}

.eva-range::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #0ca7e0;
  border: 3px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
}

.eva-range::-moz-range-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0,0,0,0.25);
}

.eva-labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.eva-label {
  text-align: center;
}

.eva-value {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  color: #495057;
  margin-bottom: 0.25rem;
}

.eva-text {
  font-size: 0.75rem;
  color: #6c757d;
}

.eva-current {
  background: #0ca7e0;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-weight: 600;
}

.eva-current .eva-value {
  color: white;
  margin: 0;
}

.eva-markers {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding: 0 12px;
}

.eva-marker {
  width: 2px;
  height: 8px;
  background: #dee2e6;
  border-radius: 1px;
}

.apreciacion-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05);
}

.toggle-card {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  padding: 8px 12px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.toggle-card:hover {
  box-shadow: 0 3px 8px rgba(0,0,0,0.08);
  transform: translateY(-1px);
}

.toggle-content {
  display: flex;
  align-items: center;
  flex: 1;
}

.toggle-icon {
  margin-right: 10px;
  font-size: 16px;
}

.toggle-active {
  background-color: #0ca7e0;
  color: white;
  box-shadow: 0 2px 10px rgba(12, 167, 224, 0.3);
  z-index: 2;
}

.toggle-active .toggle-label {
  color: white;
}

.toggle-active .toggle-icon {
  color: white;
}

.consistencia-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05);
}

.consistencia-card {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  padding: 8px 12px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.consistencia-card:hover {
  box-shadow: 0 3px 8px rgba(0,0,0,0.08);
  transform: translateY(-1px);
}

.consistencia-icon-container {
  margin-right: 10px;
  font-size: 16px;
}

.consistencia-text {
  flex: 1;
}

.consistencia-card-active {
  background-color: #0ca7e0;
  color: white;
  box-shadow: 0 2px 10px rgba(12, 167, 224, 0.3);
  z-index: 2;
}

.consistencia-card-active .consistencia-icon-container {
  color: white;
}

.consistencia-card-active .consistencia-text {
  color: white;
}

.estado-sintomas-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05);
}

.estado-card {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 6px;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.03);
  padding: 12px 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  height: 100%;
}

.estado-card:hover {
  box-shadow: 0 3px 8px rgba(0,0,0,0.08);
  transform: translateY(-1px);
}

.estado-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.estado-icon-container {
  margin-bottom: 10px;
  font-size: 24px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(12, 167, 224, 0.1);
  color: #0ca7e0;
}

.estado-icon-error {
  background-color: rgba(220, 53, 69, 0.1);
  color: #dc3545;
}

.estado-icon-warning {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.estado-text {
  flex: 1;
}

.estado-card-active {
  background-color: #0ca7e0;
  color: white;
  box-shadow: 0 2px 10px rgba(12, 167, 224, 0.3);
  border-color: #0ca7e0;
}

.estado-card-active .estado-icon-container {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.estado-card-active.estado-card .estado-icon-error {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.estado-card-active.estado-card .estado-icon-warning {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}
 
</style>