<template>
    <div>
      <ficha-layout title="Dermatologia" color="#e89b00" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
              <template #preclinica>
                  <pre-clinica></pre-clinica>
              </template>
              <template #antecedentes>
                  <antecedentes></antecedentes>
              </template>
              <template #examen_fisico>
                  <examen-fisico></examen-fisico>
              </template>
              <template #consulta>
                  <consulta></consulta>
              </template>
              <template #impresion_clinica>
                  <impresion-clinica></impresion-clinica>
              </template>
              <template #tratamiento>
                  <tratamiento></tratamiento>
              </template>
              <template #seguimiento>
                  <seguimiento></seguimiento>
              </template>
              <template #adjuntos>
                  <adjuntos></adjuntos>
              </template>
              <template #historial>
                  <historial></historial>
              </template>
              <template #resumen>
                  <resumen></resumen>
              </template>
      </ficha-layout>
    </div>
  </template>
   

<script>

//ficha's components
 import FichaLayout from '../components/FichaLayout.vue';
 import PreClinica from '../components/PreClinica.vue';
 import Antecedentes from '../components/Antecedentes.vue';
 import ExamenFisico from '../components/ExploracionFisica.vue';
 import ImpresionClinica from '../components/ImpresionClinica.vue';
 import Tratamiento from '../components/Tratamiento.vue';
 import Resumen from '../components/Resumen.vue';
 import Adjuntos from "../components/Adjuntos.vue";
 import Historial from '../components/HistorialPr.vue';
 import Consulta from './components/Consulta.vue';
 import Seguimiento from '../components/Seguimiento.vue';

export default { 
 data() {
        return {
        //data
        tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                examen_fisico: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen fisico',
                },
                consulta: {
                    icon: 'fa-solid fa-face-smile',
                    name: 'Dermatologia',//Consulta se refiere a el componente que contiene los datos del tipo de consulta actual de la ficha.
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica',
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento',
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                historial: {
                    icon: 'fas fa-paperclip',
                    name: 'Historial', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen',
                },
                seguimiento: {
                    icon: 'fa-solid fa-user-clock',
                    name: 'Seguimiento',
                },
              }
 }},
 props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    }
 },
 components:{
  FichaLayout,
  PreClinica,
  Antecedentes,
  ExamenFisico,
  ImpresionClinica,
  Tratamiento,
  Resumen,
  Consulta,
  Historial,
  Adjuntos,
  Seguimiento
 }
 }
</script>
<style scoped>
 
</style>