<template>
  <layout>
    <div class="content-body orderDetails">
      <div class="mb-80">
        <div class="container h-100">
          <form v-if="order.id" id="formData" @submit="save" method="post">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="card profile_card">
                  <div class="card-header">
                    <h4 class="card-title">
                      <a @click="$router.go(-1)"><i class="back fa fa-arrow-circle-left"></i></a>
                      Código: {{ order.codigoOrden }}
                    </h4>
                  </div>
                  <div class="card-body">
                    <div class="bg-light shadow-lg rounded-3 overflow-hidden formFillContainer">
                      <div v-if="!invalidLink" class="row">
                        <!-- Content-->
                        <section class="col-lg-12">
                          <div>
                            <div class="text-center mb-4 border-bottom">
                              <h5 class="mb-3 pb-4">{{info.formName}}</h5>
                            </div>
                            <div>
                              <div v-for="(group, key) in fields" :key="key">
                                <div class="mb-3">
                                  <h6 class="text-primary">{{key}}</h6>
                                </div>
                                <div class="row">
                                  <div v-for="field in group" :class="{'col-12 col-sm-4': field.tipo !== 'signature', 'col-12': field.tipo === 'signature'}" :key="field.id" v-show="ultraModelConditional[field.id]">
                                    <div class="form-group">

                                      <label>{{field.nombre}}</label>

                                      <!--enum('texto', 'numero', 'archivo', 'imagen', 'select', 'multiselect', 'date', 'datetime')-->
                                      <template v-if="field.tipo === 'texto'">
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="ultraModel[field.id]">
                                      </template>
                                      <template v-else-if="field.tipo === 'numero'">
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="ultraModel[field.id]">
                                      </template>
                                      <template v-else-if="field.tipo === 'select'">
                                        <select class="form-control" v-model="ultraModel[field.id]">
                                          <option v-for="(item, key) in field.opciones" :key="field.id+'-'+key" :value="key">{{item}}</option>
                                        </select>
                                      </template>
                                      <template v-else-if="field.tipo === 'date'">
                                        <input type="date" class="form-control" placeholder="Escribe aquí" v-model="ultraModel[field.id]">
                                        <!--<DatePicker v-model="ultraModel[field.id]">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                            </template>
                                        </DatePicker>-->
                                      </template>
                                      <template v-else-if="field.tipo === 'signature'">
                                        <div class="signaturePad">
                                          <div>
                                            Firma en el centro sin salir del recuadro inferior.
                                          </div>
                                          <div class="signaturePadContainer">
                                            <VPerfectSignature v-if="!formSaved" :stroke-options="strokeOptions" :ref="'signaturePad_' + field.id"  @onEnd="signatureEnd('signaturePad_' + field.id, field.id)" height="100%" width="100%"/>
                                            <div v-else>
                                              <img :src="ultraModel[field.id]"/>
                                            </div>
                                          </div>
                                          <div v-if="!formSaved" class="text-right mt-2">
                                            <a class="text-danger cursor-pointer" @click="signatureClear('signaturePad_' + field.id, field.id)">Limpiar</a>
                                            <input type="hidden" v-model="ultraModel[field.id]">
                                          </div>
                                          <div>
                                            <small>Después de guardar el formulario, la firma no puede modificarse</small>
                                          </div>
                                        </div>
                                      </template>
                                      <div class="validationError">
                                        {{field.error}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="text-center">
                              <div class="text-muted text-justify">
                                Por favor verifique su correo electrónico para verificar si existen formularios adicionales a completar. En caso ya hubiese completado todos los formularios, haga caso omiso de este aviso.
                              </div>
                              <div class="btn btn-success btn-shadow mt-4" @click="saveForm">
                                <i class="fa fa-save fs-lg me-2"></i> Guardar formulario
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div v-if="invalidLink" class="row align-items-center">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
                          <div class="my-5">
                            <h4>El enlace del formulario no existe o es inválido</h4>
                            <br>
                            Por favor verifica el enlace o contacta con el establecimiento en la cual obtuviste el enlace.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div v-else class="text-center">
            No se encontró ninguna orden
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapGetters} from "vuex";
//import {DatePicker} from "v-calendar";
import VPerfectSignature from 'v-perfect-signature'

export default {
  components: {
    Layout,
    VPerfectSignature

  },
  data() {
    return {
      userConfig: {},
      forms: {},
      order: {},
      reservas: {},
      pagos: true,
      formularios: false,
      entregas: false,
      historial: false,
      facturar: false,
      virtual: false,
      estatus: 'creada',
      estatusGuardado: '',
      confirmationOrdercode: '',
      formSaved: false,
      strokeOptions: {
        size: 9,
        thinning: 0.70,
        smoothing: 0.5,
        streamline: 0.5
      },
      invalidLink: false,
      fields: {},
      info: {},
      ultraModel: {},
      ultraModelConditional: {},
    };
  },
  computed: {
    ...mapGetters({
      AuthGetUserInfo: 'AuthGetUserInfo',
    })
  },
  mounted() {
    this.getForm();
  },
  methods: {
    dayjs,
    Numbro,
    sendFormsTemplates(token) {
      API.send('GET', 'formularios/pdf-creator/send/' + token, {},
          function (response) {
            if (response.status) {
              API.showSuccessNotify(response.msg);
            }
            else {
              API.showErrorNotify(response.msg);
            }
          },
          function (response) {
            API.showErrorNotify(response.msg);
          });
    },
    downloadFormsTemplates(token) {
      API.send('GET', 'formularios/pdf-creator/download/' + token, {},
          function (response) {
            if (response.status) {
              window.open(response.data.url);
            }
            else {
              API.showErrorNotify(response.msg);
            }
          },
          function (response) {
            API.showErrorNotify(response.msg);
          });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + " " + strTime;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2)
      return val.toString()
    },
    notEmptyObject(someObject) {
      if (someObject) {
        return Object.keys(someObject).length
      }
      else {
        return false;
      }
    },
    showCard(stringToShow) {
      this.pagos = (stringToShow == 'pagos') ? true : false;
      this.entregas = (stringToShow == 'entregas') ? true : false;
      this.historial = (stringToShow == 'historial') ? true : false;
      this.facturar = (stringToShow == 'facturar') ? true : false;
      this.formularios = (stringToShow == 'formularios') ? true : false;
      this.virtual = (stringToShow == 'virtual') ? true : false;
    },
    loadToPOS() {

      this.$store.dispatch('OrderClearProducts');
      this.$store.dispatch('OrderPosClientClear');

      this.$store.dispatch('OrderPosClientSelect', {
        id: this.order.clienteId,
        email: this.order.correoContacto,
        taxId: this.order.taxCode,
        nombres: this.order.clientName,
        apellidos: '',
        telefono: this.order.telefonoContacto,
      });

      Object.entries(this.order.detalle).map(([key, val]) => {

        if (val.producto === null) {

          const montoDirectoProduct = {
            id: '_DIRECT_AMOUNT_',
            nombre: 'Pago por monto directo',
            precio: val.precio,
            clearTotal: true,
          };

          this.$store.dispatch('OrderAddProductToPos', montoDirectoProduct);
        }
        else {
          this.$store.dispatch('OrderAddProductToPos', val.producto);
        }
      })

      this.$router.push({ path: '/order/edit/' + this.order.id });

    },
    checkSession(reservaId) {
      const self = this;
      // obtengo lsa categorías
      API.send('POST', 'virtualmanager/reservas/checkroom', {reservaId: reservaId},
          function (response) {
            if (response.status) {
              if (response.data.token){
                window.open('/client/profile/virtualcall/' + response.data.token);
              }
            }
          },
          function (response) {
            API.showErrorNotify(response.msg);
          })
    },
    signatureGetImage(signaturePadRef) {
      let dataUrl = '';
      if (typeof this.$refs[signaturePadRef] !== 'undefined') {
        if (typeof this.$refs[signaturePadRef][0] !== 'undefined') {
          dataUrl = this.$refs[signaturePadRef][0].toDataURL('image/png');
        }
      }
      return dataUrl;
    },
    signatureEnd(signaturePadRef, fieldId) {
      this.ultraModel[fieldId] = this.signatureGetImage(signaturePadRef);
    },
    signatureClear(signaturePadRef, fieldId) {
      if (typeof this.$refs[signaturePadRef] !== 'undefined') {
        if (typeof this.$refs[signaturePadRef][0] !== 'undefined') {
          this.$refs[signaturePadRef][0].clear();
        }
      }
      this.ultraModel[fieldId] = '';
    },
    validateFields(forceReset) {
      let valid = true;
      for (let group in this.fields) {

        for (let field in this.fields[group]) {

          // si está visible
          if (this.ultraModelConditional[field] === true) {

            // valido
            if (this.fields[group][field]['required']) {
              const value = this.ultraModel[this.fields[group][field]['id']];

              if (forceReset) {
                this.fields[group][field]['error'] = '';
              }
              else {
                if (Tools.isEmptyString(value, this.ultraModel[this.fields[group][field]['minimo']])) {
                  this.fields[group][field]['error'] = 'Este campo es requerido';
                  valid = false;
                }
                else {
                  this.fields[group][field]['error'] = '';
                }
              }
            }
          }
        }
      }
      return valid;
    },
    getForm(id) {
      const self = this;

      API.send('GET', 'formularios/get/by-id/' + self.$route.params.id, {},
          function (response) {
            if (response.status) {
              self.info = response.data.info;
              self.order.id = response.data.info.orderId;
              self.fields = response.data.campos;

              // each vmodel
              for (let group in self.fields) {
                for (let key in self.fields[group]) {
                  self.ultraModel[self.fields[group][key]['id']] = self.fields[group][key]['value'];

                  if (!Tools.isEmptyString(self.fields[group][key]['value'])) {
                    self.formSaved = true;
                  }

                  if (self.fields[group][key]['condicional'] !== 0) {
                    self.ultraModelConditional[self.fields[group][key]['id']] = false;
                  }
                  else {
                    self.ultraModelConditional[self.fields[group][key]['id']] = true;
                  }
                }
              }
            }
            else {
              self.invalidLink = true;
            }
          },
          function () {
            self.invalidLink = true;
          })
    },
    saveForm(payWith, id) {

      if (!payWith) payWith = false;
      const self = this;

      if (this.validateFields()) {

        const dataSave = {
          formToken: this.info.formToken,
          fields: this.ultraModel,
        };

        API.send('POST', 'formularios/save/form', dataSave,
            function (response) {
              if (response.status) {
                API.showSuccessNotify(response.msg);
                self.getForm(id);
              }
              else {
                API.showErrorNotify(response.msg);
              }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
      }
      else {
        API.showErrorNotify('Error al validar campos');
      }
    },
  },
};
</script>
