<script>
import Box from "src/modules/medico/views/fichas/Box.vue";
import API from "src/core/Api";
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { DatePicker } from 'v-calendar';

export default {
    data() {
        return {
            reconsulta: {
                fechaReservada: '',
                horaInicio: '',
                horaFin: '',
                personalId: '',
                locacionId: '',
            },
            personal: [],
            locaciones: [],
            loadingSubmit: false,
            selectedDate: new Date(),
            dayjs: dayjs,
            // Atributos del calendario para marcar días especiales
            calendarAttributes: [
                {
                    key: 'today',
                    highlight: true,
                    dates: new Date()
                }
            ]
        }
    },
    components: {
        Box,
        DatePicker
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha'
        }),
        reconsultaProgramada() {
            // Verificamos si existe una reconsulta en la nueva estructura del objeto de respuesta
            if (this.GetMedicoFichaActive && 
                this.GetMedicoFichaActive.reconsulta && 
                typeof this.GetMedicoFichaActive.reconsulta === 'object' &&
                !Array.isArray(this.GetMedicoFichaActive.reconsulta) &&
                this.GetMedicoFichaActive.reconsulta !== null) {
                
                // Verificamos que tenga el campo reservas y que contenga al menos una reserva
                if (this.GetMedicoFichaActive.reconsulta.reservas && 
                    Array.isArray(this.GetMedicoFichaActive.reconsulta.reservas) && 
                    this.GetMedicoFichaActive.reconsulta.reservas.length > 0) {
                    
                    // Extraemos los datos relevantes de la primera reserva
                    const reserva = this.GetMedicoFichaActive.reconsulta.reservas[0];
                    return {
                        id: this.GetMedicoFichaActive.reconsulta.id,
                        personalId: reserva.personalId,
                        locacionId: reserva.locacionId,
                        fechaReservada: reserva.fechaReservada,
                        horaInicio: reserva.horaInicio,
                        horaFin: reserva.horaFin,
                        clientName: this.GetMedicoFichaActive.reconsulta.clientName
                    };
                }
            }
            return null;
        },
        personalNombre() {
            if (this.reconsultaProgramada && this.reconsultaProgramada.personalId) {
                const personalEncontrado = this.personal.find(p => p.id === this.reconsultaProgramada.personalId);
                return personalEncontrado ? `${personalEncontrado.nombres} ${personalEncontrado.apellidos}` : 'No especificado';
            }
            return 'No especificado';
        },
        locacionNombre() {
            if (this.reconsultaProgramada && this.reconsultaProgramada.locacionId) {
                const locacionEncontrada = this.locaciones.find(l => l.id === this.reconsultaProgramada.locacionId);
                return locacionEncontrada ? locacionEncontrada.nombre : 'No especificada';
            }
            return 'No especificada';
        },
        isLoading() {
            // La ficha está cargando si GetReloadFicha es true
            return this.GetReloadFicha;
        }
    },
    mounted() {
        this.loadPersonal();
        this.loadLocaciones();
    },
    watch: {
        'reconsulta.horaInicio': function(newVal) {
            if (newVal) {
                // Si la hora de inicio cambia, calculamos la hora de fin 30 minutos después
                try {
                    // Separamos las horas y minutos
                    const [hours, minutes] = newVal.split(':').map(Number);
                    
                    // Calculamos los nuevos minutos y horas
                    let newMinutes = minutes + 30;
                    let newHours = hours;
                    
                    // Si los minutos superan 60, ajustamos las horas
                    if (newMinutes >= 60) {
                        newHours = newHours + 1;
                        newMinutes = newMinutes - 60;
                    }
                    
                    // Si las horas superan 23, ajustamos a 23:59
                    if (newHours > 23) {
                        newHours = 23;
                        newMinutes = 59;
                    }
                    
                    // Formateamos la nueva hora
                    const formattedHours = String(newHours).padStart(2, '0');
                    const formattedMinutes = String(newMinutes).padStart(2, '0');
                    
                    // Asignamos la hora de fin calculada
                    this.reconsulta.horaFin = `${formattedHours}:${formattedMinutes}`;
                } catch (e) {
                    console.error("Error al calcular la hora de fin:", e);
                }
            }
        }
    },
    methods: {
        loadPersonal() {
            const self = this;
            API.send('GET', 'personal/all', {}, 
                function(response) {
                    self.personal = response.data || [];
                },
                function(response) {
                    API.showErrorAlert(response.msg);
                }
            );
        },
        loadLocaciones() {
            const self = this;
            API.send('GET', 'locaciones/all', {}, 
                function(response) {
                    self.locaciones = response.data || [];
                },
                function(response) {
                    API.showErrorAlert(response.msg);
                }
            );
        },
        crearReconsulta() {
            if (this.loadingSubmit) return;
            
            const self = this;
            self.loadingSubmit = true;

            if (!this.validateForm()) {
                self.loadingSubmit = false;
                return;
            }

            API.send('POST', 'orders/reconsulta/crear', {
                ordenId: self.GetMedicoFichaActive.orden.id,
                fechaReservada: dayjs(this.selectedDate).format('YYYY-MM-DD'),
                horaInicio: self.reconsulta.horaInicio,
                horaFin: self.reconsulta.horaFin,
                personalId: self.reconsulta.personalId,
                locacionId: self.reconsulta.locacionId
            }, function(response) {
                API.showSuccessAlert('Reconsulta creada exitosamente');
                self.loadingSubmit = false;
                
                // Recargamos la ficha médica con los datos actualizados
                self.$store.dispatch('FetchFichaData', {
                    reservaId: self.GetMedicoFichaActive.reserva.id,
                    slugs: [],
                    initialData: 1
                });
            }, function(response) {
                API.showErrorAlert(response.msg);
                self.loadingSubmit = false;
            });
        },
        validateForm() {
            if (!this.selectedDate) {
                API.showErrorAlert('Debe seleccionar una fecha para la reconsulta');
                return false;
            }
            if (!this.reconsulta.horaInicio || !this.reconsulta.horaFin) {
                API.showErrorAlert('Debe especificar el horario de la reconsulta');
                return false;
            }
            if (!this.reconsulta.personalId) {
                API.showErrorAlert('Debe seleccionar el personal que atenderá');
                return false;
            }
            return true;
        },
        resetForm() {
            this.reconsulta = {
                fechaReservada: '',
                horaInicio: '',
                horaFin: '',
                personalId: '',
                locacionId: '',
            };
            this.selectedDate = new Date();
        },
        updateSelectedDate(newDate) {
            // Verificar si la fecha seleccionada es anterior a hoy
            const today = dayjs().startOf('day');
            const selectedDate = dayjs(newDate);
            
            if (selectedDate.isBefore(today)) {
                API.showErrorAlert('No puede seleccionar una fecha anterior a hoy');
                this.selectedDate = new Date(); // Reset to today
                return;
            }
            
            this.selectedDate = newDate;
            this.reconsulta.fechaReservada = dayjs(newDate).format('YYYY-MM-DD');
        },
        irAReconsulta() {
            // Verificamos que la reconsulta tenga reservas
            if (this.GetMedicoFichaActive.reconsulta && 
                this.GetMedicoFichaActive.reconsulta.reservas && 
                this.GetMedicoFichaActive.reconsulta.reservas.length > 0) {
                
                // Obtenemos el ID de la reserva
                const reservaId = this.GetMedicoFichaActive.reconsulta.reservas[0].id;
                
                // Usamos window.location.href para recargar completamente la página
                window.location.href = `/medic/ficha/${reservaId}`;
            }
        }
    }
}
</script>

<template>
    <box title="Seguimiento del paciente" icon="fa fa-calendar-check">
        <!-- Spinner mientras se carga la información -->
        <div v-if="isLoading" class="text-center py-5">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Cargando...</span>
            </div>
            <p class="mt-3">Verificando reconsultas programadas...</p>
        </div>

        <!-- Si ya existe una reconsulta programada, mostramos sus detalles -->
        <div v-else-if="reconsultaProgramada" class="reconsulta-programada">
            <div class="alert alert-success border-left-success">
                                <h4 class="alert-heading mb-3">
                                    <i class="fas fa-check-circle mr-2"></i>
                                    Reconsulta Programada
                                </h4>
                                <div class="row mb-2">
                                    <div class="col">
                                        <div class="reconsulta-card">
                                            <div class="reconsulta-details">
                                                <div class="detail-item">
                                                    <i class="far fa-calendar-alt icon-detail"></i>
                                                    <div>
                                                        <h6 class="detail-label">Fecha:</h6>
                                                        <p class="detail-value">{{ dayjs(reconsultaProgramada.fechaReservada).format('DD/MM/YYYY') }}</p>
                                                    </div>
                                                </div>
                                                <div class="detail-item">
                                                    <i class="far fa-clock icon-detail"></i>
                                                    <div>
                                                        <h6 class="detail-label">Horario:</h6>
                                                        <p class="detail-value">{{ reconsultaProgramada.horaInicio }} - {{ reconsultaProgramada.horaFin }}</p>
                                                    </div>
                                                </div>
                                                <div class="detail-item">
                                                    <i class="far fa-user icon-detail"></i>
                                                    <div>
                                                        <h6 class="detail-label">Personal:</h6>
                                                        <p class="detail-value">{{ personalNombre }}</p>
                                                    </div>
                                                </div>
                                                <div class="detail-item">
                                                    <i class="far fa-building icon-detail"></i>
                                                    <div>
                                                        <h6 class="detail-label">Locación:</h6>
                                                        <p class="detail-value">{{ locacionNombre }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <!-- Botón para ir a la ficha de la reconsulta -->
                                <div class="text-center mt-3" v-if="GetMedicoFichaActive.reconsulta && GetMedicoFichaActive.reconsulta.reservas && GetMedicoFichaActive.reconsulta.reservas.length > 0">
                                    <button 
                                        class="btn btn-primary" 
                                        @click="irAReconsulta">
                                        <i class="fas fa-file-medical mr-2"></i>
                                        Ver ficha de reconsulta
                                    </button>
                                </div>
                            </div>
        </div>

        <!-- Si no hay reconsulta programada, mostramos el formulario para crearla -->
        <section v-else class="row">
            <!-- Columna izquierda para los formularios -->
            <div class="col-12 col-md-6 form-column">
                <div class="selected-date mb-3">
                    <label>Fecha seleccionada:</label>
                    <h5 class="m-0 font-weight-bold">{{ dayjs(selectedDate).format('DD/MM/YYYY') }}</h5>
                </div>

                <div class="form-group">
                    <label>Hora inicio</label>
                    <input 
                        type="time" 
                        class="form-control" 
                        v-model="reconsulta.horaInicio" />
                </div>

                <div class="form-group">
                    <label>Hora fin</label>
                    <input 
                        type="time" 
                        class="form-control" 
                        v-model="reconsulta.horaFin" />
                </div>

                <div class="form-group">
                    <label>Personal que atenderá</label>
                    <select class="form-control" v-model="reconsulta.personalId">
                        <option value="">Seleccione personal</option>
                        <option 
                            v-for="persona in personal" 
                            :key="persona.id" 
                            :value="persona.id">
                            {{ persona.nombres }} {{ persona.apellidos }}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Locación (Opcional)</label>
                    <select class="form-control" v-model="reconsulta.locacionId">
                        <option value="">Seleccione locación</option>
                        <option 
                            v-for="locacion in locaciones" 
                            :key="locacion.id" 
                            :value="locacion.id">
                            {{ locacion.nombre }}
                        </option>
                    </select>
                </div>

                <div class="text-right mt-4">
                    <button 
                        class="btn btn-primary" 
                        @click="crearReconsulta"
                        :disabled="loadingSubmit">
                        <i class="fas fa-spinner fa-spin mr-2" v-if="loadingSubmit"></i>
                        Crear reconsulta
                    </button>
                </div>
            </div>

            <!-- Columna derecha para el calendario -->
            <div class="col-12 col-md-6 calendar-column">
                <div class="form-group">
                    <label>Seleccione fecha de reconsulta</label>
                    <div class="calendar-container">
                        <date-picker 
                            style="background-color: transparent" 
                            v-model="selectedDate"
                            @input="updateSelectedDate"
                            :min-date="new Date()"
                            :attributes="calendarAttributes"
                            mode="date"
                            is-expanded
                            trim-weeks>
                        </date-picker>
                    </div>
                </div>
            </div>
        </section>
    </box>
</template>

<style scoped>
.form-group {
    margin-bottom: 1.5rem;
}

.form-column {
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
}

.calendar-column {
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e0e0e0;
    padding-left: 1.5rem;
}

.calendar-container {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #ddd;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.selected-date {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 4px;
    border-left: 4px solid #3b82f6;
}

/* Estilos para la reconsulta programada */
.reconsulta-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #e5e7eb;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
}

.reconsulta-details {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}

.detail-item {
    display: flex;
    align-items: flex-start;
}

.icon-detail {
    font-size: 1.5rem;
    color: #3b82f6;
    margin-right: 1rem;
    width: 24px;
}

.detail-label {
    font-weight: 600;
    margin-bottom: 0.25rem;
    color: #4b5563;
}

.detail-value {
    font-size: 1.1rem;
    color: #1f2937;
    margin-bottom: 0;
}

.border-left-success {
    border-left: 4px solid #10b981;
}

/* Estilos del DatePicker similar al Dashboard */
:deep(.vc-container) {
    border: none;
    width: 100% !important;
}

:deep(.vc-header) {
    padding: 10px 0;
    background-color: transparent;
}

:deep(.vc-title) {
    font-weight: 600;
    color: #1e293b;
    font-size: 16px;
}

:deep(.vc-weeks) {
    padding: 5px;
    margin-top: 10px; /* Agregando margen superior a la sección de semanas */
}

:deep(.vc-weekdays) {
    margin-top: 8px; /* Agregando margen también a los días de la semana */
}

:deep(.vc-arrow) {
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1e293b;
    background-color: #f1f5f9;
    z-index: 10;
    width: 32px;
    height: 32px;
    position: relative;
}

:deep(.vc-arrow:hover) {
    background-color: #e2e8f0;
    color: #3b82f6;
}

:deep(.vc-weekday) {
    color: #64748b;
    font-weight: 600;
}

:deep(.vc-day) {
    height: 40px;
    padding: 3px 0;
}

:deep(.vc-day-content) {
    height: 34px;
    width: 34px;
    font-weight: 600;
}

:deep(.vc-day-content:hover) {
    background-color: #e2e8f0;
}

/* Estilo para la fecha de hoy y seleccionada */
:deep(.vc-highlight) {
    background-color: #3b82f6;
    color: white;
}

:deep(.vc-day.is-today .vc-day-content) {
    border: 1px solid #3b82f6;
    color: #3b82f6;
    font-weight: 700;
}

/* Estilos para días deshabilitados */
:deep(.vc-day.is-disabled) {
    opacity: 0.5;
}

:deep(.vc-day.is-disabled .vc-day-content) {
    color: #cbd5e1;
    cursor: not-allowed;
}

/* Responsive fixes */
@media (max-width: 767px) {
    .calendar-column {
        border-left: none;
        border-top: 1px solid #e0e0e0;
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        padding-left: 0;
    }
    
    .form-column {
        padding-right: 0;
    }
    
    .reconsulta-details {
        grid-template-columns: 1fr;
    }
}
</style>