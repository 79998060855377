<template>
    <SettingLayout :active="20">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Administración de Catálogos</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="formSubmit" method="post">
                            <div class="row mb-3">
                                <div class="col-md-4 mb-3">
                                    <label class="form-label">Nombre del Catálogo</label>
                                    <input type="text" class="form-control" v-model="nombreCatalogo" required/>
                                    <div v-if="errors.nombre" class="text-danger small">{{ errors.nombre }}</div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label class="form-label">Slug</label>
                                    <input type="text" class="form-control" v-model="slug" required/>
                                    <div v-if="errors.slug" class="text-danger small">{{ errors.slug }}</div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label class="form-check form-switch mt-4">
                                        <input class="form-check-input" type="checkbox" v-model="isGlobal">
                                        <span class="form-check-label">Catálogo Global</span>
                                    </label>
                                </div>
                                <div class="col-12">
                                    <div class="d-flex">
                                        <button class="btn btn-icon btn-primary" title="{{ id ? 'Actualizar' : 'Guardar' }} Catálogo">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
                                        </button>
                                        <button v-if="id" type="button" @click="limpiarFormulario" class="btn btn-icon btn-secondary ms-2" title="Nuevo Catálogo">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                               <path d="M12 5l0 14"></path>
                                               <path d="M5 12l14 0"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <!-- Detalles del Catálogo - Vista simplificada, el manejo se hace en el modal -->
                        <div v-if="id" class="card mt-4">
                            <div class="card-status-top bg-primary"></div>
                            <div class="card-header">
                                <h5 class="card-title">Detalles del Catálogo</h5>
                                <div class="card-actions">
                                    <button class="btn btn-icon btn-primary" @click="abrirModalEditarCatalogo" title="Editar Detalles">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                           <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                           <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                           <path d="M16 5l3 3"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="detalles.length === 0" class="text-center text-muted my-4">
                                    No hay detalles para este catálogo. Haga clic en el botón de edición para añadir uno.
                                </div>
                                <div v-else class="text-center">
                                    <p class="mb-2">Este catálogo tiene {{ detalles.length }} detalles.</p>
                                    <button class="btn btn-icon btn-primary" @click="abrirModalEditarCatalogo" title="Ver y Gestionar Detalles">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                           <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                           <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                           <path d="M16 5l3 3"></path>
                                        </svg>
                                                        </button>
                                                    </div>
                            </div>
                        </div>

                        <!-- Lista de Catálogos -->
                        <div class="card mt-4">
                            <div class="card-status-top bg-success"></div>
                            <div class="card-header">
                                <h5 class="card-title">Lista de Catálogos</h5>
                                <div class="card-actions">
                                    <div class="input-icon">
                                        <input type="text" class="form-control" placeholder="Buscar..." v-model="busqueda">
                                        <span class="input-icon-addon">
                                            <i class="fa fa-search"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="catalogos.length === 0" class="text-center text-muted my-4">
                                    No hay catálogos disponibles.
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-vcenter card-table">
                                        <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Slug</th>
                                                <th>Global</th>
                                                <th>Detalles</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="catalogo in catalogosFiltrados" :key="catalogo.id">
                                                <td>{{ catalogo.nombre }}</td>
                                                <td>
                                                    <span class="badge bg-blue-lt">{{ catalogo.slug }}</span>
                                                </td>
                                                <td>
                                                    <span v-if="catalogo.isGlobal" class="badge bg-green">Sí</span>
                                                    <span v-else class="badge bg-gray">No</span>
                                                </td>
                                                <td>{{ catalogo.detalles ? catalogo.detalles.length : 0 }} detalles</td>
                                                <td>
                                                    <div class="btn-list">
                                                        <button class="btn btn-outline-primary btn-icon" @click="editarCatalogo(catalogo)" title="Editar">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                        <button class="btn btn-outline-danger btn-icon" @click="eliminarCatalogo(catalogo.id)" title="Eliminar">
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal de Confirmación para Eliminar Catálogo -->
        <div v-if="mostrarModal" class="modal modal-blur fade show" style="display: block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancelarEliminacion"></button>
                    <div class="modal-status bg-danger"></div>
                    <div class="modal-body text-center py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
                        <h3>¿Estás seguro?</h3>
                        <div class="text-muted">Si continúas eliminarás este catálogo permanentemente.</div>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100">
                            <div class="row">
                                <div class="col">
                                    <button type="button" class="btn btn-outline-secondary w-100" @click="cancelarEliminacion">
                            Cancelar
                        </button>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn btn-danger w-100" @click="confirmarEliminacion">
                                        Eliminar
                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para Editar Catálogo -->
        <div v-if="modalEditarCatalogo.mostrar" class="modal modal-blur fade show" style="display: block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Editar Catálogo: {{ nombreCatalogo }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModalEditarCatalogo"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Nombre del Catálogo</label>
                                <input type="text" class="form-control" v-model="nombreCatalogo" required/>
                                <div v-if="errors.nombre" class="text-danger small">{{ errors.nombre }}</div>
                        </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-label">Slug</label>
                                <input type="text" class="form-control" v-model="slug" required/>
                                <div v-if="errors.slug" class="text-danger small">{{ errors.slug }}</div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label class="form-check form-switch mt-4">
                                    <input class="form-check-input" type="checkbox" v-model="isGlobal">
                                    <span class="form-check-label">Catálogo Global</span>
                                </label>
                            </div>
                        </div>

                        <!-- Detalles dentro del modal -->
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Detalles del Catálogo</div>
                            </div>
                            <div class="card-body">
                        <div class="mb-3">
                                    <div class="input-icon w-100">
                                        <input type="text" class="form-control" placeholder="Buscar detalles por descripción o código..." v-model="busquedaDetalles">
                                        <span class="input-icon-addon">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                               <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                               <path d="M21 21l-6 -6"></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                
                                <div class="d-flex justify-content-end mb-3">
                                    <button class="btn btn-icon btn-primary" @click="abrirModalDetalle()" title="Agregar Detalle">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                           <path d="M12 5l0 14"></path>
                                           <path d="M5 12l14 0"></path>
                                        </svg>
                                    </button>
                                </div>

                                <div v-if="detalles.length === 0" class="empty">
                                    <div class="empty-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                           <path d="M9 6l11 0"></path>
                                           <path d="M9 12l11 0"></path>
                                           <path d="M9 18l11 0"></path>
                                           <path d="M5 6l0 .01"></path>
                                           <path d="M5 12l0 .01"></path>
                                           <path d="M5 18l0 .01"></path>
                                        </svg>
                                    </div>
                                    <p class="empty-title">No hay detalles</p>
                                    <p class="empty-subtitle text-muted">
                                        No hay detalles para este catálogo. Haga clic en "Agregar Detalle" para añadir uno.
                                    </p>
                                </div>
                                <div v-else>
                                    <!-- Contenedor de tarjetas de detalles -->
                                    <div class="detail-cards">
                                        <div v-for="(detalle, index) in detallesPaginadosFiltrados" :key="index" class="detail-card-wrapper">
                                            <div class="card detail-card">
                                                <div class="card-status-top" :class="detalle.atributos && detalle.atributos.length > 0 ? 'bg-azure' : 'bg-gray'"></div>
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between mb-2">
                                                        <h3 class="card-title text-truncate" :title="detalle.descripcion">{{ detalle.descripcion }}</h3>
                                                        <span class="badge bg-azure">{{ detalle.atributos ? detalle.atributos.length : 0 }} atributos</span>
                                                    </div>
                                                    <div v-if="detalle.codigoExterno" class="text-muted mb-2">Código: {{ detalle.codigoExterno }}</div>
                                                    <div v-if="detalle.atributos && detalle.atributos.length > 0" class="mt-3">
                                                        <div class="text-muted mb-2">Atributos:</div>
                                                        <div class="attr-list">
                                                            <div v-for="(atributo, attrIndex) in detalle.atributos.slice(0, 3)" :key="attrIndex" class="attr-item">
                                                                <span class="attr-name">{{ atributo.nombre }}:</span>
                                                                <span class="attr-value">{{ atributo.valor || '(sin valor)' }}</span>
                                                            </div>
                                                            <div v-if="detalle.atributos.length > 3" class="attr-more">
                                                                <span class="badge bg-blue-lt me-1 mb-1">
                                                                    +{{ detalle.atributos.length - 3 }} más...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer d-flex justify-content-end">
                                                    <button class="btn btn-icon btn-ghost-primary me-2" @click="abrirModalDetalle(calcularIndiceReal(index))" title="Editar">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                           <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                                           <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                                           <path d="M16 5l3 3"></path>
                                                        </svg>
                                                    </button>
                                                    <button class="btn btn-icon btn-ghost-success me-2" @click="abrirModalAtributos(calcularIndiceReal(index))" title="Gestionar Atributos">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-list" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                           <path d="M9 6l11 0"></path>
                                                           <path d="M9 12l11 0"></path>
                                                           <path d="M9 18l11 0"></path>
                                                           <path d="M5 6l0 .01"></path>
                                                           <path d="M5 12l0 .01"></path>
                                                           <path d="M5 18l0 .01"></path>
                                                        </svg>
                                                    </button>
                                                    <button class="btn btn-icon btn-ghost-danger" @click="eliminarDetalle(calcularIndiceReal(index))" title="Eliminar">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                           <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                           <path d="M4 7l16 0"></path>
                                                           <path d="M10 11l0 6"></path>
                                                           <path d="M14 11l0 6"></path>
                                                           <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                           <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Paginador al estilo Tabler.io -->
                                    <div class="d-flex mt-4">
                                        <div class="ms-auto">
                                            <nav aria-label="Navegación de páginas">
                                                <ul class="pagination">
                                                    <li class="page-item" :class="{ disabled: paginacionDetalles.paginaActual === 1 }">
                                                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click.prevent="cambiarPaginaDetalles(1)" title="Primera página">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M11 7l-5 5l5 5" /><path d="M17 7l-5 5l5 5" /></svg>
                                                        </a>
                                                    </li>
                                                    <li class="page-item" :class="{ disabled: paginacionDetalles.paginaActual === 1 }">
                                                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true" @click.prevent="cambiarPaginaDetalles(paginacionDetalles.paginaActual - 1)" title="Anterior">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>
                                                        </a>
                                                    </li>
                                                    
                                                    <!-- Páginas numeradas -->
                                                    <template v-for="pagina in paginasVisibles" :key="pagina">
                                                        <li v-if="pagina === '...'" class="page-item disabled">
                                                            <span class="page-link">...</span>
                                                        </li>
                                                        <li v-else class="page-item" :class="{ active: pagina === paginacionDetalles.paginaActual }">
                                                            <a class="page-link" href="#" @click.prevent="cambiarPaginaDetalles(pagina)">{{ pagina }}</a>
                                                        </li>
                                                    </template>
                                                    
                                                    <li class="page-item" :class="{ disabled: paginacionDetalles.paginaActual >= totalPaginasDetalles }">
                                                        <a class="page-link" href="#" @click.prevent="cambiarPaginaDetalles(paginacionDetalles.paginaActual + 1)" title="Siguiente">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                                                        </a>
                                                    </li>
                                                    <li class="page-item" :class="{ disabled: paginacionDetalles.paginaActual >= totalPaginasDetalles }">
                                                        <a class="page-link" href="#" @click.prevent="cambiarPaginaDetalles(totalPaginasDetalles)" title="Última página">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7l5 5l-5 5" /><path d="M13 7l5 5l-5 5" /></svg>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-icon btn-link link-secondary" @click="cerrarModalEditarCatalogo" title="Cancelar">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                               <path d="M18 6l-12 12"></path>
                               <path d="M6 6l12 12"></path>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-icon btn-primary ms-auto" @click="guardarCambiosCatalogo" title="Guardar Cambios">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para Eliminar Detalle -->
        <div v-if="modalEliminarDetalle.mostrar" class="modal modal-blur fade show" style="display: block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancelarEliminacionDetalle"></button>
                    <div class="modal-status bg-danger"></div>
                    <div class="modal-body text-center py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon mb-2 text-danger icon-lg" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
                        <h3>¿Estás seguro?</h3>
                        <div class="text-muted">Si continúas eliminarás este detalle y todos sus atributos permanentemente.</div>
                    </div>
                    <div class="modal-footer">
                        <div class="w-100">
                            <div class="row">
                                <div class="col">
                                    <button type="button" class="btn btn-outline-secondary w-100" @click="cancelarEliminacionDetalle">
                            Cancelar
                        </button>
                                </div>
                                <div class="col">
                                    <button type="button" class="btn btn-danger w-100" @click="confirmarEliminacionDetalle">
                                        Eliminar
                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para Gestionar Atributos -->
        <div v-if="modalAtributos.mostrar" class="modal modal-blur fade show" style="display: block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Gestionar Atributos: {{ modalAtributos.detalle.descripcion }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModalAtributos"></button>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <h3 class="subheader">Atributos del Detalle</h3>
                            <button class="btn btn-icon btn-primary" @click="agregarAtributo(modalAtributos.detalle)" title="Agregar Atributo">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 5l0 14" /><path d="M5 12l14 0" /></svg>
                            </button>
                        </div>
                        <div v-if="!modalAtributos.detalle.atributos || modalAtributos.detalle.atributos.length === 0" class="empty">
                            <div class="empty-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-square-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                   <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z"></path>
                                   <path d="M9 9l6 6m0 -6l-6 6"></path>
                                </svg>
                            </div>
                            <p class="empty-title">No hay atributos</p>
                            <p class="empty-subtitle text-muted">
                                Haga clic en "Agregar Atributo" para añadir uno.
                            </p>
                        </div>
                        <div class="table-responsive" v-else>
                            <table class="table table-vcenter table-bordered card-table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Valor</th>
                                        <th>Tipo</th>
                                        <th class="w-1">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(atributo, index) in modalAtributos.detalle.atributos" :key="index">
                                        <td>
                                            <input type="text" class="form-control" v-model="atributo.nombre" placeholder="Nombre">
                                        </td>
                                        <td>
                                            <input type="text" class="form-control" v-model="atributo.valor" placeholder="Valor">
                                        </td>
                                        <td>
                                            <select class="form-select" v-model="atributo.tipo">
                                                <option value="text">Texto</option>
                                                <option value="number">Número</option>
                                                <option value="date">Fecha</option>
                                                <option value="boolean">Booleano</option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-icon btn-ghost-danger" @click="eliminarAtributo(modalAtributos.detalle, index)" title="Eliminar">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                   <path d="M4 7l16 0"></path>
                                                   <path d="M10 11l0 6"></path>
                                                   <path d="M14 11l0 6"></path>
                                                   <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                   <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-icon btn-outline-secondary" @click="cerrarModalAtributos" title="Cancelar">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                               <path d="M18 6l-12 12"></path>
                               <path d="M6 6l12 12"></path>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-icon btn-primary ms-auto" @click="guardarAtributos" title="Guardar Cambios">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para Agregar/Editar Detalle -->
        <div v-if="modalDetalle.mostrar" class="modal modal-blur fade show" style="display: block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ modalDetalle.titulo }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cerrarModalDetalle"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">Código Externo</label>
                            <input type="text" class="form-control" v-model="modalDetalle.detalle.codigoExterno" placeholder="Ingrese un código externo">
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Descripción</label>
                            <input type="text" class="form-control" v-model="modalDetalle.detalle.descripcion" placeholder="Ingrese una descripción">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-icon btn-outline-secondary" @click="cerrarModalDetalle" title="Cancelar">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                               <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                               <path d="M18 6l-12 12"></path>
                               <path d="M6 6l12 12"></path>
                            </svg>
                        </button>
                        <button type="button" class="btn btn-icon btn-primary ms-auto" @click="guardarDetalle" title="Guardar">
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M14 4l0 4l-6 0l0 -4" /></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="mostrarModal || modalDetalle.mostrar || modalAtributos.mostrar || modalEliminarDetalle.mostrar" class="modal-backdrop fade show"></div>
    </SettingLayout>
</template>

<script>
import SettingLayout from "../components/settings/settingLayout";
import API from "src/core/Api";

export default {
    components: {
        SettingLayout,
    },
    data() {
        return {
            id: null,
            nombreCatalogo: "",
            slug: "",
            isGlobal: false,
            detalles: [],
            catalogos: [],
            errors: {},
            busqueda: "",
            busquedaDetalles: "",
            mostrarModal: false,
            catalogoIdAEliminar: null,
            loading: false,
            paginacionDetalles: {
                paginaActual: 1,
                itemsPorPagina: 20,
                totalPaginas: 1
            },
            modalDetalle: {
                mostrar: false,
                titulo: "Agregar Detalle",
                index: null,
                detalle: {
                    codigoExterno: "",
                    descripcion: "",
                    atributos: []
                }
            },
            modalAtributos: {
                mostrar: false,
                index: null,
                detalle: {
                    codigoExterno: "",
                    descripcion: "",
                    atributos: []
                }
            },
            modalEliminarDetalle: {
                mostrar: false,
                index: null
            },
            modalEditarCatalogo: {
                mostrar: false
            }
        };
    },
    computed: {
        catalogosFiltrados() {
            if (!this.busqueda) return this.catalogos;
            const busquedaLower = this.busqueda.toLowerCase();
            return this.catalogos.filter(catalogo => 
                catalogo.nombre.toLowerCase().includes(busquedaLower) || 
                catalogo.slug.toLowerCase().includes(busquedaLower)
            );
        },
        
        detallesFiltrados() {
            if (!this.busquedaDetalles) return this.detalles;
            const busquedaLower = this.busquedaDetalles.toLowerCase();
            return this.detalles.filter(detalle => 
                detalle.descripcion.toLowerCase().includes(busquedaLower) || 
                (detalle.codigoExterno && detalle.codigoExterno.toLowerCase().includes(busquedaLower))
            );
        },
        
        detallesPaginados() {
            const inicio = (this.paginacionDetalles.paginaActual - 1) * this.paginacionDetalles.itemsPorPagina;
            const fin = inicio + this.paginacionDetalles.itemsPorPagina;
            return this.detalles.slice(inicio, fin);
        },
        
        detallesPaginadosFiltrados() {
            const inicio = (this.paginacionDetalles.paginaActual - 1) * this.paginacionDetalles.itemsPorPagina;
            const fin = inicio + this.paginacionDetalles.itemsPorPagina;
            return this.detallesFiltrados.slice(inicio, fin);
        },
        
        totalPaginasDetalles() {
            return Math.ceil(this.detallesFiltrados.length / this.paginacionDetalles.itemsPorPagina);
        },
        
        paginasVisibles() {
            const totalPaginas = this.totalPaginasDetalles;
            const paginaActual = this.paginacionDetalles.paginaActual;
            
            // Si hay 7 páginas o menos, mostrar todas
            if (totalPaginas <= 7) {
                return Array.from({ length: totalPaginas }, (_, i) => i + 1);
            }
            
            // Si la página actual está entre las primeras 3
            if (paginaActual <= 3) {
                return [1, 2, 3, 4, '...', totalPaginas];
            }
            
            // Si la página actual está entre las últimas 3
            if (paginaActual >= totalPaginas - 2) {
                return [1, '...', totalPaginas - 3, totalPaginas - 2, totalPaginas - 1, totalPaginas];
            }
            
            // Si la página actual está en el medio
            return [1, '...', paginaActual - 1, paginaActual, paginaActual + 1, '...', totalPaginas];
        }
    },
    mounted() {
        this.cargarCatalogos();
    },
    watch: {
        nombreCatalogo(newVal) {
            // Si no hay ID (nuevo catálogo) o el slug está vacío, generarlo automáticamente
            if (!this.id || !this.slug) {
                this.slug = this.generarSlug(newVal);
            }
        },
        
        busquedaDetalles() {
            // Resetear la paginación cuando cambia la búsqueda
            this.paginacionDetalles.paginaActual = 1;
        }
    },
    methods: {
        generarSlug(texto) {
            if (!texto) return '';
            
            // Convertir a minúsculas y reemplazar caracteres especiales y espacios
            return texto.toLowerCase()
                .trim()
                .replace(/[^\w\s-]/g, '')  // Eliminar caracteres especiales excepto espacios y guiones
                .replace(/\s+/g, '-')      // Reemplazar espacios con guiones
                .replace(/-+/g, '-');      // Evitar guiones duplicados
        },
        validarFormulario() {
            this.errors = {};
            let isValid = true;
            
            if (!this.nombreCatalogo.trim()) {
                this.errors.nombre = "El nombre del catálogo es obligatorio";
                isValid = false;
            }
            
            if (!this.slug.trim()) {
                this.errors.slug = "El slug es obligatorio";
                isValid = false;
            } else if (!/^[a-z0-9-]+$/.test(this.slug)) {
                this.errors.slug = "El slug solo puede contener letras minúsculas, números y guiones";
                isValid = false;
            }
            
            return isValid;
        },
        formSubmit() {
            if (!this.validarFormulario()) return;
            
            this.loading = true;
            
            const payload = {
                nombre: this.nombreCatalogo,
                slug: this.slug,
                isGlobal: this.isGlobal,
                detalles: this.prepararDetallesParaEnvio(this.detalles),
            };

            // Si estamos actualizando, asegurarnos de que el ID esté correctamente en el payload
            if (this.id) {
                console.log(`Actualizando catálogo con ID: ${this.id}`);
                payload.id = parseInt(this.id); // Asegurar que es un número
            } else {
                console.log('Creando nuevo catálogo');
            }
            
            // Log detallado para depuración
            this.logRespuesta("Enviando payload", {
                endpoint: "medico/catalogos",
                method: "POST",
                id: payload.id || "ninguno (creación)",
                nombre: payload.nombre,
                slug: payload.slug,
                detalles: `${payload.detalles.length} detalles`
            });
            
            // Usar siempre el mismo endpoint para creación y actualización
            API.send("POST", "medico/catalogos", payload, 
                response => {
                    this.logRespuesta("Respuesta del servidor", response);
                    this.onSuccess(response);
                    this.loading = false;
                }, 
                error => {
                    this.logRespuesta("Error del servidor", error);
                    this.onError(error);
                    this.loading = false;
                }
            );
        },
        prepararDetallesParaEnvio(detalles) {
            // Hacemos una copia profunda para no modificar los originales
            const detallesProcesados = JSON.parse(JSON.stringify(detalles));
            
            return detallesProcesados.map(detalle => {
                // Aseguramos que los detalles tienen los campos requeridos
                const detalleFormateado = {
                    id: detalle.id || null,  // Preservar ID si existe
                    codigoExterno: detalle.codigoExterno || null,
                    descripcion: detalle.descripcion || '',
                    catalogoId: this.id,  // Incluir el ID del catálogo
                };
                
                // Procesamos los atributos si existen
                if (detalle.atributos && Array.isArray(detalle.atributos)) {
                    detalleFormateado.atributos = detalle.atributos.map(atributo => {
                        // Asegurar que preservamos el ID del atributo si existe
                        return {
                            id: atributo.id || null,
                            nombre: atributo.nombre || '',
                            valor: atributo.valor !== undefined ? atributo.valor : null,
                            tipo: atributo.tipo || 'text',
                            ordenCatalogoDetailId: detalle.id || null, // Relación con el detalle padre
                        };
                    });
                } else {
                    detalleFormateado.atributos = [];
                }
                
                return detalleFormateado;
            });
        },
        actualizarCatalogo() {
            if (!this.id) return; // Solo actualizar si hay un ID
            
            this.formSubmit(); // Reutilizar el método formSubmit para actualizar
        },
        cargarCatalogos() {
            this.loading = true;
            API.send("GET", "medico/catalogos", {}, 
                response => {
                    this.catalogos = response.data;
                    this.loading = false;
                }, 
                error => {
                    this.onError(error);
                    this.loading = false;
                }
            );
        },
        editarCatalogo(catalogo) {
            this.id = catalogo.id;
            this.nombreCatalogo = catalogo.nombre;
            this.slug = catalogo.slug;
            this.isGlobal = catalogo.isGlobal || false;
            
            // Hacer una copia profunda de los detalles para preservar los IDs
            this.detalles = JSON.parse(JSON.stringify(catalogo.detalles || []));
            
            // Asegurarse de que cada detalle tenga un array de atributos
            this.detalles.forEach(detalle => {
                if (!detalle.atributos) {
                    detalle.atributos = [];
                }
            });
            
            // Suavizar el desplazamiento o usar modal en lugar de scroll
            this.abrirModalEditarCatalogo();
        },
        eliminarCatalogo(id) {
            // Asegurar que el ID es un número entero
            this.catalogoIdAEliminar = parseInt(id);
            this.mostrarModal = true;
        },
        confirmarEliminacion() {
            if (this.catalogoIdAEliminar) {
                this.loading = true;
                // Convertir explícitamente a número y verificar que sea válido
                const catalogoId = parseInt(this.catalogoIdAEliminar);
                
                if (isNaN(catalogoId)) {
                    API.showErrorAlert("ID de catálogo inválido");
                    this.mostrarModal = false;
                    this.loading = false;
                    return;
                }
                
                console.log(`Eliminando catálogo con ID: ${catalogoId}`);
                
                // Usando directamente DELETE /medico/catalogos/{id}
                API.send("DELETE", `medico/catalogos/${catalogoId}`, {}, 
                    () => {
                        API.showSuccessAlert("Catálogo eliminado correctamente");
                        this.cargarCatalogos();
                        this.mostrarModal = false;
                        this.loading = false;
                        if (this.id === this.catalogoIdAEliminar) {
                            this.limpiarFormulario();
                        }
                    }, 
                    error => {
                        this.onError(error);
                        this.mostrarModal = false;
                        this.loading = false;
                    }
                );
            }
        },
        cancelarEliminacion() {
            this.mostrarModal = false;
            this.catalogoIdAEliminar = null;
        },
        abrirModalDetalle(index = null) {
            if (index !== null) {
                // Modo edición: copiar el detalle existente
                this.modalDetalle.titulo = "Editar Detalle";
                this.modalDetalle.index = index;
                this.modalDetalle.detalle = JSON.parse(JSON.stringify(this.detalles[index]));
                if (!this.modalDetalle.detalle.atributos) {
                    this.modalDetalle.detalle.atributos = [];
                }
            } else {
                // Modo creación: inicializar un detalle nuevo
                this.modalDetalle.titulo = "Agregar Detalle";
                this.modalDetalle.index = null;
                this.modalDetalle.detalle = {
                    id: null,
                    codigoExterno: "",
                    descripcion: "",
                    atributos: []
                };
            }
            this.modalDetalle.mostrar = true;
        },
        cerrarModalDetalle() {
            this.modalDetalle.mostrar = false;
        },
        guardarDetalle() {
            // Validar que al menos haya una descripción
            if (!this.modalDetalle.detalle.descripcion) {
                API.showErrorAlert("La descripción del detalle es obligatoria");
                return;
            }
            
            // Validar que no exista otro detalle con la misma descripción
            // (exceptuando el detalle que estamos editando, si es el caso)
            const descripcionNormalizada = this.modalDetalle.detalle.descripcion.trim().toLowerCase();
            const codigoExterno = this.modalDetalle.detalle.codigoExterno || "";
            
            // Buscar si ya existe un detalle con la misma descripción
            const detalleExistente = this.detalles.find((d, index) => {
                // Si estamos editando, excluir el detalle actual de la comparación
                if (this.modalDetalle.index !== null && index === this.modalDetalle.index) {
                    return false;
                }
                
                // Normalizar la descripción para la comparación (ignorando mayúsculas/minúsculas y espacios)
                const descExistente = d.descripcion.trim().toLowerCase();
                
                // Si las descripciones son iguales, permitir solo si tienen códigos diferentes
                return descExistente === descripcionNormalizada && 
                       (codigoExterno === (d.codigoExterno || ""));
            });
            
            if (detalleExistente) {
                API.showErrorAlert("Ya existe un detalle con la misma descripción y código. Por favor, utilice un código diferente para distinguirlos.");
                return;
            }
            
            let detalleActualizado;
            
            if (this.modalDetalle.index !== null) {
                // Actualizar detalle existente - preservar ID original
                const detalleOriginalId = this.detalles[this.modalDetalle.index].id;
                detalleActualizado = { 
                    ...this.modalDetalle.detalle,
                    id: detalleOriginalId // Asegurar que usamos el ID original
                };
                
                // Si el detalle tiene atributos, asegurarnos de preservar sus IDs
                if (detalleActualizado.atributos && Array.isArray(detalleActualizado.atributos)) {
                    detalleActualizado.atributos = detalleActualizado.atributos.map(attr => ({
                        ...attr,
                        id: attr.id || null,
                        ordenCatalogoDetailId: detalleOriginalId
                    }));
                }
                
                this.detalles[this.modalDetalle.index] = detalleActualizado;
            } else {
                // Agregar nuevo detalle sin ID (el backend lo asignará)
                detalleActualizado = {
                    ...this.modalDetalle.detalle,
                    id: null // Aseguramos que el nuevo detalle no tenga ID
                };
                
                // Para nuevos detalles, los atributos tampoco deben tener ID
                if (detalleActualizado.atributos && Array.isArray(detalleActualizado.atributos)) {
                    detalleActualizado.atributos = detalleActualizado.atributos.map(attr => ({
                        ...attr,
                        id: null,
                        ordenCatalogoDetailId: null
                    }));
                }
                
                this.detalles.push(detalleActualizado);
            }
            
            // Actualizar el catálogo
            this.actualizarCatalogo();
            
            this.cerrarModalDetalle();
            API.showSuccessAlert("Detalle guardado correctamente");
        },
        eliminarDetalle(index) {
            this.modalEliminarDetalle.index = index;
            this.modalEliminarDetalle.mostrar = true;
        },
        confirmarEliminacionDetalle() {
            if (this.modalEliminarDetalle.index !== null) {
                const detalle = this.detalles[this.modalEliminarDetalle.index];
                
                // Si el detalle tiene ID, eliminar en el backend
                if (detalle.id) {
                    this.loading = true;
                    API.send("POST", "medico/catalogos/detalle/eliminar", { id: detalle.id }, 
                        response => {
                            this.detalles.splice(this.modalEliminarDetalle.index, 1);
                            API.showSuccessAlert(response.msg || "Detalle eliminado correctamente");
                            this.modalEliminarDetalle.mostrar = false;
                            this.loading = false;
                        }, 
                        error => {
                            this.onError(error);
                            this.modalEliminarDetalle.mostrar = false;
                            this.loading = false;
                        }
                    );
                } else {
                    // Si no tiene ID, solo eliminarlo del array local
                this.detalles.splice(this.modalEliminarDetalle.index, 1);
                API.showSuccessAlert("Detalle eliminado correctamente");
                this.modalEliminarDetalle.mostrar = false;
                }
            }
        },
        cancelarEliminacionDetalle() {
            this.modalEliminarDetalle.mostrar = false;
        },
        abrirModalAtributos(index) {
            if (!this.detalles[index].atributos) {
                this.detalles[index].atributos = [];
            }
            
            this.modalAtributos.index = index;
            // Hacer una copia profunda para no modificar directamente
            this.modalAtributos.detalle = JSON.parse(JSON.stringify(this.detalles[index]));
            
            // Precargar los nombres de atributos del detalle que más atributos tiene
            const plantillaAtributos = this.obtenerPlantillaAtributos();
            
            // Si hay una plantilla y el detalle actual tiene menos atributos que la plantilla
            if (plantillaAtributos.length > 0 && this.modalAtributos.detalle.atributos.length < plantillaAtributos.length) {
                const atributosActuales = this.modalAtributos.detalle.atributos.map(a => a.nombre.toLowerCase());
                
                // Añadir los atributos de la plantilla que no existen en el detalle actual
                plantillaAtributos.forEach(nombreAtributo => {
                    if (!atributosActuales.includes(nombreAtributo.toLowerCase())) {
                        this.modalAtributos.detalle.atributos.push({
                            id: null,
                            nombre: nombreAtributo,
                            valor: "", // Valor vacío
                            tipo: "text",
                            ordenCatalogoDetailId: this.modalAtributos.detalle.id || null
                        });
                    }
                });
            }
            
            this.modalAtributos.mostrar = true;
        },
        cerrarModalAtributos() {
            this.modalAtributos.mostrar = false;
        },
        guardarAtributos() {
            // Validamos que haya al menos un atributo con nombre
            const atributos = this.modalAtributos.detalle.atributos || [];
            const hayAtributosInvalidos = atributos.some(attr => !attr.nombre.trim());
            
            if (hayAtributosInvalidos) {
                API.showErrorAlert("Todos los atributos deben tener un nombre");
                return;
            }
            
            // Asegurar que tengamos el ID original del detalle
            const detalleOriginalId = this.detalles[this.modalAtributos.index].id;
            
            // Actualizamos el detalle completo manteniendo su ID original
            const detalleActualizado = {
                ...this.modalAtributos.detalle,
                id: detalleOriginalId // Asegurar que usamos el ID original
            };
            
            // Aseguramos que cada atributo tenga el ID correcto
            if (detalleActualizado.atributos) {
                detalleActualizado.atributos = detalleActualizado.atributos.map(attr => {
                    return {
                        ...attr,
                        // Preservar el ID del atributo si existe
                        id: attr.id || null,
                        // Asegurar que el atributo está asociado con este detalle
                        ordenCatalogoDetailId: detalleOriginalId
                    };
                });
            }
            
            // Actualizar en la lista de detalles
            this.detalles[this.modalAtributos.index] = detalleActualizado;
            
            // Actualizar el catálogo
            this.actualizarCatalogo();
            
            this.cerrarModalAtributos();
            API.showSuccessAlert("Atributos guardados correctamente");
        },
        agregarAtributo(detalle) {
            if (!detalle.atributos) {
                detalle.atributos = [];
            }
            
            // Crear un nuevo atributo con valores por defecto
            const nuevoAtributo = { 
                id: null,
                nombre: "", 
                valor: "",
                tipo: "text",
                ordenCatalogoDetailId: detalle.id || null
            };
            
            // Agregar el nuevo atributo a la lista
            detalle.atributos.push(nuevoAtributo);
        },
        eliminarAtributo(detalle, index) {
            const atributo = detalle.atributos[index];
            
            // Si el atributo tiene ID, eliminar en el backend
            if (atributo.id) {
                this.loading = true;
                API.send("POST", "medico/catalogos/atributo/eliminar", { id: atributo.id }, 
                    response => {
            detalle.atributos.splice(index, 1);
                        API.showSuccessAlert(response.msg || "Atributo eliminado correctamente");
                        this.loading = false;
                    }, 
                    error => {
                        this.onError(error);
                        this.loading = false;
                    }
                );
            } else {
                // Si no tiene ID, solo eliminarlo del array local
                detalle.atributos.splice(index, 1);
            }
        },
        onSuccess(response) {
            API.showSuccessAlert(response.msg || "Operación completada con éxito");
            
            // Actualizar la lista de catálogos
            this.cargarCatalogos();
            
            if (!this.id) {
                // Si estamos creando un nuevo catálogo, limpiamos el formulario
                console.log('Creación completada, limpiando formulario');
                this.limpiarFormulario();
            } else if (response.data) {
                // Si estamos editando y recibimos datos actualizados
                console.log('Actualización completada, actualizando formulario con datos del servidor');
                
                // Obtener el catálogo actualizado de la respuesta
                let catalogoActualizado = null;
                
                if (response.data.id) {
                    // La respuesta es directamente el objeto catálogo
                    catalogoActualizado = response.data;
                } else if (Array.isArray(response.data) && response.data.length > 0) {
                    // La respuesta es un array, buscamos el catálogo por ID
                    catalogoActualizado = response.data.find(cat => cat.id === this.id);
                }
                
                if (catalogoActualizado) {
                    console.log('Datos del catálogo actualizado:', {
                        id: catalogoActualizado.id,
                        nombre: catalogoActualizado.nombre,
                        slug: catalogoActualizado.slug,
                        detalles: catalogoActualizado.detalles ? catalogoActualizado.detalles.length : 0
                    });
                    
                    // Actualizar el formulario con los datos más recientes
                    this.id = catalogoActualizado.id;
                    this.nombreCatalogo = catalogoActualizado.nombre;
                    this.slug = catalogoActualizado.slug;
                    this.isGlobal = catalogoActualizado.isGlobal || false;
                    
                    // Hacer una copia profunda de los detalles para evitar problemas de referencia
                    this.detalles = JSON.parse(JSON.stringify(catalogoActualizado.detalles || []));
                    
                    // Asegurarse de que cada detalle tenga un array de atributos
                    this.detalles.forEach(detalle => {
                        if (!detalle.atributos) {
                            detalle.atributos = [];
                        }
                    });
                }
            }
        },
        onError(error) {
            // Manejo de errores de validación del servidor
            if (error.errors) {
                this.errors = error.errors;
            } else {
                API.showErrorAlert(error.msg || "Ha ocurrido un error");
            }
        },
        limpiarFormulario() {
            this.id = null;
            this.nombreCatalogo = "";
            this.slug = "";
            this.isGlobal = false;
            this.detalles = [];
            this.errors = {};
        },
        // Función de ayuda para debuggear problemas con la API
        logRespuesta(accion, datos) {
            console.group(`API ${accion}`);
            console.log('Datos:', datos);
            console.groupEnd();
        },
        abrirModalEditarCatalogo() {
            this.modalEditarCatalogo.mostrar = true;
            // Resetear la paginación al abrir el modal
            this.paginacionDetalles.paginaActual = 1;
        },
        
        cerrarModalEditarCatalogo() {
            this.modalEditarCatalogo.mostrar = false;
        },
        
        guardarCambiosCatalogo() {
            if (!this.validarFormulario()) return;
            
            this.formSubmit();
            this.cerrarModalEditarCatalogo();
        },
        
        calcularIndiceReal(indiceVisible) {
            // Ahora debe calcular el índice en el array detalles original, considerando el filtro
            const inicio = (this.paginacionDetalles.paginaActual - 1) * this.paginacionDetalles.itemsPorPagina;
            const indiceFiltrado = inicio + indiceVisible;
            
            // Obtener el detalle filtrado en esa posición
            const detalleFiltrado = this.detallesFiltrados[indiceFiltrado];
            
            // Encontrar ese mismo detalle en el array original
            return this.detalles.findIndex(d => {
                return d.id === detalleFiltrado.id || 
                      (d.descripcion === detalleFiltrado.descripcion && 
                       d.codigoExterno === detalleFiltrado.codigoExterno);
            });
        },
        
        cambiarPaginaDetalles(pagina) {
            if (pagina > 0 && pagina <= this.totalPaginasDetalles) {
                this.paginacionDetalles.paginaActual = pagina;
            }
        },
        obtenerPlantillaAtributos() {
            // Encontrar el detalle con más atributos para usar como plantilla
            let detalleConMasAtributos = null;
            let maxAtributos = 0;
            
            for (const detalle of this.detalles) {
                if (detalle.atributos && detalle.atributos.length > maxAtributos) {
                    maxAtributos = detalle.atributos.length;
                    detalleConMasAtributos = detalle;
                }
            }
            
            // Si encontramos un detalle con atributos, usar sus nombres como plantilla
            if (detalleConMasAtributos && detalleConMasAtributos.atributos.length > 0) {
                return detalleConMasAtributos.atributos.map(a => a.nombre);
            }
            
            // Si no hay atributos, devolver array vacío
            return [];
        },
    },
};
</script>

<style scoped>
.card-status-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 3px;
    border-radius: 3px 3px 0 0;
}

.btn-icon {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 4px;
}

.btn-ghost-primary, .btn-ghost-success, .btn-ghost-danger {
    background: transparent;
    border-color: transparent;
}

.btn-ghost-primary:hover {
    background: rgba(32, 107, 196, 0.1);
    color: #206bc4;
}

.btn-ghost-success:hover {
    background: rgba(47, 179, 68, 0.1);
    color: #2fb344;
}

.btn-ghost-danger:hover {
    background: rgba(214, 57, 57, 0.1);
    color: #d63939;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
}

.empty {
    text-align: center;
    padding: 2rem;
    color: #6c757d;
}

.empty-icon {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: #6c757d;
}

.empty-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.empty-subtitle {
    margin-bottom: 1.5rem;
}

/* Estilos para el paginador */
.pagination-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.pagination-info {
    color: #6c757d;
    font-size: 14px;
}

.pagination-buttons {
    display: flex;
    align-items: center;
}

.pagination-page-info {
    margin: 0 10px;
    font-size: 14px;
}

/* Estilos para hacer que los modales tengan scroll */
.modal-dialog-scrollable .modal-content {
    max-height: 90vh;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
    max-height: calc(90vh - 120px);
}

.modal-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    border-radius: 3px 3px 0 0;
}

.btn-close {
    width: 32px;
    height: 32px;
    padding: 0;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.btn-close:hover {
    opacity: 1;
}

.icon-lg {
    width: 48px;
    height: 48px;
}

.subheader {
    font-size: 0.625rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 1.6;
    color: #6c757d;
}

.tags {
    display: flex;
    flex-wrap: wrap;
}

.page-link {
    display: flex;
    align-items: center;
}

/* Estilo para las tarjetas de detalles */
.card .card-title {
    margin-bottom: 0;
    font-size: 1rem;
}

.pagination {
    margin-bottom: 0;
}

.pagination .page-item.active .page-link {
    background-color: #206bc4;
    border-color: #206bc4;
}

.pagination .page-link {
    color: #206bc4;
    border-color: #dee2e6;
    padding: 0.375rem 0.75rem;
}

.pagination .page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #174584;
}

.pagination .page-item.disabled .page-link {
    color: #9aa0ac;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}

/* Añadir estilos para tooltips */
.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

[data-tooltip]:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 3px;
    background: #333;
    color: white;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
}

/* Mejorar el estilo de los botones de icono */
button.btn-icon {
    width: 36px;
    height: 36px;
    padding: 6px;
    border-radius: 4px;
}

/* Ajustar tamaño para botones más pequeños */
button.btn-icon.btn-sm {
    width: 30px;
    height: 30px;
    padding: 3px;
}

/* Destacar el hover para mejor UX */
button.btn-icon:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.2s;
}

/* Añadir estilos para los atributos */
.attr-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.attr-item {
    display: flex;
    background-color: rgba(32, 107, 196, 0.05);
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.8125rem;
}

.attr-name {
    font-weight: 500;
    margin-right: 4px;
    color: #206bc4;
}

.attr-value {
    color: #64748b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.attr-more {
    margin-top: 4px;
}

/* Mejorar el estilo del buscador */
.input-icon.w-100 {
    display: flex;
}

.input-icon.w-100 .form-control {
    flex: 1;
}

/* Mejorar estilos de los atributos */
.attr-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.attr-item {
    display: flex;
    align-items: center;
    background-color: rgba(32, 107, 196, 0.05);
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 0.875rem;
}

.attr-name {
    font-weight: 500;
    margin-right: 6px;
    color: #206bc4;
    min-width: 80px;
}

.attr-value {
    color: #475569;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

/* Añadir estilos para widgets mejorados */
.detail-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.detail-card-wrapper {
    display: flex;
}

.detail-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 0.2s, box-shadow 0.2s;
    border: 1px solid rgba(98, 105, 118, 0.16);
}

.detail-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.detail-card .card-body {
    flex: 1;
}
</style>