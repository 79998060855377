<template>
    <setting-layout :active="32">
        <div class="card card-body">
            <div class="mb-3">
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="regresar-tab" href="#" onclick="window.history.go(-1); return false;">
                            Regresar
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="{ active: isActive === 'info' }" id="info-tab" @click.prevent="isActive = 'info'" href="#">
                            Información
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="{ active: isActive === 'coti' }" id="coti-tab" @click.prevent="isActive = 'coti'" href="#">
                            Cotizar
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="{ active: isActive === 'reimpresion' }" id="reimpresion-tab" @click.prevent="isActive = 'reimpresion'" href="#">
                            Reimpresión
                        </a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" :class="{ active: isActive === 'tips' }" id="tips-tab" @click.prevent="isActive = 'tips'" href="#">
                            <i class="fa fa-hands-helping"></i> Tips de ventas
                        </a>
                    </li>
                </ul>
            </div>

            <div v-html="product.descripcion" v-if="isActive === 'info'" class="mt-100 mixitUp-container"/>
            <div v-if="isActive === 'coti'">
                <!--<flow-form ref="flowform"
                           v-if="questionsLoaded && !cartNotice.status && questions && questions.length > 0 && AuthGetUserInfo.nombres !== 'Capacitacion'"
                           :force-update="forceUpdate"
                           v-bind:standalone="false"
                           v-bind:questions="questions"
                           v-bind:language="language"
                           v-bind:progressbar="true"
                           v-on:submit="onSubmit"
                           v-on:answer="onAnswer"
                           v-on:step="onStep"
                >
                </flow-form>-->
                <FormWizard :forceUpdate="true" :key="planSelected" color="#005cb9"
                            v-if="(questionsLoaded && !cartNotice.status && questions && questions.length > 0) && AuthGetUserInfo.s === 'multivendor.comasa.com.gt'">
                    <template v-for="tab in tabsSeleccionadas2">
                        <TabContent v-if="tab.value ==='plan' " :title="tab.title" :icon="tab.icon">
                            <label >Seleccione el plan</label>
                            <select  v-model="planSelected" class="form-control form-select">
                                <option value="" disabled selected>Seleccione el plan</option>
                                <option v-for="(option, optionIndex) in planesVariaciones" :key="optionIndex"
                                        :value="option.value">{{ option.label }}
                                </option>
                            </select>
                            <br/>
                            <label>Frecuencia de pago</label>
                            <select v-model="frecuenciaSelected" :class="!frecuenciaSelected?'form-control form-select border-danger':'form-control form-select'">
                                <option value="" disabled selected>Frecuencia de pago</option>
                                <template v-for="(option, optionIndex) in getFrecuencias()" :key="optionIndex">
                                    <option
                                            :value="option.value" >
                                        {{ option.title }}
                                    </option>
                                </template>
                            </select>
                            <br/>
                            <label  v-if=" AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'">Seleccione agencia</label>
                            <select v-model="formDataToSend.agencia" v-if=" AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'" :class="!formDataToSend.agencia?'form-control form-select border-danger':'form-control form-select'">
                                <option value="" disabled selected>Seleccione agencia</option>
                                <option v-for="(option, optionIndex) in agencias" :key="optionIndex"
                                        :value="option.label">{{ option.label }}
                                </option>
                            </select>

                        </TabContent>
                        <TabContent v-if="tab.value ==='titular' " :title="tab.title" :icon="tab.icon">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="nis" class="form-label">NIS/EXPEDIENTE</label>
                                    <input id="nis" v-model="formDataToSend.expediente" :class="!formDataToSend.expediente?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>
                                </div>
                                <div class="form-group col-6">
                                    <label for="DPI" class="form-label">DPI</label>
                                    <input id="DPI" v-model="formDataToSend.DPI"  :class="!formDataToSend.DPI?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>

                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6" v-if="!notieneNIT">
                                    <label for="NIT" class="form-label">NIT</label>
                                    <input id="NIT" v-model="formDataToSend.clienteTaxId" @change="validarNIT($event.target.value);" :class="!formDataToSend.clienteTaxId ? 'form-control form-select border-danger mb-1' : 'form-control form-select mb-1'" type="text"/>
                                    <button class="btn btn-secondary" @click=setConsumidorFinal()>CF</button>
                                </div>
                                <div class="form-group col-6" v-if="notieneNIT">
                                    CF
                                </div>
                                <div class="form-group col-6">
                                    <label for="NIT" class="form-label">Nombre completo</label>
                                    <input id="NIT"  v-model="formDataToSend.clienteNombre" :class="!formDataToSend.clienteNombre?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="NIT" class="form-label">Teléfono</label>
                                    <input id="NIT" v-model="formDataToSend.clienteTelefono"  :class="!formDataToSend.clienteTelefono?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>
                                </div>
                                <div class="form-group col-6" v-if="!hideInputEmail">
                                    <label for="NIT" class="form-label">Correo</label>
                                    <input id="NIT" v-model="formDataToSend.clienteEmail"  :class="!formDataToSend.clienteEmail?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>
                                    <button class="btn btn-secondary" @click="hideEmailInput()">No tiene</button>
                                </div>
                                <div class="form-group col-6" v-if="hideInputEmail">
                                  No tiene
                                </div>
                            </div>


                        </TabContent>
                        <TabContent v-if="tab.value ==='vehiculo' " :title="tab.title" :icon="tab.icon">
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="nis" class="form-label">Tipo</label>
                                    <select v-model="formDataToSend.tipoVehiculo" class="form-control">
                                        <option value="auto">Automóvil</option>
                                        <option value="moto">Motocicleta</option>
                                    </select>

                                </div>
                                <div class="form-group col-6">
                                    <label for="DPI" class="form-label">Placas</label>
                                    <input id="DPI" v-model="formDataToSend.placa_a"  :class="!formDataToSend.placa_a?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="NIT" class="form-label">Color</label>
                                    <select  v-model="formDataToSend.color" class="form-control form-select" aria-label="Seleccione un color">
                                        <option selected>Seleccione un color</option>
                                        <option value="Blanco">Blanco</option>
                                        <option value="Negro">Negro</option>
                                        <option value="Gris">Gris</option>
                                        <option value="Plateado">Plateado</option>
                                        <option value="Azul">Azul</option>
                                        <option value="Rojo">Rojo</option>
                                        <option value="Verde">Verde</option>
                                        <option value="Beige">Beige</option>
                                        <option value="Marrón">Marrón</option>
                                        <option value="Amarillo">Amarillo</option>
                                        <option value="Naranja">Naranja</option>
                                        <option value="Morado">Morado</option>
                                        <option value="Rosa">Rosa</option>
                                        <option value="Turquesa">Turquesa</option>
                                        <option value="Bronce">Bronce</option>
                                        <option value="Plata">Plata</option>
                                        <option value="Oro">Oro</option>
                                        <option value="Granate">Granate</option>
                                        <option value="Cobre">Cobre</option>
                                        <option value="Champán">Champán</option>
                                        <option value="Azul marino">Azul marino</option>
                                        <option value="Verde oscuro">Verde oscuro</option>
                                        <option value="Gris oscuro">Gris oscuro</option>
                                        <option value="Rojo cereza">Rojo cereza</option>
                                        <option value="Violeta">Violeta</option>
                                        <option value="Turquesa">Turquesa</option>
                                        <option value="Marfil">Marfil</option>
                                        <option value="Carmesí">Carmesí</option>
                                        <option value="Salmon">Salmon</option>
                                        <option value="Púrpura">Púrpura</option>
                                        <option value="Gris claro">Gris claro</option>
                                        <option value="Gris plateado">Gris plateado</option>
                                        <option value="Azul cielo">Azul cielo</option>
                                        <option value="Azul eléctrico">Azul eléctrico</option>
                                        <option value="Azul zafiro">Azul zafiro</option>
                                        <option value="Rojo rubí">Rojo rubí</option>
                                        <option value="Rojo vino">Rojo vino</option>
                                        <option value="Verde esmeralda">Verde esmeralda</option>
                                        <option value="Verde lima">Verde lima</option>
                                        <option value="Verde oliva">Verde oliva</option>
                                        <option value="Marrón claro">Marrón claro</option>
                                        <option value="Marrón oscuro">Marrón oscuro</option>
                                        <option value="Amarillo mostaza">Amarillo mostaza</option>
                                        <option value="Amarillo dorado">Amarillo dorado</option>
                                        <option value="Naranja quemado">Naranja quemado</option>
                                        <option value="Naranja brillante">Naranja brillante</option>
                                        <option value="Rosado claro">Rosado claro</option>
                                        <option value="Rosado oscuro">Rosado oscuro</option>
                                        <option value="Gris pardo">Gris pardo</option>
                                        <option value="Gris acero">Gris acero</option>
                                        <option value="Gris pizarra">Gris pizarra</option>
                                    </select>

                                </div>

                                <div class="form-group col-6" v-if="formDataToSend.tipoVehiculo === 'auto'">
                                    <label for="NIT" class="form-label">Marca</label>
                                    <select v-model="formDataToSend.linea" class="form-control form-select" aria-label="Seleccione una marca">
                                        <option selected>Seleccione una marca</option>
                                        <option value="Toyota">Toyota</option>
                                        <option value="Honda">Honda</option>
                                        <option value="Nissan">Nissan</option>
                                        <option value="Hyundai">Hyundai</option>
                                        <option value="Kia">Kia</option>
                                        <option value="Mitsubishi">Mitsubishi</option>
                                        <option value="Mazda">Mazda</option>
                                        <option value="Volkswagen">Volkswagen</option>
                                        <option value="Chevrolet">Chevrolet</option>
                                        <option value="Ford">Ford</option>
                                        <option value="Mercedes-Benz">Mercedes-Benz</option>
                                        <option value="BMW">BMW</option>
                                        <option value="Audi">Audi</option>
                                        <option value="Subaru">Subaru</option>
                                        <option value="Lexus">Lexus</option>
                                        <option value="Land Rover">Land Rover</option>
                                        <option value="Jeep">Jeep</option>
                                        <option value="Volvo">Volvo</option>
                                        <option value="Peugeot">Peugeot</option>
                                        <option value="Suzuki">Suzuki</option>
                                        <option value="Fiat">Fiat</option>
                                        <option value="Renault">Renault</option>
                                        <option value="Chrysler">Chrysler</option>
                                        <option value="Jaguar">Jaguar</option>
                                        <option value="Maserati">Maserati</option>
                                        <option value="Porsche">Porsche</option>
                                        <option value="Isuzu">Isuzu</option>
                                        <option value="Tesla">Tesla</option>
                                        <option value="Acura">Acura</option>
                                        <option value="Alfa Romeo">Alfa Romeo</option>
                                        <option value="Bentley">Bentley</option>
                                        <option value="Bugatti">Bugatti</option>
                                        <option value="Cadillac">Cadillac</option>
                                        <option value="Dodge">Dodge</option>
                                        <option value="Ferrari">Ferrari</option>
                                        <option value="Genesis">Genesis</option>
                                        <option value="Infiniti">Infiniti</option>
                                        <option value="Lamborghini">Lamborghini</option>
                                        <option value="Lincoln">Lincoln</option>
                                        <option value="Lotus">Lotus</option>
                                        <option value="McLaren">McLaren</option>
                                        <option value="Mini">Mini</option>
                                        <option value="Pagani">Pagani</option>
                                        <option value="Rolls-Royce">Rolls-Royce</option>
                                        <option value="Smart">Smart</option>
                                        <option value="Tata Motors">Tata Motors</option>
                                        <option value="Zenvo">Zenvo</option>
                                        <option value="Otros">Otros</option>
                                    </select>

                                </div>
                                <div class="form-group col-6" v-if="formDataToSend.tipoVehiculo === 'moto'">
                                    <label for="NIT" class="form-label">Marca</label>
                                    <select v-model="formDataToSend.linea" class="form-select" aria-label="Seleccione una marca de motocicleta">
                                        <option selected>Seleccione una marca de motocicleta</option>
                                        <option value="Honda">Honda</option>
                                        <option value="Yamaha">Yamaha</option>
                                        <option value="Suzuki">Suzuki</option>
                                        <option value="Kawasaki">Kawasaki</option>
                                        <option value="Bajaj">Bajaj</option>
                                        <option value="TVS">TVS</option>
                                        <option value="Hero">Hero</option>
                                        <option value="Royal Enfield">Royal Enfield</option>
                                        <option value="KTM">KTM</option>
                                        <option value="Harley-Davidson">Harley-Davidson</option>
                                        <option value="Ducati">Ducati</option>
                                        <option value="BMW Motorrad">BMW Motorrad</option>
                                        <option value="Triumph">Triumph</option>
                                        <option value="Benelli">Benelli</option>
                                        <option value="Kymco">Kymco</option>
                                        <option value="Piaggio">Piaggio</option>
                                        <option value="Vespa">Vespa</option>
                                        <option value="Aprilia">Aprilia</option>
                                        <option value="Husqvarna">Husqvarna</option>
                                        <option value="Indian">Indian</option>
                                        <option value="Moto Guzzi">Moto Guzzi</option>
                                        <option value="Zero Motorcycles">Zero Motorcycles</option>
                                        <option value="Can-Am">Can-Am</option>
                                        <option value="CFMoto">CFMoto</option>
                                        <option value="SYM">SYM</option>
                                        <option value="KSR Moto">KSR Moto</option>
                                        <option value="Gas Gas">Gas Gas</option>
                                        <option value="MV Agusta">MV Agusta</option>
                                        <option value="Husaberg">Husaberg</option>
                                        <option value="Daelim">Daelim</option>
                                        <option value="Keeway">Keeway</option>
                                        <option value="AJP">AJP</option>
                                        <option value="Lifan">Lifan</option>
                                        <option value="Mash">Mash</option>
                                        <option value="Zontes">Zontes</option>
                                        <option value="Cagiva">Cagiva</option>
                                        <option value="Norton">Norton</option>
                                        <option value="CPI">CPI</option>
                                        <option value="Rieju">Rieju</option>
                                        <option value="UM Motorcycles">UM Motorcycles</option>
                                        <option value="SWM">SWM</option>
                                        <option value="Oset">Oset</option>
                                        <option value="Vyrus">Vyrus</option>
                                        <option value="Sherco">Sherco</option>
                                        <option value="Skyteam">Skyteam</option>
                                        <option value="Aeon">Aeon</option>
                                        <option value="Bimota">Bimota</option>
                                        <option value="Brixton">Brixton</option>
                                        <option value="Cleveland CycleWerks">Cleveland CycleWerks</option>
                                        <option value="FB Mondial">FB Mondial</option>
                                        <option value="Hyosung">Hyosung</option>
                                        <option value="Motoguzzi">Motoguzzi</option>
                                        <option value="Scomadi">Scomadi</option>
                                        <option value="Tauris">Tauris</option>
                                        <option value="TGB">TGB</option>
                                        <option value="Vectrix">Vectrix</option>
                                        <option value="MRT">MRT</option>
                                        <option value="Otras">Otras</option>
                                    </select>


                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-6">
                                    <label for="yearRange" class="form-label">Año: &nbsp; {{ formDataToSend.year }} &nbsp; </label>
                                    <input
                                            id="yearRange"
                                            type="range"
                                            class="form-range"
                                            v-model="formDataToSend.year"
                                            :min="1980"
                                            :max="maxYear"
                                    />
                                </div>
                            </div>
                        </TabContent>

                    </template>

                    <template v-slot:footer="props">
                        <table class="table table-borderless"
                               v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined'">
                            <tbody>
                            <tr>
                                <td>
                                    Total a pagar:
                                </td>
                                <td v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined' && typeof (reglasDeNegocio[planSelected]) !== 'undefined' && typeof (reglasDeNegocio[planSelected][frecuenciaSelected]) !== 'undefined'">
                                    {{

                                        Intl.NumberFormat('es-US', {
                                            style: 'currency',
                                            currency: 'GTQ'
                                        }).format(reglasDeNegocio[planSelected][frecuenciaSelected])
                                    }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="wizard-footer-left">
                            <button
                                    v-if="props.activeTabIndex > 0 && !props.isLastStep"
                                    :style="props.fillButtonStyle"
                                    @click.native="props.prevTab()"
                                    class="wizard-button"
                            >
                                Atrás
                            </button>
                        </div>
                        <div class="wizard-footer-right">
                            <button
                                    v-if="!props.isLastStep &&  searchResults && formDataToSend.agencia"
                                    @click.native="validateAndNext(props)"
                                    class="wizard-button"
                                    :style="props.fillButtonStyle"
                            >
                                Siguiente
                            </button>
                            <ul v-if="!searchResults || !formDataToSend.agencia || !frecuenciaSelected || !planSelected || conyugeFechaNacimiento.hasError" class="list-unstyled small text-danger">

                                <li v-if="!formDataToSend.agencia && AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'">Por favor seleccione agencia antes de continuar</li>
                                <li v-if="!planSelected">Por favor seleccione Plan antes de continuar</li>
                                <li v-if="conyugeFechaNacimiento.hasError">Por favor valide los campos de fecha</li>
                            </ul>

                            <button
                                    v-if="props.isLastStep  && formDataToSend.agencia && frecuenciaSelected && !conyugeFechaNacimiento.hasError"
                                    @click.native="onSubmit(props)"
                                    class="finish-button"
                                    :style="props.fillButtonStyle"
                            >
                                {{ props.isLastStep ? "Enviar" : "Siguiente" }}
                            </button>

                        </div>
                    </template>
                </FormWizard>

                <FormWizard :forceUpdate="true" :key="planSelected" color="#005cb9"
                            v-if="fields && questions.length === 0 && !cartNotice.status">

                    <template v-for="tab in tabsSeleccionadas">

                        <TabContent v-if="tab.value ==='plan' " :title="tab.title" :icon="tab.icon">
                            <label v-if="skuProducto === 'AERH' || skuProducto === 'VEMI'">Seleccione el plan</label>
                            <select v-if="skuProducto === 'AERH' || skuProducto === 'VEMI'" v-model="planSelected" class="form-control form-select">
                                <option value="" disabled selected>Seleccione el plan</option>
                                <option v-for="(option, optionIndex) in planesVariaciones" :key="optionIndex"
                                        :value="option.value">{{ option.label }}
                                </option>
                            </select>
                            <br/>
                            <label>Frecuencia de pago</label>
                            <select v-model="frecuenciaSelected" :class="!frecuenciaSelected?'form-control form-select border-danger':'form-control form-select'">
                                <option value="" disabled selected>Frecuencia de pago</option>
                                <template v-for="(option, optionIndex) in getFrecuencias()">
                                    <option
                                            :value="option.value" >
                                        {{ option.title }}
                                    </option>
                                </template>
                            </select>
                            <br/>
                            <label v-if="AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'">Seleccione agencia</label>
                            <select v-if="AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'" v-model="locacionId" :class="!locacionId?'form-control form-select border-danger':'form-control form-select'">
                                <option value="" disabled selected>Seleccione agencia</option>
                                <option v-for="(option, optionIndex) in agencias" :key="optionIndex"
                                        :value="option.label">{{ option.label }}
                                </option>
                            </select>

                        </TabContent>
                        <TabContent v-if="tab.value ==='titular' " :title="tab.title" :icon="tab.icon">
                            <label>NIS/EXPEDIENTE</label>
                            <input v-model="expediente" :class="!expediente?'form-control form-select border-danger mb-1':'form-control form-select mb-1'" type="text"/>
                            <template v-for="section in fields">
                                <div class="row" v-if="section.title === 'Contacto' || section.title === 'Datos'">
                                    <template v-for="field in section.json" :key="field.key">
                                        <div :class="field.type === 'etiqueta'?'form-group col-12':'form-group col-lg-6'"
                                             v-if="field.nombre_interno !== 'frecdepag'  && field.nombre_interno !== 'plan_fracc' && field.nombre_interno !== 'nombrecoti' && field.nombre_interno !== 'nive' && field.nombre_interno !== 'porcpart'  && (field.visible === true || field.visible === '1')">
                                            <label :for="field.nombre_interno">{{ field.label }}</label>
                                            <!-- Renderizar campo de fecha -->
                                            <input v-if="field.type === 'fecha'"
                                                   type="date"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   placeholder="dd/mm/aaaa"
                                                   @input="validateAge(field)"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   :required="field.required === '1'"
                                                   class="form-control">
                                            <div v-if="field.hasError" class="error-message"> La edad debe ser entre {{ reglasDeNegocio[planSelected]['minAge'] }} y
                                                {{reglasDeNegocio[planSelected]['maxAge']}} años.
                                            </div>
                                            <!-- Renderizar campo de tel -->
                                            <input v-if="field.type === 'telefono'"
                                                   type="tel"
                                                   v-maska data-maska="########"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   :required="field.required === '1'"
                                                   class="form-control">
                                            <!-- Renderizar campo de nit -->
                                            <input v-if="field.type === 'nit' && notieneNIT === false"
                                                   type="text"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   v-maska data-maska="#######XX"
                                                   data-maska-tokens="{ 'X': { 'pattern': '[-0-9a-zA-Z]' }}"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   :required="field.required === '1'"
                                                   class="form-control"/>
                                            <br v-if="field.type === 'nit' && notieneNIT === true"/>
                                            <br v-if="field.type === 'nit' && notieneNIT === true"/>
                                            <label v-if="field.type === 'nit' && notieneNIT === true"><b>CF</b></label>
                                            <br v-if="field.type === 'nit' && notieneNIT === true"/>
                                            <br v-if="field.type === 'nit' && notieneNIT === true"/>
                                            <button v-if="field.type === 'nit'" @click="setConsumidorFinal(field)"
                                                    class="btn btn-secondary">
                                                Consumidor Final
                                            </button>

                                            <input v-if="field.type === 'dpi' || field.acsel_parametro_id_workflow === 'DPI'"
                                                   type="tel"
                                                   v-maska data-maska="#############"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   @change="validateDPIByProduct(field.value)"
                                                   :required="field.required === '1'"
                                                   class="form-control">
                                            <!-- Renderizar campo multiselect -->
                                            <select v-else-if="(field.type === 'lista' || field.type === 'planes')"
                                                    :id="field.nombre_interno"
                                                    v-model="field.value"
                                                    :class="{ 'is-invalid': !field.value || field.hasError }"
                                                    :required="field.required === '1'"
                                                    class="form-control">
                                                <option v-for="option in field.fuenteDatosValues" :key="option.key"
                                                        :value="option.value">{{ option.label }}
                                                </option>
                                            </select>
                                            <select v-else-if="field.type === 'genero'"
                                                    :id="field.nombre_interno"
                                                    v-model="field.value"
                                                    :class="{ 'is-invalid': !field.value || field.hasError }"
                                                    :required="field.required === '1'"
                                                    class="form-control">
                                                <option value="F">Femenino</option>
                                                <option value="M">Masculino</option>
                                            </select>
                                            <input v-else-if="field.type === 'numerico'" type="range"
                                                   class="form-control" :id="field.nombre_interno" v-model="field.value"
                                                   :min="field.fuenteDatosValues[0].value"
                                                   :max="field.fuenteDatosValues[field.fuenteDatosValues.length - 1].value">
                                            <div v-else-if="field.type === 'numerico'">{{ field.value }}</div>
                                            <div v-else-if="field.type === 'firma'">

                                                <VueSignaturePad width="400px" height="400px"
                                                                 :ref="'signaturePad_' + field.key"/>

                                                <div class="btn btn-outline-primary btn-sm"><i class="fa fa-tablet"></i>
                                                </div>
                                            </div>
                                            <!-- Renderizar campo multiselect -->
                                            <hr v-else-if="field.type === 'etiqueta'"/>
                                            <!-- Renderizar otros tipos de campos -->
                                            <input v-else-if="field.type === 'email'  && hideInputEmail === false"
                                                   type="email"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   :required="field.required === '1'"
                                                   :placeholder="field.label"
                                                   class="form-control"/>
                                            <br v-if="field.type === 'email'  && hideInputEmail === true"/>
                                            <br v-if="field.type === 'email'  && hideInputEmail === true"/>
                                            <label v-if="field.type === 'email'  && hideInputEmail === true">No
                                                tiene</label>
                                            <br v-if="field.type === 'email'  && hideInputEmail === true"/>
                                            <br v-if="field.type === 'email'  && hideInputEmail === true"/>
                                            <button v-if="field.type === 'email'" @click="hideEmailInput(field)"
                                                    class="btn btn-secondary">No tiene
                                            </button>

                                            <!-- Agrega más lógica para otros tipos de campos -->
                                            <div v-if="field.hasError" class="invalid-feedback">
                                                {{ field.validation }}
                                            </div>
                                            <input v-else-if="field.type === 'alfanumerico' && field.acsel_parametro_id_workflow !== 'DPI'"
                                                   type="text"
                                                   :id="field.nombre_interno"
                                                   v-model="field.value"
                                                   :class="{ 'is-invalid': !field.value || field.hasError }"
                                                   :required="field.required === '1'"
                                                   :placeholder="field.label"
                                                   class="form-control">
                                            <!-- Agrega más lógica para otros tipos de campos -->
                                            <div v-if="field.hasError" class="invalid-feedback">
                                                {{ field.validation }}
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>

                        </TabContent>
                        <template v-if="tab.value === 'beneficiario'  && skuProducto === 'VEMI'">
                            <template v-if="tab.value === 'beneficiario'  && skuProducto === 'VEMI'">
                                <TabContent v-if="tab.value === 'beneficiario'  && skuProducto === 'VEMI'"
                                            :title="tab.title" :icon="tab.icon">

                                    <template v-for="section in fields">
                                        <div class="row" v-if="section.title === 'Dependientes'">

                                            <template v-for="field in section.json" :key="field.key">
                                                <div :class="field.type === 'etiqueta'?'form-group col-12 mt-10':'form-group col-lg-6 mt-10'"
                                                     v-if="field.nombre_interno !== 'frecdepag' && field.nombre_interno !== 'plan_fracc'&& field.nombre_interno !== 'FecnacDep' && field.nombre_interno !== 'nombrecoti' && (field.visible === true || field.visible === '1') ">
                                                    <label :for="field.nombre_interno">{{ field.label }}</label>
                                                    <!-- Renderizar campo de fecha -->
                                                    <input v-if="field.type === 'fecha'"
                                                           type="date"
                                                           :id="field.nombre_interno"
                                                           v-model="field.value"
                                                           placeholder="dd/mm/aaaa"
                                                           @input="validateAge(field)"
                                                           :class="{ 'is-invalid': !field.value || field.hasError }"
                                                           :required="field.required === '1'"
                                                           class="form-control">
                                                    <div v-if="field.hasError" class="error-message"> La edad debe ser entre {{ reglasDeNegocio[planSelected]['minAge'] }} y
                                                        {{reglasDeNegocio[planSelected]['maxAge']}} años.
                                                    </div>
                                                    <!-- Renderizar campo de tel -->
                                                    <input v-if="field.type === 'telefono'"
                                                           type="tel"
                                                           :id="field.nombre_interno"
                                                           v-maska data-maska="########"
                                                           v-model="field.value"
                                                           :class="{ 'is-invalid': !field.value || field.hasError }"
                                                           :required="field.required === '1'"
                                                           class="form-control">
                                                    <!-- Renderizar campo de nit -->
                                                    <input v-if="field.type === 'nit'"
                                                           type="text"
                                                           v-maska data-maska="#######XX"
                                                           data-maska-tokens="{ 'X': { 'pattern': '[-0-9a-zA-Z]' }}"
                                                           :id="field.nombre_interno"
                                                           v-model="field.value"
                                                           :class="{ 'is-invalid': !field.value || field.hasError }"
                                                           :required="field.required === '1'"
                                                           class="form-control">
                                                    <input v-if="field.type === 'dpi' || field.acsel_parametro_id_workflow === 'DPI'"
                                                           type="tel"
                                                           v-maska data-maska="#############"
                                                           :id="field.nombre_interno"
                                                           v-model="field.value"

                                                           :class="{ 'is-invalid': field.hasError }"
                                                           :required="field.required === '1'"
                                                           class="form-control">
                                                    <!-- Renderizar campo multiselect -->
                                                    <select v-else-if="(field.type === 'lista' || field.type === 'planes')"
                                                            :id="field.nombre_interno"
                                                            v-model="field.value"
                                                            :class="{ 'is-invalid': !field.value || field.hasError }"
                                                            :required="field.required === '1'"
                                                            class="form-control">
                                                        <option v-for="option in field.fuenteDatosValues" :key="option.key"
                                                                :value="option.value">{{ option.label }}
                                                        </option>
                                                    </select>
                                                    <select v-else-if="field.type === 'genero'"
                                                            :id="field.nombre_interno"
                                                            v-model="field.value"
                                                            :class="{ 'is-invalid': !field.value || field.hasError }"
                                                            :required="field.required === '1'"
                                                            class="form-control">
                                                        <option value="F">Femenino</option>
                                                        <option value="M">Masculino</option>
                                                    </select>

                                                    <div v-else-if="field.type === 'firma'">

                                                        <VueSignaturePad width="400px" height="400px"
                                                                         :ref="'signaturePad_' + field.key"/>

                                                        <div class="btn btn-outline-primary btn-sm"><i
                                                                class="fa fa-tablet"></i></div>
                                                    </div>
                                                    <!-- Renderizar campo multiselect -->
                                                    <hr v-else-if="field.type === 'etiqueta'"/>
                                                    <!-- Renderizar otros tipos de campos -->
                                                    <input v-else-if="field.type === 'alfanumerico' && field.acsel_parametro_id_workflow !== 'DPI'"
                                                           type="text"
                                                           :id="field.nombre_interno"
                                                           v-model="field.value"
                                                           :class="{ 'is-invalid': !field.value || field.hasError }"
                                                           :required="field.required === '1'"
                                                           :placeholder="field.label"
                                                           class="form-control"/>
                                                    <input v-else-if=" field.type === 'email'"
                                                           :type="field.type"
                                                           :id="field.nombre_interno"
                                                           v-model="field.value"
                                                           :class="{ 'is-invalid': !field.value || field.hasError }"
                                                           :required="field.required === '1'"
                                                           :placeholder="field.label"
                                                           class="form-control"/>
                                                    <!-- Agrega más lógica para otros tipos de campos -->
                                                    <div v-if="field.hasError" class="invalid-feedback">
                                                        {{ field.validation }}
                                                    </div>

                                                </div>
                                            </template>
                                        </div>
                                    </template>

                                </TabContent>
                            </template>

                        </template>

                    </template>

                    <template v-slot:footer="props">
                        <table class="table table-borderless"
                               v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined'">
                            <tbody>
                            <tr>
                                <td>
                                    Prima total a pagar:
                                </td>
                                <td v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined' && typeof (reglasDeNegocio[planSelected]) !== 'undefined' && typeof (reglasDeNegocio[planSelected][frecuenciaSelected]) !== 'undefined'">
                                    {{

                                        Intl.NumberFormat('es-US', {
                                            style: 'currency',
                                            currency: 'GTQ'
                                        }).format(reglasDeNegocio[planSelected][frecuenciaSelected])
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Encargos de cobranza:
                                </td>
                                <td v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined' && typeof (reglasDeNegocio[planSelected]) !== 'undefined' && typeof (reglasDeNegocio[planSelected][frecuenciaSelected]) !== 'undefined'">
                                    {{

                                        Math.ceil((reglasDeNegocio[planSelected][frecuenciaSelected])/reglasDeNegocio[planSelected]['mensual'])
                                    }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Suma asegurada:
                                </td>
                                <td v-if="typeof (frecuenciaSelected ) !== 'undefined' && typeof (planSelected) !== 'undefined' && typeof (reglasDeNegocio[planSelected]) !== 'undefined' && typeof (reglasDeNegocio[planSelected][frecuenciaSelected]) !== 'undefined'">
                                    {{

                                        Intl.NumberFormat('es-US', {
                                            style: 'currency',
                                            currency: 'GTQ'
                                        }).format(parseFloat(reglasDeNegocio[planSelected]['prima']))
                                    }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="wizard-footer-left">
                            <button
                                    v-if="props.activeTabIndex > 0 && !props.isLastStep"
                                    :style="props.fillButtonStyle"
                                    @click.native="props.prevTab()"
                                    class="wizard-button"
                            >
                                Atrás
                            </button>
                        </div>
                        <div class="wizard-footer-right">
                            <button
                                    v-if="(!props.isLastStep &&  searchResults && locacionId && planSelected && frecuenciaSelected) ||  (AuthGetUserInfo.s === 'telemercadeo.comasa.com.gt' && frecuenciaSelected && !props.isLastStep )"
                                    @click.native="validateAndNext(props)"
                                    class="wizard-button"
                                    :style="props.fillButtonStyle"
                            >
                                Siguiente
                            </button>
                            <ul v-if="!searchResults || !locacionId || !frecuenciaSelected || !planSelected || conyugeFechaNacimiento.hasError" class="list-unstyled small text-danger">
                                <li v-if="!searchResults">Por favor valide el DPI antes de continuar</li>
                                <li v-if="!locacionId && AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'">Por favor seleccione agencia antes de continuar</li>
                                <li v-if="!frecuenciaSelected">Por favor seleccione frecuencia de pago antes de continuar</li>
                                <li v-if="!planSelected">Por favor seleccione Plan antes de continuar</li>
                                <li v-if="conyugeFechaNacimiento.hasError">Por favor valide los campos de fecha</li>
                            </ul>


                            <button
                                    v-if="(props.isLastStep &&  searchResults && locacionId && frecuenciaSelected && !conyugeFechaNacimiento.hasError) || (AuthGetUserInfo.s === 'telemercadeo.comasa.com.gt' && props.isLastStep)"
                                    @click.native="onComplete(props)"
                                    class="finish-button"
                                    :style="props.fillButtonStyle"
                            >
                                {{ props.isLastStep ? "Enviar" : "Siguiente" }}
                            </button>

                        </div>
                    </template>
                </FormWizard>



                <div v-if="cartNotice.status && questions.length > 0" class="bg-light shadow-lg rounded-3 cartProducts">
                    <div class="cartNotify">
                        <div>
                            <h3 class="text-primary">Suscripción Creada
                                <i class="fa fa-check-circle ml-2"></i>
                            </h3>
                        </div>
                        <hr class="my-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title"><i class="fas fa-file-invoice mr-2"></i> Detalles de la
                                    suscripción:</h4>
                                <div>
                                    <p>El código que debes copiar y pegar en SGC es el siguiente:
                                        <b>{{ cartNotice.codigoOrden }}</b> y debes cobrar en caja un total de
                                        <b>
                                            (
                                            {{

                                                Intl.NumberFormat('es-US', {
                                                    style: 'currency',
                                                    currency: 'GTQ'
                                                }).format(parseFloat(reglasDeNegocio[planSelected][frecuenciaSelected]))
                                            }}
                                            )
                                        </b>
                                    </p>
                                </div>
                                <div class="mt-3">
                                    <div v-if="cartNotice.metodoPago === 'card'">
                                        <p>Los datos de cobro son:</p>
                                        <ul>
                                            <li><span class="font-weight-bold">Autorización:</span> {{
                                                    cartNotice.auth
                                                }}
                                            </li>
                                            <li><span class="font-weight-bold">Referencia No.:</span>
                                                {{ cartNotice.reference }}
                                            </li>
                                            <li><span class="font-weight-bold">Procesado por:</span>
                                                {{ cartNotice.processor }}
                                            </li>
                                        </ul>
                                    </div>
                                    <div v-else-if="cartNotice.metodoPago === 'later_on_delivery'" class="my-3">
                                        <p><span class="font-weight-bold">Pagarás contra entrega</span></p>
                                    </div>
                                    <div v-else-if="cartNotice.metodoPago === 'later_on_store'" class="my-3">
                                        <p><span class="font-weight-bold">Pagarás en tienda tu pedido</span></p>
                                    </div>
                                </div>
                                <div class="my-3 text-primary">
                                    Recibirás un correo electrónico con la confirmación de tu compra.
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary" @click="continueAfterCart">Hacer otra
                                        venta
                                    </button>
                                    <button type="button" class="btn btn-primary" @click="printDocumento"><i
                                            class="fa fa-print"></i></button>
                                    <button type="button" class="btn btn-primary" @click="consultaPDF"><i
                                            class="fa fa-download"></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="fields && questions.length === 0 && cartNotice.status " class="bg-light shadow-lg rounded-3 cartProducts">
                    <div class="cartNotify">
                        <div>
                            <h3 class="text-primary">Suscripción Creada
                                <i class="fa fa-check-circle ml-2"></i>
                            </h3>
                        </div>
                        <hr class="my-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title"><i class="fas fa-file-invoice mr-2"></i> Detalles de la suscripción:</h4>
                                <div>
                                    <p>El código que debes copiar y pegar en SGC es el siguiente: <b>{{skuProducto}} - {{ newSuscription }}</b> y debes cobrar en caja un total de
                                        (<b>{{

                                                Intl.NumberFormat('es-US', {
                                                    style: 'currency',
                                                    currency: 'GTQ'
                                                }).format(parseFloat(reglasDeNegocio[planSelected][frecuenciaSelected]))
                                            }}</b>)</p>
                                </div>
                                <div class="my-3 text-primary">
                                    Recibirás un correo electrónico con la confirmación de tu compra.
                                </div>
                                <p class="text-primary text-center">Cargar solicitud de cliente <b>Firmado</b>, esta no debe exceder los 3mb</p>
                                <FileUploader
                                        url="orders/metadata-attachments-cotizacion/save"
                                        :accepted-mime="[
                                                        'image/*',
                                                        'file/.dcm',
                                                        'application/pdf',
                                                        'application/x-xls',
                                                        'application/vnd.ms-excel',
                                                        'application/msword',
                                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                        'application/octet-stream',
                                                    ]"
                                        remove-file-on-finish="1"
                                        :extra-data-form="{
                                                        cotizacionId: cotizacionIdEnviada
                                                    }"
                                ></FileUploader>
                                <div class="d-flex justify-content-between mt-3">
                                    <button type="button" class="btn btn-primary" @click="continueAfterCart">Hacer otra venta</button>
                                    <div class="dropdown">
                                        <button @click="printSolicitud" class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Imprimir solicitud y kit
                                        </button>
                                    </div>
                                    <div class="dropdown" v-if="!esperandoFirma">
                                        <button @click="esperandoFirma = true" class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Firmar desde tablet
                                        </button>
                                    </div>
                                    <div v-if="esperandoFirma">
                                        ...Esperando Firma Desde Tablet
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isActive === 'reimpresion'">
                <div class="row g-3">
                    <div class="col-lg-6 col-sm-12">
                        <div class="input-group mb-3">
                            <input type="text" v-model="searchTerm" placeholder="Ingrese DPI" class="form-control" />
                            <button @click="buscarPDF" class="btn btn-primary">
                                <i class="fas fa-search"></i> Buscar
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-12 col-sm-12" v-if="pdfUrl">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'download' }" @click="activeTab = 'download'" type="button" role="tab">
                                    Descargar PDF
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" :class="{ active: activeTab === 'upload' }" @click="activeTab = 'upload'" type="button" role="tab">
                                    Subir PDF
                                </button>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'download' }">
                                <div class="card shadow-lg mt-4">
                                    <div class="card-header bg-primary text-white">
                                        <h4 class="text-white">Hemos encontrado lo siguiente</h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <!-- Personal Information Section -->
                                            <div class="col-md-6 mb-3">
                                                <h5><strong>Información Personal</strong></h5>
                                                <p><strong>Nombre:</strong> {{ dataClienteReimpresion.Nombre }} {{ dataClienteReimpresion.Apellido }} {{ dataClienteReimpresion['Segundo Apellido'] }}</p>
                                                <p><strong>DPI:</strong> {{ dataClienteReimpresion.DPI }}</p>
                                                <p><strong>Fecha de Nacimiento:</strong> {{ dataClienteReimpresion['Fecha de Nacimiento'] }}</p>
                                                <p><strong>Género:</strong> {{ dataClienteReimpresion.Genero === 'M' ? 'Masculino' : 'Femenino' }}</p>
                                                <p><strong>Correo Electrónico:</strong> {{ dataClienteReimpresion['Correo electrónico'] }}</p>
                                                <p><strong>Teléfono:</strong> {{ dataClienteReimpresion.Telefono }}</p>
                                            </div>
                                            <!-- Order Information Section -->
                                            <div class="col-md-6 mb-3">
                                                <h5><strong>Información de la Orden</strong></h5>
                                                <p><strong>Dirección:</strong> {{ dataClienteReimpresion.Direccion }}</p>
                                                <p><strong>NIT:</strong> {{ dataClienteReimpresion.NIT }}</p>
                                                <p><strong>Ocupación:</strong> {{ dataClienteReimpresion.Ocupacion }}</p>
                                                <p><strong>Frecuencia de Pago:</strong> {{ dataClienteReimpresion['Frecuencia de pago'] }}</p>
                                                <p><strong>Sucursal:</strong> {{ dataClienteReimpresion['Sucursal'] }}</p>
                                                <p><strong>Póliza:</strong> {{ dataClienteReimpresion['Póliza'] }}</p>
                                                <p><strong>Monto:</strong> Q{{ dataClienteReimpresion.Monto.toFixed(2) }}</p>
                                            </div>
                                        </div>

                                        <!-- Download PDF Button -->
                                        <div  v-if="pdfUrl" class="mt-4 text-center">
                                            <button class="btn btn-success" @click="printSolicitud()">
                                                <i class="fa fa-download"></i> Descargar PDF
                                            </button>
                                        </div>
                                        <div v-else class="mt-3">
                                            DPI NO encontrado
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" :class="{ 'show active': activeTab === 'upload' }">
                                <div class="mt-3">
                                    <FileUploader
                                            label-idle="Arrastra y suelta tu archivo PDF o <span class='filepond--label-action'> Examinar </span>"
                                            url="orders/metadata-attachments-cotizacion/save"
                                            :accepted-mime="[
                                                'image/*',
                                                'file/.dcm',
                                                'application/pdf',
                                                'application/x-xls',
                                                'application/vnd.ms-excel',
                                                'application/msword',
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                                'application/octet-stream',
                                              ]"
                                            remove-file-on-finish="1"
                                            :extra-data-form="{ cotizacionId: cotizacionIdEnviada }"
                                    ></FileUploader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isActive === 'tips'" class="row">
                <div class="col-12">
                    <embed v-if="skuProducto === 'AEPH'" src="https://onboarding-public-docs.s3.us-east-2.amazonaws.com/Tips+de+ventas.pdf" type="application/pdf" width="100%" height="600px" />
                    <embed v-if="skuProducto === 'VIAL'" src="https://onboarding-public-docs.s3.us-east-2.amazonaws.com/Tips+Vial+plus%5B21%5D.pdf" type="application/pdf" width="100%" height="600px" />
                    <div v-else  class="alert alert-info"> Archivo pendiente</div>
                </div>
            </div>


            <div class="gap"></div>
            <div class="gap"></div>
            <div class="gap"></div>
        </div>
    </setting-layout>


</template>

<script>

import {mapActions, mapGetters} from "vuex";
import API from "/src/core/Api";
import {store} from "/src/store";
import Numbro from 'numbro';
import dayjs from "dayjs";
import axios from 'axios';
import visible from 'axios';
import VPerfectSignature from 'v-perfect-signature'
import {FormWizard, TabContent, WizardStep} from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import FileUploader from "src/components/files/FileUploader.vue";


import settingLayout from "../../components/settings/settingLayout.vue";
import {
    ChoiceOption,
    FlowForm,
    LanguageModel,
    Question,
    QuestionModel,
    QuestionType,
    MatrixRow,
    MatrixColumn,
    LinkOption

} from "@ditdot-dev/vue-flow-form";
import newSubscription from "./NewSubscription.vue";

const automoviles = [
    new ChoiceOption({label: 'Automóvil', value: 'Automóvil'}),
    new ChoiceOption({label: 'Motocicleta', value: 'Motocicleta'}),
];
const NITs = [
    new ChoiceOption({label: 'CF'}),
];
const marcaM = [
    new ChoiceOption({label: 'Honda'}),
    new ChoiceOption({label: 'Yamaha'}),
    new ChoiceOption({label: 'Suzuki'}),
    new ChoiceOption({label: 'Kawasaki'}),
    new ChoiceOption({label: 'Bajaj'}),
    new ChoiceOption({label: 'TVS'}),
    new ChoiceOption({label: 'Hero'}),
    new ChoiceOption({label: 'Royal Enfield'}),
    new ChoiceOption({label: 'KTM'}),
    new ChoiceOption({label: 'Harley-Davidson'}),
    new ChoiceOption({label: 'Ducati'}),
    new ChoiceOption({label: 'BMW Motorrad'}),
    new ChoiceOption({label: 'Triumph'}),
    new ChoiceOption({label: 'Benelli'}),
    new ChoiceOption({label: 'Kymco'}),
    new ChoiceOption({label: 'Piaggio'}),
    new ChoiceOption({label: 'Vespa'}),
    new ChoiceOption({label: 'Aprilia'}),
    new ChoiceOption({label: 'Husqvarna'}),
    new ChoiceOption({label: 'Indian'}),
    new ChoiceOption({label: 'Moto Guzzi'}),
    new ChoiceOption({label: 'Zero Motorcycles'}),
    new ChoiceOption({label: 'Can-Am'}),
    new ChoiceOption({label: 'CFMoto'}),
    new ChoiceOption({label: 'SYM'}),
    new ChoiceOption({label: 'KSR Moto'}),
    new ChoiceOption({label: 'Gas Gas'}),
    new ChoiceOption({label: 'MV Agusta'}),
    new ChoiceOption({label: 'Husaberg'}),
    new ChoiceOption({label: 'Daelim'}),
    new ChoiceOption({label: 'Keeway'}),
    new ChoiceOption({label: 'AJP'}),
    new ChoiceOption({label: 'Lifan'}),
    new ChoiceOption({label: 'Mash'}),
    new ChoiceOption({label: 'Zontes'}),
    new ChoiceOption({label: 'Cagiva'}),
    new ChoiceOption({label: 'Norton'}),
    new ChoiceOption({label: 'CPI'}),
    new ChoiceOption({label: 'Rieju'}),
    new ChoiceOption({label: 'UM Motorcycles'}),
    new ChoiceOption({label: 'SWM'}),
    new ChoiceOption({label: 'SYM'}),
    new ChoiceOption({label: 'Oset'}),
    new ChoiceOption({label: 'Vyrus'}),
    new ChoiceOption({label: 'Sherco'}),
    new ChoiceOption({label: 'AJP'}),
    new ChoiceOption({label: 'Skyteam'}),
    new ChoiceOption({label: 'Aeon'}),
    new ChoiceOption({label: 'Bimota'}),
    new ChoiceOption({label: 'Brixton'}),
    new ChoiceOption({label: 'Cleveland CycleWerks'}),
    new ChoiceOption({label: 'FB Mondial'}),
    new ChoiceOption({label: 'Hyosung'}),
    new ChoiceOption({label: 'Keeway'}),
    new ChoiceOption({label: 'Motoguzzi'}),
    new ChoiceOption({label: 'Scomadi'}),
    new ChoiceOption({label: 'Tauris'}),
    new ChoiceOption({label: 'TGB'}),
    new ChoiceOption({label: 'Vectrix'}),
    new ChoiceOption({label: 'Zontes'}),
    new ChoiceOption({label: 'MRT'}),
    new ChoiceOption({label: 'Otras'})

];


const marca = [
    new ChoiceOption({label: 'Toyota'}),
    new ChoiceOption({label: 'Honda'}),
    new ChoiceOption({label: 'Nissan'}),
    new ChoiceOption({label: 'Hyundai'}),
    new ChoiceOption({label: 'Kia'}),
    new ChoiceOption({label: 'Mitsubishi'}),
    new ChoiceOption({label: 'Mazda'}),
    new ChoiceOption({label: 'Volkswagen'}),
    new ChoiceOption({label: 'Chevrolet'}),
    new ChoiceOption({label: 'Ford'}),
    new ChoiceOption({label: 'Mercedes-Benz'}),
    new ChoiceOption({label: 'BMW'}),
    new ChoiceOption({label: 'Audi'}),
    new ChoiceOption({label: 'Subaru'}),
    new ChoiceOption({label: 'Lexus'}),
    new ChoiceOption({label: 'Land Rover'}),
    new ChoiceOption({label: 'Jeep'}),
    new ChoiceOption({label: 'Volvo'}),
    new ChoiceOption({label: 'Peugeot'}),
    new ChoiceOption({label: 'Suzuki'}),
    new ChoiceOption({label: 'Fiat'}),
    new ChoiceOption({label: 'Renault'}),
    new ChoiceOption({label: 'Chrysler'}),
    new ChoiceOption({label: 'Jaguar'}),
    new ChoiceOption({label: 'Maserati'}),
    new ChoiceOption({label: 'Porsche'}),
    new ChoiceOption({label: 'Isuzu'}),
    new ChoiceOption({label: 'Tesla'}),
    new ChoiceOption({label: 'Acura'}),
    new ChoiceOption({label: 'Alfa Romeo'}),
    new ChoiceOption({label: 'Bentley'}),
    new ChoiceOption({label: 'Bugatti'}),
    new ChoiceOption({label: 'Cadillac'}),
    new ChoiceOption({label: 'Dodge'}),
    new ChoiceOption({label: 'Ferrari'}),
    new ChoiceOption({label: 'Genesis'}),
    new ChoiceOption({label: 'Infiniti'}),
    new ChoiceOption({label: 'Lamborghini'}),
    new ChoiceOption({label: 'Lincoln'}),
    new ChoiceOption({label: 'Lotus'}),
    new ChoiceOption({label: 'McLaren'}),
    new ChoiceOption({label: 'Mini'}),
    new ChoiceOption({label: 'Pagani'}),
    new ChoiceOption({label: 'Rolls-Royce'}),
    new ChoiceOption({label: 'Smart'}),
    new ChoiceOption({label: 'Tata Motors'}),
    new ChoiceOption({label: 'Zenvo'}),
    new ChoiceOption({label: 'Otros'})
];

const year = [];
for (let i = 2024; i >= 1980; i--) {
    year.push(new ChoiceOption({label: '' + i + ''}));
}

const ciudades = [
    new ChoiceOption({label: 'CHAMPERICO'}),
    new ChoiceOption({label: 'CHIMALTENANGO'}),
    new ChoiceOption({label: 'CHIQUIMULA'}),
    new ChoiceOption({label: 'CHIQUIMULILLA'}),
    new ChoiceOption({label: 'CHISEC'}),
    new ChoiceOption({label: 'COATEPEQUE'}),
    new ChoiceOption({label: 'COBAN'}),
    new ChoiceOption({label: 'CUILAPA'}),
    new ChoiceOption({label: 'CUILCO'}),
    new ChoiceOption({label: 'ESTANZUELA'}),
    new ChoiceOption({label: 'FRAY BARTOLOME'}),
    new ChoiceOption({label: 'GUATEMALA ZONA 10'}),
    new ChoiceOption({label: 'HUEHUETENANGO'}),
    new ChoiceOption({label: 'IXCAN'}),
    new ChoiceOption({label: 'IXCHIGUAN'}),
    new ChoiceOption({label: 'JACALTENANGO'}),
    new ChoiceOption({label: 'JALAPA'}),
    new ChoiceOption({label: 'JALPATAGUA'}),
    new ChoiceOption({label: 'JUTIAPA'}),
    new ChoiceOption({label: 'LA DEMOCRACIA'}),
    new ChoiceOption({label: 'LA REFORMA'}),
    new ChoiceOption({label: 'LA TINTA'}),
    new ChoiceOption({label: 'LIVINGSTON'}),
    new ChoiceOption({label: 'MALACATAN'}),
    new ChoiceOption({label: 'MAZATENANGO'}),
    new ChoiceOption({label: 'MELCHOR DE MENCOS'}),
    new ChoiceOption({label: 'MONTERrico'}),
    new ChoiceOption({label: 'MORALES'}),
    new ChoiceOption({label: 'MORAZAN'}),
    new ChoiceOption({label: 'NUEVA CONCEPCION'}),
    new ChoiceOption({label: 'NUEVA SANTA ROSA'}),
    new ChoiceOption({label: 'PATULUL'}),
    new ChoiceOption({label: 'POPTUN'}),
    new ChoiceOption({label: 'QUETZALTENANGO'}),
    new ChoiceOption({label: 'RETALHULEU'}),
    new ChoiceOption({label: 'SAN AGUSTIN ACASAGUASTLAN'}),
    new ChoiceOption({label: 'SAN ANTONIO SUCHITEPEQUEZ'}),
    new ChoiceOption({label: 'SAN CRISTOBAL ACASAGUASTLAN'}),
    new ChoiceOption({label: 'SAN MARCOS'}),
    new ChoiceOption({label: 'SAN MIGUEL IXTAHUACAN'}),
    new ChoiceOption({label: 'SANARATE'}),
    new ChoiceOption({label: 'SANTA CRUZ BARILLAS'}),
    new ChoiceOption({label: 'SANTA CRUZ DEL QUICHE'}),
    new ChoiceOption({label: 'SANTA ELENA, PETEN'}),
    new ChoiceOption({label: 'SAYAXCHE'}),
    new ChoiceOption({label: 'SIBILIA'}),
    new ChoiceOption({label: 'SOLOLA'}),
    new ChoiceOption({label: 'SOLOMA'}),
    new ChoiceOption({label: 'TECULUTAN'}),
    new ChoiceOption({label: 'TOTONICAPAN'}),
    new ChoiceOption({label: 'TIQUISATE'}),
    new ChoiceOption({label: 'Pachalum'}),
    new ChoiceOption({label: 'San Andres Villa Seca'}),
    new ChoiceOption({label: 'TECUN UMAN'}),
    new ChoiceOption({label: 'San Luis, Peten'}),
    new ChoiceOption({label: 'Tectitán'}),
    new ChoiceOption({label: 'Los Amates'}),
    new ChoiceOption({label: 'La Unión'}),
    new ChoiceOption({label: 'San Rafael la independencia'}),
];


// Reconstruir las opciones con las etiquetas transformadas
const ciudadesTransformados = ciudades.map(ciudad => {
    const label = ciudad.label.trim().toUpperCase();
    return new ChoiceOption({label});
});

// Ordenar alfabéticamente por la propiedad label
ciudadesTransformados.sort((a, b) => a.label.localeCompare(b.label));


const colores = [
    new ChoiceOption({label: 'Blanco'}),
    new ChoiceOption({label: 'Negro'}),
    new ChoiceOption({label: 'Gris'}),
    new ChoiceOption({label: 'Plateado'}),
    new ChoiceOption({label: 'Azul'}),
    new ChoiceOption({label: 'Rojo'}),
    new ChoiceOption({label: 'Verde'}),
    new ChoiceOption({label: 'Beige'}),
    new ChoiceOption({label: 'Marrón'}),
    new ChoiceOption({label: 'Amarillo'}),
    new ChoiceOption({label: 'Naranja'}),
    new ChoiceOption({label: 'Morado'}),
    new ChoiceOption({label: 'Rosa'}),
    new ChoiceOption({label: 'Turquesa'}),
    new ChoiceOption({label: 'Bronce'}),
    new ChoiceOption({label: 'Plata'}),
    new ChoiceOption({label: 'Oro'}),
    new ChoiceOption({label: 'Granate'}),
    new ChoiceOption({label: 'Cobre'}),
    new ChoiceOption({label: 'Champán'}),
    new ChoiceOption({label: 'Azul marino'}),
    new ChoiceOption({label: 'Verde oscuro'}),
    new ChoiceOption({label: 'Gris oscuro'}),
    new ChoiceOption({label: 'Rojo cereza'}),
    new ChoiceOption({label: 'Violeta'}),
    new ChoiceOption({label: 'Turquesa'}),
    new ChoiceOption({label: 'Marfil'}),
    new ChoiceOption({label: 'Carmesí'}),
    new ChoiceOption({label: 'Salmon'}),
    new ChoiceOption({label: 'Púrpura'}),
    new ChoiceOption({label: 'Gris claro'}),
    new ChoiceOption({label: 'Gris plateado'}),
    new ChoiceOption({label: 'Azul cielo'}),
    new ChoiceOption({label: 'Azul eléctrico'}),
    new ChoiceOption({label: 'Azul zafiro'}),
    new ChoiceOption({label: 'Rojo rubí'}),
    new ChoiceOption({label: 'Rojo vino'}),
    new ChoiceOption({label: 'Verde esmeralda'}),
    new ChoiceOption({label: 'Verde lima'}),
    new ChoiceOption({label: 'Verde oliva'}),
    new ChoiceOption({label: 'Marrón claro'}),
    new ChoiceOption({label: 'Marrón oscuro'}),
    new ChoiceOption({label: 'Amarillo mostaza'}),
    new ChoiceOption({label: 'Amarillo dorado'}),
    new ChoiceOption({label: 'Naranja quemado'}),
    new ChoiceOption({label: 'Naranja brillante'}),
    new ChoiceOption({label: 'Rosado claro'}),
    new ChoiceOption({label: 'Rosado oscuro'}),
    new ChoiceOption({label: 'Gris pardo'}),
    new ChoiceOption({label: 'Gris acero'}),
    new ChoiceOption({label: 'Gris pizarra'}),
];


export default {
    name: 'ServiceDetails',
    components: {
        settingLayout,
        FlowForm,
        Question,
        QuestionType,
        LanguageModel,
        FormWizard,
        TabContent,
        WizardStep,
        VPerfectSignature,
        FileUploader

    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    async created() {
        const self = this;
        try {

            API.send('POST', 'products/public/search/' + self.AuthGetUserInfo.s + '/' + self.id, {
                    filterTermBy: 'id',
                },
                function (response) {
                    if (response.status && typeof response.data.products[0] !== 'undefined' && typeof response.data.products[0].variaciones !== 'undefined' && response.data.products[0].sku !== 'AERH' && response.data.products[0].sku !== 'AEPH'&& response.data.products[0].sku !== 'VEMI') {
                        const variaciones = response.data.products[0].variaciones;
                        console.log(variaciones);

                        const planes = [];
                        if (variaciones) {
                            variaciones.forEach(variacion => {
                                const label = `${variacion.descripcion} (Q ${variacion.precio})`;
                                const value = variacion.descripcion.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                planes.push(choiceOption);
                            });
                        }
                        self.questions = [
                            new QuestionModel({
                                id: 'Plan',
                                answer: '',
                                tagline: '',
                                title: 'Seleccione el plan',
                                type: QuestionType.MultipleChoice,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: planes,
                            }),
                            new QuestionModel({
                                id: 'clienteNombre',
                                tagline: '',
                                title: 'Nombre completo',
                                type: QuestionType.Text,
                                required: true,
                                answer: self.formularioData.clienteNombre,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                id: 'expediente',
                                tagline: "",
                                title: 'NIS/Exp',
                                type: QuestionType.Text,
                                required: true,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                tagline: '',
                                id: 'DPI',
                                title: 'Ingresa DPI',
                                mask: '#### ##### ####',
                                type: QuestionType.Text,
                                required: true,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                tagline: 'Ingresa NIT sin guión...',
                                id: 'clienteTaxId',
                                title: '¿A quién emitimos la factura?',
                                description: self.searchResults ? '' : 'NIT no encontrado en la SAT.',
                                type: QuestionType.MultipleChoice,
                                options: NITs,
                                required: true,
                                mask: '#######XX',
                                allowOther: true
                            }),
                            new QuestionModel({
                                id: 'clienteEmail',
                                tagline: "",
                                title: '¿Correo electrónico?',
                                type: QuestionType.Email,
                                required: false,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                id: 'clienteTelefono',
                                tagline: "",
                                title: 'Teléfono',
                                type: QuestionType.Phone,
                                answer: self.formularioData.clienteTelefono,
                                required: true,
                                mask: '####-####'
                            }),
                            new QuestionModel({
                                id: 'Tipo',
                                answer: self.formularioData.Tipo,
                                tagline: '',
                                title: '¿Tipo de vehículo?',
                                type: QuestionType.MultipleChoice,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: true,
                                required: true,
                                allowOther: false,
                                options: automoviles,
                                jump: {
                                    Motocicleta: 'placa_b',
                                }
                            }),
                            new QuestionModel({
                                id: 'placa_a',
                                tagline: '',
                                title: 'Placas del vehículo',
                                type: QuestionType.Text,
                                required: true,
                                answer: self.formularioData.placa_a || 'P-',
                                mask: 'X-##XXXX',
                                jump: {
                                    _other: 'linea',
                                }
                            }),
                            new QuestionModel({
                                id: 'placa_b',
                                tagline: '',
                                title: 'Placas del vehículo',
                                type: QuestionType.Text,
                                mask: 'X-##XXXX',
                                required: true,
                                answer: self.formularioData.placa_b || 'M-',
                            }),
                            new QuestionModel({
                                id: 'lineaM',
                                answer: self.formularioData.lineaM,
                                tagline: '',
                                title: '¿Marca de la Motocicleta?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: marcaM,
                                jump: {
                                    _other: 'color',
                                }
                            }),
                            new QuestionModel({
                                id: 'linea',
                                answer: self.formularioData.linea,
                                tagline: '',
                                title: '¿Marca del vehículo?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: marca,
                            }),
                            new QuestionModel({
                                id: 'color',
                                answer: self.formularioData.color,
                                tagline: '',
                                title: 'Color del vehículo',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: colores

                            }),
                            new QuestionModel({
                                id: 'year',
                                answer: self.formularioData.year,
                                tagline: '',
                                title: '¿Año del vehículo?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: year,
                            }),
                            new QuestionModel({
                                id: 'agencia',
                                answer: '',
                                tagline: '',
                                title: 'Agencia',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: ciudadesTransformados,
                            }),

                        ];
                        self.questionsLoaded = true;

                        self.skuProducto = response.data.products[0].sku;
                        self.variaciones = response.data.products[0].variaciones;
                        self.agencias = ciudadesTransformados;

                        if (self.variaciones) {
                            self.variaciones.forEach(variacion => {
                                const label = `${variacion.nombre} (Q ${variacion.precio})`;
                                const value = variacion.nombre.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                self.planesVariaciones.push(choiceOption);
                            });
                        }
                    }
                    else if (response.status && typeof response.data.products[0] !== 'undefined' && response.data.products[0].sku === 'AERH') {
                        //console.log('hola');
                        self.skuProducto = response.data.products[0].sku;
                        self.variaciones = response.data.products[0].variaciones;
                        self.agencias = ciudadesTransformados;
                        if (self.variaciones) {
                            self.variaciones.forEach(variacion => {
                                const label = `${variacion.nombre} (Q ${variacion.precio})`;
                                const value = variacion.nombre.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                self.planesVariaciones.push(choiceOption);
                            });
                        }
                        console.log(self.variaciones);
                        //API.send('GET', 'products/public/brand-by-slug/'+store.getters.StoreInfo.uriTienda+'/category/all', {},
                        let endpoint = 'https://ecws.abkbusiness.com/api/base/generate/61';
                        store.dispatch('coreShowLoading');
                        // Realiza la solicitud GET con Axios
                        axios.get(endpoint)
                            .then(response => {
                                let idProducto = 0;
                                if(self.skuProducto === 'AERH'){
                                    idProducto = 61;
                                }
                                if(self.skuProducto === 'AEPH'){
                                    idProducto = 63;
                                }
                                if(self.skuProducto === 'VEMI'){
                                    idProducto = 64;
                                }
                                // Maneja la respuesta si la petición fue exitosa
                                let hash = response.data.records.hash;
                                self.cotizacionHash = hash;
                                let endpoint = 'https://ecws.abkbusiness.com/api/base/form/' + hash +'/'+ idProducto;
                                store.dispatch('coreShowLoading');
                                // Realiza la solicitud GET con Axios
                                axios.get(endpoint)
                                    .then(response => {
                                        // Maneja la respuesta si la petición fue exitosa
                                        self.fields = response.data.records;

                                        store.dispatch('coreHideLoading');
                                        console.log(response.data.records)

                                    })
                                    .catch(error => {
                                        // Maneja el error si la petición falla
                                        console.error(error);
                                        // Muestra una notificación de error si es necesario
                                        // API.showErrorNotify(error.message);
                                    });
                            })
                            .catch(error => {
                                // Maneja el error si la petición falla
                                console.error(error);
                                // Muestra una notificación de error si es necesario
                                // API.showErrorNotify(error.message);
                            });
                    }
                    else if (response.status && typeof response.data.products[0] !== 'undefined' && response.data.products[0].sku === 'AEPH') {

                        self.skuProducto = response.data.products[0].sku;
                        self.variaciones = response.data.products[0].variaciones;
                        self.agencias = ciudadesTransformados;
                        console.log(self.skuProducto);
                        self.planSelected = ((self.skuProducto === 'AERH')?'': ((self.skuProducto === 'AEPH')?'seguro hogar mensual':'seguro migrante'));
                        console.log(self.planSelected);
                        if (self.variaciones) {
                            self.variaciones.forEach(variacion => {
                                const label = `${variacion.nombre} (Q ${variacion.precio})`;
                                const value = variacion.nombre.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                self.planesVariaciones.push(choiceOption);
                            });
                        }
                        console.log(self.variaciones);
                        //API.send('GET', 'products/public/brand-by-slug/'+store.getters.StoreInfo.uriTienda+'/category/all', {},
                        let endpoint = 'https://ecws.abkbusiness.com/api/base/generate/63';
                        store.dispatch('coreShowLoading');
                        // Realiza la solicitud GET con Axios
                        axios.get(endpoint)
                            .then(response => {
                                let idProducto = 0;
                                if(self.skuProducto === 'AERH'){
                                    idProducto = 61;
                                }
                                if(self.skuProducto === 'AEPH'){
                                    idProducto = 63;
                                }
                                if(self.skuProducto === 'VEMI'){
                                    idProducto = 64;
                                }
                                // Maneja la respuesta si la petición fue exitosa
                                let hash = response.data.records.hash;
                                self.cotizacionHash = hash;
                                let endpoint = 'https://ecws.abkbusiness.com/api/base/form/' + hash + '/' + idProducto;
                                store.dispatch('coreShowLoading');
                                // Realiza la solicitud GET con Axios
                                axios.get(endpoint)
                                    .then(response => {
                                        // Maneja la respuesta si la petición fue exitosa
                                        self.fields = response.data.records;
                                        store.dispatch('coreHideLoading');
                                        console.log(response.data.records)

                                    })
                                    .catch(error => {
                                        // Maneja el error si la petición falla
                                        console.error(error);
                                        // Muestra una notificación de error si es necesario
                                        // API.showErrorNotify(error.message);
                                    });
                            })
                            .catch(error => {
                                // Maneja el error si la petición falla
                                console.error(error);
                                // Muestra una notificación de error si es necesario
                            });
                    }
                    else if (response.status && typeof response.data.products[0] !== 'undefined' && response.data.products[0].sku === 'VEMI') {

                        self.skuProducto = response.data.products[0].sku;
                        self.variaciones = response.data.products[0].variaciones;
                        self.agencias = ciudadesTransformados;
                        console.log(self.skuProducto);
                        self.planSelected = ((self.skuProducto === 'VEMI')?'': ((self.skuProducto === 'VEMI')?'seguro hogar mensual':''));
                        console.log(self.planSelected);
                        if (self.variaciones) {
                            self.variaciones.forEach(variacion => {
                                const label = `${variacion.nombre} (Q ${variacion.precio})`;
                                const value = variacion.nombre.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                self.planesVariaciones.push(choiceOption);
                            });
                        }
                        //console.log(self.variaciones);
                        //API.send('GET', 'products/public/brand-by-slug/'+store.getters.StoreInfo.uriTienda+'/category/all', {},
                        let endpoint = 'https://ecws.abkbusiness.com/api/base/generate/64';
                        store.dispatch('coreShowLoading');
                        // Realiza la solicitud GET con Axios
                        axios.get(endpoint)
                            .then(response => {
                                let idProducto = 0;
                                if(self.skuProducto === 'VEMI'){
                                    idProducto = 64;
                                }

                                // Maneja la respuesta si la petición fue exitosa
                                let hash = response.data.records.hash;
                                self.cotizacionHash = hash;
                                let endpoint = 'https://ecws.abkbusiness.com/api/base/form/' + hash + '/' + idProducto;
                                store.dispatch('coreShowLoading');
                                // Realiza la solicitud GET con Axios
                                axios.get(endpoint)
                                    .then(response => {
                                        // Maneja la respuesta si la petición fue exitosa
                                        self.fields = response.data.records;

                                        store.dispatch('coreHideLoading');
                                        console.log(response.data.records)

                                    })
                                    .catch(error => {
                                        // Maneja el error si la petición falla
                                        console.error(error);
                                        // Muestra una notificación de error si es necesario
                                        // API.showErrorNotify(error.message);
                                    });
                            })
                            .catch(error => {
                                // Maneja el error si la petición falla
                                console.error(error);
                                // Muestra una notificación de error si es necesario
                                // API.showErrorNotify(error.message);
                            });
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        } catch (error) {
            API.showErrorNotify(error.msg);
        }
    },
    watch: {
        'formDataToSend.tipoVehiculo'(newValue) {
            this.formDataToSend.placa_a = newValue === 'auto' ? 'P-' : 'M-';
        },
        date: function (value) {
            if (value) {
                this.getDisponibilidad();
                this.hideDateSelector = true;
            }
        },
        municipioLocationCobertura: function (value) {
            if (value) {
                this.getDisponibilidad();
            }
        },
        planSelected(newPlan) {
            this.handlePlanChange(newPlan);
        },
        locacion: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
        personal: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
    },

    methods: {
        getFrecuencias() {
            return this.AuthGetUserInfo.s === 'telemercadeo.comasa.com.gt' ? [
                {
                    title: 'Semestral',
                    value: 'semestral',
                    icon: 'fa fa-signature',
                },
                {
                    title: 'Anual',
                    value: 'anual',
                    icon: 'fa fa-signature',
                }
            ] : [
                {
                    title: 'Mensual',
                    value: 'mensual',
                    icon: 'fa fa-info',
                },
                {
                    title: 'Bimensual',
                    value: 'bimensual',
                    icon: 'fa fa-user',
                },
                {
                    title: 'Trimestral',
                    value: 'trimestral',
                    icon: 'fa fa-users',
                },
                {
                    title: 'Semestral',
                    value: 'semestral',
                    icon: 'fa fa-signature',
                },
                {
                    title: 'Anual',
                    value: 'anual',
                    icon: 'fa fa-signature',
                }
            ];
        },
        updateTabsSelected() {


            // Si el plan seleccionado es 'plan familiar', agregar el tab de conyuge
            console.log(this.planSelected);

        },
        buscarPDF() {
            this.pdfUrl = true;

            // Construcción dinámica del URL con parámetros
            const params = new URLSearchParams();
            if (this.searchTerm) params.append("dpi", this.searchTerm);
            if (this.skuProducto) params.append("sku", this.skuProducto);

            const searchUrl = `orders/dpi-buscar/reimprimir?${params.toString()}`;

            API.send(
                "GET",
                searchUrl,
                {},
                (response) => {
                    if (response.status !== 0) {
                        if (response.data.length === 0) {
                            API.showErrorAlert("DPI inválido");
                        } else {
                            API.showSuccessAlert("DPI encontrado");
                            this.fields = response.data.contenidoEnviado;
                            this.planSelected = response.data.planSelected;
                            this.dataClienteReimpresion = response.data;
                            this.pdfUrl = true;
                        }
                    } else {
                        this.searchResults = false;
                        API.showSuccessAlert("Este DPI ya se encuentra inscrito con este producto");
                    }
                },
                (error) => {
                    this.searchResults = false;
                    API.showErrorNotify(error.msg || "Error desconocido al buscar PDF.");
                }
            );
        },
        validateDPIByProduct(dpi){
            const self = this;
            if(self.skuProducto === 'AERH'){
                const params = new URLSearchParams();
                if (dpi) params.append("dpi", dpi);
                if (this.skuProducto) params.append("sku", this.skuProducto);

                const searchUrl = `orders/dpi-buscar/reimprimir?${params.toString()}`;
               // const searchUrl = 'orders/dpi-buscar/cotizacion/'+dpi+'/'+ self.skuProducto;
                API.send('GET', searchUrl, {},
                    function (response) {
                        if (response.status !== 0) {
                            if (response.data.length === 0) {
                                API.showSuccessAlert('DPI válido');
                                self.searchResults = true
                            }
                            else {
                                API.showErrorAlert('Este DPI ya se encuentra inscrito con este producto');

                                //return false;
                            }
                        }
                        else {
                            self.searchResults = false
                            API.showErrorAlert('Este DPI ya se encuentra inscrito con este producto');
                            return false;
                        }
                    },
                    function (data) {
                        API.showSuccessAlert('DPI válido');
                        self.searchResults = true
                        //return false;
                    });
            }

        },
        validateAndNext(props) {
            console.log(props.activeTabIndex)
            if (this.validateFieldsInActiveTab(props)) {
                props.nextTab();
            } else {
                API.showErrorNotify('Por favor llena todos los campos obligatorios')
            }
        },

        validateFields() {
            let isValid = true;
            this.fields.forEach(section => {
                section.json.forEach(field => {
                    if (field.required === '1' && !field.value) {
                        field.hasError = true;
                        isValid = false;
                    } else {
                        field.hasError = false;
                    }
                });
            });
            return isValid;
        },
        visible() {
            return visible
        },
        hideEmailInput(field ) {
            this.hideInputEmail = true;
            if(field){
                field.value = 'mherrera@abkbusiness.com';
            }

        },
        Numbro,
        dayjs,

        continueAfterCart() {
            location.reload();
        },
        setConsumidorFinal(obj) {
            this.notieneNIT = true;
            obj.value = 'CF';
        },
        calculateAge(date) {
            // Dividir la cadena de fecha en componentes de día, mes y año
            const [year, month, day] = date.split('-').map(Number);
            console.log(date);
            console.log(day);
            console.log(month);
            console.log(year);
            // Crear el objeto Date con los componentes de año, mes (restando 1 porque los meses son 0-indexados), y día
            const birthDate = new Date(year, month - 1, day);

            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();

            // Ajustar la edad si el mes actual es anterior al mes de nacimiento,
            // o si es el mismo mes pero el día actual es anterior al día de nacimiento
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            return age;
        },
        validateAge(field) {
            const age = this.calculateAge(field.value);
            field.hasError = !(age >= this.reglasDeNegocio[this.planSelected]['minAge'] && age <= this.reglasDeNegocio[this.planSelected]['maxAge']);
        },
        getValueByNombreInterno(nombreInterno, obj) {
            if (!obj) return null;

            if (obj.nombre_interno === nombreInterno) {
                return obj;
            }

            // Si el objeto actual tiene un array 'json', realiza una búsqueda recursiva en cada elemento
            if (Array.isArray(obj.json)) {
                for (let item of obj.json) {
                    const foundObject = this.getValueByNombreInterno(nombreInterno, item);
                    if (foundObject) {
                        return foundObject;
                    }
                }
            }

            // Si el objeto actual tiene otras propiedades, realiza una búsqueda recursiva en ellas
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    const foundObject = this.getValueByNombreInterno(nombreInterno, obj[key]);
                    if (foundObject) {
                        return foundObject;
                    }
                }
            }

            // Si no se encuentra el nombre interno en ningún lugar, devuelve null
            return null;
        },
        replaceValueByNombreInterno(nombreInterno, obj, text) {
            if (!obj) return null;

            if (obj.nombre_interno === nombreInterno) {
                obj.value = text;
                return obj;
            }

            // Si el objeto actual tiene un array 'json', realiza una búsqueda recursiva en cada elemento
            if (Array.isArray(obj.json)) {
                for (let item of obj.json) {
                    const foundObject = this.replaceValueByNombreInterno(nombreInterno, item, text);
                    if (foundObject) {
                        foundObject.value = text;
                        return foundObject;
                    }
                }
            }

            // Si el objeto actual tiene otras propiedades, realiza una búsqueda recursiva en ellas
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    const foundObject = this.replaceValueByNombreInterno(nombreInterno, obj[key], text);
                    if (foundObject) {
                        foundObject.value = text;
                        return foundObject;
                    }
                }
            }

            // Si no se encuentra el nombre interno en ningún lugar, devuelve null
            return null;
        },
        handlePlanChange(newPlan) {
            if (newPlan === 'plan familiar' && this.skuProducto === 'AERH') {
                // Agregar el tab de datos del cónyuge si el plan seleccionado es 'plan familiar'
                this.addTabForConyuge();
            }
            else if (this.skuProducto === 'AERH') {
                // Si el plan seleccionado no es 'plan familiar', quitar el tab de los datos del cónyuge
                this.removeTabForConyuge();
            }
        },
        addTabForConyuge() {
            // Verificar si el tab de los datos del cónyuge ya está presente
            const isConyugeTabPresent = this.tabsSeleccionadas.some(tab => tab.value === 'conyuge');
            // Si no está presente, agregarlo
            if (!isConyugeTabPresent) {
                this.tabsSeleccionadas.push({
                    title: 'Datos del cónyuge',
                    value: 'conyuge',
                    icon: 'fa fa-users',
                });
            }
        },
        removeTabForConyuge() {
            // Filtrar el tab de los datos del cónyuge y quitarlo del array
            this.tabsSeleccionadas = this.tabsSeleccionadas.filter(tab => tab.value !== 'conyuge');
        },
        signatureGetImage(signaturePadRef) {
            let dataUrl = '';
            if (typeof this.$refs[signaturePadRef] !== 'undefined') {
                if (typeof this.$refs[signaturePadRef][0] !== 'undefined') {
                    dataUrl = this.$refs[signaturePadRef][0].toDataURL('image/png');
                }
            }
            return dataUrl;
        },
        signatureEnd(signaturePadRef, fieldId) {
            fieldId.value = this.signatureGetImage(signaturePadRef);
        },
        validateField(fields) {
            // Recorre todos los campos y verifica si están llenos
            for (const field of fields) {
                if (!field.value) {
                    return false; // Si algún campo está vacío, retorna falso
                }
            }
            return true; // Si todos los campos están llenos, retorna verdadero
        },
        validateFieldsInActiveTab(props) {
            let isValid = true;
            const activeTab = props.activeTabIndex;
            const self = this;

            if (activeTab === null) return false;

            if (activeTab === 1) {
                const requiredFields = [
                    'nombret', 'papellidot', 'sapellidot', 'input_dpi', 'telt', 'fecnact',
                    'tgenero', 'direccion', 'input_nit', 'ocupt'
                ];

                for (const field of requiredFields) {
                    const fieldValue = self.getValueByNombreInterno(field, self.fields);
                    if ((fieldValue && !fieldValue.value) || (fieldValue && fieldValue.hasError)) {
                        isValid = false;
                    } else if (fieldValue && fieldValue.value) {
                        fieldValue.hasError = false;
                    }
                }
            }

            if (activeTab === 2) {
                const requiredFields = [
                    'telcony', 'fecnacf1', 'sapelledif1', 'papellidof1', 'nombref1'
                ];
                this.replaceValueByNombreInterno('fecnacf1', this.fields, this.conyugeFechaNacimiento.value);
                for (const field of requiredFields) {
                    const fieldValue = this.getValueByNombreInterno(field, self.fields);
                    if ((fieldValue && !fieldValue.value) || (fieldValue && fieldValue.hasError)) {
                        isValid = false;
                    } else if (fieldValue && fieldValue.value) {
                        fieldValue.hasError = false;
                    }
                }
            }

            return isValid;
        },
        onComplete(props) {
            const self = this;
            console.log(props.activeTabIndex)
            if(this.validateFieldsInActiveTab(props)){
                this.cartNotice.status = true;
                let endpoint = '';
                let endpoint2 = '';
                if(self.skuProducto === 'AERH'){
                    endpoint = 'https://ecws.abkbusiness.com/api/base/workflow/61';
                    endpoint2 = 'https://ecws.abkbusiness.com/api/base/cotizacion/61';
                }
                else if(self.skuProducto === 'AEPH'){
                    endpoint = 'https://ecws.abkbusiness.com/api/base/workflow/63';
                    endpoint2 = 'https://ecws.abkbusiness.com/api/base/cotizacion/63';
                }
                else if(self.skuProducto === 'VEMI'){
                    endpoint = 'https://ecws.abkbusiness.com/api/base/workflow/64';
                    endpoint2 = 'https://ecws.abkbusiness.com/api/base/cotizacion/64';
                }
                else{
                    endpoint = 'https://ecws.abkbusiness.com/api/base/workflow';
                    endpoint2 = 'https://ecws.abkbusiness.com/api/base/cotizacion';
                }


                store.dispatch('coreShowLoading');
                let hash = self.cotizacionHash;
                const codPais = this.replaceValueByNombreInterno('input_1644444650895', this.fields, 'GT');
                this.replaceValueByNombreInterno('input_1643735533735', this.fields, 'GT');
                const codPaisAEPH = this.replaceValueByNombreInterno('input_1643990202216', this.fields, 'GT');
                const codEstado = this.replaceValueByNombreInterno('input_1644444720616', this.fields, '1');
                this.replaceValueByNombreInterno('input_1643735937535', this.fields, '001');
                this.replaceValueByNombreInterno('input_1643735853430', this.fields, '1');
                this.replaceValueByNombreInterno('input_1643735906096', this.fields, '101');
                const codEstadoAEPH = this.replaceValueByNombreInterno('input_1643990263362', this.fields, '1');
                const codCiudao = this.replaceValueByNombreInterno('input_1644444756007', this.fields, '101');
                const codCiudaoAPH = this.replaceValueByNombreInterno('input_1643990307064', this.fields, '101');
                const codMunicipio = this.replaceValueByNombreInterno('input_1644444809456', this.fields, '001');
                const codMunicipioAEPH = this.replaceValueByNombreInterno('input_1643990335015', this.fields, '001');
                const montopago = this.replaceValueByNombreInterno('montopago', this.fields, this.reglasDeNegocio[this.planSelected]['mensual']);
                this.replaceValueByNombreInterno('input_1728316323036', this.fields, this.reglasDeNegocio[this.planSelected]['mensual']);
                //const SUMAASEG =  this.replaceValueByNombreInterno('input_1644442922893', this.fields, this.reglasDeNegocio[this.planSelected]['prima']);
                const SUMAASEG = this.replaceValueByNombreInterno('input_1644442922893', this.fields, 0);
                const nombres = this.getValueByNombreInterno('nombret', this.fields);
                const nomter = this.replaceValueByNombreInterno('input_1644443155256', this.fields, nombres.value);
                const nomterAEPH = this.replaceValueByNombreInterno('input_1643926015895', this.fields, nombres.value);
                const nomterP = this.replaceValueByNombreInterno('input_1644443510390', this.fields, nombres.value);
                const nomterPAEPH = this.replaceValueByNombreInterno('input_1643926087222', this.fields, nombres.value);
                //const nomterP1 =  this.replaceValueByNombreInterno('input_1644442854030', this.fields, '001001');
                const plancoti = this.replaceValueByNombreInterno('plancoti', this.fields, this.reglasDeNegocio[this.planSelected]['plancoti']);
                const plancotiZ = this.replaceValueByNombreInterno('plan_coti', this.fields, this.reglasDeNegocio[this.planSelected]['plancoti']);
                const frecPago = this.replaceValueByNombreInterno('frecdepag', this.fields, (this.skuProducto !=='VEMI')?'LP0012':'LP0016');
                const frecPagoAEPH = this.replaceValueByNombreInterno('input_1641998639439', this.fields, (this.skuProducto !=='VEMI')?'LP0012':'LP0016');
                const frecPagoVEMI = this.replaceValueByNombreInterno('input_1643750356815', this.fields, 'LP0016');
                const nacimientoVemi = this.replaceValueByNombreInterno('FecnacDep', this.fields, new Date(1990, 0, 1));
                const frecPago3 = this.replaceValueByNombreInterno('plan_fracc', this.fields, 'LP0013');
                const frecPago2 = this.replaceValueByNombreInterno('plan_fracc', this.fields, (this.skuProducto !=='VEMI')?'LP0012':'LP0016');
                const parentescoF1 = this.replaceValueByNombreInterno('parenf1', this.fields, this.conyugeParentesco);
                const fechaNacimiento = this.replaceValueByNombreInterno('fecnacf1', this.fields, this.conyugeFechaNacimiento.value);
                const genero = this.replaceValueByNombreInterno('genef1', this.fields, this.conyugeGenero);




                let secciones = self.fields;
                let data = {
                    cotizacion_id: hash,
                    pago: false,
                    locacion: self.locacionId,
                    expediente: self.expediente,
                    sections: secciones,
                }
                self.dataEnviada = data;

                // Realiza la solicitud GET con Axios
                axios.post(endpoint2, data)
                    .then(response => {
                        // Maneja la respuesta si la petición fue exitosa
                        console.log(response.data);

                        axios.post(endpoint, data)
                            .then(response => {
                                // Maneja la respuesta si la petición fue exitosa
                                console.log(response.data);
                                self.newSuscription = response.data.records.poliza;
                                if( self.newSuscription !== ''){
                                    self.sendCotizacion();
                                }
                                store.dispatch('coreHideLoading');
                                // Realiza la solicitud GET con Axios
                            })
                            .catch(error => {
                                // Maneja el error si la petición falla
                                console.error(error);
                                // Muestra una notificación de error si es necesario
                                // API.showErrorNotify(error.message);
                            });
                    })
                    .catch(error => {
                        // Maneja el error si la petición falla
                        console.error(error);
                        // Muestra una notificación de error si es necesario
                        // API.showErrorNotify(error.message);
                    });
                // Realiza la solicitud GET con Axios
            }
            else{
                API.showErrorNotify('Por favor llena todos los campos obligatorios')
            }


        },
        calcularFechas(dias) {
            const fechaActual = dayjs();
            const fechaFutura = fechaActual.add(dias, 'day');
            this.fechaFutura = fechaFutura.format('YYYY-MM-DD');
            //console.log(this.fechaFutura);
        },
        sendEmailWithTemplate(url = 'url') {
            const self = this;
            const formData = new FormData();
            let emailData = {};
            const tel = this.getValueByNombreInterno('telt', this.fields);
            let message = '';
            if(self.skuProducto === 'AERH'){
                const correo = self.getValueByNombreInterno('input_email', self.fields);
                const nombres = self.getValueByNombreInterno('nombret', self.fields);
                const apellidos = self.getValueByNombreInterno('papellidot', self.fields);
                const tel = self.getValueByNombreInterno('telt', self.fields);
                message = 'ENERGUATE le da la Bienvenida al Seguro de Hospital Seguro . Le entregamos una renta  por c/noche que este hospitalizado. Inf  2306-1238. Puedes consultar tu kit de bienvenida en el siguiente link: ' + url;
                // Datos del correo electrónico
                formData.append('from', 'Energuate  <hospitalseguro@abkses.com>');
                formData.append('to', correo.value);
                formData.append('subject', 'Póliza No.  AERH - ' + self.newSuscription + ' - Hospital Seguro  ');
                formData.append('template', 'comasa_bienvenida_guateseguro_rdh');
                formData.append('v:nombreCliente', nombres.value + ' ' + apellidos.value);
                formData.append('v:noPoliza', 'AERH -' + self.newSuscription);
                formData.append('v:kitBienvenida', url);
            }
            if(self.skuProducto === 'VEMI'){
                const correo = self.getValueByNombreInterno('email', self.fields);
                //console.log('Jola' + correo);
                const nombres = self.getValueByNombreInterno('nombret', self.fields);
                const apellidos = self.getValueByNombreInterno('papellidot', self.fields);
                message = 'ENERGUATE le da la Bienvenida a Migrante Seguro.  Ud esta protegido  por muerte por cualquier causa.   Inf  2306-1238. Puedes consultar tu kit de bienvenida en el siguiente link: ' + url;
                emailData = {
                    from: 'Energuate  <migranteseguro@abkses.com>',
                    to: correo.value,
                    subject: 'Póliza No.  VEMI - ' + self.newSuscription + ' - Migrante Seguro',
                    data: {
                        title: "Bienvenido a Migrante seguro",
                        background_color: "#FFFFFF",
                        header_background_color: "#ffffff",
                        logo_url: "https://blog.elroble.com/hs-fs/hubfs/Logo%20El%20Roble.png?width=251&height=144&name=Logo%20El%20Roble.png",
                        logo_width: 250,
                        title_color: "#555555",
                        email_title: "Bienvenido a Migrante Seguro",
                        text_color: "#333333",
                        email_body: "Señor (a) <br>" + nombres.value + " " + apellidos.value + ",<br><br>" +
                            "Estimado (a) Asegurado (a):<br><br>" +
                            "Seguros El Roble te agradece tu confianza y preferencia al adquirir tu Seguro Migrante Seguro con asistencia médica y funeraria para el titular y tu familiar migrante asegurado. Por medio del presente te hacemos llegar la papelería correspondiente a la póliza de seguro No. " + "VEMI - " + self.newSuscription + " que adquiriste por medio de una Agencia de Energuate. Te recomendamos leer las Condiciones Generales para conocer los beneficios, obligaciones, exclusiones y responsabilidades tanto tuyas como de la aseguradora.<br><br>" +
                            "Para más información, comunícate con Servicio al cliente al número 2306-1238.<br><br>" +
                            "Saludos,<br><br>" +
                            "SEGUROS EL ROBLE",
                        button_text: "Descargar Condiciones Generales",
                        button_url: url,
                        button_color: "#EAAA21",
                        footer_text: "Seguros el Roble \n7 ave 5-10 Zona 4, Centro Financiero, Torre II, nivel 16, Ciudad de Guatemala",
                        footer_text_color: "#575757"
                    }
                };
            }
            API.send('POST', 'communications/send/single-sms', {
                    to: tel.value,
                    message: message,
                    api: 'cmv',

                },
                function (response) {
                   // console.log(response)

                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
            if(typeof(emailData.to) !== 'undefined' && emailData.to !== ''){
                API.send('POST', 'communications/send/single-email', emailData,
                    function(response) {
                       // console.log(response)
                    },
                    function(response) {
                        API.showErrorNotify(response.msg); // Error handling
                    });
            }
        },
        getProduct(idProducto) {

            const self = this;

            // obtengo lsa categorías
            API.send('POST', 'products/public/search/' + self.AuthGetUserInfo.s + '/' + idProducto, {
                    filterTermBy: 'id',
                },
                function (response) {
                    if (response.status && typeof response.data.products[0] !== 'undefined') {
                        self.product = response.data.products[0];
                        self.product = response.data.products[0];
                        self.formularioData.ordenTiendaDomain = self.AuthGetUserInfo.s;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        getCategories(category) {
            const self = this;
            let endpoint;
            endpoint = 'products/public/search/' + self.AuthGetUserInfo.s + '/category/' + category;

            // obtengo las categorías
            //API.send('GET', 'products/public/brand-by-slug/'+store.getters.StoreInfo.uriTienda+'/category/all', {},
            API.send('GET', endpoint, {},
                function (response) {
                    if (response.status) {
                        self.categories = response.data.filter(
                            (item) => item.conteoProductos === 1
                        );
                        //self.$store.dispatch('CategoryListSave', response.data)
                        //self.getProducts();
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        onSubmit() {
            const self = this;
            const productoV= this.planesVariaciones.find(producto => producto.value === this.planSelected);

            // Verifica si se encontró el producto variación
            if (productoV) {
                this.formDataToSend.ordenDetalle[0].productoVId = productoV.id;
            } else {
                // Si no se encuentra, puedes manejar el caso
                this.formDataToSend.ordenDetalle[0].productoVId = 0;
            }
            this.formDataToSend.ordenDetalle[0].productoDescMontoDirecto = "Cotización "+ this.planSelected;
            this.formDataToSend.ordenDetalle[0].productoPrecio = this.reglasDeNegocio[this.planSelected][this.frecuenciaSelected];
            this.formDataToSend.ordenDetalle[0].productoId = this.product.id;
            this.formDataToSend.Plan = this.frecuenciaSelected;
            this.formDataToSend.precio = this.reglasDeNegocio[this.planSelected][this.frecuenciaSelected];
            this.formDataToSend.ordenMonto = this.reglasDeNegocio[this.planSelected][this.frecuenciaSelected];
            this.calcularFechas(30);
            this.formDataToSend.diasRecurrencia = 30;
            this.formularioData = this.formDataToSend;
            API.send('POST', 'payments/create-pay-order', self.formDataToSend,
                function (response) {
                    if (typeof response.data !== 'undefined') {
                        self.cartNotice.status = 1;
                        self.cartNotice.msg = response.msg;
                        self.cartNotice.codigoOrden = response.data.subs[0].codigo || '';
                        self.cartNotice.fechaPago = response.data.subs[0].siguienteFechaPago || '';
                        self.cartNotice.metodoPago = 'Cotización';
                        self.ordenCreadaId = response.data.ordenId;
                        self.language.successText = 'Tu subscripción creada es ' + self.cartNotice.codigoOrden + ' Se está generando la factura...';
                        API.showSuccessNotify(response.msg);

                        // Asegura que self.cartNotice.codigoOrden esté completo antes de llamar a printDocumento
                        if (self.cartNotice.codigoOrden) {
                            // Usa $nextTick para asegurarte de que la interfaz se haya actualizado antes de llamar a printDocumento
                            self.$nextTick(function () {
                                self.printDocumento();
                                self.createInvoice();
                            });
                        }
                        else {
                            console.error('codigoOrden no está completo, no se puede llamar a printDocumento');
                        }
                    }
                    else {
                        API.showErrorAlert('Error al crear cotización, por favor, intente de nuevo');
                    }
                },
                function (response) {
                    if (typeof response.data.ordenId !== 'undefined') {
                        self.ordenCreadaId = response.data.ordenId;
                    }

                    if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                        API.showErrorAlert(response.data.extra.error);
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                });
            //this.formularioData.ordenDetalle[0].productoId = this.product.id;
            //console.log(questionList);
            //this.$router.push('/cart');
        },
        onStep(activeQuestionId, activeQuestion) {
            // Handle step event.
        },
        printDocumento() {
            let docDataQuery = {
                "token": "1a4a63c4083a687ebee5",
                "operation": "generate",
                "password": "",
                "response": "url",
                "tipeView": "F",
                "data": {
                    "codigoOrden": this.cartNotice.codigoOrden,
                    "Plan": this.formDataToSend['Plan'] ? this.formDataToSend['Plan'] + ' ' + this.formDataToSend['ordenMonto'] : '',
                    "fechaPago": this.cartNotice.fechaPago,
                    "placa_a": this.formDataToSend['placa_a'],
                    "lineaM": this.formDataToSend['linea'],
                    "color": this.formDataToSend['color'] ? this.formDataToSend['color'] : '',
                    "year": this.formDataToSend['year'] ? this.formDataToSend['year'] : '',
                    "clienteNombre": this.formDataToSend['clienteNombre'] ? this.formDataToSend['clienteNombre'] : '',
                    "DPI": this.formDataToSend['DPI'] ? this.formDataToSend['DPI'] : '',
                    "clienteTaxId": this.formDataToSend['clienteTaxId'] ? this.formDataToSend['clienteTaxId'] : ''
                }
            };
            API.send('POST', 'formularios/docs-plus/generate', docDataQuery,
                function (data) {
                    if (data.status) {
                        console.log(data);
                        // Abrir la URL en una nueva pestaña
                        let newTab = window.open(data.data.url, '_blank');
                        // Esperar un momento para asegurar que la pestaña se haya abierto
                        setTimeout(function () {
                            // Imprimir el contenido de la pestaña
                            newTab.print();
                        }, 1000); // Espera 1 segundo antes de imprimir para asegurar que el contenido se haya cargado completamente
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                });
        },
        formatDate(dateString, type = 'FULL') {
            // Divide la cadena de fecha en componentes de día, mes y año
            const [year, month, day] = dateString.split('-').map(Number);
            // Crea un objeto Date con los componentes desglosados
            const d = new Date(year, month - 1, day);

            // Ajusta la fecha a la zona horaria de Guatemala
            const options = {timeZone: 'America/Guatemala', day: '2-digit', month: '2-digit', year: 'numeric'};
            const formattedDate = d.toLocaleString('es-GT', options).split('/');

            const formattedDay = formattedDate[0];
            const formattedMonth = formattedDate[1];
            const formattedYear = formattedDate[2];

            if (type === 'FULL') {
                return `${formattedDay}/${formattedMonth}/${formattedYear}`;
            }
            if (type === 'd') {
                return formattedDay;
            }
            if (type === 'm') {
                return formattedMonth;
            }
            if (type === 'y') {
                return formattedYear;
            }
        },
        printSolicitud() {
            const self = this;
            const correo = this.getValueByNombreInterno('input_email', self.fields);
            const nombres = this.getValueByNombreInterno('nombret', self.fields);
            const apellidos = this.getValueByNombreInterno('papellidot', self.fields);
            const input_dpi = this.getValueByNombreInterno('input_dpi', self.fields);
            const telt = this.getValueByNombreInterno('telt', self.fields);
            const fecnact = this.getValueByNombreInterno('fecnact', self.fields);
            const tgenero = this.getValueByNombreInterno('tgenero', self.fields);
            const direccion = this.getValueByNombreInterno('direccion', self.fields);
            const input_nit = this.getValueByNombreInterno('input_nit', self.fields);
            const nombref1 = this.getValueByNombreInterno('nombref1', self.fields);
            const papellidof1 = this.getValueByNombreInterno('papellidof1', self.fields);
            const ocupt = this.getValueByNombreInterno('ocupt', self.fields);
            const telcony = this.getValueByNombreInterno('telcony', self.fields);
            const fecnacf1 = this.getValueByNombreInterno('fecnacf1', self.fields);
            const sapelledif1 = self.getValueByNombreInterno('sapelledif1', self.fields);
            const sapellidot = self.getValueByNombreInterno('sapellidot', self.fields);

            const dataPrint = {};

            const addIfNotEmpty = (key, value) => {
                if (value !== undefined && value !== null) {
                    // Si es una cadena, aplicamos trim
                    if (typeof value === 'string' && value.trim() !== '') {
                        // Si es una fecha, formatearla
                        if (key.toLowerCase().includes("fecha") && !isNaN(Date.parse(value))) {
                            dataPrint[key] = self.formatDate(value.trim());
                        }
                        else {
                            dataPrint[key] = value.trim();
                        }
                        // Si no es una cadena, simplemente agregarlo
                    }
                    else if (typeof value !== 'string') {
                        dataPrint[key] = value;
                    }
                }
            };

            const now = new Date();
            const utcTime = now.getTime() + (now.getTimezoneOffset() * 6000);
            const guatemalaTime = new Date(utcTime - (3600000 * 6));

            const year = guatemalaTime.getFullYear();
            const month = String(guatemalaTime.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(guatemalaTime.getDate()).padStart(2, '0');
            const today = `${year}-${month}-${day}`;
            //return guatemalaTime.toISOString().split('T')[0];

            addIfNotEmpty("nombreTitular", `${nombres.value} ${apellidos.value} ${sapellidot.value}`);
            addIfNotEmpty("nitTitular", input_nit.value);
            if(direccion){
                addIfNotEmpty("direccionTitular", direccion.value);
            }

            addIfNotEmpty("telefonoTitular", telt.value);
            addIfNotEmpty("generoTitular", tgenero.value);
            addIfNotEmpty("DPITitular", input_dpi.value);
            addIfNotEmpty("fechaNacimientoTitular", fecnact.value);
            addIfNotEmpty("precioMensual", self.reglasDeNegocio[self.planSelected]['mensual']);
            addIfNotEmpty("fechaEmision", today);
            addIfNotEmpty("prima", self.reglasDeNegocio[self.planSelected]['prima']);
            addIfNotEmpty("rdh", self.reglasDeNegocio[self.planSelected]['rdh']);
            addIfNotEmpty("dia", day);
            addIfNotEmpty("mes", month);
            addIfNotEmpty("anio", year);
            addIfNotEmpty("tipoPlan", self.planSelected);
            if(ocupt){
                addIfNotEmpty("ocupacionTitular", ocupt.value);
            }
            if(telcony){
                addIfNotEmpty("telcony", telcony.value);
                addIfNotEmpty("fnacimientoCon", fecnacf1.value);
                addIfNotEmpty("nombreAdicional", `${nombref1.value} ${papellidof1.value} ${sapelledif1.value}`);
            }


            addIfNotEmpty("noPoliza", `AERH-${self.newSuscription}`);
            addIfNotEmpty("certificado", self.newSuscription);




            const dataEnviar = {};
            const addIfNotEmpty2 = (key, value) => {
                if (value !== undefined && value !== null) {
                    // Si es una cadena, aplicamos trim
                    if (typeof value === 'string' && value.trim() !== '') {
                        // Si es una fecha, formatearla
                        if (key.toLowerCase().includes("fecha") && !isNaN(Date.parse(value))) {
                            dataEnviar[key] = self.formatDate(value.trim());
                        }
                        else {
                            dataEnviar[key] = value.trim();
                        }
                        // Si no es una cadena, simplemente agregarlo
                    }
                    else if (typeof value !== 'string') {
                        dataEnviar[key] = value;
                    }
                }
            };



            addIfNotEmpty2("nombreTitular", `${nombres.value} ${apellidos.value} ${sapellidot.value}`);
            addIfNotEmpty2("nitTitular", input_nit.value);
            if(direccion){
                addIfNotEmpty2("direccionTitular", direccion.value);
            }

            addIfNotEmpty2("telefonoTitular", telt.value);
            addIfNotEmpty2("generoTitular", tgenero.value);
            addIfNotEmpty2("DPITitular", input_dpi.value);
            addIfNotEmpty2("fechaNacimientoTitular", fecnact.value);
            addIfNotEmpty2("precioMensual", self.reglasDeNegocio[self.planSelected]['mensual']);
            addIfNotEmpty2("fechaEmision", today);
            addIfNotEmpty2("prima", self.reglasDeNegocio[self.planSelected]['prima']);
            addIfNotEmpty2("rdh", self.reglasDeNegocio[self.planSelected]['rdh']);
            addIfNotEmpty2("tipoPlan", self.planSelected);
            if(ocupt){
                addIfNotEmpty2("ocupacionTitular", ocupt.value);
            }
            if(telcony){
                addIfNotEmpty2("telcony", telcony.value);
                addIfNotEmpty2("fnacimientoCon", fecnacf1.value);
                addIfNotEmpty2("nombreAdicional", `${nombref1.value} ${papellidof1.value} ${sapelledif1.value}`);
            }

            addIfNotEmpty2("noPoliza", `AERH`);
            addIfNotEmpty2("certificado", self.newSuscription);
            let dataQueryToSend = {};
            if(this.skuProducto === 'AERH'){
                dataQueryToSend = {
                    "forms": [
                        {
                            "token": "7391daec8c7cc3456b4c",
                            "operation":"generate",
                            "data": dataPrint,
                            "order": 0
                        },
                        {
                            "token": "6742230979f58b1e9475",
                            "operation":"generate",
                            "data": dataEnviar,
                            "order": 1
                        }
                    ],
                    "response": "url"
                };
            }
            else if (this.skuProducto === 'AEPH' && this.AuthGetUserInfo.s !== 'telemercadeo.comasa.com.gt'){
                dataQueryToSend = {
                    "forms": [
                        {
                            "token": "772abd77a9fe330e8cbf",
                            "operation":"generate",
                            "data": dataPrint,
                            "order": 0
                        }
                    ],
                    "response": "url"
                };
            }
            else if (this.skuProducto === 'AEPH' && this.AuthGetUserInfo.s === 'telemercadeo.comasa.com.gt'){
                dataQueryToSend = {
                    "forms": [
                        {
                            "token": "f59bbfec5ba8f4665e10",
                            "operation":"generate",
                            "data": dataPrint,
                            "order": 0
                        }
                    ],
                    "response": "url"
                };
            }
            else if (this.skuProducto === 'VEMI'){
                dataQueryToSend = {
                    "forms": [
                        {
                            "token": "6e53a5768946fa1e7474",
                            "operation":"generate",
                            "data": dataPrint,
                            "order": 0
                        }
                    ],
                    "response": "url"
                };
            }

            API.send('POST', 'formularios/docs-plus/generate-merge', dataQueryToSend,
                function (data) {
                    if (data.status) {
                        //console.log(data);
                        // Abrir la URL en una nueva pestaña
                        let newTab = window.open(data.data.url, '_blank');
                        if (data.status) {
                            const nombres = self.getValueByNombreInterno('nombret', self.fields);
                            const apellidos = self.getValueByNombreInterno('papellidot', self.fields);
                            const input_dpi = self.getValueByNombreInterno('input_dpi', self.fields);
                            const telt = self.getValueByNombreInterno('telt', self.fields);
                            const fecnact = self.getValueByNombreInterno('fecnact', self.fields);
                            const tgenero = self.getValueByNombreInterno('tgenero', self.fields);
                            const direccion = self.getValueByNombreInterno('direccion', self.fields);
                            const input_nit = self.getValueByNombreInterno('input_nit', self.fields);
                            const nombref1 = self.getValueByNombreInterno('nombref1', self.fields);
                            const papellidof1 = self.getValueByNombreInterno('papellidof1', self.fields);
                            const sapelledif1 = self.getValueByNombreInterno('sapelledif1', self.fields);
                            const sapellidot = self.getValueByNombreInterno('sapellidot', self.fields);
                            const dataEnviar = {};
                            const addIfNotEmpty = (key, value) => {
                                if (value !== undefined && value !== null) {
                                    // Si es una cadena, aplicamos trim
                                    if (typeof value === 'string' && value.trim() !== '') {
                                        // Si es una fecha, formatearla
                                        if (key.toLowerCase().includes("fecha") && !isNaN(Date.parse(value))) {
                                            dataEnviar[key] = self.formatDate(value.trim());
                                        }
                                        else {
                                            dataEnviar[key] = value.trim();
                                        }
                                        // Si no es una cadena, simplemente agregarlo
                                    }
                                    else if (typeof value !== 'string') {
                                        dataEnviar[key] = value;
                                    }
                                }
                            };

                            const now = new Date();
                            const utcTime = now.getTime() + (now.getTimezoneOffset() * 6000);
                            const guatemalaTime = new Date(utcTime - (3600000 * 6));

                            const year = guatemalaTime.getFullYear();
                            const month = String(guatemalaTime.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                            const day = String(guatemalaTime.getDate()).padStart(2, '0');
                            const today = `${year}-${month}-${day}`;


                            addIfNotEmpty("nombreTitular", `${nombres.value} ${apellidos.value} ${sapellidot.value}`);
                            addIfNotEmpty("nitTitular", input_nit.value);
                            if(direccion){
                                addIfNotEmpty("direccionTitular", direccion.value);
                            }

                            addIfNotEmpty("telefonoTitular", telt.value);
                            addIfNotEmpty("generoTitular", tgenero.value);
                            addIfNotEmpty("DPITitular", input_dpi.value);
                            addIfNotEmpty("fechaNacimientoTitular", fecnact.value);
                            addIfNotEmpty("precioMensual", self.reglasDeNegocio[self.planSelected]['mensual']);
                            addIfNotEmpty("fechaEmision", today);
                            addIfNotEmpty("prima", self.reglasDeNegocio[self.planSelected]['prima']);
                            addIfNotEmpty("rdh", self.reglasDeNegocio[self.planSelected]['rdh']);
                            addIfNotEmpty("dia", day);
                            addIfNotEmpty("mes", month);
                            addIfNotEmpty("anio", year);
                            addIfNotEmpty("tipoPlan", self.planSelected);
                            if(ocupt){
                                addIfNotEmpty("ocupacionTitular", ocupt.value);
                            }
                            if(telcony){
                                addIfNotEmpty("telcony", telcony.value);
                                addIfNotEmpty("fnacimientoCon", fecnacf1.value);
                                addIfNotEmpty("nombreAdicional", `${nombref1.value} ${papellidof1.value} ${sapelledif1.value}`);
                            }


                            addIfNotEmpty("noPoliza", `${self.skuProducto}-${self.newSuscription}`);
                            addIfNotEmpty("certificado", self.newSuscription);
                            let dataQueryToSend = {};
                            let urlToSend = 'formularios/docs-plus/generate'
                            if(self.skuProducto === 'AERH'){
                                dataQueryToSend = {
                                    "token": "cd4306c5de594a149c4c",
                                    "operation": "generate",
                                    "password": "",
                                    "response": "url",
                                    "tipeView": "F",
                                    "data": dataEnviar
                                };
                            }
                            else if (self.skuProducto === 'AEPH'){
                                dataQueryToSend = {
                                    "token": "772abd77a9fe330e8cbf",
                                    "operation": "generate",
                                    "password": "",
                                    "response": "url",
                                    "tipeView": "F",
                                    "data": dataEnviar
                                };
                            }
                            else if (self.skuProducto === 'VEMI'){
                                urlToSend = 'formularios/docs-plus/generate-merge'
                                dataQueryToSend = {
                                    "forms": [
                                        {
                                            "token": "6e53a5768946fa1e7474",
                                            "operation":"generate",
                                            "data": dataPrint,
                                            "order": 0
                                        },
                                        {
                                            "token": "b57f46a34c7624e65e2c",
                                            "operation":"generate",
                                            "data": dataPrint,
                                            "order": 1
                                        }
                                    ],
                                    "response": "url"
                                };
                            }
                            API.send('POST', urlToSend, dataQueryToSend,
                                function (data) {
                                    if (data.status) {
                                        console.log(data);
                                        self.sendEmailWithTemplate(data.data.url);
                                    }
                                },
                                function (data) {
                                    API.showErrorNotify(data.msg);
                                });
                        }
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                });
        },

        onAnswer(questionAnswered) {
            const self = this;
            if (questionAnswered.options) {
                const filteredOptions = questionAnswered.options.filter(option => option.selected === true);
                const hasIdAndPrecio = filteredOptions.some(option => option.hasOwnProperty('id') && option.hasOwnProperty('precio'));

                //console.log(hasIdAndPrecio);
                if (hasIdAndPrecio) {
                    filteredOptions.forEach(option => {
                        if (option.hasOwnProperty('precio')) {
                            self.formularioData['precio'] = option.precio;
                            self.formularioData.ordenDetalle[0].productoPrecio = option.precio;
                            self.formularioData.ordenMonto = option.precio;
                            self.formularioData.ordenDetalle[0].productoVId = option.id;
                        }
                        if (option.hasOwnProperty('diasRecurrencia')) {
                            self.formularioData['diasRecurrencia'] = option.diasRecurrencia;
                            self.calcularFechas(option.diasRecurrencia);
                        }
                    });
                }
            }

            if (typeof this.formularioData[questionAnswered.id] !== 'undefined') {

                this.formularioData[questionAnswered.id] = questionAnswered.answer;
                this.formularioData.ordenDetalle[0].productoId = this.product.id;
                this.formularioData.ordenDetalle[0].productoDescMontoDirecto = 'Cotización de' + this.product.nombre;
            }
            else {
                this.formularioData[questionAnswered.id] = '';
                this.formularioData[questionAnswered.id] = questionAnswered.answer;
                this.dataExtra[questionAnswered.id] = questionAnswered.answer;
            }

            // Verifica si la pregunta respondida tiene el id "clienteTaxId"
            if (questionAnswered.id === 'clienteTaxId') {
                // En este caso, realiza la búsqueda y la validación
                // Envía la solicitud de búsqueda
                const searchUrl = 'clients/search/term';
                API.send('POST', searchUrl, {term: questionAnswered.answer},
                    function (response) {
                        if (response.status !== 0) {
                            if (response.data.length === 0) {
                                if (questionAnswered.answer === 'CF') {
                                    API.showSuccessNotify('NIT válido');
                                }
                                else {
                                    self.searchResults = false
                                    API.showErrorNotify('NIT Inválido');
                                    self.$refs.flowform.goToQuestion('clienteTaxId');
                                }

                            }
                            else {
                                self.searchResults = response.data;
                                if (self.searchResults.length !== 0) {
                                    API.showSuccessNotify(self.searchResults[0].nombres + ' ' + self.searchResults[0].apellidos);
                                }
                                else {
                                    if (questionAnswered.answer === 'CF') {
                                        API.showSuccessNotify('NIT válido');
                                    }
                                    else {
                                        self.searchResults = false
                                        API.showErrorNotify('NIT Inválido');
                                        return false;
                                    }
                                }

                                //return false;
                            }
                        }
                        else {
                            self.searchResults = false
                            API.showErrorNotify(response.msg);
                            return false;
                        }
                    },
                    function (data) {
                        self.searchResults = false
                        API.showErrorNotify(data.msg);
                        return false;
                    });
            }// Verifica si la pregunta respondida tiene el id "clienteTaxId"
            if (questionAnswered.id === 'placa_a' || questionAnswered.id === 'placa_b') {
                // En este caso, realiza la búsqueda y la validación
                // Envía la solicitud de búsqueda
                const searchUrl = 'clients/metadata/term';
                API.send('POST', searchUrl, {term: questionAnswered.answer},
                    function (response) {
                        if (response.status !== 0) {

                        }
                        else {
                            self.searchResults = false
                            API.showErrorNotify('Las placas ya tienen este producto activo.', 'Por favor utilice otras placas u otro producto');
                            self.$refs.flowform.goToQuestion(questionAnswered.id);
                        }
                    },
                    function (data) {
                        self.searchResults = false
                        API.showErrorNotify('Las placas ya tienen este producto activo.', 'Por favor utilice otras placas u otro producto');
                        return false;
                    });
            }
        },
        getDisponibilidad() {
            const self = this;
            API.send('POST', 'products/public/disponibilidad/' + self.AuthGetUserInfo.s + '/producto/' + self.product.id + '/' + dayjs(self.date).format('YYYY-MM-DD'), {
                    idPais: self.paisLocationCobertura,
                    idDepartamento: self.deptoLocationCobertura,
                    idMunicipio: self.municipioLocationCobertura,
                },
                function (response) {
                    if (response.status && typeof response.data !== 'undefined') {
                        self.disponibilidad = response.data;
                        self.locations = [];
                        for (let locacion in self.disponibilidad.locaciones) {
                            //console.log(locacion);
                            const positions = {
                                position: {
                                    lat: Number(self.disponibilidad.locaciones[locacion].latitud),
                                    lng: Number(self.disponibilidad.locaciones[locacion].longitud)
                                },
                                label: self.disponibilidad.locaciones[locacion].nombre,
                                title: self.disponibilidad.locaciones[locacion].nombre,
                                moneda: self.disponibilidad.locaciones[locacion].moneda,
                                id: self.disponibilidad.locaciones[locacion].id
                            }
                            //console.log(position);
                            self.locations.push(positions);

                            //console.log(self.locations)
                        }
                    }
                },
                function (response) {
                    self.horarios = {};
                    API.showErrorNotify(response.msg);
                });
        },
        validarNIT(value = '77253825'){
            const searchUrl = 'clients/search/term';
            const self = this;
            API.send('POST', searchUrl, {term: value},
                function (response) {
                    if (response.status !== 0) {
                        self.searchResults = response.data;
                        if (self.searchResults.length !== 0) {
                            API.showSuccessNotify(self.searchResults[0].nombres + ' ' + self.searchResults[0].apellidos);
                        }

                    }
                    else {
                        self.searchResults = false
                        API.showErrorNotify(response.msg);
                        return false;
                    }
                },
                function (data) {
                    self.searchResults = false
                    API.showErrorNotify(data.msg);
                    return false;
                });
        },
        sendCotizacion(){
            const self = this;
            API.send('POST', 'orders/create/cotizacion',
                {
                    idCotizacion: 0,
                    certificado: self.skuProducto+` - `+ self.newSuscription,
                    variacionId: self.reglasDeNegocio[self.planSelected]['plancoti'],
                    contenidoEnviado: self.dataEnviada,
                    monto: self.reglasDeNegocio[self.planSelected][self.frecuenciaSelected],
                    tipoPago: self.frecuenciaSelected,
                },
                function (response) {
                    if (response.status && typeof response.data !== 'undefined') {
                        self.cotizacionIdEnviada = response.data.idCotizacion;
                        API.showSuccessAlert('Orden creada con éxito');
                    }
                },
                function (response) {

                    API.showErrorNotify(response.msg);
                });
        },
        handleItem(category) {
            this.isActive = category
        },
        updateLocaciones(event, cluster, map) {
            //console.log(cluster);
            //console.log(map);
            map.fitBounds(cluster.bounds);
        },
        filterLocation() {
            const self = this;
            return (this.locacionSelected = this.locations.filter(
                (item) => item.id === self.locacionId
            ));
        },
        someEventw(event) {
            this.center = event.position;
            this.zoomData = 15;
            //this.$refs.mapRef.map.panTo({ lat: 0, lng: 0 });
            //this.$refs.mapRef.map.setCenter(this.$refs.mapRef.map.marker.getPosition());

            this.locacionId = event.id;
            this.filterLocation();
        },
        finishSelection() {
            if (this.horarioSelected && this.horarioSelected !== '') {
                this.$router.push('/cart');
                this.hidePicker();
            }
        },
        selectPersonal() {
            if (parseInt(this.personal) === 0) {
                if (typeof this.disponibilidad.locaciones !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion] !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion].personal !== 'undefined') {
                    this.personalSelected = Object.keys(this.disponibilidad.locaciones[this.locacion].personal)[0];
                }
            }
            else {
                this.personalSelected = this.personal;
            }
        },
        selectHorario(data) {
            const dataToPushDate = {
                'citaFecha': data.fecha,
                'citaHora': data.horario,
                'locacionId': data.locacionId,
                'locacionName': data.locacion,
                'personal': data.personal,
                'pais': this.paisLocationCobertura,
                'depto': this.deptoLocationCobertura,
                'dire': this.locationTextCobertura,
                'moneda': this.disponibilidad.locaciones[data.locacionId].moneda,
                'muni': this.municipioLocationCobertura
            }
            this.horarioSelected = dataToPushDate;
            this.$store.dispatch('CartAddDate', {
                id: this.product.id,
                product: this.product,
                dateSelected: this.horarioSelected,
                quantity: 1,
            });
            if (this.locationTextCobertura === '') {
                window.location.href = '/cart'
            }

        },
        addToLead() {
            this.$store.dispatch('CartAddProduct', this.product);
            this.$store.dispatch('CartChangeProductQuantity', {
                id: this.product.id,
                quantity: this.quantity,
            });
            this.quantity = 1;
        },
        consultaPDF() {
            const self = this;
            // para al final es el mismo pdf solo consulto este servicio si no tengo el blob del pdf
            if (self.invoiceBlob.length === 0) {

                API.send('GET', 'orders/factura/pdf/' + self.ordenCreadaId + '/fcUID', {},
                    function (response) {
                        if (response.status) {
                            self.downloadPDF(response.data, 'fcUID');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                self.downloadPDF(self.invoiceBlob, 'fcUID');

            }

        },
        downloadPDF(file, tipo) {
            const linkSource = `${file}`;
            const downloadLink = document.createElement("a");
            const fileName = this.ordenCreadaId + tipo + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        createInvoice() {
            const self = this;
            const dataSend = {


                id: self.ordenCreadaId,
                taxCode: (self.formularioData['clienteTaxId']) ? self.formularioData['clienteTaxId'] : 'CF',
                clientName: (self.formularioData['clienteNombre']) ? self.formularioData['clienteNombre'] : 'Consumidor Final',
                correoContacto: (self.formularioData['clienteEmail']) ? self.formularioData['clienteEmail'] : '',
                idSubscription: (self.cartNotice.codigoOrden) ? self.cartNotice.codigoOrden : '',
                voucher: 'pos-subscripciones'
            }
            //store.dispatch('coreShowLoading');
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/crear', dataSend,
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                        //self.printDocumento();
                        self.consultaPDF()
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },

    },
    mounted() {

        this.getProduct(this.id);
        this.getCategories(this.category);
    },
    data() {
        return {
        dataClienteReimpresion: false,
        formDataToSend: {
            "clienteDireccion":"",
            "clienteEmail":null,
            "clienteNombre":"",
            "ordenMonto":"",
            "facturaEmitir":0,
            "clienteTaxId":"CF",
            "clienteTelefono":"",
            "ordenDetalle":{
            "0":{
                "productoCantidad": 1,
                    "productoId": 0,
                    "productoPrecio": "",
                    "productoDescMontoDirecto": "",
                    "productoVId": 0
            }
        },
            "ordenId":0,
            "ordenTiendaDomain": "multivendor.comasa.com.gt",
            "pagoMetodo": "cotizacion",
            "pagoTcCvv": "",
            "pagoTcExp": "",
            "pagoTcNombre": "",
            "pagoTcNumero": "",
            "pagoCuotas":0,
            "precio": "",
            "diasRecurrencia": 0,
            "Plan": "bimensual",
            "expediente": "",
            "DPI":"",
            "Tipo":"",
            "placa_a": "",
            "tipoVehiculo": 'auto',
            "linea":"",
            "color":"",
            "year":"",
            "agencia":""
        },
            notieneNIT: false,
            hideInputEmail: false,
            conyugeParentesco: '0002',
            conyugeFechaNacimiento: {
                value: '',
                hasError: false
            },
            conyugeGenero: '',
            suscriptionList: {},
            cotizacionHash: '',
            strokeOptions: {
                size: 9,
                thinning: 0.70,
                smoothing: 0.5,
                streamline: 0.5
            },
            searchTerm: '',
            itemFacturar: '',
            dataClientes: {
                nombres: '',
                dpi: '',
                nit: '',
                direccion: '',
                tipoV: '',
                colorV: '',
                yearV: '',
                marcaV: '',
            },
            voucherPago: '',
            invoiceBlob: '',
            fields: [],
            questionsLoaded: false,
            logoTienda: '',
            step: 'agendamiento',
            id: this.$route.params.id,
            categories: {},
            isActive: 'coti',
            fechaFutura: '',
            forceUpdate: false,
            active: false,
            show: false,
            showPrintDialog: false,
            showFacturaDialog: false,
            product: {},
            category: 'all',
            center: {lat: 15.783471, lng: -90.23075899999999},
            zoomData: 4,
            showModal: false,
            theme: 'a257512d80524267',
            type: 'JavaScript – Vector',
            locations: [],
            locacionId: 0,
            expediente: '',
            filterLocations: [],
            planSelected: '',
            planSelectedVial: 'plan vial',
            frecuenciaSelected: false,
            tabsSeleccionadas: [
                {
                    title: 'Plan',
                    value: 'plan',
                    icon: 'fa fa-info',
                },
                {
                    title: 'Titular',
                    value: 'titular',
                    icon: 'fa fa-user',
                },
                {
                    title: 'Familiar Migrante Asegurado',
                    value: 'beneficiario',
                    icon: 'fa fa-users',
                },
                {
                    title: 'Subir documento',
                    value: 'documento',
                    icon: 'fa fa-upload',
                }
            ],
            tabsSeleccionadas2: [
                {
                    title: 'Plan',
                    value: 'plan',
                    icon: 'fa fa-info',
                },
                {
                    title: 'Titular',
                    value: 'titular',
                    icon: 'fa fa-user',
                },
                {
                    title: 'Vehículo',
                    value: 'vehiculo',
                    icon: 'fa fa-car',
                }
            ],
            frecuencias: [
                {
                    title: 'Mensual',
                    value: 'mensual',
                    icon: 'fa fa-info',
                },
                {
                    title: 'Bimensual',
                    value: 'bimensual',
                    icon: 'fa fa-user',
                },
                {
                    title: 'Trimestral',
                    value: 'trimestral',
                    icon: 'fa fa-users',
                },
                {
                    title: 'Semestral',
                    value: 'semestral',
                    icon: 'fa fa-signature',
                },
                {
                    title: 'Anual',
                    value: 'anual',
                    icon: 'fa fa-signature',
                }
            ],
            formularioData: {
                clienteDireccion: "Ciudad",
                clienteEmail: "",
                clienteNombre: "",
                ordenMonto: "",
                facturaEmitir: 0,
                clienteTaxId: "",
                clienteTelefono: "",
                ordenDetalle: {
                    0: {
                        productoCantidad: 1,
                        productoId: 0,
                        productoPrecio: 0.5,
                        productoDescMontoDirecto: "",
                        productoVId: 0
                    }
                },
                ordenId: 0,
                ordenTiendaDomain: '',
                pagoMetodo: "cotizacion",
                pagoTcCvv: "",
                pagoTcExp: "",
                pagoTcNombre: "",
                pagoTcNumero: "",
                pagoCuotas: 0,
                tipoVehiculo: '',
            },
            placas: '',
            activeTab: 'download',
            productoRecurrente: false,
            modalidadCompra: 'recurrente',
            maxToBuy: 0,
            quantity: 1,
            totalAmount: 0,
            galleryItemActive: 0,

            reglasDeNegocio: {
                "plan familiar": {
                    "mensual": 30.99,
                    "bimensual": 61.98,
                    "trimestral": 92.97,
                    "semestral": 185.94,
                    "anual": 371.88,
                    "prima": 72000,
                    "rdh": 240,
                    "minAge": 18,
                    "maxAge": 60,
                    "plancoti": '002001'
                },
                "seguro migrante": {
                    "anual":300.00 ,
                    "mensual": 25.00,
                    "bimensual": 50.00,
                    "trimestral": 75.00,
                    "semestral": 150.00,
                    "prima": 15000,
                    "rdh": 0,
                    "minAge": 18,
                    "maxAge": 65,
                    "plancoti": '001001'
                },
                "plan individual": {
                    "mensual": 21.99,
                    "bimensual": 43.98,
                    "trimestral": 65.97,
                    "semestral": 131.94,
                    "anual": 263.88,
                    "prima": 49500,
                    "rdh": 330,
                    "minAge": 18,
                    "maxAge": 60,
                    "plancoti": '001001'
                },
                "seguro hogar mensual": {
                    "anual": 203.88,
                    "mensual": 16.99,
                    "bimensual": 33.98,
                    "trimestral": 50.97,
                    "semestral": 101.94,
                    "prima": 5000,
                    "rdh": 0,
                    "minAge": 18,
                    "maxAge": 75,
                    "plancoti": '001001'
                },
                "seguro hogar anual": {
                    "anual": 203.88,
                    "mensual": 16.99,
                    "bimensual": 33.98,
                    "trimestral": 50.97,
                    "semestral": 101.94,
                    "prima": 5000,
                    "rdh": 0,
                    "minAge": 18,
                    "maxAge": 75,
                    "plancoti": '001001'
                },
                "plan vial plus": {
                    "mensual": 35.99,
                    "bimensual": 71.98,
                    "trimestral": 107.97,
                    "semestral": 215.94,
                    "anual": 431.88,
                    "prima": 0,
                    "rdh": 0,
                    "plancoti": '002001'
                },
                "plan vial": {
                    "mensual": 20.99,
                    "bimensual": 41.98,
                    "trimestral": 62.97,
                    "semestral": 125.94,
                    "anual": 251.88,
                    "prima": 0,
                    "rdh": 0,
                    "plancoti": '001001'
                }
            },
            reglasDeNegocioVial: {
                "plan vial plus": {
                    "mensual": 35.99,
                    "bimensual": 61.98,
                    "trimestral": 92.97,
                    "semestral": 185.94,
                    "anual": 371.88,
                    "prima": 72000,
                    "rdh": 240,
                    "plancoti": '002001'
                },
                "plan vial": {
                    "mensual": 21.99,
                    "bimensual": 43.98,
                    "trimestral": 65.97,
                    "semestral": 131.94,
                    "anual": 263.88,
                    "prima": 49500,
                    "rdh": 330,
                    "plancoti": '001001'
                }
            },
            tipoVehiculo: '',
            selectedYear: 1980, // Año inicial seleccionado
            maxYear: new Date().getFullYear() + 1, // Año máximo (actual + 1)
            maskaPatternNit: '#######XX',
            maskaTokensNit: {
                'X': {pattern: '[-0-9a-zA-Z]'}
            },
            language: new LanguageModel({
                ok: 'Siguiente',
                percentCompleted: ':percent% completado',
                ariaTypeAnswer: ':Escribe aquí',
                invalidPrompt: 'Por favor complete el campo correctamente',
                ariaMultipleChoice: 'Presione :letter para seleccionar',
                ariaSubmitText: 'Presione para enviar',
                longTextHelpText: ':shiftKey + :enterKey Si quieres escribir más de una línea',
                continue: 'Continuar',
                multipleChoiceHelpTextSingle: 'Seleccione una respuesta',
                pressEnter: 'presione Enter',
                otherPrompt: 'Ingresar NIT',
                submitText: 'Enviar',
                thankYouText: 'Listo, hemos terminado',
                skip: 'No tiene',
                successText: 'Tu formulario ha sido enviado'
            }),
            questions: [],
            showFormLead: false,
            dataExtra: [],
            cartNotice: {
                status: false,
                msg: '',
                codigoOrden: '',
                metodosPago: '',
                auth: '',
                reference: '',
                processor: '',
                fechaPago: '',
            },
            cotizacionIdEnviada: 0,
            // envio
            paisEnvio: 0,
            deptoEnvio: 0,
            municipioEnvio: 0,
            direccionCompletaEnvio: "",
            comentarioEnvio: "",
            direccionEnvioText: "",
            direccionEnvioSelected: false,
            envioShowPicker: false,
            bodegasEnvio: {},
            shippingRates: {},
            selectedShippingRates: {},
            disponibilidad: {},
            horarios: {},
            date: new Date(),
            time: '',

            orden: {},
            isFastPayProduct: false,
            isPayLink: false,
            token: false,
            globalError: '',
            //step: 'agenda',
            productsInOrderCustomAmount: {},
            productsInCart: {},

            // tipos de producto para cambiar asistente
            productsType: {
                agendable: false,
                agendableDomicilio: false,
                agendablePresencial: false,
                normal: false,
                virtual: false,
                envio: false,
            },

            // montos
            subtotal: 0,
            totalShipping: 0,
            total: 0,
            searchResults: true,

            // paso carrito

            // paso ubicacion
            autoLocationUsed: false,
            enterLocation: false,
            geolocationData: {},
            paises: {},
            deptos: {},
            municipios: {},
            locacionSelected: {},

            // Validación de coberturas
            paisLocationCobertura: 0,
            deptoLocationCobertura: 0,
            municipioLocationCobertura: 0,
            ubicacionSelectedCobertura: false,
            locationTextCobertura: "",


            // facturación
            direccionFacturaSelected: false,
            direccionFacturaText: "",


            // disponibilidad, horarios y locaciones
            horariosCitas: {},

            // form de pago
            formDataPay: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                cardCuotas: 0,
            },


            newSuscription: '',

            // Pago y proceso
            ordenCreadaId: 0,
            payRedirectionData: "none",
            variaciones: {},
            planesVariaciones: [],
            agencias: [],
            //Calendario
            esperandoFirma: false,
            hideDateSelector: false,
            showDataTimePicker: false,
            datePickerParams: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,

                },
            ],
            locacion: 0,
            personal: 0,
            personalSelected: 0,
            locacionName: '',
            horarioSelected: '',
            skuProducto:'',
            pdfUrl: false,
            dataEnviada: {}
        }
    },
}
</script>
<style lang="css">

@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';


@media (prefers-color-scheme: dark) {
    :root {
        --vff-bg-color: #FFFFFF; /* Fondo principal en blanco */
        --vff-main-text-color: #0000FF; /* Texto principal en azul oscuro */
        --vff-secondary-text-color: #333333; /* Texto secundario en gris oscuro */
        --vff-tertiary-text-color: #0000FF; /* Texto terciario en azul oscuro */
        --vff-main-accent-color: #3399FF; /* Acento principal en azul claro */
        --vff-secondary-accent-color: #FFFF00; /* Acento secundario en amarillo */
    }

    .vff,
    .vff textarea,
    .vff input,
    .vff select,
    .vff select option,
    .vff kbd,
    .vff-header,
    .vff-footer {
        font-family: 'gordita';
    }

    .vff-footer .f-progress-bar {
        display: inline-block;
        height: 4px;
        margin: 0 6px 0 0;
        position: static;
        vertical-align: middle;
        width: 64px;
    }

    .vff-footer .f-progress-bar-inner {
        height: 4px;
        -webkit-transition: width .3s ease;
        transition: width .3s ease;
    }

    .vff-footer .footer-inner-wrap {
        background-color: var(--vff-bg-color);

    }


}

.voucher {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: 0 auto; /* Agrega esta línea */
}

.voucher-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.voucher-body {
    margin-bottom: 20px;
}

.voucher-footer {
    text-align: right;
}


</style>
