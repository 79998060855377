<script setup>
import { ref, onMounted, watch } from 'vue';
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Multiselect from 'vue-multiselect';
import * as XLSX from 'xlsx';

const medicos = ref([]);
const showRejectionModal = ref(false);
const rejectionReason = ref('');
const selectedLinkId = ref(null);
const tiposSeguros = ref([]);
const tiposPacientes = ref([]);
const tiposPlantillas = ref([]);
const selectedMedico = ref(null);
const selectedPlantilla = ref(null);
const selectedTipoSeguro = ref(null);
const selectedTipoPaciente = ref(null);
const monto = ref('');
const paciente = ref('');
const concepto = ref('');
const telefono = ref('');
const email = ref('');
const msg = ref('');
const activeTab = ref('generacion_link');
const estados = ['generacion_link', 'link_generado', 'link_completado', 'autorizado', 'pagado'];
const links = ref([]);

// Objeto para almacenar datos de pago por link
const paymentDetails = ref({});

function getPaymentDetail(linkId) {
    if (!paymentDetails.value[linkId]) {
        paymentDetails.value[linkId] = { fecha: '', referencia: '', monto: '', metodo: '' };
    }
    return paymentDetails.value[linkId];
}

onMounted(() => {
    loadTiposSeguros();
    loadTiposPacientes();
    loadPlantilla();
    fetchLinks();
});

watch(activeTab, fetchLinks);

function getPdfUrl(link) {
    const numero = String(link.factura || '').trim();
    const serie = String(link.serie || '').trim();
    let nit = '';
    if (link.medico && Array.isArray(link.medico.atributos)) {
        const nitAttr = link.medico.atributos.find(attr => attr.nombre.toLowerCase() === 'nit');
        if (nitAttr) {
            nit = String(nitAttr.valor).trim();
        }
    }
    const receptor = "79655939"; // Valor correspondiente (por ejemplo, NIT del emisor)
    const montoValue = String(link.monto || '').trim();
    return `https://felpub.c.sat.gob.gt/verificador-web/publico/vistas/verificacionDte.jsf?tipo=autorizacion&numero=${encodeURIComponent(numero)}&serie=${encodeURIComponent(serie)}&emisor=${encodeURIComponent(nit)}&receptor=${encodeURIComponent(receptor)}&monto=${encodeURIComponent(montoValue)}`;
}

function openRejectionModal(linkId) {
    selectedLinkId.value = linkId;
    rejectionReason.value = "";
    showRejectionModal.value = true;
}
function closeRejectionModal() {
    showRejectionModal.value = false;
}
function confirmRejection() {
    if (!rejectionReason.value.trim()) {
        alert("Por favor, ingrese un motivo de rechazo.");
        return;
    }
    // Llamar a la función de rechazo (si existe)
    // Cerrar modal
    showRejectionModal.value = false;
}
function loadTiposSeguros() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'tipos-de-seguro', smallCatalog: 1 },
        (response) => response.status ? tiposSeguros.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}
function loadTiposPacientes() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'tipo-de-paciente', smallCatalog: 1 },
        (response) => response.status ? tiposPacientes.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}
function loadPlantilla() {
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'plantilla-link', smallCatalog: 1 },
        (response) => response.status ? tiposPlantillas.value = response.data : msg.value = response.msg,
        (error) => API.showErrorAlert(error.msg)
    );
}
function approveLink(linkId) {
    API.send(
        'POST',
        'medico/links/actualizar',
        { id_link: linkId, estado: 'autorizado' },
        (response) => {
            if (response.status) {
                API.showSuccessAlert("Estado actualizado exitosamente");
                activeTab.value = 'autorizado';
                fetchLinks();
            } else {
                API.showErrorAlert(response.msg);
            }
        },
        (error) => API.showErrorAlert(error.msg)
    );
}
function pagar(linkId) {
    // Obtener los datos de pago para el link
    const payment = getPaymentDetail(linkId);
    // Validar que se hayan ingresado los datos necesarios
    if (!payment.referencia || !payment.fecha || !payment.metodo || !payment.monto) {
        API.showErrorAlert("Ingrese todos los datos de pago: Referencia, Fecha, Monto Pagado y Método de Pago.");
        return;
    }
    API.send(
        'POST',
        'medico/links/actualizar',
        {
            id_link: linkId,
            estado: 'pagado',
            //monto: '', // Este campo puede no usarse si se pasa monto_pagado
            referencia: payment.referencia,
            fecha: payment.fecha,
            metodo: payment.metodo,
            monto_pagado: payment.monto
        },
        (response) => {
            if (response.status) {
                API.showSuccessAlert("Estado actualizado a pagado exitosamente");
                activeTab.value = 'pagado';
                fetchLinks();
            } else {
                API.showErrorAlert(response.msg);
            }
        },
        (error) => API.showErrorAlert(error.msg)
    );
}
function exportToExcel() {
    const dataToExport = links.value.map(link => ({
        ID: link.id,
        Médico: link.medico.descripcion,
        Tipo_Seguro: link.tipo_seguro.descripcion||'',
        Tipo_Paciente: link.tipo_paciente.descripcion||'',
        Monto: link.monto||'',
        Paciente: link.paciente||'',
        Teléfono: link.telefono||'',
        Email: link.email||'',
        Estado: link.estado
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Links Generados");
    XLSX.writeFile(workbook, "links_generados.xlsx");
}
function fetchLinks() {
    if(activeTab.value !== 'generacion_link'){
        API.send('POST', 'medico/links/obtener', { estado: activeTab.value },
            (response) => response.status ? links.value = response.data : API.showErrorAlert(response.msg),
            (error) => API.showErrorAlert(error.msg)
        );
    }
}
function searchMedicos(query) {
    if (!query) return;
    API.send('POST', 'medico/catalogo/autocomplete', { slug: 'catalogo-medicos', query },
        (response) => response.status ? medicos.value = response.data : null,
        (error) => API.showErrorAlert(error.msg)
    );
}
watch(selectedMedico, (medico) => {
    if (medico && medico.atributos) {
        const telefonoAttr = medico.atributos.find(attr => attr.nombre === 'telefono');
        const emailAttr = medico.atributos.find(attr => attr.nombre === 'correoElectronico');
        telefono.value = telefonoAttr ? telefonoAttr.valor : '';
        email.value = emailAttr ? emailAttr.valor : '';
    }
});
function generateLink() {
    if (!selectedMedico.value || !selectedMedico.value.id ||
        !selectedTipoPaciente.value || !selectedTipoPaciente.value.id) {
        API.showErrorAlert("Debe seleccionar un médico y tipo de paciente");
        return;
    }
    const medicoId = selectedMedico && selectedMedico.value ? selectedMedico.value.id : 0;
    const tipoSeguroId = selectedTipoSeguro && selectedTipoSeguro.value ? selectedTipoSeguro.value.id : 0;
    const tipoPacienteId = selectedTipoPaciente && selectedTipoPaciente.value ? selectedTipoPaciente.value.id : 0;
    const montoValue = monto && monto.value ? monto.value : 0;
    const pacienteValue = paciente && paciente.value ? paciente.value : '';
    const conceptoValue = concepto && concepto.value ? concepto.value : '';
    const telefonoValue = telefono && telefono.value ? telefono.value : '';
    const emailValue = email && email.value ? email.value : '';
    API.send('POST', 'medico/links/generar', {
            medico_id: medicoId,
            tipo_seguro_id: tipoSeguroId,
            tipo_paciente_id: tipoPacienteId,
            monto: montoValue,
            paciente: pacienteValue,
            concepto: conceptoValue,
            telefono: telefonoValue,
            email: emailValue
        },
        (response) => {
            if (response.status) {
                API.showSuccessAlert('Link generado exitosamente');
            } else {
                API.showErrorAlert(response.msg);
            }
        },
        (error) => {
            API.showErrorAlert(error.msg);
        }
    );
}
function getBankAttribute(medico, attribute) {
    if (medico && medico.atributos) {
        const attr = medico.atributos.find(attr => attr.nombre.toLowerCase() === attribute);
        return attr ? attr.valor : null;
    }
    return null;
}
</script>

<template>
    <setting-layout>
        <div class="card">
            <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item" v-for="estado in estados" :key="estado">
                        <button class="nav-link" :class="{ active: activeTab === estado }" @click="activeTab = estado">
                            {{ estado.replace('_', ' ').toUpperCase() }}
                        </button>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <!-- Sección: Generar Link -->
                <div v-if="activeTab === 'generacion_link'" class="p-2">
                    <h5 class="card-title mb-3">Generar Link</h5>
                    <div class="row g-3">
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Hospital</label>
                            <multiselect v-model="selectedPlantilla" :options="tiposPlantillas" label="descripcion"
                                track-by="id" placeholder="Seleccione Hospital" class="form-select-container">
                            </multiselect>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Médico</label>
                            <multiselect v-model="selectedMedico" :options="medicos" :searchable="true"
                                label="descripcion" track-by="id" placeholder="Buscar médico..."
                                @search-change="searchMedicos" class="form-select-container"></multiselect>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Tipo de Paciente</label>
                            <multiselect v-model="selectedTipoPaciente" :options="tiposPacientes" label="descripcion"
                                track-by="id" placeholder="Seleccione un tipo de paciente"
                                class="form-select-container"></multiselect>
                        </div>
                        <div class="col-md-6 col-lg-4"
                            v-if="selectedTipoPaciente && selectedTipoPaciente.descripcion.toLowerCase().includes('seguro')">
                            <label class="form-label">Seguro</label>
                            <multiselect v-model="selectedTipoSeguro" :options="tiposSeguros" label="descripcion"
                                track-by="id" placeholder="Seleccione un seguro" class="form-select-container">
                            </multiselect>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Monto</label>
                            <input type="number" class="form-control" v-model="monto" placeholder="Monto" />
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Nombre del Paciente</label>
                            <input type="text" class="form-control" v-model="paciente"
                                placeholder="Nombre del paciente" />
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Concepto</label>
                            <textarea class="form-control" v-model="concepto" placeholder="Concepto"
                                rows="3"></textarea>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Teléfono</label>
                            <input type="text" class="form-control" v-model="telefono" disabled
                                placeholder="Teléfono" />
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <label class="form-label">Correo</label>
                            <input type="email" class="form-control" v-model="email" disabled placeholder="Correo" />
                        </div>
                        <div class="col-12 mt-3">
                            <button class="btn btn-primary" @click="generateLink">
                                <i class="fa fa-link me-2"></i> Generar Link
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Sección: Links Generados -->
                <div v-if="activeTab === 'link_generado'" class="p-2">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="card-title">Links Generados</h5>
                        <button class="btn btn-success" @click="exportToExcel">
                            <i class="fa fa-file-excel me-2"></i> Descargar Excel
                        </button>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-vcenter card-table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Médico</th>
                                    <th>Seguro</th>
                                    <th>Paciente</th>
                                    <th>Tipo</th>
                                    <th>Monto</th>
                                    <th>Teléfono</th>
                                    <th>Email</th>
                                    <th>Fecha creación</th>
                                    <th>Fecha actualización</th>
                                    <th>Rechazos</th>
                                    <th>Notificado por</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="link in links" :key="link.id">
                                    <td>{{ link.id }}</td>
                                    <td>{{ link.medico.descripcion }}</td>
                                    <td v-if="link.tipo_seguro">{{ link.tipo_seguro.descripcion||'' }}</td>
                                    <td v-else>&nbsp;</td>
                                    <td>{{ link.paciente||'' }}</td>
                                    <td>{{ link.tipo_paciente.descripcion||'' }}</td>
                                    <td>{{ link.monto||'' }}</td>
                                    <td>{{ link.telefono||'' }}</td>
                                    <td>{{ link.email||'' }}</td>
                                    <td>{{ link.creado_en||'' }}</td>
                                    <td>{{ link.actualizado_en||'' }}</td>
                                    <td>{{ (typeof(link.serie) !== null) ? 1 : 0 }}</td>
                                    <td>
                                        <div class="btn-list">
                                            <button class="btn btn-icon btn-sm">
                                                <i class="fa fa-sms"></i>
                                            </button>
                                            <button class="btn btn-icon btn-sm">
                                                <i class="fa fa-envelope"></i>
                                            </button>
                                            <button class="btn btn-icon btn-sm">
                                                <i class="fa fa-mobile-phone"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Sección: Links Completados -->
                <div v-if="activeTab === 'link_completado'" class="p-2">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="card-title">Links Completados</h5>
                        <div class="btn-group" role="group">
                            <button class="btn btn-success" @click="exportToExcel">
                                <i class="fas fa-file-excel me-2"></i> Excel
                            </button>
                            <button class="btn btn-danger" @click="exportToPdf">
                                <i class="fas fa-file-pdf me-2"></i> PDF
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-vcenter card-table table-striped">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Médico</th>
                                    <th>Seguro</th>
                                    <th>Tipo</th>
                                    <th>Paciente</th>
                                    <th>Monto</th>
                                    <th>Serie</th>
                                    <th>Factura</th>
                                    <th>Cuenta bancaria</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="link in links" :key="link.id">
                                    <td>{{ link.id }}</td>
                                    <td>{{ link.medico.descripcion }}</td>
                                    <td v-if="link.tipo_seguro">{{ link.tipo_seguro.descripcion||'' }}</td>
                                    <td v-else>&nbsp;</td>
                                    <td>{{ link.tipo_paciente.descripcion||'' }}</td>
                                    <td>{{ link.paciente||'' }}</td>
                                    <td>{{ link.monto||'' }}</td>
                                    <td>{{ link.serie||'' }}</td>
                                    <td>{{ link.factura||'' }}</td>
                                    <td>
                                        <div class="row g-2">
                                            <div class="col-6">
                                                <span class="text-muted">Tipo:</span> {{ getBankAttribute(link.medico,
                                                'tipoCuenta') || 'Monetaria' }}
                                            </div>
                                            <div class="col-6">
                                                <span class="text-muted">Moneda:</span> {{ getBankAttribute(link.medico,
                                                'moneda') || 'GTQ' }}
                                            </div>
                                            <div class="col-6">
                                                <span class="text-muted">Banco:</span> {{ getBankAttribute(link.medico,
                                                'banco') || 'Banrural' }}
                                            </div>
                                            <div class="col-6">
                                                <span class="text-muted">Número:</span> {{ getBankAttribute(link.medico,
                                                'numeroCuenta') || '001-0054141-1' }}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="btn-list">
                                            <a class="btn btn-icon btn-success" title="Descargar PDF" target="_blank"
                                                :href="getPdfUrl(link)">
                                                <i class="fa fa-file-pdf"></i>
                                            </a>
                                            <button class="btn btn-icon btn-info" title="Aprobar"
                                                @click="approveLink(link.id)">
                                                <i class="fas fa-check"></i>
                                            </button>
                                            <button class="btn btn-icon btn-danger"
                                                @click="openRejectionModal(link.id)">
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Sección: Links Pagados -->
                <div v-if="activeTab === 'pagado'" class="p-2">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="card-title">Links Pagados</h5>
                        <div class="btn-group" role="group">
                            <button class="btn btn-success" @click="exportToExcel">
                                <i class="fas fa-file-excel me-2"></i> Excel
                            </button>
                            <button class="btn btn-danger" @click="exportToPdf">
                                <i class="fas fa-file-pdf me-2"></i> PDF
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-vcenter card-table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center w-1">ID</th>
                                    <th>Médico</th>
                                    <th>Seguro</th>
                                    <th>Tipo</th>
                                    <th>Paciente</th>
                                    <th>Retención IVA</th>
                                    <th>Retención ISR</th>
                                    <th>Monto</th>
                                    <th>Ref. Bancaria</th>
                                    <th>Monto Depositado</th>
                                    <th>Tipo de Pago</th>
                                    <th>Fecha de Pago</th>
                                    <th class="text-center w-1">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="link in links" :key="link.id">
                                    <td class="text-center">{{ link.id }}</td>
                                    <td>{{ link.medico.descripcion }}</td>
                                    <td v-if="link.tipo_seguro">{{ link.tipo_seguro.descripcion||'' }}</td>
                                    <td v-else>&nbsp;</td>
                                    <td>{{ link.tipo_paciente.descripcion||'' }}</td>
                                    <td>{{ link.paciente||'' }}</td>
                                    <td>00.00</td>
                                    <td>00.00</td>
                                    <td>{{ link.monto||'' }}</td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            v-model="getPaymentDetail(link.id).referencia"
                                            placeholder="Ref. Bancaria" />
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            v-model="getPaymentDetail(link.id).monto_pagado"
                                            placeholder="Monto Depositado" />
                                    </td>
                                    <td>
                                        <select class="form-select form-select-sm"
                                            v-model="getPaymentDetail(link.id).metodo">
                                            <option value="">Seleccione</option>
                                            <option value="Transferencia">Transferencia</option>
                                            <option value="Cheque">Cheque</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="date"
                                            v-model="getPaymentDetail(link.id).fecha" />
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-list flex-nowrap">
                                            <button class="btn btn-icon btn-success" title="Descargar PDF"
                                                @click="$window.open(getPdfUrl(link), '_blank')">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                            <button class="btn btn-icon btn-primary" title="Pagar"
                                                @click="pagar(link.id)">
                                                <i class="fa fa-dollar-sign"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Sección: Links Autorizados -->
                <div v-if="activeTab === 'autorizado'" class="p-2">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="card-title">Links Autorizados</h5>
                        <div class="btn-group" role="group">
                            <button class="btn btn-success" @click="exportToExcel">
                                <i class="fas fa-file-excel me-2"></i> Excel
                            </button>
                            <button class="btn btn-danger" @click="exportToPdf">
                                <i class="fas fa-file-pdf me-2"></i> PDF
                            </button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-vcenter card-table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center w-1"><input type="checkbox" class="form-check-input" /></th>
                                    <th>Médico</th>
                                    <th>Seguro</th>
                                    <th>Tipo</th>
                                    <th>Paciente</th>
                                    <th>Retención IVA</th>
                                    <th>Retención ISR</th>
                                    <th>Monto</th>
                                    <th>Serie</th>
                                    <th>Factura</th>
                                    <th>Fecha Pago</th>
                                    <th>Ref. Bancaria</th>
                                    <th>Monto Depositado</th>
                                    <th>Tipo de Pago</th>
                                    <th>Cuenta Monetaria</th>
                                    <th class="text-center w-1">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="link in links" :key="link.id">
                                    <td class="text-center"><input type="checkbox" class="form-check-input" /></td>
                                    <td>{{ link.medico.descripcion||'' }}</td>
                                    <td v-if="link.tipo_seguro">{{ link.tipo_seguro.descripcion||'' }}</td>
                                    <td v-else>&nbsp;</td>
                                    <td>{{ link.tipo_paciente.descripcion||'' }}</td>
                                    <td>{{ link.paciente||'' }}</td>
                                    <td>00.00</td>
                                    <td>00.00</td>
                                    <td>{{ link.monto||'' }}</td>
                                    <td>{{ link.serie||'' }}</td>
                                    <td>{{ link.factura }}</td>
                                    <td>
                                        <input class="form-control form-control-sm" type="date"
                                            v-model="getPaymentDetail(link.id).fecha" />
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            v-model="getPaymentDetail(link.id).referencia"
                                            placeholder="Ref. Bancaria" />
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            v-model="getPaymentDetail(link.id).monto" placeholder="Monto Depositado" />
                                    </td>
                                    <td>
                                        <select class="form-select form-select-sm"
                                            v-model="getPaymentDetail(link.id).metodo">
                                            <option value="">Seleccione</option>
                                            <option value="Transferencia">Transferencia</option>
                                            <option value="Cheque">Cheque</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input class="form-control form-control-sm" type="text"
                                            v-model="getPaymentDetail(link.id).cuenta"
                                            :placeholder="getBankAttribute(link.medico, 'numeroCuenta') || 'Cuenta Monetaria'" />
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-list flex-nowrap">
                                            <a class="btn btn-icon btn-success" title="Descargar PDF" target="_blank"
                                                :href="getPdfUrl(link)">
                                                <i class="fa fa-file-pdf"></i>
                                            </a>
                                            <button class="btn btn-icon btn-info" title="Pagar" @click="pagar(link.id)">
                                                <i class="fa fa-dollar-sign"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para rechazo -->
        <div v-if="showRejectionModal" class="modal modal-blur fade show" style="display: block" tabindex="-1"
            aria-labelledby="rejectionModalLabel" aria-hidden="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="rejectionModalLabel">Motivo de Rechazo</h5>
                        <button type="button" class="btn-close" @click="closeRejectionModal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="rejectionReason" class="form-label">Ingrese el motivo de rechazo:</label>
                            <textarea id="rejectionReason" class="form-control" v-model="rejectionReason"
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-link link-secondary"
                            @click="closeRejectionModal">Cancelar</button>
                        <button type="button" class="btn btn-danger ms-auto" @click="confirmRejection">
                            <i class="fas fa-times me-2"></i> Confirmar Rechazo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<style>
/* Estilos adicionales para vue-multiselect para que coincida con Tabler UI */
.form-select-container {
    width: 100%;
}

.multiselect {
    min-height: 36px;
    border-radius: 4px;
}

.multiselect__tags {
    min-height: 36px;
    padding: 4px 40px 0 8px;
    border: 1px solid #d9dbde;
    border-radius: 4px;
}

.multiselect__select {
    height: 36px;
}

.multiselect__input, .multiselect__single {
    margin-bottom: 4px;
    padding: 2px 0;
}

.multiselect--active {
    z-index: 100;
}

/* Estilo para los botones de acción */
.btn-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

/* Estilos para el modal */
.modal-blur .modal-dialog {
    transform: scale(0.95);
}

.modal-blur.show .modal-dialog {
    transform: scale(1);
    transition: transform 0.3s ease;
}
</style>