<template>
    <div>
        <ficha-layout title="Ginecología" color="#f69bab" :tabs="tabs" :tab-default=tabDefault enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #consulta>
               <consulta></consulta>
            </template>
            <template #prenatal>
               <prenatal></prenatal>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #seguimiento>
                <seguimiento></seguimiento>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #historial>
                <historial></historial>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
        </ficha-layout>
    </div>
</template>
<script>

import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


// Componentes globales
import FichaLayout from '../components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';

// componentes medicos
import Antecedentes from '../components/Antecedentes.vue';
import ExploracionFisica from '../components/ExploracionFisica.vue';
import ImpresionClinica from '../components/ImpresionClinica.vue';
import Tratamiento from '../components/Tratamiento.vue';
import Resumen from '../components/Resumen.vue';
import PreClinica from '../components/PreClinica.vue';
import Adjuntos from '../components/Adjuntos.vue';
import Historial from '../components/HistorialPr.vue';
import Prenatal from "../Prenatal/components/Consulta.vue";
import Seguimiento from '../components/Seguimiento.vue';
//Componentes 
import Consulta from "./components/Consulta.vue";

export default {
    components: {
        Box,
        FichaLayout,
        PreClinica,
        Antecedentes,
        ExploracionFisica,
        ImpresionClinica,
        Tratamiento,
        Resumen,
        Adjuntos,
        Historial,
        Consulta,
        Prenatal,
        Seguimiento
    },
    props:{
    tabDefault: {
        default: 'preclinica',
        type: String
    },
    },
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fas fa-stethoscope',
                    name: 'Ginecología',
                },
                prenatal: {
                    icon: 'fa-solid fa-person-pregnant',
                    name: 'Prenatal',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos',
                },
                historial: {
                    icon: 'fas fa-history',
                    name: 'Historial', 
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', 
                },
                seguimiento: {
                    icon: 'fa-solid fa-user-clock',
                    name: 'Seguimiento',
                },
            },
         
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
    },
    mounted() {
      
    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
        refresh() {
            this.$emit('refresh');
        },
       
    },
};

</script>
