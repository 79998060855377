<template>
    <layout>
        <div class="content-body orderDetails">
             <!-- Modal para visualización e impresión de PDF con fondo transparente y blur -->
             <div v-if="invoiceShow" class="print-modal-overlay" @click="closeModal">
                <div class="print-modal-container" @click.stop>
                    <div class="print-modal-content">
                        <div class="print-modal-header">
                            <h5 class="print-modal-title">Vista previa del documento</h5>
                            <button type="button" class="print-modal-close" @click="invoiceShow = false">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                        <div class="print-modal-body">
                            <div v-if="invoiceBlob" class="pdf-container">
                                <VuePdfEmbed
                                    ref="pdfRef"
                                    :source="invoiceBlob"
                                    :page="1"
                                    class="pdf-viewer"
                                />
                            </div>
                        </div>
                        <div class="print-modal-footer">
                            <div class="print-modal-actions">
                                <button type="button" class="btn btn-primary btn-sm" @click="finalPrint">
                                    <i class="la la-print mr-1"></i> Imprimir
                                </button>
                                <button type="button" class="btn btn-success btn-sm" @click="downloadPDF(invoiceBlob, 'documento')">
                                    <i class="la la-download mr-1"></i> Descargar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-80">
                <div class="container h-100">
                    <form v-if="order.id" id="formData" @submit.prevent="save" method="post">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-12">
                                <div class="card profile_card">
                                    <div :class="{
                                        'card-header bg-warning text-light py-2 px-4': (!order.pagado && (order.estado === 'creada' || order.estado === 'en_progreso')),
                                        'card-header bg-danger text-light py-2 px-4': order.estado === 'cancelada',
                                        'card-header bg-success text-light py-2 px-4': (order.pagado && (order.estado === 'creada' || order.estado === 'en_progreso')),
                                        'card-header bg-primary text-light py-2 px-4': order.estado === 'finalizada'
                                    }">
                                        <div class="card-title text-light mt-1">
                                            <router-link to="/orders">
                                                <i class="back fa fa-arrow-circle-left text-light"></i>
                                            </router-link>
                                            Código: <b class="text-light">{{ order.codigoOrden }}</b>
                                        </div>
                                        <div class="mt-1 font-weight-bold text-light text-right">
                                            <div v-if="order.estado === 'cancelada'">
                                                Orden cancelada
                                            </div>
                                            <div v-else-if="order.estado === 'finalizada'">
                                                Orden finalizada
                                            </div>
                                            <div v-else>
                                                <span v-if="order.pagado">Orden pagada</span>
                                                <span v-else>Pendiente de pago</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="media">
                                            <div class="media-body">
                                                <div class="font-weight-bold mb-3 text-center">
                                                    <span v-if="order.estado === 'cancelada'" class="text-danger">
                                                        Orden cancelada
                                                    </span>
                                                    <span v-else-if="order.estado === 'finalizada'" class="text-primary">
                                                        Orden finalizada
                                                    </span>
                                                    <span v-else-if="order.estado === 'en_progreso' && order.pagado" class="text-success">
                                                        Orden en progreso (Pagada)
                                                    </span>
                                                    <span v-else-if="order.estado === 'en_progreso' && !order.pagado" class="text-warning">
                                                        Orden en progreso (Pendiente de pago)
                                                    </span>
                                                    <span v-else-if="order.estado === 'creada' && order.pagado" class="text-success">
                                                        Orden creada (Pagada)
                                                    </span>
                                                    <span v-else class="text-warning">
                                                        Orden creada (Pendiente de pago)
                                                    </span>
                                                </div>
                                                <hr>
                                                <h4 class="mb-2" v-if="order.cliente && order.cliente !== '0'"><i class="fa fa-user-circle mr-3 text-primary"></i>{{ order.cliente.nombre }}</h4>
                                                <h4 class="mb-2" v-else><i class="fa fa-user-circle mr-3 text-primary"></i>{{ order.clientName }}</h4>
                                                <p class="mb-1"><span><i class="fa fa-phone mr-2"></i></span>
                                                    {{ (typeof order.telefonoContacto !== 'undefined' && order.telefonoContacto !== '0' && order.telefonoContacto !== '') ? order.telefonoContacto : 'Sin teléfono' }}
                                                </p>
                                                <p class="mb-1">
                                                    <span><i class="fa fa-address-card mr-2"></i></span>
                                                    {{ (typeof order.taxCode !== 'undefined' && order.taxCode !== '0') ? order.taxCode : 'Sin NIT' }}
                                                </p>
                                                <p class="mb-1">
                                                    <span><i class="fa fa-calendar mr-2"></i></span>
                                                    {{ dayjs(order.createdAt).subtract(6, 'hour').format('DD-MM-YYYY H:mm') }}
                                                </p>

                                                <p class="mb-1">
                                                    <span>Dirección de envío: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.direccionEnvio !== 'undefined' && order.direccionEnvio !== '0' && order.direccionEnvio) ? order.direccionEnvio : 'Sin dirección de envío' }}</span>
                                                </p>
                                                <p class="mb-1">
                                                    <span>Dirección de facturación: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.direccionFactura !== 'undefined' && order.direccionFactura !== '0' && order.direccionFactura) ? order.direccionFactura : 'Sin dirección de facturación' }}</span>
                                                </p>
                                                <p class="mb-1">
                                                    <span>Observaciones: </span>
                                                    <span class="font-italic text-dark">{{ (typeof order.observaciones !== 'undefined' && order.observaciones !== '0' && order.observaciones !== '' && order.observaciones) ? order.observaciones : 'Sin observaciones' }}</span>
                                                </p>
                                                <span class="text-success cursor-pointer hover-underline" @click="printInvoice('ordnPrnt')">
                                                    <i class="la la-file-pdf "></i>
                                                    Imprimir comprobante de orden
                                                </span>

                                            </div>
                                        </div>
                                        <div>
                                            <label>
                                                Estado de orden
                                            </label>
                                            <div class="input-group">
                                                <select v-model="estatus" class="form-control">
                                                    <option value="creada">Creada</option>
                                                    <option value="en_progreso">En progreso</option>
                                                    <option value="finalizada">Finalizada</option>
                                                    <option value="cancelada">Cancelada</option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button class="btn btn-dark btn-sm" type="button" @click="saveStatus">Actualizar</button>
                                                </div>
                                            </div>
                                            <div v-if="AuthGetUserInfo.configuration">
                                                <div v-if="typeof AuthGetUserInfo.configuration.order !== 'undefined' && (AuthGetUserInfo.configuration.order.verificarOrdenAlFinalizar && estatus === 'finalizada' && estatusGuardado !== 'finalizada')" class="mt-2">
                                                    <label>Ingrese el código de orden para confirmar</label>
                                                    <input type="text" class="form-control" v-model="confirmationOrdercode">
                                                </div>
                                            </div>
                                            <div v-if="showMotivoInput" class="mt-3">
                                                <label>Motivo de cancelación</label>
                                                <textarea class="form-control" v-model="cancelReason" rows="3" placeholder="Ingrese el motivo de cancelación"></textarea>
                                            </div>
                                            <div v-if="(!showMotivoInput && order.estado === 'cancelada')" class="mt-3">
                                                <label>Motivo de cancelación</label>
                                                <span
                                                    v-html="order.motivo_cancelacion"
                                                    type="text"
                                                    class="form-control"
                                                    id="motivo"
                                                    ></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header border-0">
                                        <h4 class="card-title">Detalle</h4>
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="detail-mini-navbar mb-4">
                                            <div class="nav-tabs-container">
                                                <div class="nav-tabs-row w-100 d-flex border-bottom">
                                                    <div @click="showSubCard('productos')"
                                                         class="nav-tab-item flex-grow-1 text-center py-2 cursor-pointer"
                                                         :class="{'active-tab': subSeccion === 'productos'}">
                                                        <span>Productos</span>
                                                    </div>
                                                    <div @click="showSubCard('formularios')"
                                                         class="nav-tab-item flex-grow-1 text-center py-2 cursor-pointer"
                                                         :class="{'active-tab': subSeccion === 'formularios'}">
                                                        <span>Formularios</span>
                                                    </div>
                                                    <div @click="showSubCard('historial')"
                                                         class="nav-tab-item flex-grow-1 text-center py-2 cursor-pointer"
                                                         :class="{'active-tab': subSeccion === 'historial'}">
                                                        <span>Historial</span>
                                                    </div>
                                                    <div @click="showSubCard('envios')"
                                                         class="nav-tab-item flex-grow-1 text-center py-2 cursor-pointer"
                                                         :class="{'active-tab': subSeccion === 'envios'}">
                                                        <span>Envíos</span>
                                                    </div>
                                                    <div @click="showSubCard('virtual')"
                                                         class="nav-tab-item flex-grow-1 text-center py-2 cursor-pointer"
                                                         :class="{'active-tab': subSeccion === 'virtual'}">
                                                        <span>Citas</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Subsección de productos -->
                                        <div v-if="subSeccion === 'productos'" class="mt-3">
                                            <div class="transaction-table">
                                                <div class="table-responsive">
                                                    <table class="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th width="40" class="pl-0"></th>
                                                                <th>Descripción</th>
                                                                <th class="text-center" width="80">Cantidad</th>
                                                                <th class="text-right" width="120">Precio</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="order.detalle">
                                                        <tr v-for="item in order.detalle" :key="item.id">
                                                            <td class="pt-2">
                                                                <i class="fas fa-check-circle text-success"></i>
                                                            </td>
                                                            <td v-if="item.producto">
                                                                <i class="cc"></i>
                                                                {{ item.producto.nombre }} {{ item.descripcion }}
                                                            </td>
                                                            <td v-else><i class="cc"></i> {{ item.descripcion }}</td>
                                                            <td class="text-primary text-center">{{ item.cantidad }} x</td>
                                                            <td class="text-right">
                                                                <span v-if="parseInt(item.precioOnSale) === 1">{{ Numbro((item.precioOnSale) ? item.precioOnSale : 0).formatCurrency() }}</span>
                                                                <span v-else>{{ Numbro((item.precio) ? item.precio : 0).formatCurrency() }}</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <div class="text-right pt-1">
                                                    <h5>Total: {{ Numbro((order.monto) ? order.monto : 0).formatCurrency() }}</h5>
                                                </div>
                                                <div class="text-right pt-1">
                                                    <h5 class="text-warning">
                                                        Descuento: {{ Numbro((order.descuento) ? order.descuento : 0).formatCurrency() }}
                                                    </h5>
                                                </div>
                                                <div class="text-right pt-1">
                                                    <h5 :class="{'text-success': order.montoPagado > 0, 'text-danger': parseFloat(order.montoPagado) === 0, 'text-danger': !order.montoPagado}">
                                                        Pagado: {{ Numbro((order.montoPagado) ? order.montoPagado : 0).formatCurrency() }}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Subsección de formularios -->
                                        <div v-if="subSeccion === 'formularios'">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-12">
                                                    <h4 class="card-title">Formularios asociados</h4>
                                                    <hr>
                                                    <div class="scroll-area">
                                                        <div v-for="form in forms" :key="'form_' + form.id">
                                                            <div class="row mb-4" v-if="((form.type === 'orderCreate' || form.type === 'orderPay') || (form.type === 'orderPost' && estatusGuardado === 'finalizada'))">
                                                                <div class="col-12">
                                                                    <h6 class="timeline-title bm-2">
                                                                        {{ form.descripcion }}
                                                                    </h6>
                                                                    <div class="text-right">
                                                                        <a :href="form.url" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Editar formulario</a>
                                                                        <div @click="downloadFormsTemplates(form.token)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Descargar</div>
                                                                        <div @click="sendFormsTemplates(form.token)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Enviar</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-for="form in formsNew" :key="'form_' + form.id">
                                                            <div class="row mb-4">
                                                                <div class="col-12">
                                                                    <h6 class="timeline-title bm-2">
                                                                        {{ form.prod }}
                                                                    </h6>
                                                                    <div class="text-right">
                                                                        <div @click="printFormsTemplates(form)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Imprimir</div>
                                                                        <div @click="validarAsistencia(form)" class="d-inline-block btn btn-sm btn-outline-dark mx-1" target="_blank">Marcar asistencia</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Subsección de historial -->
                                        <div v-if="subSeccion === 'historial'">
                                            <div class="row d-flex justify-content-center">
                                                <div class="col-12">
                                                    <h5 class="card-title mb-3">Historial de orden</h5>
                                                    <div class="scroll-area">
                                                        <div class="simple-timeline">
                                                            <div v-for="(item, index) in [...order.logs].reverse()"
                                                                :key="item.id"
                                                                class="timeline-item"
                                                                :class="{'first-item': index === 0, 'last-item': index === order.logs.length - 1}">
                                                                <div class="timeline-dot">
                                                                   <i class="fas fa-circle"></i>
                                                                </div>
                                                                <div class="timeline-content">
                                                                   <div class="timeline-header">
                                                                      <span class="timeline-title">
                                                                         {{ item.nota }}
                                                                      </span>
                                                                      <span class="timeline-date">
                                                                         {{ item.createdAt }}
                                                                      </span>
                                                                   </div>
                                                                   <div v-if="!Tools.isEmptyString(item.nota_data)" class="timeline-details">
                                                                      <a @click="item.showLogDetails = !item.showLogDetails" class="text-primary toggle-details">
                                                                         {{ item.showLogDetails ? 'Ocultar detalles' : 'Ver detalles' }}
                                                                         <i :class="item.showLogDetails ? 'fa fa-chevron-up ml-1' : 'fa fa-chevron-down ml-1'"></i>
                                                                      </a>
                                                                      <div v-if="item.showLogDetails" class="details-content">
                                                                         {{ item.nota_data }}
                                                                      </div>
                                                                   </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Subsección de envíos -->
                                        <div v-if="subSeccion === 'envios'">
                                            <h6>Envíos</h6>
                                            <div class="mt-3">
                                                <div class="row">
                                                    <div class="col-5 font-weight-bold">
                                                        Detalle
                                                    </div>
                                                    <div class="col-3 font-weight-bold">
                                                        Precio
                                                    </div>
                                                    <div class="col-3 font-weight-bold">
                                                        <i class="fas fa-list"></i>
                                                    </div>
                                                </div>
                                                <hr class="my-2">
                                                <div v-for="item in envios" :key="item.id">
                                                    <div class="row">
                                                        <div class="col-5">
                                                            <div class="font-weight-bold text-primary">
                                                                <span v-if="item.proveedorEnvio === 'estandarShipping'">Envío Estándar</span>
                                                                <span v-if="item.proveedorEnvio === 'forzaDeliveryExpress'">Forza Delivery Express</span>
                                                            </div>
                                                            <div v-if="item.tracking">
                                                                Tracking: {{ item.tracking }}
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            {{ Numbro((item.precio) ? item.precio : 0).formatCurrency() }}
                                                        </div>
                                                        <div class="col-4">
                                                            <div v-if="!item.tracking">
                                                                <a class="text-primary" @click="generarGuia(item.id)">
                                                                    <i class="fas fa-cloud-upload mr-2"></i> Generar guía
                                                                </a>
                                                            </div>
                                                            <div v-else>
                                                                <div>
                                                                    <a class="text-primary" @click="cancelarGuia(item.id)">
                                                                        <i class="fas fa-ban mr-2"></i> Cancelar guía
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <a class="text-primary" @click="printLabel(item)">
                                                                        <i class="fas fa-print mr-2"></i> Imprimir etiqueta
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <a class="text-primary" @click="getTracking(item.id)">
                                                                        <i class="fas fa-shipping-fast mr-2"></i> Ver Tracking
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="text-muted mt-3">
                                                                Podrás ver el tracking del pedido cuando se procese el envío
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="typeof tracking[item.id] !== 'undefined'" class="mt-2">
                                                        <div>
                                                            <div>
                                                                <span class="font-weight-bold">Origen:</span> {{ tracking[item.id].origin }}
                                                            </div>
                                                            <div>
                                                                <span class="font-weight-bold">Destino:</span> {{ tracking[item.id].destiny }}
                                                            </div>
                                                        </div>
                                                        <div v-for="(trackItem, index) in tracking[item.id].milestones" :key="'milestone' + trackItem.id + '-' + index">
                                                            <div>
                                                                <i class="fas fa-check mr-2"></i> {{ trackItem.date }} - {{ trackItem.description }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Subsección de citas -->
                                        <div v-if="subSeccion === 'virtual'">
                                            <div class="row">
                                                <div class="col-12">
                                                    <h4 class="card-title mb-3">Citas o agendamiento</h4>

                                                    <div v-if="Object.keys(reservas).length === 0" class="alert alert-info p-3 text-center">
                                                        <i class="fas fa-calendar-times mb-2" style="font-size: 1.5rem;"></i>
                                                        <p class="mb-0">No hay citas programadas para esta orden.</p>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-12 mb-3" v-for="item in reservas" :key="'reserva_' + item.id">
                                                            <div class="appointment-card-horizontal" @click="viewAppointmentDetails(item)">
                                                                <div class="appointment-status-indicator" :class="getStatusClass(item.estado)"></div>
                                                                <div @click.stop="$router.push('/medic/ficha/' + item.id)" class="appointment-content">
                                                                    <div class="appointment-main-info">
                                                                        <div class="appointment-icon">
                                                                            <i class="fas fa-calendar-check"></i>
                                                                        </div>
                                                                        <div class="appointment-title">
                                                                            <h6 class="mb-0 text-truncate">{{ item.producto ? item.producto.nombre : 'Cita' }}</h6>
                                                                            <span class="badge" :class="getStatusBadgeClass(item.estado)">
                                                                                {{ getStatusLabel(item.estado) }}
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="appointment-date-info">
                                                                        <div class="appointment-info-row">
                                                                            <i class="fas fa-calendar-day"></i>
                                                                            <span>{{ item.fechaReservada ?  item.fechaReservada : 'Sin fecha' }}</span>
                                                                        </div>
                                                                        <div class="appointment-info-row mb-0">
                                                                            <i class="fas fa-clock"></i>
                                                                            <span>{{ formatTime(item.horaInicio) }} - {{ formatTime(item.horaFin) }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Modal para detalles de la cita -->
                                            <div class="modal fade" id="appointmentDetailsModal" tabindex="-1" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header" :class="selectedAppointment ? getStatusHeaderClass(selectedAppointment.estado) : ''">
                                                            <h5 class="modal-title text-white">
                                                                {{ selectedAppointment ? (selectedAppointment.producto ? selectedAppointment.producto.nombre : 'Detalles de la cita') : 'Detalles de la cita' }}
                                                            </h5>
                                                            <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body p-4" v-if="selectedAppointment">
                                                            <div class="appointment-detail-item">
                                                                <span class="detail-label">Estado:</span>
                                                                <span class="badge" :class="getStatusBadgeClass(selectedAppointment.estado)">
                                                                    {{ getStatusLabel(selectedAppointment.estado) }}
                                                                </span>
                                                            </div>
                                                            <div class="appointment-detail-item">
                                                                <span class="detail-label">Fecha:</span>
                                                                <span>{{ formatDate(selectedAppointment.fechaReservada) }}</span>
                                                            </div>
                                                            <div class="appointment-detail-item">
                                                                <span class="detail-label">Horario:</span>
                                                                <span>{{ formatTime(selectedAppointment.horaInicio) }} - {{ formatTime(selectedAppointment.horaFin) }}</span>
                                                            </div>
                                                            <div class="appointment-detail-item" v-if="selectedAppointment.producto && selectedAppointment.producto.descripcion">
                                                                <span class="detail-label">Descripción:</span>
                                                                <span>{{ selectedAppointment.producto.descripcion }}</span>
                                                            </div>
                                                            <div class="appointment-detail-item" v-if="selectedAppointment.entrecitaMotivo">
                                                                <span class="detail-label">Motivo:</span>
                                                                <span>{{ selectedAppointment.entrecitaMotivo }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                                            <a v-if="selectedAppointment" class="btn btn-primary" @click="$router.push('/medic/ficha/' + selectedAppointment.id)">
                                                                Ver ficha completa
                                                            </a>
                                                            <a v-if="selectedAppointment && selectedAppointment.estado === 1" class="btn btn-success" @click="checkSession(selectedAppointment.id)">
                                                                Unirse a la cita
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 fullHeight">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="card-title">
                                            <h4>Pago y Facturación</h4>
                                        </div>
                                        <div class="actionContainer">
                                            <!-- Sección de Pago y Facturación -->
                                            <div>
                                                <div class="mb-1" v-if="notEmptyObject(order.pagos)">
                                                    <div>
                                                        <h6>Pagos efectuados a esta orden</h6>
                                                        <hr>
                                                        <div class="d-none d-sm-block">
                                                            <div class="row text-dark font-weight-bold">
                                                                <div class="col-12 col-sm-3">
                                                                    Tipo
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    Autorización
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    Fecha
                                                                </div>
                                                                <div class="col-12 col-sm-2">
                                                                    Monto
                                                                </div>
                                                                <div class="col-12 col-sm-1">
                                                                    <i class="fas fa-bars"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr class="d-none d-sm-block">
                                                        <div v-for="item in order.pagos" :key="item.id">
                                                            <div :class="{'row text-danger': (item.type === 'card' && (!item.autorizacion || item.autorizacion === '0')), 'row': (item.type !== 'card' || (item.type === 'card' && item.autorizacion))}">
                                                                <div class="col-12 col-sm-3">
                                                                    <div v-if="item.type === 'card'">Tarjeta
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span><span v-if="item.cuotas > 0">({{ item.cuotas }}) cuotas</span>
                                                                    </div>
                                                                    <div v-if="item.type === 'cash'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>
                                                                        Efectivo
                                                                    </div>
                                                                    <div v-if="item.type === 'pinpad'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>Tarjeta (POS)
                                                                    </div>
                                                                    <div v-if="item.type === 'paywallet_card_validation'">
                                                                        <span class="d-inline-block d-sm-none font-weight-bold">Tipo:</span>
                                                                        PayG-Wallet
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Autorización:</span>
                                                                    {{ (item.autorizacion && item.autorizacion !== '0'&& item.autorizacion !== '') ? item.autorizacion : ((item.type === 'card') ? "Pago no autorizado": 'No aplica') }}
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Fecha:</span>
                                                                    {{ formatDate(item.createdAt) }}
                                                                </div>
                                                                <div class="col-12 col-sm-2">
                                                                    <span class="d-inline-block d-sm-none font-weight-bold">Monto:</span>
                                                                    {{ formatPrice(item.monto) }}
                                                                </div>
                                                                <div class="col-12 col-sm-1">
                                                                    <div @click="printPayment(item.id)" v-tooltip="'Permite imprimir el voucher de pago'" v-if="((item.type === 'card' && item.autorizacion && item.autorizacion !== '0') || item.type !== 'card') && !item.anuladoDate">
                                                                        <i class="fa fa-print text-primary cursor-pointer"></i>
                                                                    </div>
                                                                    <div @click="cancelPayment(item)" v-tooltip="'Anular pago'" v-if="(((item.type === 'card' || item.type === 'pinpad')&& item.autorizacion && item.autorizacion !== '0') || item.type !== 'card') && !item.anuladoDate">
                                                                        <i class="fa fa-ban text-danger cursor-pointer"></i>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 text-dark mt-2" v-if="item.type === 'card'">
                                                                    <div>
                                                                        <b>Tarjeta:</b> **** **** **** {{item.cardLastDigits}}
                                                                    </div>
                                                                    <div v-if="!Tools.isEmptyString(item.errorLog)">
                                                                        <b>Errores:</b> {{item.errorLog}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="item.anuladoDate" class="row text-danger">
                                                                <div class="col-12 col-sm-3">
                                                                    Anulado
                                                                </div>
                                                                <div class="col-12 col-sm-3 text-uppercase">
                                                                    {{ item.anuladoReferencia }}
                                                                </div>
                                                                <div class="col-12 col-sm-3">
                                                                    {{ formatDate(item.anuladoDate) }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                </div>
                                                <div v-if="(parseFloat(order.montoPagado) < parseFloat(order.monto) || !order.montoPagado)">
                                            <div>
                                                <div class="d-flex justify-content-between align-items-center mb-1">
                                                    <h6 class="mb-0">Pagar por link de pago o en punto de venta</h6>
                                                </div>
                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-8 col-sm-12 mb-1">
                                                        <div class="small" v-if="parseInt(order.pagado) === 0">

                                                            <div class="input-group mb-1">
                                                                <input type="email"
                                                                      class="form-control"
                                                                      placeholder="Email para enviar enlace"
                                                                      v-model="resendEmail">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-primary px-3"
                                                                            type="button"
                                                                            @click="resendOrderPaylink"
                                                                            v-tooltip="'Enviar enlace por correo'">
                                                                        <i class="la la-paper-plane"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <a class="btn btn-outline-primary btn-block mb-1"
                                                               :href="'https://' + order.tokenUrl"
                                                               target="_blank"
                                                               v-tooltip="'Abrir enlace de pago'">
                                                                <i class="la la-external-link-alt mr-1"></i>
                                                                Abrir enlace de pago
                                                            </a>
                                                            <button @click.prevent="loadToPOS" class="btn btn-outline-primary btn-block">
                                                                <i class="la la-cash-register mr-1"></i>
                                                                Ver orden en punto de venta
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 col-sm-12 text-center mb-1">
                                                        <img :src="order.payLinkQR"
                                                             class="img-fluid payment-qr"
                                                             v-tooltip="'Escanea para pagar'"/>
                                                        <div class="mt-1 text-muted small">Escanea para pagar</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr>
                                        </div>
                                                <div class="mt-2">
                                                    <div class="d-flex justify-content-between align-items-center mb-3">
                                                        <h6 class="mb-0">Facturación</h6>
                                                    </div>
                                                    <hr>
                                                    <div v-if="Access('orders-pos-fel')">
                                                        <!-- Estado informativo de pago y facturación -->
                                                        <div v-if="!notEmptyObject(order.fcUID.link)" class="mb-3">
                                                            <div class="alert" :class="{
                                                                'alert-warning': !order.pagado,
                                                                'alert-info': order.pagado
                                                            }">
                                                                <div class="d-flex align-items-center">
                                                                    <i class="fa" :class="{
                                                                        'fa-exclamation-triangle': !order.pagado,
                                                                        'fa-info-circle': order.pagado
                                                                    }" style="font-size: 1.2rem; margin-right: 10px;"></i>
                                                                    <div>
                                                                        <span v-if="order.pagado">
                                                                            <strong>Orden pagada.</strong> La orden ha sido pagada pero aún no se ha generado la factura.
                                                                        </span>
                                                                        <span v-else>
                                                                            <strong>Orden pendiente de pago.</strong> La orden no ha sido pagada y no se ha generado factura.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <button
                                                                @click.prevent="previewInvoice = !previewInvoice"
                                                                class="btn btn-outline-primary btn-block py-2 mt-1"
                                                            >
                                                                <i :class="previewInvoice ? 'la la-arrow-circle-up mr-2': 'la la-file-invoice mr-2'"></i>
                                                                <span v-text="previewInvoice ? 'Cancelar': 'Generar factura'"></span>
                                                            </button>
                                                        </div>

                                                        <!-- Información cuando ya hay una factura generada pero no se ha pagado -->
                                                        <div v-else-if="notEmptyObject(order.fcUID.link) && !order.pagado" class="alert alert-warning mb-3">
                                                            <div class="d-flex align-items-center">
                                                                <i class="fa fa-exclamation-circle" style="font-size: 1.2rem; margin-right: 10px;"></i>
                                                                <div>
                                                                    <strong>Orden sin pago completo.</strong> La factura ha sido generada pero la orden no registra pago completo.
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div v-if="!notEmptyObject(order.fcUID.link)">
                                                            <!-- Interfaz de generación de facturas -->
                                                            <div v-if="previewInvoice">
                                                                <div class="p-3 border rounded bg-light">
                                                                    <div class="d-flex justify-content-end mb-3">
                                                                        <label class="toggle">
                                                                            <input class="toggle-checkbox" type="checkbox" v-model="isCambiaria"/>
                                                                            <div class="toggle-switch"></div>
                                                                            <span class="toggle-label">
                                                                                Factura Cambiaria
                                                                                <i class="fa fa-question-circle" v-tooltip="'Se emitirá una factura tributaria cambiaria correspondiente al monto de esta orden'"></i>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-6 col-sm-12 mb-3">
                                                                            <label>NIT</label>
                                                                            <div class="input-group">
                                                                                <div class="input-group-prepend cursor-pointer" @click="order.taxCode = 'CF'">
                                                                                    <span class="input-group-text">C/F</span>
                                                                                </div>
                                                                                <input type="text" v-on:keyup.enter="consultaNit" class="form-control" placeholder="NIT" v-model="order.taxCode">
                                                                                <div class="input-group-append cursor-pointer" @click="consultaNit()">
                                                                                    <span class="input-group-text"><i class="la la-search"></i></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-6 col-sm-12 mb-3">
                                                                            <label>Fecha de emisión</label>
                                                                            <DatePicker v-model="invoiceDate" mode="date" :attributes="facturacionCalendar" :max-date="lastDayOfMonth" :min-date="minDateInvoice">
                                                                                <template v-slot="{ inputValue, inputEvents }">
                                                                                    <input
                                                                                        class="form-control"
                                                                                        :value="inputValue"
                                                                                        v-on="inputEvents"
                                                                                    />
                                                                                </template>
                                                                            </DatePicker>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 mb-3">
                                                                            <label>Nombre</label>
                                                                            <input class="form-control" v-model="order.clientName" placeholder="Nombre Cliente">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 mb-3">
                                                                            <label>Dirección</label>
                                                                            <input class="form-control" v-model="order.direccionFactura" placeholder="Dirección factura">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 mb-3">
                                                                            <label>Correo</label>
                                                                            <input class="form-control" v-model="order.correoContacto" placeholder="Email">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 mb-3" v-if="Object.keys(locaciones).length > 0">
                                                                            <label>Seleccione la locación</label>
                                                                            <multiselect v-model="locacionId"
                                                                                        :options="locaciones"
                                                                                        track-by="id" label="nombre"
                                                                                        :close-on-select="true"
                                                                                        :clear-on-select="true"
                                                                                        :preserve-search="true"
                                                                                        placeholder="Selecciona una locación para facturar"
                                                                                        :multiple="false"
                                                                                        :internal-search="true">
                                                                            </multiselect>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 mb-3" v-if="fel.length > 0">
                                                                            <label>Seleccione emisor de factura</label>
                                                                            <multiselect v-model="felSelected"
                                                                                        :options="fel"
                                                                                        track-by="id" label="NombreComercial"
                                                                                        :close-on-select="true"
                                                                                        :clear-on-select="true"
                                                                                        :preserve-search="true"
                                                                                        placeholder="Selecciona un emisor"
                                                                                        :multiple="false"
                                                                                        :internal-search="true">
                                                                            </multiselect>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-right mt-3">
                                                                        <button @click.prevent="createInvoice()" class="btn btn-success btn-block py-2">
                                                                            <i class="la la-file-invoice mr-2"></i>
                                                                            Emitir factura por {{ formatPrice(order.monto || 0) }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <div class="invoice-card card-body bg-light rounded p-3">
                                                                <div class="d-flex align-items-center justify-content-between mb-2">
                                                                    <h6 class="font-weight-bold mb-0" :class="{'text-success': !order.acUID, 'text-danger': order.acUID}">
                                                                        <i :class="order.acUID ? 'fa fa-ban mr-2' : 'fa fa-check-circle mr-2'"></i> 
                                                                        {{ order.acUID ? 'Factura anulada' : 'Factura emitida' }}
                                                                    </h6>
                                                                    <span class="badge" :class="order.acUID ? 'badge-danger' : 'badge-success'">
                                                                        {{ order.acUID ? 'Anulada' : 'Emitida' }}
                                                                    </span>
                                                                </div>
                                                                <hr class="my-2">
                                                                <div class="row">
                                                                    <div class="col-6 mb-2">
                                                                        <span class="text-muted small">Número:</span>
                                                                        <div class="font-weight-bold">{{ order.fcUID.numero || '' }}</div>
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <span class="text-muted small">Serie:</span>
                                                                        <div class="font-weight-bold">{{ order.fcUID.serie || '' }}</div>
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <span class="text-muted small">Autorización:</span>
                                                                        <div class="font-weight-bold text-truncate">{{ order.fcUID.fcUID || '' }}</div>
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <span class="text-muted small">Fecha:</span>
                                                                        <div class="font-weight-bold">{{ formatOriginalDate(order.fcDate) }}</div>
                                                                    </div>
                                                                </div>
                                                                <!-- Se muestra el input de motivo o los botones -->
                                                                <div v-if="previewAnulacion" class="mt-3 p-3 border rounded bg-white">
                                                                    <!-- Input para el motivo -->
                                                                    <div class="input-group mb-3">
                                                                        <input placeholder="Motivo de anulación" type="text" class="form-control" v-model="motivo" aria-label="Motivo" aria-describedby="inputGroup-sizing-default">
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text" id="inputGroup-sizing-default">
                                                                                <i class="fa fa-comment"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-right">
                                                                        <button @click.prevent="previewAnulacion = false" class="btn btn-outline-danger btn-sm mr-2">
                                                                            <i class="la la-times mr-1"></i> Cancelar
                                                                        </button>
                                                                        <button @click.prevent="anularInvoice()" class="btn btn-outline-success btn-sm">
                                                                            <i class="la la-check mr-1"></i> Anular por {{ formatPrice(order.monto || 0) }}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div v-if="previewNc" class="mt-3 p-3 border rounded bg-white">
                                                                    <div class="row mx-auto pb-3">
                                                                        <div class="col-12 p-1" v-if="order.taxCode">
                                                                            <div class="small text-muted mb-3">
                                                                                <i class="fa fa-info-circle mr-1"></i>
                                                                                La nota de crédito permite anular parcial o totalmente una factura sin generar un nuevo documento fiscal.
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <div @click.prevent="previewNc = false" class="btn btn-outline-danger btn-sm mt-3 mr-2">
                                                                                    Cancelar
                                                                                </div>
                                                                                <div @click.prevent="ncInvoice()" class="btn btn-outline-success btn-sm mt-3" v-tooltip="'Genera una nota de crédito contra la factura actual'">
                                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                                    Nota de crédito por {{ formatPrice(order.monto || 0) }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="previewNa" class="mt-3 p-3 border rounded bg-white">
                                                                    <div class="row mx-auto pb-3">
                                                                        <div class="col-12 p-1" v-if="order.taxCode">
                                                                            <div class="small text-muted mb-3">
                                                                                <i class="fa fa-info-circle mr-1"></i>
                                                                                La nota de abono registra un crédito a favor del cliente que puede ser utilizado en compras futuras.
                                                                            </div>
                                                                            <div class="text-right">
                                                                                <div @click.prevent="previewNa = false" class="btn btn-outline-danger btn-sm mt-3 mr-2">
                                                                                    Cancelar
                                                                                </div>
                                                                                <div @click.prevent="naInvoice()" class="btn btn-outline-success btn-sm mt-3" v-tooltip="'Emite una nota de abono por el monto de la factura'">
                                                                                    <i class="la la-file-invoice mr-2"></i>
                                                                                    Nota de abono por {{ formatPrice(order.monto || 0) }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div v-if="(!previewNa && !previewNc && !previewAnulacion)" class="d-flex flex-wrap justify-content-center mt-3">
                                                                    <a target="_blank" :href="order.fcUID.link" class="btn btn-sm btn-outline-primary mx-1 mb-2" v-tooltip="'Ver documento en el portal de validación'">
                                                                        <i class="la la-external-link-alt mr-1"></i> Validar
                                                                    </a>
                                                                    <div @click.prevent="printInvoice('fcUID')" class="btn btn-sm btn-outline-success mx-1 mb-2" v-tooltip="'Ver e imprimir factura'">
                                                                        <i class="fas fa-print mr-1"></i> Imprimir
                                                                    </div>
                                                                    <div v-if="!notEmptyObject(order.acUID) && notEmptyObject(order.fcUID.link)" 
                                                                         @click.prevent="previewAnulacion = true" 
                                                                         class="btn btn-sm mx-1 mb-2" 
                                                                         :class="{ 
                                                                             'btn-outline-danger': isCurrentMonth(order.fcDate),
                                                                             'btn-outline-dark': !isCurrentMonth(order.fcDate)
                                                                         }"
                                                                         v-tooltip="'Anular factura'">
                                                                         <i class="fas fa-ban mr-1"></i> Anular
                                                                    </div>
                                                                    <div v-if="!notEmptyObject(order.ncUID) && notEmptyObject(order.fcUID.link)" 
                                                                         @click.prevent="previewNc = true" 
                                                                         class="btn btn-sm mx-1 mb-2" 
                                                                         :class="{
                                                                             'btn-outline-danger': isPastMonth(order.fcDate),
                                                                             'btn-outline-dark': !isPastMonth(order.fcDate)
                                                                         }"
                                                                         v-tooltip="'Crear nota de crédito contra esta factura'">
                                                                         <i class="fas fa-receipt mr-1"></i> Nota de Crédito
                                                                    </div>
                                                                    <div v-if="!notEmptyObject(order.naUID) && notEmptyObject(order.fcUID.link)" 
                                                                         @click.prevent="previewNa = true" 
                                                                         class="btn btn-sm mx-1 mb-2" 
                                                                         :class="{
                                                                             'btn-outline-danger': isPast60Days(order.fcDate),
                                                                             'btn-outline-dark': !isPast60Days(order.fcDate)
                                                                         }"
                                                                         v-tooltip="'Generar un abono a favor del cliente'">
                                                                         <i class="fas fa-money-bill-wave mr-1"></i> Nota de Abono
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="alert alert-warning">
                                                            <i class="fa fa-exclamation-triangle mr-2"></i> No tiene acceso para generar facturas electrónicas.
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mt-5">
                                                    <h6>Códigos de descuento aplicados</h6>
                                                    <div v-if="order.codigos_promocionales && order.codigos_promocionales.length > 0">
                                                        <ul class="list-group">
                                                            <li class="list-group-item" v-for="item in order.codigos_promocionales" :key="'descuento'+item.id">
                                                                <b>Código:</b> {{item.shortCode}} - <b>Monto:</b> {{item.amount}}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div v-else class="text-muted">
                                                        Sin códigos de descuento aplicados
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div v-else>
                        <div class="text-center">
                            No se encontró ninguna orden
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapGetters} from "vuex";
import {DatePicker} from 'v-calendar';
import VuePdfEmbed from 'vue-pdf-embed'
import AndroidBridge from "src/core/AndroidBridge";
import Multiselect from "vue-multiselect";
import bac_pinpad from "src/core/vendor/pinpad/bac_pinpad";

export default {
    components: {
        Multiselect,
        Layout,
        DatePicker,
        VuePdfEmbed
    },
    data() {
        return {
            // pinpad
            bacPinpad: false,

            locaciones:{},
            locacionId:{id:0},
            BridgeAndroid: null,
            Tools: Tools,
            userConfig: {},
            forms: {},
            order: {},
            reservas: {},
            envios: {},
            tracking: {},
            pagos: true,
            previewInvoice: false,
            previewAnulacion: false,
            previewNc: false,
            previewNa: false,
            viewInvoice: false,
            estatus: 'cancelada',
            estatusGuardado: '',
            confirmationOrdercode: '',
            motivo: '',
            showMotivoInput:'',
            cancelReason: '',
            facturaDetails: {},
            customEmailSendFactura: '',
            invoiceDate: new Date(),
            lastDayOfMonth: '',
            minDateInvoice: '',
            isCambiaria: false,
            fel:[],
            felSelected:{id:0},
            impuestos: 0,
            iva: 0,
            detalleImpuesto: [],
            facturacionCalendar: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,
                    dates: new Date(),

                }
            ],
            // reenvío de orden
            resendingOrder: false,
            resendEmail: "",
            invoiceShow: false,
            invoiceData: {},
            invoiceBlob: '',

            // voucher
            impresionVoucherUrl: '',

            // formularios nuevos
            formsNew: {},
            detalles: false,
            subSeccion: 'productos',
            selectedAppointment: null,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted() {
        const self = this;
        if(this.Access('orders-pos-fel')){
            this.getLocaciones();
        }
        this.BridgeAndroid = new AndroidBridge();
        this.loadItems();
        this.lastDayOfMonth = new Date(self.invoiceDate.getFullYear(), self.invoiceDate.getMonth() + 1, 0);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 5);

        this.minDateInvoice = minDate;

        this.bacPinpad = new bac_pinpad();
    },
    watch:{
        previewInvoice: function(){
            if(this.previewInvoice){
                this.getDataFel();
            }
        },
        invoiceDate: function(){
            console.log( this.invoiceDate );
        },
        estatus: function(newVal, oldVal) {
            if (newVal === 'cancelada' && oldVal !== 'cancelada') {
                this.showMotivoInput = true;
            } else {
                this.showMotivoInput = false;
            }
        },
        'order.taxCode': {
            handler(newValue) {
                // Si el valor es null, vacío, 0 o no es un número, setear 'CF'
                if (!newValue || newValue === '0' || newValue === '') {
                    this.order.taxCode = 'CF';
                }
            },
            immediate: true // Esto hará que se ejecute cuando el componente se monte
        }
    },
    methods: {
        dayjs,
        Numbro,
        getLocaciones(){
            const self = this;
            API.send('GET', 'locaciones/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },
        loadItems() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {
                API.send('GET', 'orders/generadas/' + self.$route.params.id, {},
                    function (data) {
                        if (data.status) {
                            self.order = data.data;
                            self.reservas = data.data.reservasReadable;
                            self.estatus = data.data.estado;
                            self.estatusGuardado = data.data.estado;
                            self.envios = data.data.envios;
                            // Inicializar el correo de reenvío con el correo de contacto de la orden
                            self.resendEmail = (typeof data.data.correoContacto !== 'undefined' && data.data.correoContacto !== '0')
                                ? data.data.correoContacto
                                : '';

                            // get forms
                            API.send('GET', 'formularios/responses/' + self.$route.params.id, {},
                                function (data) {
                                    if (data.status) {
                                        self.forms = data.data;
                                    }
                                },
                                function (data) {
                                    API.showErrorNotify(data.msg);
                                });

                            // get forms news
                            API.send('POST', 'formularios/get-for-order', {
                                    orderId: self.order.id
                                },
                                function (data) {
                                    if (data.status) {
                                        self.formsNew = data.data;
                                    }
                                },
                                function (data) {
                                    API.showErrorNotify(data.msg);
                                });
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    });
            }
        },
        sendFormsTemplates(token) {
            API.send('GET', 'formularios/pdf-creator/send/' + token, {},
                function (response) {
                    if (response.status) {
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
        saveStatus() {
            const self = this;

            // Validación de código para finalizar orden
            if (self.estatus === 'finalizada' && Tools.check.config('order', 'verificarOrdenAlFinalizar')) {
                if (String(self.confirmationOrdercode.trim()) !== String(self.order.codigoOrden.trim())) {
                    API.showErrorNotify('El código no pertenece a la orden actual');
                    return false;
                }
            }

            // Función principal para cambiar estado
            const changeStatus = (refreshOnFinish = false) => {
                API.send('PUT', 'orders/changeStatus', {
                    orderId: self.order.id,
                    status: self.estatus,
                    motivo: self.cancelReason
                },
                function (response) {
                    if (response.status) {
                        self.estatusGuardado = self.estatus;
                        self.order.estado = self.estatus;
                        API.showSuccessAlert(response.msg);
                        if (refreshOnFinish) {
                            self.loadItems();
                        }
                    } else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorAlert("Error al cancelar orden", response.msg);
                });
            };

            // Si el estado es 'cancelada'
            if (self.estatus === 'cancelada') {
                const processCancellation = () => {
                    if (self.notEmptyObject(self.order.pagos)) {
                        API.showConfirm(
                            'Pagos detectados',
                            'Si la orden es cancelada, se anularán los pagos que se encuentren activos. Esta acción no es reversible, ¿desea continuar?',
                            () => changeStatus(true),
                            false,
                            'Cancelar orden',
                            'Regresar'
                        );
                    } else {
                        changeStatus(false);
                    }
                };

                // Validación de motivo de cancelación
                if (self.cancelReason === '') {
                    API.showConfirm(
                        'No se agregó motivo de cancelación',
                        '¿Desea avanzar sin motivo de cancelación?',
                        processCancellation,
                        false,
                        'Cancelar orden',
                        'Regresar'
                    );
                } else {
                    processCancellation();
                }
            } else {
                // Para cualquier otro estado
                changeStatus(false);
            }
        },
        downloadFormsTemplates(token) {
            API.send('GET', 'formularios/pdf-creator/download/' + token, {},
                function (response) {
                    if (response.status) {
                        window.open(response.data.url);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
        printFormsTemplates(form) {
            const self = this;
            API.send('POST', 'formularios/ticket-printer/print-credential', {
                    token: self.order.token,
                    orderDetailId: form.id,
                    noEntrada: form.num,
                    formToken: form.ft,
                    fields: [],
                },
                function (response) {
                    if (response.status) {
                        window.open(response.data);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        validarAsistencia(form) {
            const self = this;
            API.showConfirm('Se marcará la asitencia, esta acción no se puede deshacer', '¿desea continuar?',function (){
                API.send('POST', 'formularios/verifyAssistance/verify', {
                        token: self.order.token,
                        od: form.id,
                        dn: form.num,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            })
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2)
            return val.toString()
        },
        notEmptyObject(someObject) {
            if (someObject) {
                return Object.keys(someObject).length
            }
            else {
                return false;
            }
        },
        showCard(stringToShow) {
            this.pagos = (stringToShow == 'pagos') ? true : false;
            this.detalles = (stringToShow == 'detalles') ? true : false;
        },
        showSubCard(subSeccion) {
            this.subSeccion = subSeccion;
        },
        loadToPOS() {

            this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');

            this.$store.dispatch('OrderPosClientSelect', {
                id: this.order.clienteId,
                email: this.order.correoContacto,
                taxId: this.order.taxCode,
                nombres: this.order.clientName,
                apellidos: '',
                telefono: this.order.telefonoContacto,
            });

            Object.entries(this.order.detalle).map(([key, val]) => {
                if (val.producto === null) {

                    const montoDirectoProduct = {
                        id: '_DIRECT_AMOUNT_',
                        nombre: 'Pago por monto directo',
                        precio: val.precio,
                        clearTotal: true,
                    };

                    this.$store.dispatch('OrderAddProductToPos', montoDirectoProduct);
                }
                else {
                    val.producto['quantity']=val.cantidad;
                    if (val.reserva && val.reserva.horaInicio) {
                        val.producto['citaHora'] = val.reserva.horaInicio;
                    }
                    if (val.reserva && val.reserva.fechaReservada) {
                        val.producto['citaFecha'] = val.reserva.fechaReservada;
                    }
                    this.$store.dispatch('OrderAddProductToPos', val.producto);
                }
            })

            this.$router.push({path: '/order/edit/' + this.order.id});

        },
        checkSession(reservaId) {
            const self = this;
            // obtengo lsa categorías
            API.send('POST', 'virtualmanager/reservas/checkroom', {reservaId: reservaId},
                function (response) {
                    if (response.status) {
                        if (response.data.token) {
                            window.open('/client/profile/virtualcall/' + response.data.token);
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        getDataFel() {
        const self = this;
            API.send('GET', 'payments/fel-by-user', {},
            function (response) {
                if (response.status) {
                    const felData = response.data.map(item => {
                        const payload = JSON.parse(item.payload); // Parseo el campo payload
                        return {
                            id: item.id,
                            NITEmisor: payload.NITEmisor,
                            NombreComercial: payload.NombreComercial
                        };
                    });

                    self.fel = felData;
                } else {
                    API.showErrorNotify(response.msg);
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            });
         },
        createInvoice() {
            const self = this;
            const dataSend = {
                id: self.$route.params.id,
                taxCode: self.order.taxCode,
                clientName: self.order.clientName,
                direccionFactura: self.order.direccionFactura,
                correoContacto: self.order.correoContacto,
                fcDate: self.invoiceDate ? self.dayjs(self.invoiceDate).format('DD-MM-YYYY H:mm') : null,
                isCambiaria: self.isCambiaria,
                sucursalId: self.locacionId.id,
                felId: self.felSelected.id

            }
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/crear', dataSend,
                function (response) {
                    if (response.status) {
                        self.order.fcUID = response.data;
                        self.previewInvoice = false;
                        self.viewInvoice = true;
                        API.showSuccessNotify(response.msg);
                    }
                },
                function (response) {
                    const { msg, data } = response;

                    if (!msg) {
                        API.showErrorNotify("Ha ocurrido algun error, intente nuevamente")
                        return;
                    }

                    let errorMsg = msg;

                    if (data && data.data && data.data.listado_errores && data.data.listado_errores.error) {

                        if(data.data.listado_errores.error.length > 0){
                        const errorMessages = data.data.listado_errores.error.map(item =>
                            `\n ->${item.desc_error} ERROR: [${item.cod_error}] `
                        );
                        errorMsg += errorMessages.join('');
                     }else if(data.data.listado_errores.error.desc_error){
                        errorMsg += `\n ->${data.data.listado_errores.error.desc_error} ERROR: [${data.data.listado_errores.error.cod_error}]`;
                     }

                    }
                    API.showErrorNotify(errorMsg);
                })
        },
        formatDate(date) {
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            const hours = String(d.getHours()).padStart(2, '0');
            const minutes = String(d.getMinutes()).padStart(2, '0');
            const seconds = String(d.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        async getFelIdForDocument() {
            // Si la orden ya tiene felId, lo usamos directamente
            if (this.order.felId) {
                return this.order.felId;
            }
            
            // Si no tiene felId, verificamos las afiliaciones disponibles
            if (this.fel.length === 0) {
                await new Promise((resolve) => {
                    API.send('GET', 'payments/fel-by-user', {},
                        (response) => {
                            if (response.status) {
                                const felData = response.data.map(item => {
                                    const payload = JSON.parse(item.payload);
                                    return {
                                        id: item.id,
                                        NITEmisor: payload.NITEmisor,
                                        NombreComercial: payload.NombreComercial
                                    };
                                });
                                this.fel = felData;
                                resolve();
                            } else {
                                throw new Error(response.msg || "Error al obtener afiliaciones FEL");
                            }
                        },
                        (response) => {
                            throw new Error(response.msg || "Error al obtener afiliaciones FEL");
                        }
                    );
                });
            }
            
            // Si hay solo una afiliación, usamos esa automáticamente
            if (this.fel.length === 1) {
                return this.fel[0].id;
            }
            
            // Si hay múltiples afiliaciones, mostramos selector y esperamos selección
            if (this.fel.length > 1) {
                this.felSelected = { id: 0 };
                
                return new Promise((resolve, reject) => {
                    // Creamos un modal para seleccionar la afiliación
                    const modalHtml = `
                        <div class="modal fade" id="felSelectorModal" tabindex="-1" role="dialog">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Seleccione el emisor de factura</h5>
                                        <button type="button" class="close" data-dismiss="modal">
                                            <span>&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Esta factura no tiene información del emisor. Por favor seleccione una afiliación FEL:</p>
                                        <select id="felSelectorDropdown" class="form-control">
                                            <option value="">-- Seleccione un emisor --</option>
                                            ${this.fel.map(f => `<option value="${f.id}">${f.NombreComercial}</option>`).join('')}
                                        </select>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                        <button type="button" id="felSelectorConfirm" class="btn btn-primary">Confirmar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;
                    
                    // Añadimos el modal al DOM
                    const modalDiv = document.createElement('div');
                    modalDiv.innerHTML = modalHtml;
                    document.body.appendChild(modalDiv);
                    
                    // Inicializamos el modal
                    const modalElement = jQuery('#felSelectorModal');
                    modalElement.modal('show');
                    
                    // Manejamos la selección
                    jQuery('#felSelectorConfirm').on('click', () => {
                        const selectedFelId = jQuery('#felSelectorDropdown').val();
                        if (!selectedFelId) {
                            API.showErrorNotify("Debe seleccionar un emisor de factura");
                            return;
                        }
                        
                        modalElement.modal('hide');
                        setTimeout(() => {
                            modalElement.remove();
                            resolve(selectedFelId);
                        }, 500);
                    });
                    
                    // Manejamos el cierre sin selección
                    modalElement.on('hidden.bs.modal', () => {
                        modalElement.remove();
                        if (!this.felSelected.id) {
                            reject(new Error("Debe seleccionar un emisor de factura para continuar"));
                        }
                    });
                });
            }
            
            throw new Error("No hay afiliaciones FEL disponibles para emitir documentos");
        },  
        async anularInvoice() {
            try {
                const felId = await this.getFelIdForDocument();
                const dataSend = {
                    id: this.$route.params.id,
                    motivo: this.motivo,
                    felId: felId
                };

                API.showConfirm('¿Está seguro de anular el documento?', 'Esta acción no se puede revertir', () => {
                    API.send('POST', 'orders/factura/anular', dataSend,
                        (response) => {
                            if (response.status) {
                                this.order.acUID = response.data;
                                this.previewAnulacion = false;
                                this.viewAnulacion = true;
                                API.showSuccessNotify(response.msg);
                            }
                        },
                        (response) => {
                            API.showErrorNotify(response.msg);
                        });
                });
            } catch (error) {
                API.showErrorNotify(error.message);
            }
        },

        async ncInvoice() {
            try {
                const felId = await this.getFelIdForDocument();
                const dataSend = {
                    id: this.$route.params.id,
                    taxCode: this.order.taxCode,
                    clientName: this.order.clientName,
                    direccionFactura: this.order.direccionFactura,
                    correoContacto: this.order.correoContacto,
                    fcDate: this.invoiceDate ? this.dayjs(this.invoiceDate).format('DD-MM-YYYY H:mm') : null,
                    isCambiaria: this.isCambiaria,
                    tipo: 'NCRE',
                    felId: felId
                }
                
                API.showConfirm('¿Está seguro de generar una nota de crédito?', 'Esta acción no se puede revertir', () => {
                    API.send('POST', 'orders/factura/crear', dataSend,
                        (response) => {
                            if (response.status) {
                                this.order.ncUID = response.data;
                                this.previewNc = false;
                                API.showSuccessNotify(response.msg);
                            }
                        },
                        (response) => {
                            API.showErrorNotify(response.msg);
                        });
                });
            } catch (error) {
                API.showErrorNotify(error.message);
            }
        },

        async naInvoice() {
            try {
                const felId = await this.getFelIdForDocument();
                const dataSend = {
                    id: this.$route.params.id,
                    taxCode: this.order.taxCode,
                    clientName: this.order.clientName,
                    direccionFactura: this.order.direccionFactura,
                    correoContacto: this.order.correoContacto,
                    fcDate: this.invoiceDate ? this.dayjs(this.invoiceDate).format('DD-MM-YYYY H:mm') : null,
                    isCambiaria: this.isCambiaria,
                    tipo: 'NABN',
                    felId: felId
                }
                
                API.showConfirm('¿Está seguro de generar una nota de abono?', 'Esta acción no se puede revertir', () => {
                    API.send('POST', 'orders/factura/crear', dataSend,
                        (response) => {
                            if (response.status) {
                                this.order.naUID = response.data;
                                this.previewNa = false;
                                API.showSuccessNotify(response.msg);
                            }
                        },
                        (response) => {
                            API.showErrorNotify(response.msg);
                        });
                });
            } catch (error) {
                API.showErrorNotify(error.message);
            }
        },
        consultaNit() {
            const self = this;
            // obtengo la info del NIT
            if (self.order.taxCode !== 'CF' && self.order.taxCode !== '') {
                API.send('GET', 'orders/factura/consulta/' + self.order.taxCode, {},
                    function (response) {
                        if (response.status) {
                            if (response.data.empty === false) {
                                self.order.clientName = response.data.nombres;
                                self.order.direccionFactura = response.data.direccion;
                            }
                            else {
                                self.loadItems();

                            }
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }

        },
        downloadPDF(file, tipo) {
            const linkSource = `${file}`;
            const downloadLink = document.createElement("a");
            const fileName = this.order.codigoOrden + tipo + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        resendOrderPaylink() {

            const self = this;
            if (self.resendEmail !== '') {
                API.send('POST', 'orders/resend', {
                        "id": self.order.id,
                        "email": self.resendEmail,
                        "type": "email"
                    },
                    function (response) {
                        if (response.status) {
                            self.resendingOrder = false;
                            API.showSuccessAlert(response.msg);
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                API.showErrorNotify('Ingrese un correo electrónico válido');
            }
        },
        printInvoice(tipo) {
            const self = this;
            if (self.invoiceBlob.length === 0) {
                API.send('GET', 'orders/factura/datos/' + self.order.id + '/' + tipo, {},
                    function (response) {
                        if (response.status) {
                            self.invoiceData = response.data;
                            if (self.BridgeAndroid.isOnTopWise()) {
                                self.BridgeAndroid.PrintFactura(self.invoiceData);
                            }
                            else {
                                self.invoiceShow = true;
                                self.invoiceBlob = self.invoiceData.pdf;
                            }
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
            else {
                if (self.BridgeAndroid.isOnTopWise()) {
                    self.BridgeAndroid.PrintFactura(self.invoiceData);
                }
                else {
                    self.invoiceShow = true;
                    self.invoiceBlob = self.invoiceData.pdf;
                }

            }
        },
        finalPrint() {
            const self = this;
            this.$refs.pdfRef.print(670, self.order.codigoOrden + "-fcUID.pdf");
        },

        // Envíos
        generarGuia(idEnvio) {
            const self = this;

            API.showConfirm('¿Está seguro?', 'Se generará una la guía de envío, no podrá cancelara luego de que esta sea aceptada por el proveedor de envíos', function () {
                // get forms
                API.send('POST', 'delivery/create/guide', {
                        'envioId': idEnvio
                    },
                    function (data) {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                API.showSuccessAlert(data.msg);
                                self.loadItems();
                            }
                            else {
                                API.showErrorAlert(data.msg);
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                        }
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    });
            });
        },
        cancelarGuia(idEnvio) {
            const self = this;
            API.showConfirm('¿Está seguro?', 'Se cancelará la guía de envío, esta accion no se puede revertir', function () {
                // get forms
                API.send('POST', 'delivery/cancel/guide', {
                        'envioId': idEnvio
                    },
                    function (data) {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                API.showSuccessAlert(data.msg);
                                self.loadItems();
                            }
                            else {
                                API.showErrorAlert(data.msg);
                            }
                        }
                        else {
                            API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                        }
                    },
                    function (data) {
                        console.log(data);
                        API.showErrorNotify(data.msg);
                    });
            })
        },
        getTracking(idEnvio) {
            const self = this;
            API.send('POST', 'delivery/tracking/guide', {
                    'envioId': idEnvio
                },
                function (response) {
                    if (typeof response.status !== 'undefined') {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                            self.tracking[idEnvio] = response.data;
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    }
                    else {
                        API.showErrorAlert('Error al crear guía, por favor intente de nuevo');
                    }
                },
                function (data) {
                    console.log(data);
                    API.showErrorNotify(data.msg);
                });
        },
        printLabel(item) {
            // get forms
            if (!Tools.isEmptyString(item.labelPrint)) {
                window.open(item.labelPrint);
            }
            else {
                API.showErrorAlert('Error al imprimir etiqueta');
            }
        },
        cancelPayment(payment) {
            const self = this;
            API.showConfirm('¿Está seguro de revertir el pago?', 'Esta acción no se puede deshacer', function () {

                const anular = function (reference) {

                    if (!reference) reference = false;

                    API.send('POST', 'payments/revert-pay', {
                            pagoId: payment.id,
                            reference: reference,
                        },
                        function (response) {
                            if (response.status) {
                                API.showSuccessNotify(response.msg);
                                self.loadItems();
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }
                        },
                        function (response) {
                            API.showErrorNotify(response.msg);
                        });
                }

                if (payment.type === 'pinpad') {
                    self.anulacionBacPinpad(payment, function (reference) {
                        anular(reference);
                    });
                }
                else {
                    anular();
                }


            })
        },
        printPayment(idPayment) {
            const self = this;

            self.impresionVoucherUrl = '';

            if (self.impresionVoucherUrl === '') {
                API.send('POST', 'payments/print-voucher', {
                        pagoId: idPayment
                    },
                    function (response) {
                        if (response.status) {
                            if (typeof response.data.url !== 'undefined') {
                                self.impresionVoucherUrl = response.data.url;
                                window.open(self.impresionVoucherUrl);
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }
                        }
                        else {
                            API.showErrorNotify(response.msg);
                                                                      }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            }
            else {
                window.open(self.impresionVoucherUrl);
            }
        },

        // Métodos para las citas
        viewAppointmentDetails(appointment) {
            this.selectedAppointment = appointment;
            // Usar jQuery para mostrar el modal (asumiendo que Bootstrap está disponible)
            document.getElementById('appointmentDetailsModal').modal('show');
        },

        formatDate(dateString) {
            const d = new Date(dateString);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const day = String(d.getDate()).padStart(2, '0');
            const hours = String(d.getHours()).padStart(2, '0');
            const minutes = String(d.getMinutes()).padStart(2, '0');
            const seconds = String(d.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },

        formatOriginalDate(dateString) {
            if (!dateString) return '';
            
            // Parse the date string manually to preserve the original time
            const date = new Date(dateString);
            
            // Use the date-fns library if available, or handle it directly
            const options = { 
                year: 'numeric', 
                month: 'numeric', 
                day: 'numeric',
                hour: '2-digit', 
                minute: '2-digit', 
                second: '2-digit',
                hour12: true,
                timeZone: 'America/Guatemala' // Force Guatemala timezone
            };
            
            return date.toLocaleString('es-GT', options);
        },

        formatTime(timeString) {
            if (!timeString) return '';

            // Convertir "18:34:13" a "18:34"
            return timeString.substring(0, 5);
        },

        getStatusClass(status) {
            // Estado numérico: 1 = confirmada, 2 = completada, 3 = cancelada, 0 = pendiente
            switch(parseInt(status)) {
                case 1: return 'status-confirmed';
                case 2: return 'status-completed';
                case 3: return 'status-cancelled';
                default: return 'status-pending';
            }
        },

        getStatusHeaderClass(status) {
            // Para el encabezado del modal
            switch(parseInt(status)) {
                case 1: return 'bg-success';
                case 2: return 'bg-secondary';
                case 3: return 'bg-danger';
                default: return 'bg-warning';
            }
        },

        getStatusBadgeClass(status) {
            switch(parseInt(status)) {
                case 1: return 'badge-success';
                case 2: return 'badge-secondary';
                case 3: return 'badge-danger';
                default: return 'badge-warning';
            }
        },

        getStatusLabel(status) {
            switch(parseInt(status)) {
                case 1: return 'Confirmada';
                case 2: return 'Completada';
                case 3: return 'Cancelada';
                default: return 'Pendiente';
            }
        },
        closeModal(e) {
            // Cerrar el modal al hacer clic fuera de él
            if (e.target.classList.contains('print-modal-overlay')) {
                this.invoiceShow = false;
            }
        },


        // Pinpad BAC - Anulación
        anulacionBacPinpad(payment, callback) {
            console.log("Default hardware:" + payment.TRM);
            const pinpadtest = "transactionType:VOID;terminalId:"+payment.TRM+";authorizationNumber:"+payment.autorizacion+";referenceNumber:"+payment.referenceNumber+";systemTraceNumber:"+payment.autorizacion+"";

            if (payment.TRM) {
                store.dispatch('coreShowLoading');
                this.bacPinpad.invoke(pinpadtest, "SdkInvoke", function (response) {
                    console.log(response);
                    store.dispatch('coreHideLoading');

                    if (typeof response.responseCode !== 'undefined') {
                        if (response.responseCode === '00' && typeof response.authorizationNumber !== 'undefined') {
                            const reference = (typeof response.referenceNumber !== 'undefined' ? response.referenceNumber : '');
                            callback(reference);
                        }
                        else {
                            API.showErrorNotify(response.responseCodeDescription);
                        }
                    }
                    else {
                        API.showErrorNotify('No es posible comunicarse con el POS, verifique su conexión');
                    }
                }, function (){
                    store.dispatch('coreHideLoading');
                    API.showErrorNotify('No es posible comunicarse con el POS, verifique su conexión y configuración');
                }, false);
            }
            else {
                API.showErrorNotify('Afiliación de pago inválida');
            }
        },
        isCurrentMonth(fechaEmision) {
            if (!fechaEmision) return false;
            const emisionDate = new Date(fechaEmision);
            const today = new Date();
            return emisionDate.getMonth() === today.getMonth() && 
                   emisionDate.getFullYear() === today.getFullYear();
        },

        isPastMonth(fechaEmision) {
            if (!fechaEmision) return false;
            const emisionDate = new Date(fechaEmision);
            const today = new Date();
            const diffMonths = (today.getFullYear() - emisionDate.getFullYear()) * 12 + 
                              (today.getMonth() - emisionDate.getMonth());
            return diffMonths >= 1;
        },

        isPast60Days(fechaEmision) {
            if (!fechaEmision) return false;
            const emisionDate = new Date(fechaEmision);
            const today = new Date();
            const diffTime = Math.abs(today - emisionDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            return diffDays >= 60;
        },
    },
};
</script>

<style scoped>
.nav-tabs-container {
    width: 100%;
    margin-bottom: 15px;
}

.nav-tabs-row {
    background-color: #fff;
    border-bottom: 1px solid #dee2e6;
}

.nav-tab-item {
    position: relative;
    padding: 10px 0;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}

.nav-tab-item.active-tab {
    border-bottom: 2px solid #467bfe;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    font-weight: 600;
}

.nav-tab-item:hover {
    background-color: #f8f9fa;
}

.cursor-pointer {
    cursor: pointer;
}

.detail-mini-navbar {
    margin-top: -5px;
}

/* Estilos para los botones de enlace */
.btn-outline-primary.btn-sm {
    transition: all 0.2s ease;
}

.btn-outline-primary.btn-sm:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Estilos para el QR */
.img-fluid {
    transition: transform 0.2s ease;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.img-fluid:hover {
    transform: scale(1.05);
}

/* Estilos para el QR */
.img-fluid.payment-qr {
    width: 130px;
    height: 130px;
    transition: transform 0.2s ease;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 0 auto;
}

.img-fluid.payment-qr:hover {
    transform: scale(1.05);
}

/* Nuevo timeline simple */
.simple-timeline {
    position: relative;
    padding: 0;
    margin-bottom: 1rem;
    max-height: 400px;
    overflow-y: auto;
}

.simple-timeline:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    background: #e9ecef;
    left: 12px;
    top: 0;
}

.timeline-item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 35px;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #f1f1f1;
}

.timeline-item:last-child {
    border-bottom: none;
}

.timeline-dot {
    position: absolute;
    left: 6px;
    top: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #e0e6ff; /* Color primario claro */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.timeline-item:first-child .timeline-dot {
    background-color: #467bfe; /* Color primario para el primer item */
}

.timeline-dot i {
    font-size: 8px;
    color: #fff;
}

.timeline-content {
    padding: 0;
}

.timeline-header {
    display: flex;
    justify-content: space-between; /* Space between para nota y fecha */
    align-items: flex-start;
    margin-bottom: 5px;
}

.timeline-title {
    font-weight: 500;
    color: #333;
    word-break: break-word;
    flex: 1;
    padding-right: 10px;
}

.timeline-date {
    font-size: 0.8rem;
    color: #6c757d;
    white-space: nowrap;
}

.timeline-details {
    margin-top: 5px;
}

.toggle-details {
    cursor: pointer;
    font-size: 0.85rem;
    color: #467bfe;
}

.details-content {
    background-color: #f9f9f9;
    padding: 8px;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 0.9rem;
}

/* Estilos para scroll */
.scroll-area {
    max-height: 400px;
    overflow-y: auto;
    padding-right: 5px;
}

.scroll-area::-webkit-scrollbar {
    width: 4px;
}

.scroll-area::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scroll-area::-webkit-scrollbar-thumb {
    background: #ced4da;
}

.scroll-area::-webkit-scrollbar-thumb:hover {
    background: #adb5bd;
}

/* Estilos para las cards de citas */
.appointment-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.08);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.appointment-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.appointment-status-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}

.status-confirmed {
    background-color: #28a745;
}

.status-completed {
    background-color: #6c757d;
}

.status-cancelled {
    background-color: #dc3545;
}

.status-pending {
    background-color: #ffc107;
}

.appointment-header {
    padding: 12px 15px 10px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
}

.appointment-icon {
    width: 36px;
    height: 36px;
    background-color: rgba(70, 123, 254, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.appointment-icon i {
    font-size: 16px;
    color: #467bfe;
}

.appointment-title {
    flex: 1;
}

.appointment-title h6 {
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 4px;
}

.appointment-body {
    padding: 12px 15px;
    flex-grow: 1;
}

.appointment-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 0.8rem;
}

.appointment-info-row i {
    width: 16px;
    margin-right: 8px;
    color: #6c757d;
    text-align: center;
}

.appointment-footer {
    padding: 10px 15px;
    display: flex;
    border-top: 1px solid rgba(0,0,0,0.05);
}

.appointment-footer .btn {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
}

/* Estilos para el modal de detalles */
.appointment-detail-item {
    margin-bottom: 12px;
}

.detail-label {
    font-weight: 600;
    color: #495057;
    margin-right: 8px;
}

/* Ajustando los estilos de badges */
.badge {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0.2rem 0.6rem;
    border-radius: 30px;
}

.badge-success {
    background-color: #d4edda;
    color: #155724;
}

.badge-secondary {
    background-color: #e2e3e5;
    color: #383d41;
}

.badge-danger {
    background-color: #f8d7da;
    color: #721c24;
}

.badge-warning {
    background-color: #fff3cd;
    color: #856404;
}

/* Estilos actualizados para las cards de citas */
.appointment-card-horizontal {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.08);
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    cursor: pointer;
    height: 80px;
    display: flex;
    width: 100%;
}

.appointment-card-horizontal:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.appointment-status-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
}

.appointment-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 15px 0 20px;
}

.appointment-main-info {
    display: flex;
    align-items: center;
    min-width: 220px;
    padding-right: 15px;
}

.appointment-icon {
    width: 36px;
    height: 36px;
    background-color: rgba(70, 123, 254, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
}

.appointment-title {
    max-width: 160px;
}

.appointment-date-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
}

.appointment-actions {
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.appointment-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
}
.print-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
}
.print-modal-container {
    width: 90%;
    max-width: 1000px;
    max-height: 90vh;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    animation: modalFadeIn 0.3s ease-out;
}
.print-modal-content {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.print-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    color: #343a40;
}
.print-modal-title {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
}
.print-modal-close {
    background: transparent;
    border: none;
    color: #6c757d;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0;
    transition: transform 0.2s ease;
}
.print-modal-close:hover {
    transform: scale(1.1);
    color: #343a40;
}
.print-modal-body {
    flex: 1;
    overflow: auto;
    padding: 0;
    background-color: #f8f9fa;
}
.pdf-container {
    height: 70vh;
    display: flex;
    flex-direction: column;
}
.pdf-viewer {
    flex: 1;
}
.print-modal-footer {
    padding: 15px 20px;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
}
.print-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
@keyframes modalFadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
</style>
