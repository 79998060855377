<template>
        <box  title="Antecedentes Generales" icon="fas fa-notes-medical" :contraction='true'>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Antecedentes</label>
                        <textarea v-model="antecedentes_generales" class="form-control" placeholder="Escribe aquí los antecedentes que presenta el paciente..." @change="saveAntecedentesG" style="min-height: 250px"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box  v-if="tipoFicha === 'ginecologia'" title="Antecedentes Obstétricos" icon="fas fa-notes-medical" :contraction='true'>
            <div class="row">
                <div v-for="item in antecedentes_obstetricos" class="col-12 col-md-4">
                    <div v-if="item.type !== 'select'" class="form-group w-75 mt-1">
                        <label>{{ item.label }}</label>
                        <input :type="item.type" class="form-control" :placeholder="item.placeholder" v-model="item.value" @change="saveGinec('antecedentes_obstetricos')">
                    </div>
                    <div v-else class="form-group w-75 mt-1">
                        <label>{{ item.label }}</label>
                        <select class="form-control" v-model="item.value" @change="saveGinec('antecedentes_obstetricos')" :placeholder="item.placeholder">
                            <option v-for="opt in item.options" :value="opt.value">{{ opt.label }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </box>
        <box title="Antecedentes Familiares" icon="fas fa-users" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in familiares" :key="'familiares_' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveFamiliares"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveFamiliares"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Antecedentes Médicos" icon="fas fa-notes-medical" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in medicos" :key="'medicos_' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveMedicos"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveMedicos"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Antecedentes Quirúrgicos" icon="fas fa-bed-pulse" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in quirurgicos" :key="'quirurgicos_' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveQuirurgicos"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveQuirurgicos"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Antecedentes Traumáticos" icon="fas fa-bone" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in traumaticos" :key="'traumaticos_' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveTraumaticos"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveTraumaticos"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <box title="Hábitos" icon="fas fa-smoking" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in habitos" :key="'habitos_' + key">
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveHabitos"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            {{ item.name }}
                        </span>
                    </label>
                    <div class="form-group" v-if="item.value">
                        <label>Observacion adicional</label>
                        <textarea v-model="item.detail" class="form-control text-limit" :placeholder="`Escribe aquí informacion adicional sobre (${item.name})`" @change="saveHabitos" style="min-height: 200px"></textarea>
                    </div>
                </div>
            </div>
        </box>
        <hr>
        <box title="Alergias" icon="fas fa-allergies" :contraction='true'>
            <div v-if="(GetClienteMetada.antecedentes_alergia)">
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                <th class="font-weight-bold">Fecha identificada</th>
                <th class="font-weight-bold">Alergia a</th>
                <th class="font-weight-bold">Reacción</th>
                <th class="font-weight-bold">Severidad</th>
                <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetClienteMetada.antecedentes_alergia" :key="item.id">
                <template v-if="(typeof item.detail !== 'undefined')">
                    <td>{{ item.detail.fechaIdentificada }}</td>
                    <td>{{ item.detail.alergiaA }}</td>
                    <td>{{ item.detail.reaccion }}</td>
                    <td>{{ item.detail.severidad }}/10</td>
                    <td>
                    <i @click="editAlergia(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                    <i @click="deleteAlergia(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                    </td>
                </template>
                </tr>
                </tbody>
            </table>
            </div>
            <div v-else>
            <div class="text-center text-danger">No tiene alergias ingresadas</div>
            </div>
            <div class="mt-5" v-if="showEditionAlergias">
            <div>
                <h5>Agregar Alergias</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Fecha identificada</label>
                    <date-picker v-model="alergia.detail.fechaIdentificada" mode="dateTime">
                    <template #default="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                    </template>
                    </date-picker>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Alergia a</label>
                    <input type="text" class="form-control" v-model="alergia.detail.alergiaA"/>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Reacción</label>
                    <input type="text" class="form-control" v-model="alergia.detail.reaccion"/>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Severidad</label>
                    <Slider class="m-0 m-auto" v-model="alergia.detail.severidad" :min="0" :max="10" tooltipPosition="bottom"/>
                </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditAlergia" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveAlergia" class="btn btn-primary mr-3">Guardar</button>
            </div>
            </div>
            <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addAlergia">
                <i class="fa fa-plus mr-2"></i>Agregar alergias
            </button>
            </div>
        </box>
        <hr>
        <box title="Medicamentos" icon="fas fa-pills" :contraction='true'>
            <div v-if="(GetClienteMetada.antecedentes_medicamentos)">
                <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                <th class="font-weight-bold">Nombre y formulación del medicamento</th>
                <th class="font-weight-bold">Fuente</th>
                <th class="font-weight-bold">Fecha de inicio</th>
                <th class="font-weight-bold">Observaciones</th>
                <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetClienteMetada.antecedentes_medicamentos" :key="item.id">
                <template v-if="(typeof item.detail !== 'undefined')">
                    <td>{{ item.detail.nombreYFormulacion }}</td>
                    <td>{{ item.detail.fuente }}</td>
                    <td>{{ item.detail.fechaInicio }}</td>
                    <td>{{ item.detail.observaciones }}</td>
                    <td>
                    <i @click="editMedicamento(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                    <i @click="deleteMedicamento(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                    </td>
                </template>
                </tr>
                </tbody>
            </table>
            </div>
            <div v-else>
                <div class="text-center text-danger">No tiene medicamentos ingresados</div>
            </div>
            <div class="mt-5" v-if="showEditionMedicamento">
            <div>
                <h5>Agregar historia de medicamentos</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Nombre y formulación del medicamento</label>
                    <input type="text" class="form-control" v-model="medicamento.detail.nombreYFormulacion"/>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Fuente</label>
                    <input type="text" class="form-control" v-model="medicamento.detail.fuente"/>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Fecha de inicio</label>
                    <date-picker v-model="medicamento.detail.fechaInicio" mode="dateTime">
                    <template #default="{ inputValue, inputEvents }">
                        <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                    </template>
                    </date-picker>
                </div>
                </div>
                <div class="col-12 col-sm-3">
                <div class="form-group">
                    <label>Observaciones</label>
                    <input type="text" class="form-control" v-model="medicamento.detail.observaciones"/>
                </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditMedicamento" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveMedicamento" class="btn btn-primary mr-3">Guardar</button>
            </div>
            </div>
            <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addMedicamento">
                <i class="fa fa-plus mr-2"></i>Agregar medicamento
            </button>
            </div>
        </box>
</template>

<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import DatosComplemento from "src/modules/medico/views/fichas/DatosComplemento.vue";

// Componentes

export default {
    components: {
        Box,
        DatosComplemento,
        Slider,
        DatePicker,
    },
    props: [
        'type'
    ],
    data() {
        return {
            nombre_conyugue: '',

            antecedentes_generales:'',

            antecedentes_obstetricos: {
            grupo_rh: {
                label: "Grupo sanguíneo Rh",
                value: "",
                placeholder: "Seleccione el grupo sanguíneo Rh de la paciente (A+, A-, B+, B-, AB+, AB-, O+, O-).",
                type: "select",
                options: [
                { value: "", label: "Seleccione el grupo sanguíneo Rh de la paciente (A+, A-, B+, B-, AB+, AB-, O+, O-)."},
                { value: "A+", label: "A+" },
                { value: "A-", label: "A-" },
                { value: "B+", label: "B+" },
                { value: "B-", label: "B-" },
                { value: "AB+", label: "AB+" },
                { value: "AB-", label: "AB-" },
                { value: "O+", label: "O+" },
                { value: "O-", label: "O-" }
                ]
            },
            coitarquia: {
                label: "Coitarquia ",
                value: "",
                placeholder: "Ingrese la fecha",
                type : "date"
            },
            fecha_ultima_regla: {
                label: "FUP (Fecha de última regla)",
                value: "",
                placeholder: "Ingrese la fecha de la última menstruación de la paciente (formato dd/mm/aaaa).",
                type : "date"
            },
            fecha_ultima_revision: {
                label: "FUR (Fecha de última revisión)",
                value: "",
                placeholder: "Ingrese la fecha de la última menstruación de la paciente (formato dd/mm/aaaa).",
                type : "date"
            },
            menarquia: {
                label: "Menarquia",
                value: "",
                placeholder: "Ingrese la edad a la que la paciente tuvo su primera menstruación.",
                type : "text"
            },
            ciclos_menstruales: {
                label: "Ciclos menstruales",
                value: "",
                placeholder: "Describa las características de los ciclos menstruales de la paciente (regularidad, duración, flujo, etc.).",
                type : "text"
            },
            coilarquia: {
                label: "Coilarquia",
                value: "",
                placeholder: "Ingrese la edad a la que la paciente inició su vida sexual.",
                type : "text"
            },
            acos: {
                label: "Acos (Planificación familiar)",
                value: "",
                placeholder: "Ingrese descripcion de su planificacion oral.",
                type : "text"
            },
            trh: {
                label: "TRH (Terapia de reemplazo hormonal)",
                value: "",
                placeholder: "Indique si la paciente ha recibido o está recibiendo terapia de reemplazo hormonal y por qué motivo.",
                type : "text"
            },
            its: {
                label: "Infecciones de transmisión sexual",
                value: "",
                placeholder: "Indique si la paciente ha tenido o tiene alguna infección de transmisión sexual.",
                type : "text"
            },
            menopausia: {
                label: "Menopausia",
                value: "",
                placeholder: "Ingrese la edad a la que la paciente experimentó la menopausia (si aplica).",
                type : "text"
            },
            parejas_sexuales: {
                label: "Parejas sexuales",
                value: "",
                placeholder: "Describa el número de parejas sexuales que ha tenido la paciente a lo largo de su vida.",
                type : "text"
            },
            fecha_mamografia: {
                label: "Fecha de mamografía",
                value: "",
                placeholder: "Ingrese la fecha de la última mamografía realizada a la paciente (formato dd/mm/aaaa).",
                type : "date"
            },
            observaciones_mamografia: {
                label: "Observaciones de mamografía",
                value: "",
                placeholder: "Incluya los hallazgos y resultados de la última mamografía realizada a la paciente.",
                type : "text"
            },
            fecha_densitometria: {
                label: "Fecha de densitometría",
                value: "",
                placeholder: "Ingrese la fecha de la última densitometría ósea realizada a la paciente (formato dd/mm/aaaa).",
                type : "date"
            },
            observaciones_densitometria: {
                label: "Observaciones de densitometría",
                value: "",
                placeholder: "Incluya los resultados e interpretación de la última densitometría ósea realizada a la paciente.",
                type : "text"
            },
            fecha_papanicolau: {
                label: "Fecha de Papanicolau",
                value: "",
                placeholder: "Ingrese la fecha de la última citología cervical (Papanicolau) realizada a la paciente (formato dd/mm/aaaa).",
                type : "date"
            },
            observaciones_papanicolau: {
                label: "Observaciones de Papanicolau",
                value: "",
                placeholder: "Incluya el resultado e interpretación de la última citología cervical (Papanicolau) realizada a la paciente.",
                type : "text"
            },
            fecha_vacuna_hpv1: {
                label: "Fecha de vacuna HPV 1",
                value: "",
                placeholder: "Ingrese la fecha de la primera dosis de la vacuna contra el VPH (formato dd/mm/aaaa).",
                type : "date"
            },
            fecha_vacuna_hpv2: {
                label: "Fecha de vacuna HPV 2",
                value: "",
                placeholder: "Ingrese la fecha de la segunda dosis de la vacuna contra el VPH (formato dd/mm/aaaa).",
                type : "date"
            },
            fecha_vacuna_hpv3: {
                label: "Fecha de vacuna HPV 3",
                value: "",
                placeholder: "Ingrese la fecha de la tercera dosis de la vacuna contra el VPH (si aplica, formato dd/mm/aaaa).",
                type : "date"
            },
            gesta: {
                label: "Gestaciones (Número de embarazos)",
                value: "",
                placeholder: "Ingrese el número total de embarazos que ha tenido la paciente.",
                type: "number"
            },
            pan: {
                label: "PAN (Partos a término)",
                value: "",
                placeholder: "Ingrese el número total de partos a término que ha tenido la paciente.",
                type: "number"
            },
            hijos_vivos: {
                label: "Hijos vivos",
                value: "",
                placeholder: "Ingrese el número de hijos vivos que tiene la paciente.",
                type: "number"
            },
            hijos_muertos: {
                label: "Hijos muertos",
                value: "",
                placeholder: "Ingrese el número de hijos nacidos muertos o fallecidos durante la infancia que tiene la paciente.",
                type: "number"
            },
            partos: {
                label: "Partos",
                value: "",
                placeholder: "Ingrese el número de partos vaginales que ha tenido la paciente.",
                type: "number"
            },
            abortos: {
                label: "Abortos",
                value: "",
                placeholder: "Ingrese el número de abortos espontáneos o inducidos que ha tenido la paciente.",
                type: "number"
            },
            cesareas: {
                label: "Cesáreas",
                value: "",
                placeholder: "Ingrese el número de cesáreas que ha tenido la paciente.",
                type: "number"
            },
            fecha_probable_parto: {
                label: "Fecha probable de parto",
                value: "",
                placeholder: "Ingrese la fecha probable de parto para el embarazo actual (formato dd/mm/aaaa), si aplica.",
                type: "date"
            },
            edad_gestacional: {
                label: "Edad gestacional",
                value: "",
                placeholder: "Ingrese la edad gestacional actual en semanas, si aplica.",
                type: "number"
            },
            planificacion_previa: {
                label: "¿Planificación familiar previa?",
                value: "no",
                placeholder: "Seleccione si la paciente tenía planificación familiar previa",
                type: "select",
                options: [
                { value: "no", label: "No" },
                { value: "si", label: "Sí" }
                ]
            },
            confiabilidad: {
                label: "Confiabilidad de la planificación",
                value: "no",
                placeholder: "Seleccione la confiabilidad de la planificación familiar previa",
                type: "select",
                options: [
                { value: "no", label: "No" },
                { value: "alta", label: "Alta" },
                { value: "media", label: "Media" },
                { value: "baja", label: "Baja" }
                ]
            },
            fecha_ultimo_parto: {
                label: "Fecha del último parto",
                value: "",
                placeholder: "Ingrese la fecha del último parto de la paciente (formato dd/mm/aaaa), si aplica.",
                type: "date"
            },
            peso: {
                label: "Peso actual (libras)",
                value: "",
                placeholder: "Ingrese el peso actual de la paciente en libras.",
                type: "number"
            },
            rn_con_mayor_peso: {
                label: "Peso del RN con mayor peso (libras)",
                value: "",
                placeholder: "Ingrese el peso del hijo de la paciente con mayor peso al nacer (en libras), si aplica.",
                type: "number"
            },
            rn_con_menor_peso: {
                label: "Peso del RN con menor peso (libras)",
                value: "",
                placeholder:"Ingrese el peso del hijo de la paciente con menor peso al nacer (en libras), si aplica",
                type: "number"
            },
            complicaciones_embarazo_actual: {
                label: "¿Complicaciones durante el embarazo actual?",
                value: "no",
                placeholder: "Seleccione si ha habido complicaciones durante el embarazo actual",
                type: "select",
                options: [
                { value: "no", label: "No" },
                { value: "si", label: "Sí" }
                ]
            },
            descripcion_complicaciones: {
                label: "Descripción de las complicaciones",
                value: "",
                placeholder: "Si ha habido complicaciones, describa la naturaleza y el manejo de las mismas.",
                type: "text"
            },
            control_prenatal: {
                label: "¿Control prenatal?",
                value: "no",
                placeholder: "Seleccione si la paciente ha recibido control prenatal",
                type: "select",
                options: [
                { value: "no", label: "No" },
                { value: "si", label: "Sí" }
                ]
            },
            numero_consultas_prenatales: {
                label: "Número de consultas prenatales",
                value: "",
                placeholder: "Ingrese el número de consultas prenatales realizadas hasta la fecha.",
                type: "number"
            },
            lugar_control_prenatal: {
                label: "Lugar de control prenatal",
                value: "",
                placeholder: "Ingrese el lugar donde la paciente ha recibido control prenatal.",
                type: "text"
            },
            observaciones: {
                label: "Observaciones",
                value: "",
                placeholder: "Incluya cualquier otra información relevante sobre el embarazo actual.",
                type: "textarea"
            },
            otras_observaciones: {
                label: "Otras observaciones",
                value: "",
                placeholder: "Incluya cualquier otra información relevante sobre el historial ginecológico de la paciente.",
                type : "text"
            }
            },
            familiares: {
                alergias: {
                    name: 'Alergias',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                asma: {
                    name: 'Asma',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                cancer: {
                    name: 'Cancer',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                cerebrovascular: {
                    name: 'Cerebrovascular',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                colesterol: {
                    name: 'Colesterol',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                depresion: {
                    name: 'Depresión',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                diabetes: {
                    name: 'Diabetes',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                dislipidemias: {
                    name: 'Dislipidemias',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedades_coronarias: {
                    name: 'Enfermedades coronarias',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedades_hereditarias: {
                    name: 'Enfermedades Hereditarias',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                gemelares: {
                    name: 'Gemelares',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hemofilia: {
                    name: 'Hemofilia',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hipertension: {
                    name: 'Hipertensión',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hipertension_arterial: {
                    name: 'Hipertensión Arterial',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                neoplasias: {
                    name: 'Neoplasias',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                obesidad: {
                    name: 'Obesidad',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                transtornos_psiquiatricos: {
                    name: 'Trastornos psiquiátricos',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                otra_enfermedad: {
                    name: 'Otra enfermedad',
                    complemento: {},
                    value: false,
                    detail: ''
                },
            },
            medicos: {
                alalia: {
                    name: 'Alalia (mudo)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                alteraciones_de_la_coagulacion_sanguieo: {
                    name: 'Alteraciones de la coagulación sanguínea',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                anemia: {
                    name: 'Anemia',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                angina_de_pecho: {
                    name: 'Angina de pecho',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                arritmia_cardiaca: {
                    name: 'Arritmia cardíaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                asma: {
                    name: 'Asma',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                calculos_renales: {
                    name: 'Cálculos renales',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                ceguera: {
                    name: 'Ceguera (ciego)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                dano_cerebral: {
                    name: 'Daño cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                dano_de_la_medula_espinal: {
                    name: 'Daño de la médula espinal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                descompensacion_cardiaca: {
                    name: 'Descompensación cardíaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                diabetes: {
                    name: 'Diabetes',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedad_cardiaca_congenita: {
                    name: 'Enfermedad cardíaca congénita',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedad_de_whipple: {
                    name: 'Enfermedad de WHIPPLE',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedad_valvular_cardiaca: {
                    name: 'Enfermedad valvular cardíaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enucleacion_de_globo_ocular: {
                    name: 'Enucleación de globo ocular (Tuerto)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                epilepsia: {
                    name: 'Epilepsia',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                esclerosis_multiple: {
                    name: 'Esclerosis múltiple',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                glaucoma: {
                    name: 'Glaucoma (hipertensión ocular)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hepatitis_virica: {
                    name: 'Hepatitis vírica',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hipertension_arterial: {
                    name: 'Hipertensión arterial',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hipertiroidismo: {
                    name: 'Hipertiroidismo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hipotiroidismo: {
                    name: 'Hipotiroidismo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                infarto_de_miocardio: {
                    name: 'Infarto de miocardio',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                insuficiencia_hepatica: {
                    name: 'Insuficiencia hepática (hígado)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                insuficiencia_pulmonar_grave: {
                    name: 'Insuficiencia pulmonar grave',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                insuficiencia_renal: {
                    name: 'Insuficiencia renal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                leucemia: {
                    name: 'Leucemia',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                malaria: {
                    name: 'Malaria',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                miastenia: {
                    name: 'Miastenia',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                miopatia: {
                    name: 'Miopatía (músculos)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                mucoviscidosis: {
                    name: 'Mucoviscidosis',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                poliomielitis: {
                    name: 'Poliomielitis',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                prostatismo: {
                    name: 'Prostatismo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                sida: {
                    name: 'SIDA',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                sordera: {
                    name: 'Sordera (sordo)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tuberculosis: {
                    name: 'Tuberculosis',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tumor_laringeo: {
                    name: 'Tumor laríngeo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tumor_o_malformacion_bucal: {
                    name: 'Tumor o malformación bucal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                ulcera_gastrica_o_duodenal: {
                    name: 'Úlcera gástrica o duodenal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                diabetes_diabetes: {
                    name: 'Diabetes',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                insuficienciua_cardiaca: {
                    name: 'Insuficiencia cardiaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                osteoporosis: {
                    name: 'Osteoporosis',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                otra_enfermedad: {
                    name: 'Otra enfermedad',
                    complemento: {},
                    value: false,
                    detail: ''
                },
            },
            quirurgicos: {
                amputacion_de_miembro: {
                    name: 'Amputación de miembro',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                aneurisma_cerebral: {
                    name: 'Aneurisma cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                aneurisma_de_aorta_abdominal: {
                    name: 'Aneurisma de aorta abdominal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                aneurisma_de_aorta_toracica: {
                    name: 'Aneurisma de aorta torácica',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                apendicectomia: {
                    name: 'Apendicectomía',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                by_pass_coronario: {
                    name: 'By-Pass coronario',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                carrefour_de_la_aorta_abdominal: {
                    name: 'Carrefour de la aorta abdominal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                cataratas: {
                    name: 'Cataratas',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                cesarea: {
                    name: 'Cesárea',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                cistectomia: {
                    name: 'Cistectomía (vesícula)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                colecistectomia: {
                    name: 'Colecistectomía',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                colectomia: {
                    name: 'Colectomía (intestino grueso)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                derivacion_ventricular_cerebral: {
                    name: 'Derivación ventricular cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                dilatacion_coronaria: {
                    name: 'Dilatación coronaria',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                duodenopancreatectomia: {
                    name: 'Duodenopancreatectomía',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                enfermedad_congenita_operada: {
                    name: 'Enfermedad congénita operada',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                esplenectomia: {
                    name: 'Esplenectomía (bazo)',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                fistula_arterio_venosa: {
                    name: 'Fístula arterio-venosa',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                fractura_de_columna: {
                    name: 'Fractura de columna',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                gastrectomia_parcial: {
                    name: 'Gastrectomía parcial',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                gastrectomia_total: {
                    name: 'Gastrectomía total',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hemorragia_cerebral: {
                    name: 'Hemorragia cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                hernia_discal: {
                    name: 'Hernia discal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                histerectomia: {
                    name: 'Histerectomía',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                implante_de_morfina_a_bombeo: {
                    name: 'Implante de morfina a bombeo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                implante_estimulador_nervioso: {
                    name: 'Implante estimulador nervioso',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                intervencion_cerebral: {
                    name: 'Intervención cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                marcapasos_cardiaco: {
                    name: 'Marcapasos cardíaco',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                nefrectomia_total: {
                    name: 'Nefrectomía total',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                obstruccion_carotidea: {
                    name: 'Obstrucción carotídea',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                pneumectomia_parcial: {
                    name: 'Pneumectomía parcial',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                pneumectomia_total: {
                    name: 'Pneumectomía total',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                stent_coronario: {
                    name: 'Stent coronario',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                sustitucion_valvular_cardiaca: {
                    name: 'Sustitución valvular cardíaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tipo_de_marcapasos: {
                    name: 'Tipo de marcapasos',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                trasplante_cardiaco: {
                    name: 'Trasplante cardíaco',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                trasplante_de_corazon_y_pulmon: {
                    name: 'Trasplante de corazón y pulmón',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                trasplante_de_higado: {
                    name: 'Trasplante de corazón y pulmón',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                trasplante_renal: {
                    name: 'Trasplante renal',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                traumatismo_cerebral: {
                    name: 'Traumatismo cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tumor_cerebral: {
                    name: 'Tumor cerebral',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tumor_esofagico: {
                    name: 'Tumor esofágico',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                tumor_oseo: {
                    name: 'Tumor óseo',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                valvuloplastia_cardiaca: {
                    name: 'Valvuloplastia cardíaca',
                    complemento: {},
                    value: false,
                    detail: ''
                },
                otros: {
                    name: 'Otros',
                    complemento: {},
                    value: false,
                    detail: ''
                }
            },
            traumaticos: {
                articulaciones: {
                    name: 'Articulaciones',
                    complemento: {},
                    value: false,
                },
                artritis_traumatica: {
                    name: 'Artritis traumática',
                    complemento: {},
                    value: false,
                },
                contusiones: {
                    name: 'Contusiones',
                    complemento: {},
                    value: false,
                },
                esguinces: {
                    name: 'Esguinces',
                    complemento: {},
                    value: false,
                },
                fractura_de_femur: {
                    name: 'Fractura de fémur',
                    complemento: {},
                    value: false,
                },
                fractura_de_humero: {
                    name: 'Fractura de húmero',
                    complemento: {},
                    value: false,
                },
                ligamentos: {
                    name: 'Ligamentos',
                    complemento: {},
                    value: false,
                },
                luxaciones: {
                    name: 'Luxaciones',
                    complemento: {},
                    value: false,
                },
                tendinitis: {
                    name: 'Tendinitis',
                    complemento: {},
                    value: false,
                },
                tendones: {
                    name: 'Tendones',
                    complemento: {},
                    value: false,
                },
                otros: {
                    name: 'Otros',
                    complemento: {},
                    value: false,
                },
            },
            habitos: {
                tabaquismo: {
                    name: 'Tabaquismo',
                    detail: '',
                    value: false,
                },
                drogas_ilicitas: {
                    name: 'Drogas ilícitas',
                    detail: '',
                    value: false,
                },
                alcoholismo: {
                    name: 'Alcoholismo',
                    detail: '',
                    value: false,
                }
            },

            tipoFicha: null,

            // alergias
            showEditionAlergias: false,
            alergia: {},

            // medicamentos
            showEditionMedicamento: false,
            medicamento: {},
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
            GetReloadFicha: 'GetReloadFicha',
            GetClienteMetada: 'GetClienteMetada',
        })
    },
    watch: {
        GetClienteMetada: {
        handler(newValue) {
            if (newValue) {
                const self = this;
                if(self.GetMedicoFichaActive.producto){
                    self.tipoFicha = self.GetMedicoFichaActive.producto.fichaMedica;
                }

                if(self.GetClienteMetada !== undefined) {

                self.antecedentes_generales = (self.GetClienteMetada.antecedentes) ? self.GetClienteMetada.antecedentes.antecedentes_generales : '';

                if(self.GetClienteMetada.antecedentes_obstetricos){
                    Object.keys(self.antecedentes_obstetricos).map(function (value) {
                                self.antecedentes_obstetricos[value].value = self.GetClienteMetada.antecedentes_obstetricos[value] || '';
                                });
                }


                // Cargo familiares
                Object.keys(self.familiares).map(function (value) {
                    if (self.GetClienteMetada.antecedentes_familiares) {

                        if (self.GetClienteMetada.antecedentes_familiares[value]) { 
                            self.familiares[value].value = self.GetClienteMetada.antecedentes_familiares[value] ? true : false;
                        }

                        if (self.GetClienteMetada.antecedentes_familiares[value+'_detail']) { 
                            self.familiares[value].detail = self.GetClienteMetada.antecedentes_familiares[value+'_detail'];
                        }

                    }
                });

                // Cargo medicos
                Object.keys(self.medicos).map(function (value) {
                    if (self.GetClienteMetada.antecedentes_medicos) {

                        if (self.GetClienteMetada.antecedentes_medicos[value]) {
                            self.medicos[value].value = self.GetClienteMetada.antecedentes_medicos[value] ? true : false;
                        }

                        if (self.GetClienteMetada.antecedentes_medicos[value+'_detail']) { 
                            self.medicos[value].detail = self.GetClienteMetada.antecedentes_medicos[value+'_detail'];
                        }
                    }
                });

                // Cargo quirurjico
                Object.keys(self.quirurgicos).map(function (value) {
                    if (self.GetClienteMetada.antecedentes_quirurgicos) {

                        if (self.GetClienteMetada.antecedentes_quirurgicos[value]) {
                            self.quirurgicos[value].value = self.GetClienteMetada.antecedentes_quirurgicos[value] ? true : false;
                        }

                        if (self.GetClienteMetada.antecedentes_quirurgicos[value+'_detail']) { 
                            self.quirurgicos[value].detail = self.GetClienteMetada.antecedentes_quirurgicos[value+'_detail'];
                        }
                    }
                });

                // Cargo traumaticos
                Object.keys(self.traumaticos).map(function (value) {
                    if (self.GetClienteMetada.antecedentes_traumaticos) {

                        if (self.GetClienteMetada.antecedentes_traumaticos[value]) {
                            self.traumaticos[value].value = self.GetClienteMetada.antecedentes_traumaticos[value] ? true : false;
                        }

                        if (self.GetClienteMetada.antecedentes_traumaticos[value+'_detail']) { 
                            self.traumaticos[value].detail = self.GetClienteMetada.antecedentes_traumaticos[value+'_detail'];
                        }
                    }
                });

                // Cargo habitos
                Object.keys(self.habitos).map(function (value) {
                    if (self.GetClienteMetada.antecedentes_habitos) {

                        if (self.GetClienteMetada.antecedentes_habitos[value]) {
                            self.habitos[value].value = self.GetClienteMetada.antecedentes_habitos[value] ? true : false; 
                        }
                        if (self.GetClienteMetada.antecedentes_habitos[value+'_detail']) { 
                            self.habitos[value].detail = self.GetClienteMetada.antecedentes_habitos[value+'_detail'];
                        }
                    }
                });
                }

            }
            }
        }
    },
    mounted() {
        this.FetchClienteMetadaBySlug({slugs:['datos_paciente','antecedentes','antecedentes_obstetricos','antecedentes_familiares','antecedentes_medicos','antecedentes_quirurgicos','antecedentes_traumaticos','antecedentes_habitos','antecedentes_alergia','antecedentes_medicamentos']});
    },
    methods: {
        ...mapActions(
            [
            // Recibe un argumento (slugs) los slugs de la metadata a consultar y (merge) si se quiere hacer merge con la metadata existente en caso de no pasarle Merge la metadata se Muta
            'FetchClienteMetadaBySlug',
            // Recibe un argumento (payload) los datos de la metadata a guardar y (merge) si se quiere hacer merge con la metadata existente en caso de no pasarle Merge la metadata no se altera
            'SaveClienteMetada',
            // Recibe un argumento (data) con metadataId y slug para eliminar y actualizar
            'DeleteClienteMetadaDetail'
        ]),
        dayjs,
        Numbro,
        saveAntecedentesG(){
            const self = this;
            
            let formattedData= {};
            formattedData['antecedentes_generales']=self.antecedentes_generales;
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug: 'antecedentes',
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },
        saveGinec(slug){
            const self = this;
            let formattedData={};
            
            Object.keys(self[slug]).map(function (value) {
                formattedData[value]=self[slug][value].value;
            });
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug,
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },
        // Antecedentes
        saveFamiliares() {
            const self = this;

            // transformo los keys a un array normal
            const formattedData = {};
            Object.keys(self.familiares).map(function (value) {
                formattedData[value] = self.familiares[value].value;
                if(self.familiares[value].value===false){
                    formattedData[value + '_detail'] = '';
                    self.familiares[value].detail = '';
                }else{
                    formattedData[value + '_detail'] = self.familiares[value].detail;
                }
            });
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug: 'antecedentes_familiares',
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },
        saveMedicos() {
            const self = this;

            // transformo los keys a un array normal
            const formattedData = {};
            Object.keys(self.medicos).map(function (value) {
                formattedData[value] = self.medicos[value].value;
                if(self.medicos[value].value===false){
                    formattedData[value + '_detail'] = '';
                    self.medicos[value].detail = '';
                }else{
                    formattedData[value + '_detail'] = self.medicos[value].detail;
                }
            });
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug: 'antecedentes_medicos',
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },
        saveQuirurgicos() {
            const self = this;

            // transformo los keys a un array normal
            const formattedData = {};
            Object.keys(self.quirurgicos).map(function (value) {
                formattedData[value] = self.quirurgicos[value].value;
                if(self.quirurgicos[value].value===false){
                    formattedData[value + '_detail'] = '';
                    self.quirurgicos[value].detail = '';
                }else{
                    formattedData[value + '_detail'] = self.quirurgicos[value].detail;
                }
            });
            self.SaveClienteMetada({
                payload:{
                clienteId: self.GetMedicoFichaActive.cliente.id,
                slug: 'antecedentes_quirurgicos',
                detail: formattedData,
                uniqueMeta: 1,
                },
                merge: true
            });
        },
        saveTraumaticos() {
            const self = this;

            // transformo los keys a un array normal
            const formattedData = {};
            Object.keys(self.traumaticos).map(function (value) {
                formattedData[value] = self.traumaticos[value].value;
                if(self.traumaticos[value].value===false){
                    formattedData[value + '_detail'] = '';
                    self.traumaticos[value].detail = '';
                }else{
                    formattedData[value + '_detail'] = self.traumaticos[value].detail;
                }
            });
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug: 'antecedentes_traumaticos',
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },
        saveHabitos() {
            const self = this;

            // transformo los keys a un array normal
            const formattedData = {};
            Object.keys(self.habitos).forEach(value => {
                formattedData[value] = self.habitos[value].value;
                formattedData[value + '_detail'] = self.habitos[value].value ? self.habitos[value].detail : '';
            });
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    slug: 'antecedentes_habitos',
                    detail: formattedData,
                    uniqueMeta: 1,
                },
                merge: true
            });
        },

        // Alergias
        resetAlergia() {
            this.alergia = {
                id: 0,
                detail: {
                    fechaIdentificada: dayjs().format('DD-MM-YYYY HH:mm'),
                    alergiaA: '',
                    reaccion: '',
                    severidad: 0,
                }
            };
        },
        cancelEditAlergia() {
            this.showEditionAlergias = false;
            this.resetAlergia();
        },
        saveAlergia() {
            const self = this;
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    metadataId: self.alergia.id,
                    slug: 'antecedentes_alergia',
                    detail: self.alergia.detail,
                },
                merge: true
            });
            this.cancelEditAlergia();
        },
        addAlergia() {
            this.resetAlergia();
            this.showEditionAlergias = true;
        },
        editAlergia(item) {
            this.resetAlergia();
            if (!item.detail.severidad) item.detail.severidad = 0; // arreglo el dolor por si viene null
            this.alergia = item;
            this.showEditionAlergias = true;
        },
        deleteAlergia(item) {
            const self = this;
            this.DeleteClienteMetadaDetail({
                metadataId: item.id,
                slug: 'antecedentes_alergia',
            })
        },

        // Medicamentos
        resetMedicamento() {
            this.medicamento = {
                id: 0,
                detail: {
                    nombreYFormulacion: '',
                    fechaInicio: dayjs().format('DD-MM-YYYY HH:mm'),
                    fuente: '',
                    observaciones: '',
                }
            };
        },
        cancelEditMedicamento() {
            this.showEditionMedicamento = false;
            this.resetMedicamento();
        },
        saveMedicamento() {
            const self = this;
            self.SaveClienteMetada({
                payload:{
                    clienteId: self.GetMedicoFichaActive.cliente.id,
                    metadataId: self.medicamento.id,
                    slug: 'antecedentes_medicamentos',
                    detail: self.medicamento.detail,
                },
                merge: true
            });
            this.cancelEditMedicamento();
        },
        addMedicamento() {
            this.resetMedicamento();
            this.showEditionMedicamento = true;
        },
        editMedicamento(item) {
            this.resetMedicamento();
            this.medicamento = item;
            this.showEditionMedicamento = true;
        },
        deleteMedicamento(item) {
            const self = this;
           
            this.DeleteClienteMetadaDetail({
                metadataId: item.id,
                slug: 'antecedentes_medicamentos',
            })
        },
    },
};

</script>
<style scoped>
.text-limit{
    resize: none;
    max-height: 150px;
}
</style>