<script>
//Utils
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";

//Layout
import Box from '/src/modules/medico/views/fichas/Box.vue';


export default { 
data() {
    return {

    tableShow:0,
    tableOptions:{
        ALL:0,
        WOMAM:1,
        MAN:2,
        WOMAM_ERC:3,
        MAN_ERC:4,
    },

    //data
    recordatorio:{
        actividad_fisica:{
            value:"si",
            options:[
                { label: "Si", value: "si"},
                { label: "No", value: "no"},
            ]
        },
        descp_af:{
            value:"",
        },
        tiempo_af:{
            value:"",
        },
        hora_desayuno:{
            value:"",
        },
        comida_desayuno:{
            value:"",
        },
        bebida_desayuno:{
            value:"",
        },
        hora_refaccion_manana:{  // Nuevo campo
            value:"",
        },
        comida_refaccion:{
            value:"",
        },
        bebida_refaccion:{
            value:"",
        },
        hora_almuerzo:{  // Nuevo campo
            value:"",
        },
        comida_almuerzo:{
            value:"",
        },
        bebida_almuerzo:{
            value:"",
        },
        hora_refaccion_tarde:{  // Nuevo campo
            value:"",
        },
        comida_refaccion_tarde:{  // Nuevo campo
            value:"",
        },
        bebida_refaccion_tarde:{  // Nuevo campo
            value:"",
        },
        hora_cena:{  // Nuevo campo
            value:"",
        },
        comida_cena:{
            value:"",
        },
        bebida_cena:{
            value:"",
        },
        otras_comidas:{  // Nuevo campo
            value:"",
        },
        cantida_agua:{
            value:"",
        },
        type_agua:{
            value:"lt",
            options:[
                { label: "Mililitros", value: "ml"},
                { label: "Litros", value: "lt"},
            ]
        },
        observaciones_hidratacion:{  // Nuevo campo
            value:"",
        },
        otras_bebidas:{  // Nuevo campo
            value:"",
        },
        hora_dormir:{
            value:"",
        },
        observaciones_dormir:{  // Nuevo campo
            value:"",
        },
        hora_despertar:{
            value:"",
        },
        observaciones_despertar:{  // Nuevo campo
            value:"",
        },
        horas_de_sueño:{
            value:"",
        },
        interrupcion_suenio:{
            value:"",
        },
    },

    evaluacion:{
        porcentaje_grasa:"",
        masa_muscular:"",
        porcentaje_agua:"",
        masa_sin_grasa:"",
        masa_osea:"",
        porcentaje_proteina:"",
        grasa_visceral:"",
        meta_basal:"",
        meta_edad:"",
    },

    pa_femenino:{
    cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    verduras_lc:{
         grupo:"Verduras de libre consumo",
         porciones:"",
         calorias:"10",
         carbohidratos:"2.5",
         lipidos:"0",
         proteinas:"0"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_ag:{
         grupo:"Carnes altas en grasa",
         porciones:"",
         calorias:"120",
         carbohidratos:"1",
         lipidos:"8",
         proteinas:"11"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_ag:{
         grupo:"Lacteos alto en grasa",
         porciones:"",
         calorias:"110",
         carbohidratos:"9",
         lipidos:"6",
         proteinas:"5"
        },
    lacteos_mg:{
         grupo:"Lacteo medio en grasa",
         porciones:"",
         calorias:"85",
         carbohidratos:"9",
         lipidos:"3",
         proteinas:"5"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    alimentos_lipidos:{
         grupo:"Alimentos ricos en lipidos",
         porciones:"",
         calorias:"175",
         carbohidratos:"5",
         lipidos:"15",
         proteinas:"5"
        },
    proteina_scp:{
         grupo:"Proteína (scoop)",
         porciones:"",
         calorias:"100",
         carbohidratos:"0",
         lipidos:"0",
         proteinas:"25"
        },
    },
    total_pa_femenino:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    },
    suma_total_pa_femenino:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_f:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_f:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_f:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_masculino:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    verduras_lc:{
         grupo:"Verduras de libre consumo",
         porciones:"",
         calorias:"10",
         carbohidratos:"2.5",
         lipidos:"0",
         proteinas:"0"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_ag:{
         grupo:"Carnes altas en grasa",
         porciones:"",
         calorias:"120",
         carbohidratos:"1",
         lipidos:"8",
         proteinas:"11"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_ag:{
         grupo:"Lacteos alto en grasa",
         porciones:"",
         calorias:"110",
         carbohidratos:"9",
         lipidos:"6",
         proteinas:"5"
        },
    lacteos_mg:{
         grupo:"Lacteo medio en grasa",
         porciones:"",
         calorias:"85",
         carbohidratos:"9",
         lipidos:"3",
         proteinas:"5"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    alimentos_lipidos:{
         grupo:"Alimentos ricos en lipidos",
         porciones:"",
         calorias:"175",
         carbohidratos:"5",
         lipidos:"15",
         proteinas:"5"
        },
    proteina_scp:{
         grupo:"Proteína (scoop)",
         porciones:"",
         calorias:"100",
         carbohidratos:"0",
         lipidos:"0",
         proteinas:"25"
        },
    },
    total_pa_masculino:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    },
    suma_total_pa_masculino:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_m:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_m:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_m:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_femenino_erc:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    },
    total_pa_femenino_erc:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    },
    suma_total_pa_femenino_erc:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_ferc:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_ferc:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_ferc:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    pa_masculino_erc:{
        cereales:{
         grupo:"Cereales",
         porciones:"",
         calorias:"140",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"3"
        },
    verduras_g:{
         grupo:"Verduras general",
         porciones:"",
         calorias:"30",
         carbohidratos:"5",
         lipidos:"0",
         proteinas:"2"
        },
    frutas:{
         grupo:"Frutas",
         porciones:"",
         calorias:"65",
         carbohidratos:"15",
         lipidos:"0",
         proteinas:"1"
        },
    carnes_bg:{
         grupo:"Carnes bajas en grasa",
         porciones:"",
         calorias:"65",
         carbohidratos:"1",
         lipidos:"2",
         proteinas:"11"
        },
    leguminosa:{
         grupo:"Leguminosas",
         porciones:"",
         calorias:"170",
         carbohidratos:"30",
         lipidos:"1",
         proteinas:"11"
        },
    lacteos_bg:{
         grupo:"Lacteo bajo en grasa",
         porciones:"",
         calorias:"70",
         carbohidratos:"10",
         lipidos:"0",
         proteinas:"7"
        },
    aceites_grasas:{
         grupo:"Aceites y grasas",
         porciones:"",
         calorias:"180",
         carbohidratos:"0",
         lipidos:"20",
         proteinas:"0"
        },
    },
    total_pa_masculino_erc:{
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    },
    suma_total_pa_masculino_erc:{
        total:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        adecuado:{
         calorias:"",
         carbohidratos:"",
         lipidos:"",
         proteinas:""
        }
    },
    requerimientos_merc:{
        kcal_dia:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        carbohidratos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        lipidos:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        proteinas:{
            vct: "",
            gr_d: "",
            gr_kg_d: "",
        },
        peso:{
            kg: "",
        },
    },
    metabolismo_basal_merc:{
        kcal_basal: "",
        gasto_calorico: "",
        af_sedentario: "",
        af_moderada: "",
        af_severa: "",
        tiempo_meta: "",
    },
    sumatoria_seis_pliegues_merc:{
        total: "",
        triceps: "",
        subescapular: "",
        supraespinal: "",
        abdominal:  "",
        muslo_anterior: "",
        pantorrilla: "",
    },

    extra:{
        reconmendaciones: "",
        observaiones: "",
        historial_nutricional: "",
    },
    habitos: {
        tabaquismo: {
            name: 'Tabaquismo',
            value: false,
            detail: ''
        },
        drogas_ilicitas: {
            name: 'Drogas ilícitas',
            value: false,
            detail: ''
        },
        alcoholismo: {
            name: 'Consumo de alcohol',
            value: false,
            detail: ''
        },
        actividad_fisica: {
            name: 'Actividad física',
            value: false,
            detail: ''
        },
        dieta_especial: {
            name: 'Dieta especial',
            value: false,
            detail: ''
        },
        suplementos: {
            name: 'Consumo de suplementos',
            value: false,
            detail: ''
        },
        vegetarianismo: {
            name: 'Vegetarianismo/Veganismo',
            value: false,
            detail: ''
        },
        comida_rapida: {
            name: 'Consumo de comida rápida',
            value: false,
            detail: ''
        },
        azucares: {
            name: 'Consumo de azúcares',
            value: false,
            detail: ''
        },
        ayuno: {
            name: 'Prácticas de ayuno',
            value: false,
            detail: ''
        },
    },

    pesoKg:'',
    datos_paciente: {
            talla: '',
            peso: '',
            imc: '',
        },
    shouldSaveMetabolismo: false, // Flag para controlar si se debe guardar
    isInitializing: true, // Nueva bandera para controlar la inicialización
}},
components: {
    Box
},
computed: {
    ...mapGetters({
        AuthGetUserInfo: 'AuthGetUserInfo',
        GetMedicoFichaActive: 'GetMedicoFichaActive',
        GetReloadFicha: 'GetReloadFicha',
        GetClienteMetada: 'GetClienteMetada'  // Make sure this getter is included
    })
},
watch: {
    // Add this new watch
    GetClienteMetada: {
        handler(newValue) {
            if (newValue && newValue.antecedentes_habitos) {
                Object.keys(this.habitos).forEach(key => {
                    if (newValue.antecedentes_habitos[key] !== undefined) {
                        this.habitos[key].value = newValue.antecedentes_habitos[key]? true : false; 
                    }
                    if (newValue.antecedentes_habitos[key + '_detail'] !== undefined) {
                        this.habitos[key].detail = newValue.antecedentes_habitos[key + '_detail'];
                    }
                });
            }
        },
        deep: true,
        // immediate: true
    },
    
    GetReloadFicha(newValue, oldValue) {
            if (newValue === false && oldValue === true) {
        
            const self = this;

            if (self.GetMedicoFichaActive.metadata.nutricion_recordatorio) {
            Object.keys(self.recordatorio).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.nutricion_recordatorio[value]) {
                        self.recordatorio[value].value = self.GetMedicoFichaActive.metadata.nutricion_recordatorio[value];
                    }
                });
            }

            if (self.GetMedicoFichaActive.metadata.nutricion_evaluacion) {
            Object.keys(self.evaluacion).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.nutricion_evaluacion[value]) {
                        self.evaluacion[value] = self.GetMedicoFichaActive.metadata.nutricion_evaluacion[value];
                    }
                });
            }

            if(self.GetMedicoFichaActive.metadata.datos_paciente){
                self.datos_paciente.peso=self.GetMedicoFichaActive.metadata.datos_paciente.peso || '';
                self.datos_paciente.talla=self.GetMedicoFichaActive.metadata.datos_paciente.talla || '';
                self.datos_paciente.imc=self.GetMedicoFichaActive.metadata.datos_paciente.imc || '';

                self.calcularLbToKg();
            }
            


            Object.keys(self.pa_femenino).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.pa_femenino) {
                        
                            self.pa_femenino[value].porciones = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_porciones'] || "";
                            self.pa_femenino[value].calorias = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_calorias'] || "";
                            self.pa_femenino[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_carbohidratos'] || "";
                            self.pa_femenino[value].lipidos = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_lipidos'] || "";
                            self.pa_femenino[value].proteinas = self.GetMedicoFichaActive.metadata.pa_femenino[value+'_proteinas'] || "";
                        
                    }
                });
            Object.keys(self.pa_masculino).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_masculino) {
                    
                        self.pa_masculino[value].porciones = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_porciones'] || "";
                        self.pa_masculino[value].calorias = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_calorias'] || "";
                        self.pa_masculino[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_carbohidratos'] || "";
                        self.pa_masculino[value].lipidos = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_lipidos'] || "";
                        self.pa_masculino[value].proteinas = self.GetMedicoFichaActive.metadata.pa_masculino[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.pa_femenino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_femenino_erc) {
                    
                        self.pa_femenino_erc[value].porciones = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_porciones'] || "";
                        self.pa_femenino_erc[value].calorias = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_calorias'] || "";
                        self.pa_femenino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_carbohidratos'] || "";
                        self.pa_femenino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_lipidos'] || "";
                        self.pa_femenino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.pa_femenino_erc[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.pa_masculino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.pa_masculino_erc) {
                    
                        self.pa_masculino_erc[value].porciones = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_porciones'] || "";
                        self.pa_masculino_erc[value].calorias = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_calorias'] || "";
                        self.pa_masculino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_carbohidratos'] || "";
                        self.pa_masculino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_lipidos'] || "";
                        self.pa_masculino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.pa_masculino_erc[value+'_proteinas'] || "";
                    
                }
            });

            // SUMA TOTAL

            Object.keys(self.suma_total_pa_femenino).map(function (value) {
                    if (self.GetMedicoFichaActive.metadata.suma_total_pa_femenino) {
                        
                            self.suma_total_pa_femenino[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_porciones'] || "";
                            self.suma_total_pa_femenino[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_calorias'] || "";
                            self.suma_total_pa_femenino[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_carbohidratos'] || "";
                            self.suma_total_pa_femenino[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_lipidos'] || "";
                            self.suma_total_pa_femenino[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino[value+'_proteinas'] || "";
                        
                    }
                });
            Object.keys(self.suma_total_pa_masculino).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_masculino) {
                    
                        self.suma_total_pa_masculino[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_porciones'] || "";
                        self.suma_total_pa_masculino[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_calorias'] || "";
                        self.suma_total_pa_masculino[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_carbohidratos'] || "";
                        self.suma_total_pa_masculino[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_lipidos'] || "";
                        self.suma_total_pa_masculino[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.suma_total_pa_femenino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc) {
                    
                        self.suma_total_pa_femenino_erc[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_porciones'] || "";
                        self.suma_total_pa_femenino_erc[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_calorias'] || "";
                        self.suma_total_pa_femenino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_carbohidratos'] || "";
                        self.suma_total_pa_femenino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_lipidos'] || "";
                        self.suma_total_pa_femenino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_femenino_erc[value+'_proteinas'] || "";
                    
                }
            });
            Object.keys(self.suma_total_pa_masculino_erc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc) {
                    
                        self.suma_total_pa_masculino_erc[value].porciones = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_porciones'] || "";
                        self.suma_total_pa_masculino_erc[value].calorias = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_calorias'] || "";
                        self.suma_total_pa_masculino_erc[value].carbohidratos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_carbohidratos'] || "";
                        self.suma_total_pa_masculino_erc[value].lipidos = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_lipidos'] || "";
                        self.suma_total_pa_masculino_erc[value].proteinas = self.GetMedicoFichaActive.metadata.suma_total_pa_masculino_erc[value+'_proteinas'] || "";
                    
                }
            });


            //LOAD REQUERIMENTS

            
            Object.keys(self.requerimientos_f).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_f) {
                    Object.keys(self.requerimientos_f[value]).map(function (value2) {
                        self.requerimientos_f[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_f[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_m).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_m) {
                    Object.keys(self.requerimientos_m[value]).map(function (value2) {
                        self.requerimientos_m[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_m[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_ferc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_ferc) {
                    Object.keys(self.requerimientos_ferc[value]).map(function (value2) {
                        self.requerimientos_ferc[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_ferc[value+'_'+value2] || "";
                    });       
                }
            });
            Object.keys(self.requerimientos_merc).map(function (value) {
                if (self.GetMedicoFichaActive.metadata.requerimientos_merc) {
                    Object.keys(self.requerimientos_merc[value]).map(function (value2) {
                        self.requerimientos_merc[value][value2] = self.GetMedicoFichaActive.metadata.requerimientos_merc[value+'_'+value2] || "";
                    });       
                }
            });
            self.calcularRequerimientos('requerimientos_f')
            self.calcularRequerimientos('requerimientos_m')
            self.calcularRequerimientos('requerimientos_ferc')
            self.calcularRequerimientos('requerimientos_merc')

            // LOAD METABOLISMO BASAL
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_f){
                self.metabolismo_basal_f=self.GetMedicoFichaActive.metadata.metabolismo_basal_f;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_m){
                self.metabolismo_basal_m=self.GetMedicoFichaActive.metadata.metabolismo_basal_m;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_ferc){
                self.metabolismo_basal_ferc=self.GetMedicoFichaActive.metadata.metabolismo_basal_ferc;
            }
            if(self.GetMedicoFichaActive.metadata.metabolismo_basal_merc){
                self.metabolismo_basal_merc=self.GetMedicoFichaActive.metadata.metabolismo_basal_merc;
            }

            // LOAD PLIEGUES
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_f){
                self.sumatoria_seis_pliegues_f=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_f;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_m){
                self.sumatoria_seis_pliegues_m=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_m;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_ferc){
                self.sumatoria_seis_pliegues_ferc=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_ferc;
            }
            if(self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_merc){
                self.sumatoria_seis_pliegues_merc=self.GetMedicoFichaActive.metadata.sumatoria_seis_pliegues_merc;
            }

            // LOAD EXTRA
            if(self.GetMedicoFichaActive.metadata.extra){
                self.extra=self.GetMedicoFichaActive.metadata.extra;
            }

            self.calcularTotal(1);
            self.calcularTotal(2);
            self.calcularTotal(3);
            self.calcularTotal(4);

        }
    },
     'datos_paciente.peso': function () {
            this.calcularLbToKg();
            this.recalcularMetabolismoBasal();
        },
    'datos_paciente.talla': function () {
        this.calcularIMC();
        this.recalcularMetabolismoBasal();
    },
    'pesoKg': function() {
        this.recalcularMetabolismoBasal();
    },
    'tiempo_lograr_meta': function(newVal) {
        if (newVal) {
            // Solo guardar cuando se ingrese el tiempo para lograr meta
            this.saveMetaBasal('metabolismo_basal_f');
            this.saveMetaBasal('metabolismo_basal_m');
            this.saveMetaBasal('metabolismo_basal_ferc');
            this.saveMetaBasal('metabolismo_basal_merc');
        }
    }
},
mounted() {
    this.emitter.emit("medico-ficha-refresh",['nutricion_recordatorio','nutricion_evaluacion','datos_paciente','pa_femenino','pa_masculino','pa_femenino_erc','pa_masculino_erc','suma_total_pa_femenino','suma_total_pa_masculino','suma_total_pa_femenino_erc','suma_total_pa_masculino_erc','requerimientos_f','requerimientos_m','requerimientos_ferc','requerimientos_merc','metabolismo_basal_f','metabolismo_basal_m','metabolismo_basal_ferc','metabolismo_basal_merc','sumatoria_seis_pliegues_f','sumatoria_seis_pliegues_m','sumatoria_seis_pliegues_ferc','sumatoria_seis_pliegues_merc','extra'])

    this.FetchClienteMetadaBySlug({slugs:['datos_paciente','antecedentes_habitos']});

    // Solo calcular valores iniciales
    if (this.datos_paciente.peso) {
        this.calcularLbToKg();
    }
    if (this.datos_paciente.talla) {
        this.calcularIMC();
    }
    this.recalcularMetabolismoBasal();
},
methods: {
    ...mapActions({
        FetchClienteMetadaBySlug: 'FetchClienteMetadaBySlug',
        SaveClienteMetada: 'SaveClienteMetada'
    }),
    dayjs,
    Numbro,
    calcularLbToKg() {
        if (this.datos_paciente.peso) {
            this.pesoKg = parseFloat(parseFloat(this.datos_paciente.peso) * 0.45359237).toFixed(2);
        } else {
            this.pesoKg = "0";
        }
        this.calcularIMC();
    },
    calcularKgToLb() {
        if (this.pesoKg) {
            this.datos_paciente.peso = parseFloat(parseFloat(this.pesoKg) / 0.45359237).toFixed(2);
        } else {
            this.datos_paciente.peso = "0";
        }
        this.calcularIMC();
        this.saveDPMetadata();
    },
    calcularIMC() {
        if (!this.datos_paciente.talla || !this.datos_paciente.peso) {
            this.datos_paciente.imc = '0';
            return '0';
        }

        // Convert weight from pounds to kg
        const pesoKg = parseFloat(this.datos_paciente.peso || 0) * 0.45359237;
        
        // Convert height from cm to meters
        const tallaMeters = parseFloat(this.datos_paciente.talla || 0) / 100;
        
        if (tallaMeters === 0) {
            this.datos_paciente.imc = '0';
            return '0';
        }
        
        // Calculate BMI using metric formula
        const imc = pesoKg / (tallaMeters * tallaMeters);
        const formattedIMC = isNaN(imc) ? '0' : parseFloat(imc).toFixed(1);
        
        this.datos_paciente.imc = formattedIMC;
        return formattedIMC;
    },
    saveDPMetadata() {
        const self = this;
        this.calcularIMC();

        API.send('POST', 'orders/metadata/save/', {
            orderId: self.GetMedicoFichaActive.orden.id,
            slug: 'datos_paciente',
            detail: self.datos_paciente,
            uniqueMeta: 1,
        }, function (response) {
            API.showSuccessAlert(response.msg);
            // Notifica a otros componentes que los datos del paciente han sido actualizados
            self.emitter.emit("medico-ficha-notify", ['datos_paciente']);
        },
        function (response) {
            API.showErrorAlert(response.msg);
        });
    },
    saveRecordatorio(){
        const self = this;

        const arrToSave = {};

        Object.keys(self.recordatorio).map(function(value){
            arrToSave[value] = self.recordatorio[value].value;
        });

        API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'nutricion_recordatorio',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['nutricion_recordatorio']);
                    
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
    },
    saveEvaluacion(src){
        const self = this;

        API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'nutricion_evaluacion',
                    detail: self.evaluacion,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['nutricion_evaluacion']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
    },
    lbToKg(value) {
            return parseFloat(parseFloat(value) * 0.45359237).toFixed(2);
    },
    kgToLb(value) {
    return parseFloat(parseFloat(value) / 0.45359237).toFixed(2);
    },
    savePa(type){
        const self = this;
      
        self.calcularTotal(type);     
            if(type === 1){
                self.savePaFemenino();
                self.saveSumaTotalPaFemenino();   
            }else if(type === 2){
                self.savePaMasculino();
                self.saveSumaTotalPaMasculino();  
            }else if(type === 3){
                self.savePaFemeninoERC();
                self.saveSumaTotalPaFemeninoERC();  
            }else if(type === 4){
                self.savePaMasculinoERC();  
                self.saveSumaTotalPaMasculinoERC();
            }
    },
    calcularTotal(type){
        const self = this;

        if(type===1){
            self.resetPaFemenino();
            Object.keys(self.pa_femenino).map(function (value) {
                // Asegurarse que todos los valores son números y redondear a 2 decimales
                const porciones = parseFloat(self.pa_femenino[value].porciones) || 0;
                const calorias = parseFloat(self.pa_femenino[value].calorias) || 0;
                const carbohidratos = parseFloat(self.pa_femenino[value].carbohidratos) || 0;
                const lipidos = parseFloat(self.pa_femenino[value].lipidos) || 0;
                const proteinas = parseFloat(self.pa_femenino[value].proteinas) || 0;
                
                // Calcular los totales con redondeo
                self.total_pa_femenino[value].calorias = Math.round(calorias * porciones * 100) / 100;
                self.total_pa_femenino[value].carbohidratos = Math.round(carbohidratos * porciones * 100) / 100;
                self.total_pa_femenino[value].lipidos = Math.round(lipidos * porciones * 100) / 100;
                self.total_pa_femenino[value].proteinas = Math.round(proteinas * porciones * 100) / 100;

                // Acumular en sumas totales
                self.suma_total_pa_femenino.total.calorias += self.total_pa_femenino[value].calorias;
                self.suma_total_pa_femenino.total.carbohidratos += self.total_pa_femenino[value].carbohidratos;
                self.suma_total_pa_femenino.total.lipidos += self.total_pa_femenino[value].lipidos;
                self.suma_total_pa_femenino.total.proteinas += self.total_pa_femenino[value].proteinas;
            });
            
            // Redondear también los totales finales para evitar imprecisiones
            self.suma_total_pa_femenino.total.calorias = Math.round(self.suma_total_pa_femenino.total.calorias);
            self.suma_total_pa_femenino.total.carbohidratos = Math.round(self.suma_total_pa_femenino.total.carbohidratos * 100) / 100;
            self.suma_total_pa_femenino.total.lipidos = Math.round(self.suma_total_pa_femenino.total.lipidos * 100) / 100;
            self.suma_total_pa_femenino.total.proteinas = Math.round(self.suma_total_pa_femenino.total.proteinas * 100) / 100;
            
            self.calcularPorcentajeAdecuado('requerimientos_f');
        }
        else if(type===2){
            self.resetPaMasculino();
            Object.keys(self.pa_masculino).map(function (value) {
                // Asegurarse que todos los valores son números y redondear a 2 decimales
                const porciones = parseFloat(self.pa_masculino[value].porciones) || 0;
                const calorias = parseFloat(self.pa_masculino[value].calorias) || 0;
                const carbohidratos = parseFloat(self.pa_masculino[value].carbohidratos) || 0;
                const lipidos = parseFloat(self.pa_masculino[value].lipidos) || 0;
                const proteinas = parseFloat(self.pa_masculino[value].proteinas) || 0;
                
                // Calcular los totales con redondeo
                self.total_pa_masculino[value].calorias = Math.round(calorias * porciones * 100) / 100;
                self.total_pa_masculino[value].carbohidratos = Math.round(carbohidratos * porciones * 100) / 100;
                self.total_pa_masculino[value].lipidos = Math.round(lipidos * porciones * 100) / 100;
                self.total_pa_masculino[value].proteinas = Math.round(proteinas * porciones * 100) / 100;

                // Acumular en sumas totales
                self.suma_total_pa_masculino.total.calorias += self.total_pa_masculino[value].calorias;
                self.suma_total_pa_masculino.total.carbohidratos += self.total_pa_masculino[value].carbohidratos;
                self.suma_total_pa_masculino.total.lipidos += self.total_pa_masculino[value].lipidos;
                self.suma_total_pa_masculino.total.proteinas += self.total_pa_masculino[value].proteinas;
            });
            
            // Redondear también los totales finales para evitar imprecisiones
            self.suma_total_pa_masculino.total.calorias = Math.round(self.suma_total_pa_masculino.total.calorias);
            self.suma_total_pa_masculino.total.carbohidratos = Math.round(self.suma_total_pa_masculino.total.carbohidratos * 100) / 100;
            self.suma_total_pa_masculino.total.lipidos = Math.round(self.suma_total_pa_masculino.total.lipidos * 100) / 100;
            self.suma_total_pa_masculino.total.proteinas = Math.round(self.suma_total_pa_masculino.total.proteinas * 100) / 100;
            
            self.calcularPorcentajeAdecuado('requerimientos_m');
        }
        else if(type===3){
            self.resetPaFemeninoERC();
            Object.keys(self.pa_femenino_erc).map(function (value) {
                // Asegurarse que todos los valores son números y redondear a 2 decimales
                const porciones = parseFloat(self.pa_femenino_erc[value].porciones) || 0;
                const calorias = parseFloat(self.pa_femenino_erc[value].calorias) || 0;
                const carbohidratos = parseFloat(self.pa_femenino_erc[value].carbohidratos) || 0;
                const lipidos = parseFloat(self.pa_femenino_erc[value].lipidos) || 0;
                const proteinas = parseFloat(self.pa_femenino_erc[value].proteinas) || 0;
                
                // Calcular los totales con redondeo
                self.total_pa_femenino_erc[value].calorias = Math.round(calorias * porciones * 100) / 100;
                self.total_pa_femenino_erc[value].carbohidratos = Math.round(carbohidratos * porciones * 100) / 100;
                self.total_pa_femenino_erc[value].lipidos = Math.round(lipidos * porciones * 100) / 100;
                self.total_pa_femenino_erc[value].proteinas = Math.round(proteinas * porciones * 100) / 100;

                // Acumular en sumas totales
                self.suma_total_pa_femenino_erc.total.calorias += self.total_pa_femenino_erc[value].calorias;
                self.suma_total_pa_femenino_erc.total.carbohidratos += self.total_pa_femenino_erc[value].carbohidratos;
                self.suma_total_pa_femenino_erc.total.lipidos += self.total_pa_femenino_erc[value].lipidos;
                self.suma_total_pa_femenino_erc.total.proteinas += self.total_pa_femenino_erc[value].proteinas;
            });
            
            // Redondear también los totales finales para evitar imprecisiones
            self.suma_total_pa_femenino_erc.total.calorias = Math.round(self.suma_total_pa_femenino_erc.total.calorias);
            self.suma_total_pa_femenino_erc.total.carbohidratos = Math.round(self.suma_total_pa_femenino_erc.total.carbohidratos * 100) / 100;
            self.suma_total_pa_femenino_erc.total.lipidos = Math.round(self.suma_total_pa_femenino_erc.total.lipidos * 100) / 100;
            self.suma_total_pa_femenino_erc.total.proteinas = Math.round(self.suma_total_pa_femenino_erc.total.proteinas * 100) / 100;
            
            self.calcularPorcentajeAdecuado('requerimientos_ferc');
        }
        else if(type===4){
            self.resetPaMasculinoERC();
            Object.keys(self.pa_masculino_erc).map(function (value) {
                // Asegurarse que todos los valores son números y redondear a 2 decimales
                const porciones = parseFloat(self.pa_masculino_erc[value].porciones) || 0;
                const calorias = parseFloat(self.pa_masculino_erc[value].calorias) || 0;
                const carbohidratos = parseFloat(self.pa_masculino_erc[value].carbohidratos) || 0;
                const lipidos = parseFloat(self.pa_masculino_erc[value].lipidos) || 0;
                const proteinas = parseFloat(self.pa_masculino_erc[value].proteinas) || 0;
                
                // Calcular los totales con redondeo
                self.total_pa_masculino_erc[value].calorias = Math.round(calorias * porciones * 100) / 100;
                self.total_pa_masculino_erc[value].carbohidratos = Math.round(carbohidratos * porciones * 100) / 100;
                self.total_pa_masculino_erc[value].lipidos = Math.round(lipidos * porciones * 100) / 100;
                self.total_pa_masculino_erc[value].proteinas = Math.round(proteinas * porciones * 100) / 100;

                // Acumular en sumas totales
                self.suma_total_pa_masculino_erc.total.calorias += self.total_pa_masculino_erc[value].calorias;
                self.suma_total_pa_masculino_erc.total.carbohidratos += self.total_pa_masculino_erc[value].carbohidratos;
                self.suma_total_pa_masculino_erc.total.lipidos += self.total_pa_masculino_erc[value].lipidos;
                self.suma_total_pa_masculino_erc.total.proteinas += self.total_pa_masculino_erc[value].proteinas;
            });
            
            // Redondear también los totales finales para evitar imprecisiones
            self.suma_total_pa_masculino_erc.total.calorias = Math.round(self.suma_total_pa_masculino_erc.total.calorias);
            self.suma_total_pa_masculino_erc.total.carbohidratos = Math.round(self.suma_total_pa_masculino_erc.total.carbohidratos * 100) / 100;
            self.suma_total_pa_masculino_erc.total.lipidos = Math.round(self.suma_total_pa_masculino_erc.total.lipidos * 100) / 100;
            self.suma_total_pa_masculino_erc.total.proteinas = Math.round(self.suma_total_pa_masculino_erc.total.proteinas * 100) / 100;
            
            self.calcularPorcentajeAdecuado('requerimientos_merc');
        }
    },
    resetPaFemenino(){
    this.total_pa_femenino ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    }
    this.suma_total_pa_femenino.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaMasculino(){
    this.total_pa_masculino ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_lc:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_ag:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_mg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        alimentos_lipidos:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        proteina_scp:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        }, 
    }
    this.suma_total_pa_masculino.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaFemeninoERC(){
    this.total_pa_femenino_erc ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    }
    this.suma_total_pa_femenino_erc.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    resetPaMasculinoERC(){
    this.total_pa_masculino_erc ={
        cereales:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        verduras_g:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        frutas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        carnes_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        leguminosa:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        lacteos_bg:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
        aceites_grasas:{
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0
        },
    }
    this.suma_total_pa_masculino_erc.total={
         calorias:0,
         carbohidratos:0,
         lipidos:0,
         proteinas:0 
    }

    },
    savePaFemenino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_femenino).map(function (value) {
            Object.keys(self.pa_femenino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_femenino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_femenino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_femenino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaMasculino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_masculino).map(function (value) {
            Object.keys(self.pa_masculino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_masculino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_masculino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_masculino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaFemeninoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_femenino_erc).map(function (value) {
            Object.keys(self.pa_femenino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_femenino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_femenino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_femenino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    savePaMasculinoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.pa_masculino_erc).map(function (value) {
            Object.keys(self.pa_masculino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.pa_masculino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"pa_masculino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['pa_masculino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaFemenino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_femenino).map(function (value) {
            Object.keys(self.suma_total_pa_femenino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_femenino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_femenino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_femenino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaMasculino(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_masculino).map(function (value) {
            Object.keys(self.suma_total_pa_masculino[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_masculino[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_masculino",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_masculino']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaFemeninoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_femenino_erc).map(function (value) {
            Object.keys(self.suma_total_pa_femenino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_femenino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_femenino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_femenino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveSumaTotalPaMasculinoERC(){
        const self = this;
        let itemsTosave={};
        Object.keys(self.suma_total_pa_masculino_erc).map(function (value) {
            Object.keys(self.suma_total_pa_masculino_erc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.suma_total_pa_masculino_erc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"suma_total_pa_masculino_erc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['suma_total_pa_masculino_erc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosF(){
        const self = this;
        self.calcularRequerimientos('requerimientos_f')
        let itemsTosave={};
        Object.keys(self.requerimientos_f).map(function (value) {
            Object.keys(self.requerimientos_f[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_f[value][value2] || 0;
            });
            });
                console.log( itemsTosave)
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_f",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_f']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosM(){
        const self = this;
        self.calcularRequerimientos('requerimientos_m')
        let itemsTosave={};
        Object.keys(self.requerimientos_m).map(function (value) {
            Object.keys(self.requerimientos_m[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_m[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_m",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_m']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosFERC(){
        const self = this;
        self.calcularRequerimientos('requerimientos_ferc')
        let itemsTosave={};
        Object.keys(self.requerimientos_ferc).map(function (value) {
            Object.keys(self.requerimientos_ferc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_ferc[value][value2] || 0;
            });
            });
             
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_ferc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_ferc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    saveRequerimientosMERC(){
        const self = this;
        self.calcularRequerimientos('requerimientos_merc')
        let itemsTosave={};
        Object.keys(self.requerimientos_merc).map(function (value) {
            Object.keys(self.requerimientos_merc[value]).map(function (value2){
                itemsTosave[value+"_"+value2]=self.requerimientos_merc[value][value2] || 0;
            });
            });
                
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug:"requerimientos_merc",
                    detail: itemsTosave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                     self.emitter.emit("medico-ficha-notify",['requerimientos_merc']);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                }); 
    },
    calcularRequerimientos(item){
        const self = this;
        //Calculo de GR_D
        self[item].carbohidratos.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].carbohidratos.vct) || 0)).toFixed(5) || 0) / 4;

        self[item].lipidos.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].lipidos.vct) || 0)).toFixed(5) || 0) / 9;

        self[item].proteinas.gr_d=(parseFloat((parseFloat(self[item].kcal_dia.vct) || 0)*(parseFloat(self[item].proteinas.vct) || 0)).toFixed(5) || 0) / 4;

        //Calculo de GR_KG_D
        self[item].carbohidratos.gr_kg_d = this.dividirYValidad(self[item].carbohidratos.gr_d, self[item].peso.kg);
        self[item].lipidos.gr_kg_d = this.dividirYValidad(self[item].lipidos.gr_d, self[item].peso.kg);
        self[item].proteinas.gr_kg_d = this.dividirYValidad(self[item].proteinas.gr_d, self[item].peso.kg);

        self.calcularPorcentajeAdecuado(item);
    },
    calcularPorcentajeAdecuado(item){
        const self = this;
        
        function calcular(req,porc) {
            self[porc].adecuado.calorias= parseFloat((parseFloat(self[porc].total.calorias)*100)/parseFloat(self[req].kcal_dia.vct)).toFixed(2);
            self[porc].adecuado.carbohidratos= parseFloat((parseFloat(self[porc].total.carbohidratos)*100)/parseFloat(self[req].carbohidratos.gr_d)).toFixed(2);
            self[porc].adecuado.lipidos= parseFloat((parseFloat(self[porc].total.lipidos)*100)/parseFloat(self[req].lipidos.gr_d)).toFixed(2);
            self[porc].adecuado.proteinas= parseFloat((parseFloat(self[porc].total.proteinas)*100)/parseFloat(self[req].proteinas.gr_d)).toFixed(2);

            if (isNaN(self[porc].adecuado.calorias) || !isFinite(self[porc].adecuado.calorias))
            self[porc].adecuado.calorias=0;
            if (isNaN(self[porc].adecuado.carbohidratos) || !isFinite(self[porc].adecuado.carbohidratos))
            self[porc].adecuado.carbohidratos=0;
            if (isNaN(self[porc].adecuado.lipidos) || !isFinite(self[porc].adecuado.lipidos))
            self[porc].adecuado.lipidos=0;
            if (isNaN(self[porc].adecuado.proteinas) || !isFinite(self[porc].adecuado.proteinas))
            self[porc].adecuado.proteinas=0;
        }

        if(item=='requerimientos_f'){
            calcular(item,'suma_total_pa_femenino');
            self.calcularKcalBasal('metabolismo_basal_f');

        }else if(item=='requerimientos_m'){
            calcular(item,'suma_total_pa_masculino');
            self.calcularKcalBasal('metabolismo_basal_m');

        }else if(item=='requerimientos_ferc'){
            calcular(item,'suma_total_pa_femenino_erc');
            self.calcularKcalBasal('metabolismo_basal_ferc');

        }else if(item=='requerimientos_merc'){
            calcular(item,'suma_total_pa_masculino_erc');
            self.calcularKcalBasal('metabolismo_basal_merc');

        }

      
    },
    dividirYValidad(gr_d, kg) {
    let resultado = parseFloat(gr_d) / parseFloat(kg);
    if (isNaN(resultado) || !isFinite(resultado)) {
        resultado = 0;
    }
    return resultado.toFixed(5);
    },
    calcularKcalBasal(item) {
        const self = this;
        
        if (!self.pesoKg || !self.datos_paciente.talla || !self.datos_paciente.edad) {
            return;
        }

        let resultado = 0;
        const peso = parseFloat(self.pesoKg);
        const talla = parseFloat(self.datos_paciente.talla);
        const edad = parseFloat(self.datos_paciente.edad);

        if (item === 'metabolismo_basal_f' || item === 'metabolismo_basal_ferc') {
            resultado = 655.1 + (9.56 * peso) + (1.85 * talla) - (4.68 * edad);
        } else {
            resultado = 66.47 + (13.75 * peso) + (5 * talla) - (6.76 * edad);
        }

        // Solo actualizar el valor
        self[item] = resultado.toFixed(2);
    },
    saveMetaBasal(slug){
        
        const self = this;
        self.calcularKcalBasal(slug);
        let itemsTosave = self[slug];
            
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug,
                detail: itemsTosave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",[slug]);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 
    },
    savePlieges(slug){

        const self = this;

        self[slug].total = 0;

        Object.keys(self[slug]).map(function (value){
            if(value !== 'total'){
                self[slug].total+= parseFloat(self[slug][value]) || 0;
            }
        });

        
        let itemsTosave = self[slug];
            
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug,
                detail: itemsTosave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",[slug]);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 
    },
    saveExtra(){
        const self= this;
      
        API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'extra',
                detail: self.extra,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
                 self.emitter.emit("medico-ficha-notify",['extra']);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            }); 

    },
    saveHabitos() {
        const self = this;

        const formattedData = {};
            Object.keys(self.habitos).forEach(value => {
                formattedData[value] = self.habitos[value].value;
                formattedData[value + '_detail'] = self.habitos[value].value ? self.habitos[value].detail : '';
            });

        self.SaveClienteMetada({
            payload:{
                clienteId: self.GetMedicoFichaActive.cliente.id,
                slug: 'antecedentes_habitos',
                detail: formattedData,
                uniqueMeta: 1,
            },
            merge: true
        });
    },
    calcularHorasDeSueño() {
        const self = this;
        
        // Verificar que ambas horas estén definidas
        if (!self.recordatorio.hora_dormir.value || !self.recordatorio.hora_despertar.value) {
            return;
        }
        
        // Parse las horas usando dayjs
        let horaDormir = dayjs(`2000-01-01 ${self.recordatorio.hora_dormir.value}`);
        let horaDespertar = dayjs(`2000-01-01 ${self.recordatorio.hora_despertar.value}`);
        
        // Si la hora de despertar es antes de la hora de dormir, significa que la persona durmió durante la noche
        // por lo que hay que agregar un día a la hora de despertar
        if (horaDespertar.isBefore(horaDormir)) {
            horaDespertar = horaDespertar.add(1, 'day');
        }
        
        // Calcular la diferencia en horas
        const differenciaEnMilisegundos = horaDespertar.diff(horaDormir);
        const differenciaEnHoras = differenciaEnMilisegundos / (1000 * 60 * 60);
        
        // Formatear las horas y minutos
        const horas = Math.floor(differenciaEnHoras);
        const minutos = Math.round((differenciaEnHoras - horas) * 60);
        
        // Formar el texto descriptivo
        let textoHoras = `El paciente indica que tiene aproximadamente ${horas} `;
        textoHoras += horas === 1 ? 'hora' : 'horas';
        
        if (minutos > 0) {
            textoHoras += ` y ${minutos} `;
            textoHoras += minutos === 1 ? 'minuto' : 'minutos';
        }
        
        textoHoras += ' de sueño diarias.';
        
        // Actualizar el campo y guardar
        self.recordatorio.horas_de_sueño.value = textoHoras;
        self.saveRecordatorio();
    },
    calcularMetabolismoBasalInicial() {
        // Solo calcula sin guardar ni disparar watchers
        this.calcularKcalBasal('metabolismo_basal_f', false);
        this.calcularKcalBasal('metabolismo_basal_m', false);
        this.calcularKcalBasal('metabolismo_basal_ferc', false);
        this.calcularKcalBasal('metabolismo_basal_merc', false);
    },
    recalcularMetabolismoBasal() {
        // Solo calcular y actualizar valores, sin guardar
        this.calcularKcalBasal('metabolismo_basal_f');
        this.calcularKcalBasal('metabolismo_basal_m');
        this.calcularKcalBasal('metabolismo_basal_ferc');
        this.calcularKcalBasal('metabolismo_basal_merc');
    },
    
    // Modificar el método calcularKcalBasal para usar pesoKg en lugar de evaluacion.peso_kg
    calcularKcalBasal(item) {
        const self = this;
        
        // Usamos pesoKg que es la conversión de libras a kilogramos
        const peso = parseFloat(self.pesoKg) || 0;
        const talla = parseFloat(self.datos_paciente.talla) || 0;
        const edad = parseFloat(self.GetMedicoFichaActive.cliente.edad) || 0;

        if (self.GetMedicoFichaActive.cliente.genero == 'F') {
            self[item].kcal_basal = (peso * 10) + (6.25 * talla) - (5 * edad) - 161;
        } else {
            self[item].kcal_basal = (peso * 10) + (6.25 * talla) - (5 * edad) + 5;
        }
        
        if (isNaN(self[item].kcal_basal) || !isFinite(self[item].kcal_basal)) {
            self[item].kcal_basal = 0;
        }

        self[item].af_sedentario = parseFloat(self[item].kcal_basal * 1.4).toFixed(0);
        self[item].af_moderada = parseFloat(self[item].kcal_basal * 1.53).toFixed(0);
        self[item].af_severa = parseFloat(self[item].kcal_basal * 1.75).toFixed(0);
        
        // Guardar cambios automáticamente (opcional)
        // self.saveMetaBasal(item);
    }

},
 }
</script>
 
 
<template>
    <box title="Historial Nutricional" icon="fas fa-history" :contraction='true'>
    <div class="row">
        <div class="col-12">
            <div class="form-group w-100">
                <label>Historial y antecedentes nutricionales</label>
                <textarea 
                    v-model="extra.historial_nutricional" 
                    class="form-control" 
                    placeholder="Agregar historial nutricional, antecedentes relacionados con la nutrición, patrones alimentarios previos, etc..." 
                    style="min-height: 250px" 
                    @change="saveExtra"
                ></textarea>
            </div>
        </div>
    </div>
  </box>
  <box title="Hábitos" icon="fas fa-utensils" :contraction='true'>
    <div class="row">
        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in habitos" :key="'habito_' + key">
            <label class="toggle">
                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveHabitos"/>
                <div class="toggle-switch"></div>
                <span class="toggle-label">
                    {{ item.name }}
                </span>
            </label>
            <div class="form-group" v-if="item.value">
                <label>Detalles</label>
                <textarea v-model="item.detail" class="form-control" 
                    :placeholder="`Información adicional sobre ${item.name.toLowerCase()}`" 
                    @change="saveHabitos" 
                    style="min-height: 120px"></textarea>
            </div>
        </div>
    </div>
   </box>
   <box title="Recordatorio de 24 horas" icon="fas fa-clock" :contraction='true'>
    <div class="row">
               <!-- SECCIÓN: ALIMENTACIÓN -->
        <div class="col-12 mt-4">
            <h5 class="font-weight-bolder text-primary mb-3"><i class="fas fa-utensils mr-2"></i>Alimentación</h5>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Desayuno (hora, alimentos y bebidas)</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                <span class="input-group-text">Hora:</span>
                </div>
                <input type="time" class="form-control" v-model="recordatorio.hora_desayuno.value" @change="saveRecordatorio"/>
            </div>
            <div class="d-flex">
                <div class="w-50 pr-1">
                <textarea 
                    v-model="recordatorio.comida_desayuno.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa los alimentos consumidos en el desayuno..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
                <div class="w-50 pl-1">
                <textarea 
                    v-model="recordatorio.bebida_desayuno.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa las bebidas consumidas en el desayuno..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Refacción mañana (hora, alimentos y bebidas)</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                <span class="input-group-text">Hora:</span>
                </div>
                <input type="time" class="form-control" v-model="recordatorio.hora_refaccion_manana.value" @change="saveRecordatorio"/>
            </div>
            <div class="d-flex">
                <div class="w-50 pr-1">
                <textarea 
                    v-model="recordatorio.comida_refaccion.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa los alimentos consumidos en la refacción..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
                <div class="w-50 pl-1">
                <textarea 
                    v-model="recordatorio.bebida_refaccion.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa las bebidas consumidas en la refacción..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Almuerzo (hora, alimentos y bebidas)</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                <span class="input-group-text">Hora:</span>
                </div>
                <input type="time" class="form-control" v-model="recordatorio.hora_almuerzo.value" @change="saveRecordatorio"/>
            </div>
            <div class="d-flex">
                <div class="w-50 pr-1">
                <textarea 
                    v-model="recordatorio.comida_almuerzo.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa los alimentos consumidos en el almuerzo..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
                <div class="w-50 pl-1">
                <textarea 
                    v-model="recordatorio.bebida_almuerzo.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa las bebidas consumidas en el almuerzo..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Refacción tarde (hora, alimentos y bebidas)</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                <span class="input-group-text">Hora:</span>
                </div>
                <input type="time" class="form-control" v-model="recordatorio.hora_refaccion_tarde.value" @change="saveRecordatorio"/>
            </div>
            <div class="d-flex">
                <div class="w-50 pr-1">
                <textarea 
                    v-model="recordatorio.comida_refaccion_tarde.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa los alimentos consumidos en la refacción de tarde..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
                <div class="w-50 pl-1">
                <textarea 
                    v-model="recordatorio.bebida_refaccion_tarde.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa las bebidas consumidas en la refacción de tarde..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Cena (hora, alimentos y bebidas)</label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                <span class="input-group-text">Hora:</span>
                </div>
                <input type="time" class="form-control" v-model="recordatorio.hora_cena.value" @change="saveRecordatorio"/>
            </div>
            <div class="d-flex">
                <div class="w-50 pr-1">
                <textarea 
                    v-model="recordatorio.comida_cena.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa los alimentos consumidos en la cena..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
                <div class="w-50 pl-1">
                <textarea 
                    v-model="recordatorio.bebida_cena.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa las bebidas consumidas en la cena..." 
                    @change="saveRecordatorio"
                ></textarea>
                </div>
            </div>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Otras comidas o colaciones (especificar hora, alimentos y bebidas)</label>
            <textarea 
                v-model="recordatorio.otras_comidas.value"
                class="form-control" 
                style="min-height: 100px;" 
                placeholder="Describa otras comidas o colaciones consumidas durante el día que no hayan sido mencionadas anteriormente..." 
                @change="saveRecordatorio"
            ></textarea>
            </div>
        </div>
        <!-- SECCIÓN: HIDRATACIÓN -->
        <div class="col-12 mt-4">
            <h5 class="font-weight-bolder text-primary mb-3"><i class="fas fa-tint mr-2"></i>Hidratación</h5>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>Cantidad de agua durante el día</label>
                <div class="d-flex mb-2">
                    <input v-model="recordatorio.cantida_agua.value" type="text" class="form-control w-50" aria-label="Text input with dropdown button" @change="saveRecordatorio">
                    <div class="input-group-append w-50">
                        <select class="form-control" v-model="recordatorio.type_agua.value" @change="saveRecordatorio">
                            <option v-for="(item, index) in recordatorio.type_agua.options" :value="item.value" :key="index">{{ item.label }}</option>
                        </select>
                    </div>
                </div>
                <textarea 
                    v-model="recordatorio.observaciones_hidratacion.value"
                    placeholder="Información adicional sobre los hábitos de hidratación, distribución durante el día, etc..." 
                    class="form-control" 
                    style="min-height: 100px;" 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>Otras bebidas consumidas</label>
                <textarea 
                    v-model="recordatorio.otras_bebidas.value"
                    placeholder="Describa otras bebidas consumidas durante el día (café, té, refrescos, jugos, bebidas alcohólicas, etc.)" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
                <!-- SECCIÓN: ACTIVIDAD FÍSICA -->
        <div class="col-12 mt-4">
            <h5 class="font-weight-bolder text-primary mb-3"><i class="fas fa-running mr-2"></i>Actividad Física</h5>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>Descripción de la actividad física</label>
                <textarea 
                    v-model="recordatorio.descp_af.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa el tipo de actividad física realizada, intensidad y frecuencia semanal..." 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
        
        <div  class="col-12 col-md-6">
            <div class="form-group">
                <label>Frecuencia y Tiempo de la actividad física</label>
                <textarea 
                    v-model="recordatorio.tiempo_af.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa frecuencia y duración de cada sesión de actividad física y desde cuándo mantiene esta rutina..." 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
                <!-- SECCIÓN: RUTINA DE SUEÑO -->
        <div class="col-12 mt-4">
            <h5 class="font-weight-bolder text-primary mb-3"><i class="fas fa-bed mr-2"></i>Rutina de Sueño</h5>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Hora de acostarse</label>
            <div class="input-group mb-2">
                <input 
                type="time" 
                class="form-control" 
                v-model="recordatorio.hora_dormir.value" 
                @change="calcularHorasDeSueño"
                />
            </div>
            <textarea 
                v-model="recordatorio.observaciones_dormir.value"
                class="form-control" 
                placeholder="Observaciones sobre la hora de acostarse..." 
                style="min-height: 70px;" 
                @change="saveRecordatorio"
            ></textarea>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
            <label>Hora de despertar</label>
            <div class="input-group mb-2">
                <input 
                type="time" 
                class="form-control" 
                v-model="recordatorio.hora_despertar.value" 
                @change="calcularHorasDeSueño"
                />
            </div>
            <textarea 
                v-model="recordatorio.observaciones_despertar.value"
                class="form-control" 
                placeholder="Observaciones sobre la hora de despertar..." 
                style="min-height: 70px;" 
                @change="saveRecordatorio"
            ></textarea>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>Promedio de horas de sueño</label>
                <textarea 
                    v-model="recordatorio.horas_de_sueño.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Indique la cantidad de horas promedio que duerme el paciente y la calidad percibida del sueño..." 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
        
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label>Interrupciones durante el sueño</label>
                <textarea 
                    v-model="recordatorio.interrupcion_suenio.value" 
                    class="form-control" 
                    style="min-height: 100px;" 
                    placeholder="Describa si despierta durante la noche, con qué frecuencia y posibles causas..." 
                    @change="saveRecordatorio"
                ></textarea>
            </div>
        </div>
    </div>
</box>
   <box title="Evaluación" icon="fas fa-clipboard-list" :contraction='true'>
            <div class="row">
                <div class="col-12 col-sm-8 col-md-4">
    <div class="form-group">
        <label>Peso</label>
        <div class="input-group">
            <input type="text" class="form-control" v-model="datos_paciente.peso" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveDPMetadata"/>
            <div class="input-group-append">
                <span class="input-group-text">lb</span>
            </div>
            <input type="text" class="form-control" v-model="pesoKg" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}"  @change="calcularKgToLb"/>
            <div class="input-group-append">
                <span class="input-group-text">Kg</span>
            </div>
        </div>
    </div>
</div>
<div class="col-12 col-sm-4 col-md-4">
    <div class="form-group">
        <label>Talla</label>
        <div class="input-group">
            <input type="text" class="form-control" v-model="datos_paciente.talla" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveDPMetadata"/>
            <div class="input-group-append">
                <span class="input-group-text">cm</span>
            </div>
        </div>
    </div>
</div>
<div class="col-12 col-sm-4 col-md-4">
    <div class="form-group">
        <label>índice de masa corporal</label>
        <div class="input-group">
            <input type="text" class="form-control" v-model="datos_paciente.imc" disabled/>
            <div class="input-group-append">
                <span class="input-group-text">IMC</span>
            </div>
        </div>
    </div>
</div>
            <div class="col-12 col-sm-4 col-md-4">
    <div class="form-group">
        <label>Porcentaje de grasa corporal</label>
        <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_grasa" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa muscular</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_muscular" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">lb</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de agua corporal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_agua" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa libre de grasa</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_sin_grasa" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">lb</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Masa ósea</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.masa_osea" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">lb</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Porcentaje de proteína</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.porcentaje_proteina" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Grasa visceral</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.grasa_visceral" v-maska data-maska="#00" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">nivel</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Tasa de metabolismo basal</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.meta_basal" v-maska data-maska="#000" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                        <div class="input-group-append">
                            <span class="input-group-text">Kcal/día</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-4">
                <div class="form-group">
                    <label>Edad metabólica</label>
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="evaluacion.meta_edad" v-maska data-maska="#00.#0" data-maska-tokens="{ '0': { 'pattern': '[0-9]', 'optional': true }}" @change="saveEvaluacion"/>
                    </div>
                </div>
            </div>
            </div>
   </box>
   <box title="Plan de alimentación" icon="fas fa-mortar-pestle" :contraction='true'>
    <div class="row p-2">
        <div class="col-12 form-group">
            <label class="">Seleccione la tabla de calculo que desea ver:</label>
            <div class="input-group">
                <select class="form-control" v-model="tableShow">
                        <option :value="0">
                        Mostrar todo
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null" :value="1">
                        Femenino
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null" :value="2">
                        Masculino
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null" :value="3">
                        Femenino ERC
                        </option>
                        <option v-if="GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null" :value="4">
                        Masculino ERC
                        </option>
                    </select>   
            </div>
        </div>
    </div>
    
    <div v-if="(tableShow === tableOptions.WOMAM ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo femenino
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_femenino" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(1)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_femenino" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.kcal_dia.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.carbohidratos.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_f.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.lipidos.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.proteinas.vct" @change="saveRequerimientosF()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_f.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_f.peso.kg" @change="saveRequerimientosF()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class=" text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-success font-weight-bold text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_femenino.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class="font-weight-bold">
                <th class="font-weight-bold">Metabolismo Basal</th>
                <th class="font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal 
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Este valor se calcula automáticamente al cambiar el peso (lb/kg) y talla en la sección de Evaluación'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.4)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.53)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.75)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_f.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Ingrese un valor para guardar. Este campo actualiza manualmente'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_f.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_f')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class="font-weight-bold">
                <th class="font-weight-bold">Sumatoria 6 pliegues</th>
                <th class="font-weight-bold">
                   {{ sumatoria_seis_pliegues_f.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.triceps" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.subescapular" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.abdominal" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_f.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_f')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.MAN ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo masculino
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_masculino" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(2)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_masculino" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.kcal_dia.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.carbohidratos.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_m.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.lipidos.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.proteinas.vct" @change="saveRequerimientosM()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_m.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_m.peso.kg" @change="saveRequerimientosM()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_masculino.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal 
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Este valor se calcula automáticamente al cambiar el peso (lb/kg) y talla en la sección de Evaluación'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.4)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.53)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.75)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_m.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Ingrese un valor para guardar. Este campo actualiza manualmente'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_m.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_m')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_m.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.triceps" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.subescapular" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.abdominal" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_m.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_m')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.WOMAM_ERC ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'F' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo femenino ERC
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_femenino_erc" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(3)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in total_pa_femenino_erc" :key="index">
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.kcal_dia.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.carbohidratos.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_ferc.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.lipidos.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.proteinas.vct" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_ferc.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_ferc.peso.kg" @change="saveRequerimientosFERC()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0"> {{ suma_total_pa_femenino_erc.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_femenino_erc.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_femenino_erc.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal 
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Este valor se calcula automáticamente al cambiar el peso (lb/kg) y talla en la sección de Evaluación'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.4)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.53)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Valor calculado automáticamente (Kcal Basal × 1.75)'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_ferc.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Ingrese un valor para guardar. Este campo actualiza manualmente'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_ferc.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_ferc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_ferc.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.triceps" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.subescapular" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.abdominal" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_ferc.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_ferc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
    <div v-if="(tableShow === tableOptions.MAN_ERC ) || (tableShow === tableOptions.ALL ) && (GetMedicoFichaActive.cliente.genero === 'M' || GetMedicoFichaActive.cliente.genero === null)"  class="row">
    <div class="col-12 font-weight-bold text-primary p-4">
        Calculo masculino ERC
    </div>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Grupo</th>
                <th class=" font-weight-bold">Porciones</th>
                <th class=" font-weight-bold">Calorias</th>
                <th class=" font-weight-bold">CHO</th>
                <th class=" font-weight-bold">Lipidos</th>
                <th class=" font-weight-bold">Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in pa_masculino_erc" :key="index">
                <td class=" font-weight-bold">
                    {{ item.grupo }}
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="item.porciones" @change="savePa(4)"/>
                    </div>
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.calorias || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.carbohidratos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.lipidos || 0 }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ item.proteinas || 0 }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-7">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Requerimientos</th>
                <th class=" font-weight-bold">%VCT</th>
                <th class=" font-weight-bold">GR/D</th>
                <th class=" font-weight-bold">GR/KG/D</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                   Kcal/dia
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.kcal_dia.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.kcal_dia.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.kcal_dia.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Carbohidratos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.carbohidratos.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.carbohidratos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control  m-0">{{ requerimientos_merc.carbohidratos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                   Lípidos
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.lipidos.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.lipidos.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.lipidos.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Proteínas
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.proteinas.vct" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.proteinas.gr_d }}</label>
                        
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ requerimientos_merc.proteinas.gr_kg_d }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    Peso en Kg a lograr
                </td>
              
                <td colspan="3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="requerimientos_merc.peso.kg" @change="saveRequerimientosMERC()"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-5">
        <thead>
            <tr class=" font-weight-bold">
                <th> <i class="fa-solid fa-chart-simple"></i> </th>
                <th class=" font-weight-bold">Total calorías</th>
                <th class=" font-weight-bold">Total CHO</th>
                <th class=" font-weight-bold">Total Lipidos</th>
                <th class=" font-weight-bold">Total Proteínas</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="text-success font-weight-bold">
                    Total
                </td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.calorias }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.carbohidratos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.lipidos }}</label>
                    </div></td>
                <td> <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.total.proteinas }}</label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class=" text-seccess"> 
                    Porcentaje de adecuación 
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0" >{{ suma_total_pa_masculino_erc.adecuado.calorias }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.carbohidratos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.lipidos }}</label>
                    </div>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control m-0">{{ suma_total_pa_masculino_erc.adecuado.proteinas }}</label>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Metabolismo Basal</th>
                <th class=" font-weight-bold">Valor</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Kcal/Dia Basal
                    <i class="fas fa-info-circle text-primary" 
                       v-tooltip="'Este valor se calcula automáticamente al cambiar el peso (lb/kg) y talla en la sección de Evaluación'"></i>
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.kcal_basal }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="font-weight-bold" colspan="2">
                    Gasto calorico total
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Sedentario
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_sedentario }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Moderado
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_moderada }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    AF. Severa
                </td>
                <td>
                    <div class="form-group">
                        <label type="text" class="form-control">
                            {{ metabolismo_basal_merc.af_severa }}
                        </label>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Tiempo para lograr la meta
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="metabolismo_basal_merc.tiempo_meta" @change="saveMetaBasal('metabolismo_basal_merc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <table class="table table-sm col-12 col-lg-6">
        <thead>
            <tr class=" font-weight-bold">
                <th class=" font-weight-bold">Sumatoria 6 pliegues</th>
                <th class=" font-weight-bold">
                    {{ sumatoria_seis_pliegues_merc.total }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="">
                    Triceps
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.triceps" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Subescapular
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.subescapular" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Supraespinal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.supraespinal" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Abdominal
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.abdominal" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Muslo anterior
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.muslo_anterior" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="">
                    Pantorrilla
                </td>
                <td>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="sumatoria_seis_pliegues_merc.pantorrilla" @change="savePlieges('sumatoria_seis_pliegues_merc')"/>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    </div>

   </box>
   <box title="Recomendaciones y Observaciones" icon="fas fa-comments" :contraction='true'>

    <div class="row">
            <div class="col-12">
                <div class="form-group w-100">
                    <label>Recomendaciones</label>
                    <textarea v-model="extra.reconmendaciones" class="form-control" placeholder="Agregar recomendación..." style="min-height: 250px" @change="saveExtra"></textarea>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group w-100">
                    <label>Observaciones</label>
                    <textarea v-model="extra.observaiones" class="form-control" placeholder="Agregar observaciones..." style="min-height: 250px" @change="saveExtra"></textarea>
                </div>
            </div>
        </div>
   </box>
</template>
 
 
<style scoped>
</style>
