import OrderForm from "./views/OrderForm";
import POS from "./views/POS/POS.vue";
import Order from "./views/Order/Order";
import ListadoSuscripciones from "./views/Subscription/ListadoSuscripciones";
import NuevaSubscription from "./views/Subscription/NewSubscription.vue";
import OrderDetail from "./views/Order/OrderDetailForm";
import FirmarOrden from "./views/Subscription/sign-order.vue";
import procesarOrdenes from "./views/Subscription/procesar-polizas.vue";
import preRegistro from "./views/PreRegistro.vue";
//import Medico from "../medico/views/Record/OrderDetailForm";
//import Medico from "../medico/views/Record/OrderDetailForm";
import ReservaForm from "./views/Reservas/OrderDetailForm";
import ReservaList from "./views/Reservas/Reserva";
import ReservasForms from "./views/Reservas/ReservasForms";
import AplicarCuotas from "./views/Subscription/AplicarCuotas.vue";

import CodigoPromoList from "./views/CodigoPromoList.vue";
import CodigoPromoCreate from "./views/CodigoPromoCreate.vue";
import ORderFacturacionMasiva from "./views/Order/ORderFacturacionMasiva.vue";

export default [
    {
        path: "/order/creation",
        component: OrderForm,
    },
    {
        path: "/order/POS",
        component: POS,
    },
    {
        path: "/order/edit/:id",
        component: OrderForm,
    },
    {
        path: "/orders",
        component: Order,
    },
    {
        path: "/orders/generated",
        component: Order,
    },
    {
        path: "/orders/subscriptions",
        component: ListadoSuscripciones,
    },
    {
        path: "/orders/subscriptions/new/:id",
        component: NuevaSubscription,
    },
    {
        path: "/order/view/:id",
        component: OrderDetail,
    },
    {
        path: "/reserva/view/:id",
        component: ReservaForm,
    },
    {
        path: "/orders/reservas",
        component: ReservaList,
    },
    {
        path: "/orders/reservas/forms/:id",
        component: ReservasForms,
    },
    {
        path: "/orders/cod-promo-list",
        component: CodigoPromoList,
    },
    {
        path: "/orders/cod-promo/:id",
        component: CodigoPromoCreate,
    },
    {
        path: "/orders/massive-invoce",
        component: ORderFacturacionMasiva,
    },
    {
        path: "/suscripciones/massive-payments",
        component: AplicarCuotas,
    },
    {
        path: "/orders/sign-order",
        component: FirmarOrden,
    },
    {
        path: "/orders/order-process",
        component: procesarOrdenes,
    },
    {
        path: "/pre-registro",
        component: preRegistro,
        meta: {
            isPublic: true
        }
    }
];
