<template>
    <div class="AuthContainer">
        <div class="container h-100">
            <div class="justify-content-center h-100 align-items-center">
                <div class="authWidthContainer">
                    <div class="mini-logo text-center my-5">
                        <router-link to="/">
                            <img v-if="!StoreInfo.uriTienda" class="logoBrand" :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre"/>
                            <img v-if="StoreInfo.uriTienda" class="logoBrand" :src="StoreInfo.logoTienda" :alt="StoreInfo.logoTienda"/>
                        </router-link>
                    </div>
                    <div v-if="!registrado">
                        <div v-if="tipoRegistro === ''">
                            <div class="text-center mb-3">
                                <h5>Pre-registro mediante</h5>
                            </div>
                            <button class="btn btn-primary w-100 mb-2" @click="tipoRegistro = 'orden'">Código de entrada</button>
                            <button class="btn btn-primary w-100" @click="tipoRegistro = 'promo'">Código promocional</button>
                        </div>
                        <div v-else>
                            <div v-if="(tipoRegistro === 'orden' && !token) || (tipoRegistro === 'promo' && !validado)" class="text-center">
                                <div class="form-group">
                                    <div v-if="tipoRegistro === 'orden'">
                                        <label>Código de orden (entrada)</label>
                                        <input type="text" class="form-control" placeholder="0000000000-0" name="no_orden" v-model="orden"/>
                                    </div>
                                    <div v-if="tipoRegistro === 'promo'">
                                        <label>Código promocional</label>
                                        <input type="text" class="form-control" placeholder="Escribe aquí" name="no_orden" v-model="orden"/>
                                    </div>
                                </div>
                                <button @click="validar" class="btn btn-primary btn-block">
                                    <i class="fas fa-search mr-2"></i>Validar
                                </button>
                            </div>
                            <div v-else class="text-center mt-2">
                                <h5>Datos de registro</h5>
                                <input class="form-control mt-2" v-model="nombre" placeholder="Nombre">
                                <input class="form-control mt-2" v-model="apellido" placeholder="Apellido">
                                <input class="form-control mt-2" v-model="telefono" placeholder="Teléfono">
                                <input class="form-control mt-2" v-model="telefono_oficina" placeholder="Teléfono Oficina">
                                <input class="form-control mt-2" v-model="correo" placeholder="Correo electrónico">
                                <input class="form-control mt-2" v-model="compania" placeholder="Compañía o empresa">
                                <input class="form-control mt-2" v-model="puesto" placeholder="Rol o puesto">
                                <input class="form-control mt-2" v-model="direccion" placeholder="Dirección">
                                <button @click="registrar" class="btn btn-primary btn-block mt-3">
                                    <i class="fas fa-save mr-2"></i>Guardar registro
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-4 mt-3 text-center">{{msg}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="appVersion">
        V.{{appVersion}}
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from 'src/store';
//import controlPanelPopupStyle from "../../../assets/controlPanel/css/popup.lazy.css";
//import controlPanelStyle from "../../../assets/controlPanel/css/style.lazy.css";
// import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "/src/layout/admin/assets/css/Components.lazy.css";

import DataValidation from "src/core/DataValidation";
import AndroidBridge from "src/core/AndroidBridge";

export default {
    name: "Signin",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            validado: false,
            orden: '',
            number: '',
            tipoRegistro: '',
            token: false,
            msg: '',
            registrado: false,

            // registro
            nombre: '',
            apellido: '',
            telefono: '',
            telefono_oficina: '',
            correo: '',
            compania: '',
            puesto: '',
            direccion: '',
        };
    },
        beforeCreate() {
            //controlPanelStyle.use();
            //controlPanelPopupStyle.use();
            // publicStore.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        // publicStore.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.appVersion = CONFIG.appVersion;
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        validar() {

            const self = this;

            store.dispatch('coreShowLoading');

            fetch(CONFIG.getWsUrl('orders/pre-registro/validar'), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                },
                body: JSON.stringify({
                    orden: self.orden,
                    tipoRegistro: self.tipoRegistro,
                    wl: btoa(store.getters.whiteLabelInfo.id),
                }),
            })
                .then(response => response.json())
                .then(data => {
                    store.dispatch('coreHideLoading');
                    if (data.status) {
                        self.msg = data.msg;
                        self.token = (typeof data.data.t !== 'undefined' ? data.data.t : false);
                        self.number = (typeof data.data.number !== 'undefined' ? data.data.number : false);
                        self.validado = true;
                    }
                    else {
                        self.msg = data.msg;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    store.dispatch('coreHideLoading');
                    self.token = false;
                    self.msg = 'Error de conexión, por favor intente de nuevo';
                });
        },
        registrar() {

            const self = this;

            store.dispatch('coreShowLoading');

            fetch(CONFIG.getWsUrl('orders/pre-registro/guardar'), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                },
                body: JSON.stringify({
                    wl: btoa(store.getters.whiteLabelInfo.id),
                    number: self.number,
                    ordenToken: self.token,
                    codigoExterno: self.orden,
                    tipoRegistro: self.tipoRegistro,
                    nombre: self.nombre,
                    apellido: self.apellido,
                    telefono: self.telefono,
                    telefono_oficina: self.telefono_oficina,
                    correo: self.correo,
                    compania: self.compania,
                    puesto: self.puesto,
                    direccion: self.direccion,
                }),
            })
                .then(response => response.json())
                .then(data => {
                    store.dispatch('coreHideLoading');

                    if (data.status) {
                        self.msg = data.msg;
                        self.registrado = true;
                    }
                    else {
                        self.msg = data.msg;
                    }
                })
                .catch((e) => {
                    console.log(e);
                    store.dispatch('coreHideLoading');
                    self.msg = 'Error al realizar registro, por favor intente de nuevo';
                });
        },
    },
};
</script>
